import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vn-vo-label',
  templateUrl: './vn-vo-label.component.html',
  styleUrls: ['./vn-vo-label.component.css']
})
export class VnVoLabelComponent {
  @Input() type! : number;
}
