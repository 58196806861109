import { BaseModule, ModuleDetails } from "./Factory/BaseModule";
import { M_MTRModule } from "./M_MTRModule";

export class M_RecambiosModule extends BaseModule {
    get name() { return "Recambios"; }
    get corename() { return "recambios"; }
    get title() { return "El módulo que todo recambista necesita"; }
    get desc() { return "Simplifica la gestión de repuestos, reduce errores y mantén todo bajo control con nuestro módulo avanzado."; }
    override isMTR(): this is M_MTRModule { return false; }

    generateFuncionalities() {
        return [
            { icon: "local_shipping", title: "Proveedores", tooltip: "Crear contactos de tipo 'proveedor'" },
            { icon: "storefront", title: "Almacenes", tooltip: "Crear almacenes" },
            { icon: "production_quantity_limits", title: "Reservas/faltas", tooltip: "Gestionar reservas y faltas de productos" },
            { icon: "assignment", title: "Pedidos/albaranes", tooltip: "Generación de pedidos y albaranes" },
        ];
    }

    generateModuleDetails(): ModuleDetails[] {
        return [
            {
                text: `Con el módulo de recambios, dispondrás de un nuevo apartado en el menú llamado "Almacén", que incluye una amplia variedad de funcionalidades. ¡No dudes en explorarlo! 👀"`,
                scrType: "img",
                src: `${this.basePath}/recambios1.png`
            },
            {
                text: "El módulo trae de todo 🤯 : control de faltas, pedidos y entradas de almacén. ¡Todo lo que un recambista necesita para no perderse nada!",
                scrType: "img",
                src: `${this.basePath}/recambios2.png`
            }
        ]
    }
}