import { Component, Inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateProductComponent, I_ProductEdit } from 'src/app/views/create-product/create-product.component';
import { M_Product } from 'src/app/models/Products/M_Product';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { CreateCustomProductComponent } from '../create-custom-product/create-custom-product.component';
import { ICustomProductData } from 'src/app/interfaces/ICustomProductData';
import { DragGroupComponent } from 'src/app/components/drag/drag-group/drag-group.component';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { CreateInvoiceComponent } from 'src/app/views/create-invoice/create-invoice.component';
import { ProductLineTableComponent } from '../product-line-table/product-line-table.component';
import { AddRemoveComponent } from 'src/app/views/products/add-remove/add-remove.component';

@Component({
  selector: 'app-product-line-title',
  templateUrl: './product-line-title.component.html',
  styleUrls: ['./product-line-title.component.css']
})


export class ProductLineTitleComponent {
  @Input({ required: true }) product!: M_Product | M_CustomProduct;
  @Input({ required: true }) addRemove!: AddRemoveComponent;
  @Input({ required: true }) clickable!: boolean;
  constructor(@Inject(ProductLineTableComponent) public parent: ProductLineTableComponent, private d: MatDialog, public companyS: CompanyService) {
  }

  getRef() {
    if (this.product instanceof M_Product) {
      return (!this.product.ref_factory ? "Ref : " : "Ref. fab. : ") + this.product.smartRef(this.companyS.recambiosModule);
    }
    else if (this.product instanceof M_CustomProduct) {
      let title;
      if(this.product.reference){
        return " Ref : " + this.product.reference + "";
      }
      if(this.product.isTime){
        title = this.product.name + ' | ' + this.product.price + "€/hora";
      }
      else if(this.product.isComment){
        title = this.product.comment;
      }
      else{
        return "Producto personalizado";
      }
      return title
     
    }
    else return "Producto personalizado"
  }

  editProductDialog() {

    const product = this.product;
    if (!this.parent.canModifyTable || product == undefined || !this.clickable) { return; }

    /** Product */
    if (product instanceof M_Product) {
      this.d.open<CreateProductComponent, I_ProductEdit>(CreateProductComponent, {
        data: {
          product: product,
          action_id: this.parent.parent instanceof DragGroupComponent ? this.parent.parent.group.action_id : undefined,
          draft_id: this.parent.parent instanceof CreateInvoiceComponent ? this.parent.parent.currentBill?.id : undefined
        }, autoFocus: false
      }).afterClosed().subscribe(res => {
        if (res instanceof M_Product) {
          this.refreshProductOnParent(res, product);
        }
      });
    }
    else if (product instanceof M_CustomProduct) {
      /** Custom Porduct */
      if (product.isProduct) {

        this.d.open<CreateCustomProductComponent, ICustomProductData>
          (CreateCustomProductComponent,
            {
              autoFocus: false,
              data: {
                inputValue: undefined,
                interno: this.parent.isInterno,
                product: product,
              }
            }).afterClosed().subscribe(res => {
              if (res instanceof M_CustomProduct) {
                if (this.product instanceof M_CustomProduct) {
                  this.product.copyCoreAttributes(res);
                  this.addRemove.setValue(res.quantity);
                  this.product!.line_hasChanges = true;
                }
              }
            });
      }
    /**Comment */
    else if(this.product.isComment){
      this.addRemove.openCommentDialog();
    }
      /** Time */
      else {
        this.addRemove.openTimeDialog();
      }
    }
  }

  refreshProductOnParent(dialogPorduct: M_Product, beforeProduct: M_Product) {
    /** We copy the core/edited attributes of the dialog product */
    beforeProduct.copyCoreAttributes(dialogPorduct);
    /** Sync the product on the screen */
    this.parent.onEditProduct(beforeProduct);
  }
}