<div class="advanced-details-container">
    <!-- <form eForm class="reports-form" [formGroup]="form">
        <div eForm-section>
            <mat-form-field appearance="outline">
                <mat-label>Centro</mat-label>
                <mat-select formControlName="center">
                    <mat-option *ngFor="let center of companyS.company.centers" [value]="center.id">
                        {{ center.name }}
                    </mat-option>
                    <mat-option [value]="'all'">Todos</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div eForm-section>
            <mat-form-field appearance="outline">
                <mat-label>Desde</mat-label>
                <input matInput [matDatepicker]="picker_1" [max]="dataEnd.value" [formControlName]="'dataIni'"
                    placeholder="dd/mm/yyyy" (click)="picker_1.open()">
                <mat-datepicker-toggle matSuffix [for]="picker_1"></mat-datepicker-toggle>
                <mat-datepicker #picker_1></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Hasta</mat-label>
                <input matInput [matDatepicker]="picker_2" [min]="dataIni.value" [formControlName]="'dataEnd'"
                    placeholder="dd/mm/yyyy" (click)="picker_2.open()">
                <mat-datepicker-toggle matSuffix [for]="picker_2"></mat-datepicker-toggle>
                <mat-datepicker #picker_2></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="df jcc">
            <button mat-flat-button color="primary" [disabled]="!form.valid" (click)="refresh()">
                Actualizar
            </button>
        </div>

    </form>

    <mat-divider class="mt10 mb10"></mat-divider> -->
    <to-do></to-do>

    <app-view-title>Informes</app-view-title>

    <div class="reports">
        <!-- <app-report [name]="'Gestión'" [filters]="[]" [results]="gResults"></app-report> -->
        <app-report [report]="rTaller"></app-report>
        <app-report [report]="rTaller"></app-report>
        <app-report [report]="rTaller"></app-report>
        <app-report [report]="rTaller"></app-report>
        <app-report [report]="rTaller"></app-report>
        <!-- <app-report comercial [name]="'Comercial'" [filters]="[]" [results]="cResults"></app-report>
        <app-report recambios [name]="'Almacén'" [filters]="[]" [results]="aResults"></app-report>
        <app-report [name]="'Finanzas'" [filters]="[]" [results]="fResults"></app-report> -->
    </div>
</div>