export function toFormData<T>(formValue: any) {
    const formData = new FormData();
    for (const key of Object.keys(formValue)) {
        if (key != "needsToBeParsed") {
            let value = (<any>formValue)[key];
            if (value != "null") {
                formData.append(key, value);
            }
            
        }
    }
    return formData;
}