<app-card [noStyle]="true">
  <div class="df aic">
    <app-card-subtitle>{{filter.label}}</app-card-subtitle>
  </div>
  <!--<ngx-slider 
    (userChange)="onInputChange($event)" 
    [value]="filter.minval" [highValue]="" [options]="{floor: 0 , ceil: filter.max}">
  </ngx-slider>!-->
  <mat-slider [min]="0" [max]="filter.max" thumbLabel discrete [color]="'accent'" class="range-slider-filter">
    <input [value]="filter.minval" matSliderStartThumb (change)="onChangeMin($event)">
    <input [value]="filter.maxval" matSliderEndThumb (change)="onChangeMax($event)">
  </mat-slider>
</app-card>