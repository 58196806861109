import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FaultProductTableComponent } from 'src/app/components/fault-product-table/fault-product-table.component';
import { ManualFaultComponent } from 'src/app/components/manual-fault/manual-fault.component';
import { M_Fault } from 'src/app/models/M_Fault';
import { M_Product } from 'src/app/models/Products/M_Product';

@Component({
  selector: 'app-product-details-faults-dialog',
  templateUrl: './product-details-faults-dialog.component.html',
  styleUrls: ['./product-details-faults-dialog.component.css']
})
export class ProductDetailsFaultsDialogComponent {
  @ViewChild(FaultProductTableComponent) productTableFault?: FaultProductTableComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public p: M_Product, private d: MatDialog) { }
  
  openManualFault() {
    if (!this.p) { return; }
    this.d.open(ManualFaultComponent, { data: this.p }).afterClosed().subscribe(res => {
      if (res instanceof M_Fault) {
        this.p?.faults.push(res);
        this.productTableFault?.ngAfterViewInit();
      }
    })
  }
  
}
