import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { M_User } from 'src/app/models/M_User';

@Component({
  selector: 'app-change-shared-user',
  templateUrl: './change-shared-user.component.html',
  styleUrls: ['./change-shared-user.component.css']
})
export class ChangeSharedUserComponent {
  constructor(
    public dialogRef: MatDialogRef<ChangeSharedUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: M_User[]
  ) { }

  selectUser(user: M_User): void {
    // Aquí puedes manejar la selección de usuario y cerrar el diálogo.
    this.dialogRef.close(user);
  }
}
