import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/Api/api.service';
import { DiscountMonthlyComponent } from '../discount-monthly/discount-monthly.component';
import { SnackService } from 'src/app/services/snack.service';
export interface SendMailCodePromotionalComponentInterface {
  codePromotional: string;
  type: string
}
@Component({
  selector: 'app-send-mail-code-promotional',
  templateUrl: './send-mail-code-promotional.component.html',
  styleUrls: ['./send-mail-code-promotional.component.css']
})
export class SendMailCodePromotionalComponent {
  public form: UntypedFormGroup;
  constructor(private apiS:ApiService,
    public dialogRef: MatDialogRef<DiscountMonthlyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SendMailCodePromotionalComponentInterface,
    private formBuilder: UntypedFormBuilder,
    public snackS: SnackService
  ){
    this.form = this.formBuilder.group({
      email: ['',Validators.required],
      phone:['' ,
      ]
    });
    
  }
  send(){
    if(this.data.type == "email"){
       this.apiS.emailPromo(this.form.value).then(res=>{
         if(res){
           this.snackS.show("Email enviado con éxito")
         }
       });
   }
   else{
     const baseUrl = "https://api.whatsapp.com/send/?phone=34"+this.form.get('phone')?.value+"&text=Hola!"+"+Te+comparto+el+software+para+taller+EINA+que+con+este+c%C3%B3digo+promocional+podr%C3%A1s+obtener+1+mes+gratis!++c%C3%B3digo:+"+this.data.codePromotional + "+en+https://eina.sinigual.com/subscription";
     window.open(baseUrl, '_blank');
     this.snackS.show("Whatsapp enviado con éxito")
   }
 }
}
