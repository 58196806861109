<div class="container create-order" *ngIf="!orderFailedToLoad; else notfound">

    <div class="title" *ngIf="loaded">
        <app-view-title>Crear pedido</app-view-title>
    </div>

    <app-card [contentLoaded]="loaded" class="order">
        <mat-stepper linear #stepper color="primary">

            <!-- Step 1 - Creation -->
            <ng-template matStepperIcon="step1"><mat-icon>list_alt</mat-icon></ng-template>
            <mat-step [stepControl]="firstStep" [editable]="!created" state="step1">
                <input class="dn" [formControl]="firstStep" [ngModel]="preOrder.isOk()" />
                <ng-template matStepLabel>Lista los productos</ng-template>


                <!-- No faults -->
                <ng-container *ngIf="!faults.length; else faultsTag">
                    <app-card-subtitle [first]="true" [noMarginBottom]="true">Faltas</app-card-subtitle>
                    <p class="c_t2">{{no_stock_message}}</p>
                </ng-container>

                <!-- Faults -->
                <ng-template #faultsTag>
                    <div class="inside-card"
                        [ngClass]="{'no-faults' : preOrder.containsAll(faults), 'some-faults' : preOrder.containsSome(faults)}">
                        <div class="fault-header">
                            <app-card-subtitle [first]="true" [noMarginBottom]="true">Faltas</app-card-subtitle>
                            <button (click)="preOrder.add(faults)" mat-button
                                [disabled]="preOrder.containsAll(faultsWithProviders)"
                                color="accent"><mat-icon>keyboard_double_arrow_down</mat-icon>Añadir todo</button>
                        </div>

                        <div class="faults-body">
                            <div *ngFor="let fault of faults" mat-menu-item class="fault-menu-item"
                                (click)="fault.hasProviders ? preOrder.add([fault]) : addNewProvider(fault)">
                                <div class="df w100 aic"
                                    [ngClass]="{'list-contains' : preOrder.contains(fault), 'no-providers' : !fault.hasProviders}">
                                    <app-fault [fault]="fault" [slash]="preOrder.contains(fault)"
                                        [large]="true"></app-fault>
                                    <mat-icon class="delete-icon"
                                        [ngClass]="{'dn_i' : !fault.isManual || preOrder.contains(fault)}" prevent
                                        (click)="deleteFault(fault)">
                                        delete
                                    </mat-icon>
                                    <mat-icon *ngIf="fault.hasProviders"
                                        class="final-fault-icon">add_shopping_cart</mat-icon>
                                    <mat-icon *ngIf="!fault.hasProviders" class="final-fault-icon c_r"
                                        [matTooltip]="'Sin proveedores'" smartIcon
                                        [matTooltipPosition]="'right'">no_provider</mat-icon>
                                </div>

                            </div>
                        </div>
                    </div>
                </ng-template>




                <div class="mb20"></div>

                <app-card-subtitle>Listado</app-card-subtitle>

                <app-product-line-table [onlyProviders]="true" [products]="preOrder.products"
                    [showProductsStock]="false" 
                    [showCost]="true"
                    [showPVP]="false"
                    [lineTypes]="['product']" [showSave]="false" [showDiscount]="false"
                    [showDrag]="true" [showProviders]="true" [showPrio]="true" [extendedInfoConfig]="{
                        showColumn : true,
                        actions : ['quantity-by-stock-min']
                    }"></app-product-line-table>

                <div class="mt10 mb10"></div>

                <div class="df">
                    <button mat-flat-button class="df mla" color="primary" [disabled]="!firstStep.valid" matStepperNext>
                        Siguiente
                        <mat-icon iconPositionEnd>navigate_next</mat-icon>
                    </button>
                </div>
            </mat-step>

            <!-- Step 2 - Breakdown -->
            <ng-template matStepperIcon="step2"><mat-icon>view_agenda</mat-icon></ng-template>
            <mat-step [stepControl]="secondStep" [editable]="!created" [state]="'step2'">
                <input class="dn" [formControl]="secondStep" [ngModel]="created" />
                <ng-template matStepLabel>Desglose</ng-template>
                <app-order-breakdown-section *ngFor="let section of preOrder.getBreakdown()"
                    [section]="section"></app-order-breakdown-section>
                <div class="df jcfe">
                    <div class="df fdc">
                        <button mat-flat-button color="primary" (click)="createOrder()">
                            {{preOrder.multiple ? 'Crear pedidos' :'Crear pedido'}}
                            <mat-icon iconPositionEnd>navigate_next</mat-icon>
                        </button>
                        <span class="fsi c_t2 fss">Al proceder, se creará el pedido</span>
                    </div>
                </div>
            </mat-step>

            <!-- Step 3 - Order created -->
            <ng-template matStepperIcon="step3"><mat-icon>local_shipping</mat-icon></ng-template>
            <mat-step [state]="'step3'">
                <ng-template matStepLabel>¡Hecho!</ng-template>
                <div class="tac">
                    <p class="fsm fwb" *ngIf="preOrder.multiple">¡Pedidos generados con éxito!</p>
                    <p class="fsm fwb" *ngIf="!preOrder.multiple">¡Pedido generado con éxito!</p>
                    <p *ngFor="let order of createdOrders">
                        • {{order.order}}
                    </p>

                    <p>¿Qué quieres hacer ahora?</p>

                </div>

                <div class="end-button-actions">
                    <button mat-flat-button color="primary" (click)="routerS.refresh()">
                        <mat-icon>restart_alt</mat-icon>
                        Otro pedido
                    </button>
                    <button mat-stroked-button color="primary" (click)="routerS.goTo(v.orders)">
                        <mat-icon>low_priority</mat-icon>
                        Listado de pedidos
                    </button>
                </div>

            </mat-step>

        </mat-stepper>
    </app-card>
</div>

<ng-template #notfound>
    <app-model-not-found [title]="'No se ha encontrado el pedido'" [goText]="'Ir al listado de pedidos'"
        [view]="v.workshopBudget">
    </app-model-not-found>
</ng-template>