import { Component } from '@angular/core';

@Component({
  selector: 'app-roles-explanation',
  templateUrl: './roles-explanation.component.html',
  styleUrls: ['./roles-explanation.component.css']
})
export class RolesExplanationComponent {

}
