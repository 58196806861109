import { CdkDragStart } from "@angular/cdk/drag-drop";
import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Filter } from "src/app/custom-classes/Filter";
import { or_status_invoiced } from "src/app/custom-classes/or_states";
import { WorkloadData } from "src/app/custom-classes/WorkloadData";
import { AppointmentStatus } from "src/app/enums/AppointmentStatus";
import { PeriodEnum } from "src/app/enums/PeriodEnum";
import { M_Action } from "src/app/models/M_Action";
import { M_Appointment } from "src/app/models/M_Appointment";
import { M_User } from "src/app/models/M_User";
import { RouterService } from "src/app/services/router.service";
import { WorkloadDataGetterService } from "src/app/services/workload-data-getter.service";
import { getPrimaryColor } from "src/app/utils/FunctionUtils";
import { filterAppointment, filterOR } from "src/app/utils/WorkloadUtils";
import { EditOrComponent } from "src/app/views/or-create-edit/edit-or/edit-or.component";

@Component({
    selector: 'app-grid-card',
    templateUrl: './grid-card.component.html',
    styleUrls: ['./grid-card.component.css', './grid-week.css', './grid-month.css',]
})
export class GridCardComponent implements OnInit {
    /**[AppointmentInterface, ("ra" |"eliminar" | "done" | "hour")] */
    @Output() onModifyAppointment: EventEmitter<[M_Appointment, ("hour")]> = new EventEmitter();
    @Output() onRemoveHighlight: EventEmitter<any> = new EventEmitter();
    @Output() onStartDragging: EventEmitter<M_Action | M_Appointment> = new EventEmitter();
    @Output() deliveryWarn: EventEmitter<M_Action> = new EventEmitter();
    @Input() filters: Filter[] = [];
    @Input() searchFilter: string = "";
    @Input() allUsers: M_User[] = []; //Menu that allow  to change the worker assigned to the or
    @Input() period!: PeriodEnum;
    @Input() item!: M_Action | M_Appointment;
    @Input() draggable: boolean | undefined = undefined;
    @Input() otherCardIsDragging: boolean = false;
    @Input() isOnMatMenu = false;
    @Input() workloadData?: WorkloadData;
    @Input() highlightId: number | undefined;
    @Input() day!: Date;
    pe = PeriodEnum;
    onfilters = false;

    constructor(private dataGetter: WorkloadDataGetterService, private routerS: RouterService, private d: MatDialog) { }
    ngOnInit(): void { }

    /** First we check the input. */
    get canDrag() {
        if (this.isAppointmentItem(this.item)) {
            return this.item.status == AppointmentStatus.pending;
        }
        var draggableByWorkloadData = this.getWDDraggableSettings();
        return this.draggable != undefined ? this.draggable && draggableByWorkloadData : draggableByWorkloadData;
    }

    getWDDraggableSettings() {
        if (this.isOrItem(this.item)) {
            if (this.period == this.pe.WEEK) { return this.workloadData?.data.or.draggable.week; }
            else { return this.workloadData?.data.or.draggable.month; }
        }
        else {
            if (this.period == this.pe.WEEK) { return this.workloadData?.data.appointments.draggable.week; }
            else { return this.workloadData?.data.appointments.draggable.month; }
        }
    }

    onFilters() {
        if (this.isOrItem(this.item)) {
            var filtersOk = filterOR(this.item, ...this.filters);
            var searchOk = this.item.defaultSearchFilter(this.searchFilter);
            this.onfilters = filtersOk && searchOk;
            return this.onfilters;
        }
        else {
            var filtersOk = filterAppointment(this.item, ...this.filters);
            var searchOk = this.item.defaultSearchFilter(this.searchFilter);
            this.onfilters = filtersOk && searchOk;
            return this.onfilters;
        }
    }

    goEdit(id: number) {
        if (this.workloadData?.isOrView) {
            var editOr = this.workloadData?.data.or.views.editOr
            if (editOr) {
                if (editOr != "dialog") {
                    this.routerS.goWithQueryParams(editOr, { or: id })
                }
                else {
                    this.d.open(EditOrComponent, { data: id, autoFocus : false})
                }
            }
        }
        else {
            var editAppo = this.workloadData?.data.appointments.views.editAppointment;
            if (editAppo) {
                this.routerS.goWithQueryParams(editAppo, { appointment: id })
            }
        }
    }

    disabledUserDragging() {
        return !this.onfilters && this.otherCardIsDragging && this.draggable;
    }

    preventCardClick(e: Event) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    onDrag(e: CdkDragStart<M_Action | M_Appointment>) {
        this.onStartDragging.emit(this.item);
        if (this.needH()) {
            this.onRemoveHighlight.emit();
        }
    }


    onHover() {
        this.highlightId = undefined;
        if (this.needH()) {
            this.onRemoveHighlight.emit();
        }
    }

    needH() {
        return this.item.id == this.highlightId;
    }


    /** If is OR item */
    isOrItem(v: M_Action | M_Appointment): v is M_Action {
        return "images" in v;
    }


    /** If is appointment item */
    isAppointmentItem(v: M_Action | M_Appointment): v is M_Appointment {
        return !this.isOrItem(v);
    }

    assignNewUser(user: M_User) {
        if (this.workloadData?.data.or.assignAction) {
            this.dataGetter.assignAction(this.workloadData?.data.or.assignAction, this.item.id, user.id!).then(res => {
                if (this.item instanceof M_Action) {
                    this.item.assigned = user;
                }
            });
        }
    }

    assignNewUserAppointment(user: M_User) {
        if (this.workloadData?.data.appointments.assignUserAppointment) {
            this.dataGetter.assignAppointment(this.workloadData?.data.appointments.assignUserAppointment, this.item.id, user.id!).then(res => {
                if (this.item instanceof M_Appointment) {
                    this.item.receptor_user = user;
                }
            });
        }
    }


    get icon() { return this.isOrItem(this.item) ? "build" : "event" }
    get isWeek() { return this.period == this.pe.WEEK; }
    get isMonth() { return this.period == this.pe.MONTH; }
    get invoiced() {
        if (this.isOrItem(this.item)) {
            return this.item.status.num == or_status_invoiced.num
        }
        return false;
    }
}