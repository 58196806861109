<span mat-menu-item (click)="onClickOption.emit(t_n.num)" class="chip-option">
    <app-or-type-label [pointer]="true" [tipo]="[t_n]" [showText]="false"></app-or-type-label>
</span>

<span *ngIf="showInterno" mat-menu-item (click)="onClickOption.emit(t_ci.num)" class="chip-option">
    <app-or-type-label [pointer]="true" [tipo]="[t_ci]" [showText]="false"></app-or-type-label>
</span>

<span mat-menu-item (click)="onClickOption.emit(t_g.num)" class="chip-option">
    <app-or-type-label [pointer]="true" [tipo]="[t_g]" [showText]="false"></app-or-type-label>
</span>

<span mat-menu-item (click)="onClickOption.emit(t_s.num)" class="chip-option" *ngIf="!isAlbaran">
    <app-or-type-label [pointer]="true" [tipo]="[t_s]" [showText]="false"></app-or-type-label>
</span>

<span mat-menu-item (click)="onClickOption.emit(t_m.num)" class="chip-option" *ngIf="!isAlbaran">
    <app-or-type-label [pointer]="true" [tipo]="[t_m]" [showText]="false"></app-or-type-label>
</span>