import { M_Schedule } from "./M_Schdeule";
import { M_SigningTime } from "./M_SigningTime";

export class M_Signing {

    now: M_SigningTime;
    schedule: M_Schedule[] = [];
    history: M_SigningTime[] = [];

    constructor(d: any) {
        this.now = d.now;
        this.schedule = d.schedule;
        this.history = [
            this.now
        ];
    }
}