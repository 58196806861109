<table [class]="'breakdown no-pointer ' +  alignment" *ngIf="invoice && invoice.getTotalBreakdown(fixedTotals) as totals">
    <tr>
        <td class="fw400 total">
            Subtotal
            <p class="fss fsi nmb" *ngIf="totals.client_excent_iva">Cliente exento de IVA</p>
        </td>
        <td><span class="c_p fw600" money [val]="totals.subtotal"></span></td>
    </tr>
    <tr *ngFor="let row of totals.iva_rows">
        <td *ngIf="row.iva != 0" class="fw400 total">IVA ({{row.iva}}%)</td>
        <td *ngIf="row.iva == 0" class="fw400 total">EXENTO</td>
        <td><span class="c_p fw600" money [val]="row.iva == 0? row.subtotal : row.total"></span></td>
    </tr>
    <!-- change iva code-->
    <!--
        <tr>
            <td class="fsm fw500 total">
            <span class="c_p fsxs mr5 cp" (click)="openChangeIva()">Modificar</span> IVA ({{iva.value}}%) :
            </td>
            <td><span class="c_p fw400">{{bc.iva}} €</span></td>
        </tr>
    -->
    <tr>
        <td class="fsm fw500 total">Total</td>
        <td><span class="c_p fw600" money [val]="totals.total"></span></td>
    </tr>
</table>