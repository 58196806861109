import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Views } from '../custom-classes/View';
import { RouterService } from '../services/router.service';

@Directive({
  selector: '[userCanGo]'
})
export class UserCanGoDirective implements OnInit {

  @Input('userCanGo') view!: Views | undefined;
  
  constructor(private el: ElementRef<HTMLElement>, private routerS: RouterService) { }

  ngOnInit(): void {
    if (this.view && !this.routerS.userCanGo(this.view)) {
      this.el.nativeElement.classList.add("dn_i");
    }
  }

}
