<div class="advanced-details-container">

    <app-view-title>
        Crear factura compra
    </app-view-title>
    <app-card>

        <mat-tab-group mat-align-tabs="center" [color]="'primary'" (selectedTabChange)="onTabChange($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">archive</mat-icon>
                    Compra recambio
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">garage</mat-icon>
                    Compra vehículo
                </ng-template>
            </mat-tab>
        </mat-tab-group>
        <div eForm-wrapper>
            <app-sell-vehicle-form [notVehicle]="selectedTab === 1"
                [purchaseType]="selectedTab"></app-sell-vehicle-form>
        </div>
        <div class="df aic jcc mt10">
            <button mat-flat-button [disabled]="!sellForm.form.valid" color="primary" (click)="create()">Crear factura
                compra</button>
        </div>

    </app-card>
</div>