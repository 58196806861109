<app-close-dialog-button></app-close-dialog-button>
<app-card-title mat-dialog-title>Enviar Código Promocional</app-card-title>

<div mat-dialog-content class="tac">
    <p>Se enviará el código promocional: <strong><u>{{data.codePromotional}}</u></strong></p>
    <form [formGroup]="form">
    <mat-form-field class="w100 " appearance="outline">
        <mat-label>{{data.type  === "email" ? "Email": "Whatsapp"}}</mat-label>
        <input matInput formControlName="email" placeholder="Email" *ngIf="data.type === 'email'"/>
        <input matInput formControlName="phone" placeholder="Whatsapp" *ngIf="data.type === 'whatsapp'"/>
        <button [disabled]="true" mat-icon-button matSuffix>
        <mat-icon>send</mat-icon>
        </button>
    </mat-form-field>
    </form>
</div>
<div mat-dialog-actions style="justify-content: center;">
    <button color="primary" mat-flat-button [mat-dialog-close]="true" (click)="send()"><mat-icon>send</mat-icon>{{data.type =="email"?"Enviar email":"Enviar Whatsapp"}}</button>
</div>