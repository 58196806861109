import { ChangeDetectorRef, Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CustomFile } from 'src/app/custom-classes/CustomFile';
import { M_Company } from 'src/app/models/M_Company';
import { R_Zip } from 'src/app/models/R_Zip';
import { ApiService } from 'src/app/services/Api/api.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { FormService } from 'src/app/services/form.service';
import { RouterService } from 'src/app/services/router.service';
import { SessionService } from 'src/app/services/session.service';
import { SnackService } from 'src/app/services/snack.service';
import { toFormData } from 'src/app/utils/MultipartFormData';
import { DeleteCompanyComponent } from 'src/app/views/profile/delete-company/delete-company.component';
import { DocumentationColorPickerComponent } from '../documentation-config/documentation-color-picker/documentation-color-picker.component';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { CompanyTabComponent } from '../company-tab.component';
import { ModulesEnum } from 'src/app/enums/ModulesEnum';
import { ViewPath } from 'src/app/app-routing.module';
import { COMPANY_SUBTABS, SETTINGS_TAB } from '../../settings.component';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.css']
})
export class CompanyFormComponent {

  @ViewChild(FileUploadComponent) upload!: FileUploadComponent;
  @ViewChild(DocumentationColorPickerComponent, { static: true }) cp!: DocumentationColorPickerComponent;
  form: UntypedFormGroup;
  initialCCAA: number | undefined;
  arrayZips: R_Zip[] | undefined = [];
  c: M_Company | undefined;
  imgSaved = true;
  MODULES = ModulesEnum;
  v = ViewPath;
  info = false;

  constructor(private formBuilder: UntypedFormBuilder, private fs: FormService,
    private snack: SnackService, public routerS: RouterService,
    private chdRef: ChangeDetectorRef,
    private sessionS: SessionService,
    private confirmD: ConfirmDialogService,
    private d: MatDialog, private apiS: ApiService, public companyS: CompanyService, public userS: UserService,
    @Inject(CompanyTabComponent) public companyTab: CompanyTabComponent) {
    this.form = this.formBuilder.group({
      cif: ['', [Validators.required]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      address: ['', [Validators.required]],
      address2: [''],
      zip: ['', [Validators.required]],
      city: ['', [Validators.required]],
      ccaaObject: ['', [Validators.required]],
      ccaa: ['', [Validators.required]],
      province: ['', [Validators.required]],

    });
  }


  initTab(c: M_Company) {
    this.c = c;
    this.initialCCAA = c.ccaa;
    this.fillColors(this.c);
    if (c.logo) {
      this.upload.setImageFromDatabase(c.logo);
    }
    this.form.patchValue(c);
    this.form.get('province')?.disable();
    this.form.get('ccaaObject')?.disable();
  }


  /** Fills the colors of the company */
  private fillColors(c: M_Company) {
    this.cp.fillValues(c.primary_color, c.secondary_color)
  }

  /** Check if there is some change on the colors inputs */
  saveColorDisabled() {
    return !this.cp.touched;
  }

  onFileupload(c: CustomFile) {
    this.c!.logo = c.src;
    this.imgSaved = false;
  }

  makeCall() {
    if (this.fs.isOk(this.form)) {

      if (!this.form.get("address2")?.value) {
        this.form.get("address2")?.patchValue("");
      }

      let formData = toFormData(this.form.getRawValue());
      var f = this.upload.getUploadedFile();
      if (f) {
        formData.set("logo", f)
      }

      formData.append("primary_color", this.cp.getPrimary().value)
      formData.append("secondary_color", this.cp.getPrimary().contrastValue)

      this.apiS.updateCompany(formData).then(res => {
        this.c!.template_fields = res.template_fields;
        this.companyTab.documentationTab.fillTemplateFields(this.c!.template_fields);
        this.showCCAAChangeDialog(formData);
        this.imgSaved = true;
        this.cp.touched = false;
        this.companyS.setNewCompany(res);
        this.snack.show("Datos de empresa guardados con éxito")
      })
    }
  }

  showCCAAChangeDialog(formData: FormData) {
    let formCCAA = formData.get('ccaa')?.toString().getNumber();
    if (formCCAA && formCCAA != this.initialCCAA) {
      this.confirmD.show({
        title: "Cambio de comunidad autónoma",
        body: "Te recomendamos echar un vistazo a los campos adicionales de la pestaña 'Documentos'.",
        confirmTxt: "¡Vamos allá!",
        showCancel: true,
        type: "info"
      }).afterClosed().subscribe(res => {
        if (res) {
          this.routerS.goWithQueryParams(ViewPath.settings, {"tab" : SETTINGS_TAB.COMPANY, "subtab" : COMPANY_SUBTABS.DOCUMENTATION});
        }
      })
    }
    this.initialCCAA = formCCAA;
  }


  hasInfo(): boolean {
    if (this.c != undefined) {
      return !this.c.isMissingInfo();
    }
    return false;
  }
  
  decimalFilter(event: any) {
    const reg = /^-?\d*([,.]\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }
  deleteCompany() {
    let title_ = "Eliminar empresa";
    let body_ = '¿Está seguro de que desea eliminar la empresa?';
    this.confirmD.show({ title: title_, body: body_, type: "danger" }).afterClosed().subscribe(confirmResult => {
      if (confirmResult) {
        this.d.open(DeleteCompanyComponent, { panelClass: "core-error-dialog" }).afterClosed().subscribe(deleteResult => {
          if (deleteResult == true) {
            this.apiS.deleteCompany().then(res => {
              if (res) {
                this.sessionS.logOut();
              } else {
                this.confirmD.showError("Error al eliminar la empresa", "Ponte en contacto con nosotros para solventar este problema");
              }
            });
          }
        });
      }
    });
  }


}
