import { ViewPath } from 'src/app/app-routing.module';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Product } from 'src/app/models/Products/M_Product';
import { ParamsService } from 'src/app/services/params.service';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { RouterService } from 'src/app/services/router.service';
import { MatDialog } from '@angular/material/dialog';
import { AllProductProvidersComponent } from './all-product-providers/all-product-providers.component';

@Component({
  selector: 'app-product-providers-row',
  templateUrl: './product-providers-row.component.html',
  styleUrls: ['./product-providers-row.component.css']
})
export class ProductProvidersRowComponent {
  MAX_RESULTS = 2;
  @Input({ required: true }) providers!: M_Contact[];

  constructor(private d: MatDialog) { }

  openAllProviders() {
    this.d.open(AllProductProvidersComponent, { data: this.providers, autoFocus: false })
  }
}
