<div class="form-field-simulation" [ngClass]="{'disabled': fc.disabled, 'error': error}">
    <div class="df jcfs field-content" (click)="createChange()">
        <mat-icon [ngClass]="{'c_r': error}" style="min-width: 24px;">location_on</mat-icon>
        <div class="df fdc">
            <p class="nmb selected-name">{{selectedName ? selectedName : 'Dirección de envío '}}
                {{required && !selectedName? '*' : ''}}</p>
            <p class="c_t2 nmb full-address">{{fullAddress}}</p>
        </div>
        <button type="button" mat-button class="mla df aic" *ngIf="canChange"
            [color]="error ? 'warn' : 'primary'" [disabled]="!canChange">Guardar</button>
    </div>
</div>