import { AddressToString, getTextAddress } from "./M_Address";
import { M_AppointmentConfig } from "./M_AppointmentConfig";
import { M_StoreHouse } from "./M_StoreHouse";
import { getArrayOf } from "../utils/FunctionUtils";
import { generateHoliday } from "../utils/functions";
import { environment } from "src/environments/environment";
import { ViewPath } from "../app-routing.module";
import { M_Schedule } from "./M_Schdeule";

export type type_payment_center = {
    payment_id: number,
    accounting: string;
}

export class M_Center implements AddressToString {

    id: number;
    urlToken: string;
    url: string;
    name: string;
    address: string;
    address2: string;
    zip: string;
    city: number;
    province: string;
    workshop: boolean;
    storehouse: boolean;
    boutique: boolean;
    created_at: Date | undefined;
    updated_at: Date | undefined;

    workshopConfig: M_AppointmentConfig | undefined;
    holidays: Date[] = [];
    storehouses: M_StoreHouse[] = [];
    schedule: M_Schedule[] = [];
    exceptions: M_Schedule[] = [];
    type_payment_center : type_payment_center[] = [];

    /** To do centers  */
    /** El calendario y la configuración de taller citas va por centro! */

    constructor(d: any) {
        this.id = d.id;
        this.urlToken = d.url;
        this.name = d.name;
        this.address = d.address;
        this.address2 = d.address2;
        this.zip = d.zip;
        this.city = d.city;
        this.province = d.province;
        this.workshop = d.workshop;
        this.storehouse = d.storehouse;
        this.boutique = d.boutique;
        this.created_at = d.created_at ? new Date(d.created_at) : undefined;
        this.updated_at = d.updated_at ? new Date(d.updated_at) : undefined;
        let appointmentConfig = d.workshopConfig || d.config_appointment;
        this.workshopConfig = appointmentConfig ? new M_AppointmentConfig(appointmentConfig) : undefined;
        if (this.workshopConfig) {
            if (d.places) { this.workshopConfig.places = d.places; }
            if (d.price_hour) { this.workshopConfig.price_hour = d.price_hour; }
        }
        this.holidays = d.holidays ? generateHoliday(d.holidays, "company") : [];
        let storehouses = d.storehouses || d.stores;
        this.storehouses = getArrayOf(M_StoreHouse, storehouses);
        this.schedule = d.schedule ? getArrayOf(M_Schedule, d.schedule) : [];
        console.log("Centerize exceptions")
        this.exceptions = d.exceptions ? getArrayOf(M_Schedule, d.exceptions) : [];
        this.type_payment_center = this.getTypePayments(d.type_payment_center);
        this.url = document.location.protocol + "//" + window.location.hostname + (environment.local ? ':4200' : '') + "/" + ViewPath.appointmentclientside.path + '?center=' + this.urlToken;

    }

    getTypePayments(payments: any) {
        let finalArray : type_payment_center[] = [];
        const p = payments;
        if (Array.isArray(p)) {
            p.forEach(payment => {
                finalArray.push({
                    payment_id : payment.payment_id,
                    accounting : payment.accounting
                })
            })
        }
        return finalArray;
    }

    toText(): string {
        return getTextAddress(this)
    }
}