import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { M_Order } from 'src/app/models/M_Order';

@Component({
  selector: 'app-all-incoming-orders-in-dialog',
  templateUrl: './all-incoming-orders-in-dialog.component.html',
  styleUrls: ['./all-incoming-orders-in-dialog.component.css']
})
export class AllIncomingOrdersInDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public orders: M_Order[]) { }
}
