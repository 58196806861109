import { M_TypePayment } from "./M_TypePayment";
import { LastMovementType } from "../enums/LastMovementType";
import { M_HistoryAdvances } from "./M_HistoryAdvances";
import { getArrayOf } from "../utils/FunctionUtils";
import { M_Contact } from "./M_Contact";
import { M_Invoice } from "./M_Invoice";
import { M_Purchase } from "./M_Purchase";
import { IPageStructureItem } from "../interfaces/IPageStructureItem";
import { match } from "../services/search.service";

export class M_LastMovements implements IPageStructureItem {
    id: number;
    concept: string;
    import: number;
    invoice_id: number;
    client_id: number;
    type_payment_id: number;
    created_at: Date;
    updated_at: Date;
    type_payment: M_TypePayment;
    type: LastMovementType;
    history_advances: M_HistoryAdvances[];
    import_origin: number;
    deleted: boolean; //Abono is 'used'
    id_movement: string;
    abono: boolean;
    id_advance: number | undefined;
    id_buytransac: number | undefined;
    contact: M_Contact | undefined;
    invoice: M_Invoice | undefined;
    purchase: M_Purchase | undefined;
    constructor(d: any) {
        this.id = d.id;
        this.concept = d.concept;
        this.import = d.import;
        this.invoice_id = d.invoice_id;
        this.client_id = d.client_id;
        this.type_payment = new M_TypePayment(d.type_payment);
        this.type_payment_id = this.type_payment.id;
        this.type = d.type;
        this.history_advances = getArrayOf(M_HistoryAdvances, d.history_advances);
        this.import_origin = d.import_origin;
        this.deleted = d.deleted;
        this.created_at = new Date(d.created_at);
        this.updated_at = new Date(d.updated_at);
        this.abono = d.abono;
        this.id_advance = d.id_advances;
        this.id_buytransac = d.id_buytransac;
        if (this.isAdvance) {
            this.id_movement = d.id_movement;
        } else {
            this.id_movement = "";
        }
        if (d.invoices) {
            this.invoice = new M_Invoice(d.invoices);
            if (this.invoice.client) {
                this.contact = this.invoice.client;
            } else {
                this.contact = undefined;
            }
        }
        else if (d.client) {
            this.contact = new M_Contact(d.client);
        }
        else if (this.contact === undefined) {

            if (d.buy_transac) {
                this.purchase = new M_Purchase(d.buy_transac);

            }
            if (this.purchase?.contact) {
                this.contact = this.purchase.contact;

            } else {
                this.contact = undefined;

            }
        }

    }
    defaultSearchFilter(text: string): boolean {
        return match(text, this.invoice?.company_scope_id, this.purchase?.num_purchase, this.typeName, this.contact?.name);
    }
    get itemId(): number | undefined {
        return this.id;
    }

    get isAdvance() {
        return this.type == LastMovementType.type_advances;
    }
    get importNatural() {
        if (this.abono) {
            return -this.import;
        }
        else if(this.typeName ==='Compra'){return -this.import;}
        return this.import;
    }
    get typeName() {
        let name = "";


        if (this.abono) { return 'Abono' }
        if (this.type == LastMovementType.type_invoice && this.invoice_id != undefined) { return 'Factura' }
        else {
            name = "Anticipo"
        }

        if (this.id_buytransac != undefined) {
            name = 'Compra'

        }
        return name;
    }

}