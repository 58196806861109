import { Component, ViewChild } from '@angular/core';
import { HolidaysScheduleCalendarComponent } from 'src/app/components/holidays-schedule-calendar/holidays-schedule-calendar.component';
import { calendarEnabled } from 'src/app/constants/constants';
import { M_Center } from 'src/app/models/M_Center';

@Component({
  selector: 'app-holidays-company',
  templateUrl: './holidays-company.component.html',
  styleUrls: ['./holidays-company.component.css']
})
export class HolidaysCompanyComponent {
  enabledCalender = calendarEnabled;
  @ViewChild(HolidaysScheduleCalendarComponent) holidays!: HolidaysScheduleCalendarComponent;

  constructor() { }
  
  initCenter(center: M_Center) {
    this.holidays.initComponent({ companyH: center.holidays, userH: [], exceptions : center.exceptions });
  }
}
