import { Component, Input, OnInit} from '@angular/core';
import { DeveloperService } from '../../services/developer.service';
import { endpointResponse } from '../types/endpoint-response';

@Component({
  selector: 'lib-endpoint-viewer',
  templateUrl: './endpoint-viewer.component.html',
})
export class EndpointViewerComponent implements OnInit {

  @Input() er!: endpointResponse[];
  constructor(private developerS: DeveloperService) { }
  ngOnInit(): void { }
  clear() { this.developerS.onClearEndpoints.next(true) }

}
