<app-close-dialog-button *ngIf="isOnDialog"></app-close-dialog-button>

<p mat-dialog-title *ngIf="isOnDialog">{{this.isEdit ? "Editar modelo" : "Crear modelo"}}</p>
<div class="advanced-details-container" mat-dialog-content>
    <app-card [contentLoaded]="loaded" [noStyle]="isOnDialog">
        <form [formGroup]="form" eForm>
            <!-- tener en cuenta al actualizar -->
            <app-card-subtitle [first]="true">Datos obligatorios</app-card-subtitle>

            <div eForm-wrapper>
                <div eForm-section>
                    <app-brand-model-input #bmi [form]="form" [OnlyBrand]="true" [OnlySerie]="true"
                        (onSelectBrand)="selectBrand($event)"
                        (onSelectSerie)="selectSerie($event)"></app-brand-model-input>
                    <mat-form-field appearance="outline">
                        <mat-label>Nombre modelo</mat-label>
                        <input matInput formControlName="name" placeholder="Nombre modelo">
                    </mat-form-field>

                    <app-accounting-group-selector [form]="form" [type]="'C'"></app-accounting-group-selector>
                </div>
            </div>
            <app-card-subtitle>Datos opcionales</app-card-subtitle>
            <div eForm-wrapper>
                <div eForm-section>

                    <mat-form-field appearance="outline">
                        <mat-label>Versión</mat-label>
                        <input matInput formControlName="version" placeholder="Código versión">
                    </mat-form-field>
                    <app-year-datapiker [form]="form"></app-year-datapiker>

                </div>
            </div>

        </form>
        <div class="df aic jcc mt10" *ngIf="!isOnDialog">
            <button mat-flat-button [disabled]="!form.valid" color="primary" (click)="createUpdate()">Crear
                modelo
            </button>
        </div>

        <div mat-dialog-actions *ngIf="isOnDialog">
            <div [ngTemplateOutlet]="createBtn"></div>
        </div>


        <ng-template #createBtn>
            <button [disabled]="!form.valid" mat-flat-button color="primary" (click)="createUpdate()">
                Guardar
            </button>
        </ng-template>
        <ng-template #notfound>
            <app-model-not-found [title]="'No se ha encontrado el modelo'" [goText]="'Ir al listado de modelos'"
                [view]="v.models">
            </app-model-not-found>
        </ng-template>

    </app-card>
</div>