import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalSchedule } from 'src/app/components/week-schedule/week-schedule.component';
import { endpoints } from 'src/app/constants/Endpoints';
import { M_Schedule } from 'src/app/models/M_Schdeule';
import { M_Signing } from 'src/app/models/M_Signing';
import { M_SigningTime } from 'src/app/models/M_SigningTime';
import { getArrayOf } from 'src/app/utils/FunctionUtils';
import { RouterService } from '../../router.service';
import { ConfirmDialogService } from '../../confirm-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class ApiSigningService {

  constructor(private http: HttpClient, private confirmD: ConfirmDialogService) { }

  getUserSchedule(user_id: number) {
    return new Promise<{ accountForWorkload: boolean | null, schedule: M_Schedule[] }>(resolve => {
      console.log("que me llevas ashasdl asclse");
      this.http.post<any>(endpoints.getUserSchedule.url, { user_id: user_id }).subscribe(
        data => {
          const acount = data.accountForWorkload;
          const schedule = getArrayOf(M_Schedule, data.schedule);
          resolve({
            accountForWorkload: acount,
            schedule: schedule
          });
        }
      );
    })
  }

  updateSchedule(obj: { accountForWorkload: boolean; schedule: finalSchedule, user_id?: number } | finalSchedule) {
    return new Promise<boolean>(resolve => {
      this.http.post<any>(endpoints.updateUserSchedule.url, obj).subscribe(
        data => {
          resolve(true);
        }
      );
    })
  }

  getMyPresence() {
    return new Promise<M_Signing>(resolve => {
      let s = [
        new M_Schedule({}), // Monday
        new M_Schedule({}), // Tuesday
        new M_Schedule({
          morningStart: "10:00",
          morningEnd: "11:00",
        }), // Wednesday
        new M_Schedule({
          morningStart: "10:00",
          morningEnd: "11:00",
        }), // Thursday
        new M_Schedule({}), // Friday
        new M_Schedule({}), // Saturday
        new M_Schedule({}), // Sunday 
      ];
      resolve(
        new M_Signing({
          now: new M_SigningTime({
            day: new Date(),
            morningStart: undefined,
            morningEnd: undefined,
            afternoonStart: undefined,
            afternoonEnd: undefined,
            schedule: s
          }),
          schedule: s,
          history: []
        }))
      // this.http.get<any>(endpoints.myPresence.url).subscribe(
      //   data => {
      //     let s = new M_Signing(data);
      //     s.now.schedule = s.now.getScheduleByDay(s.schedule);

      //     resolve(s);
      //   }
      // );
    })
  }


  startEnd(signing: M_SigningTime) {
    return new Promise<M_SigningTime>(resolve => {
      resolve(signing);
      // this.http.post<any>(endpoints.startStopSigning.url, signing).subscribe(
      //   data => {
      //     resolve(new M_SigningTime(data));
      //   },
      //   error => {
      //     this.confirmD.show({
      //       title : "Algo salió mal en el fichaje :/",
      //       body : "Recomendamos actualizar la página para evitar errores",
      //       disableClose : true, 
      //       confirmTxt : "Refrescar",
      //       showCancel : false,
      //       type : "info",
      //       action : "refresh"
      //     })
      //     resolve(new M_SigningTime(error));
      //   }
      // );
    })
  }

}
