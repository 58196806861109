import { Component, Input } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Contact } from 'src/app/models/M_Contact';
import { ParamsService } from 'src/app/services/params.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-main-budget-comercial-button',
  templateUrl: './main-budget-comercial-button.component.html',
  styleUrls: ['./main-budget-comercial-button.component.css']
})
export class MainBudgetComercialButtonComponent {
  v = ViewPath;
  @Input({ required: true }) c!: M_Contact;
  @Input() style: "flat" | "menu-option" = "flat";
  constructor(public routerS: RouterService){}
}
