<app-close-dialog-button *ngIf="isOnDialog"></app-close-dialog-button>

<p mat-dialog-title *ngIf="isOnDialog">Crear Serie</p>
<div class="advanced-details-container" mat-dialog-content>
    <app-card [noStyle]="isOnDialog">
        <app-serie-form></app-serie-form>
        <div class="df aic jcc mt10">
            <button mat-flat-button [disabled]="!serieform.form.valid" color="primary" (click)="create()">Crear
                serie</button>
        </div>
    </app-card>
</div>