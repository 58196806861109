import { Component, Inject } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BrandModelEditComponent } from 'src/app/components/brand-model-edit/brand-model-edit.component';
import { M_SigningTime } from 'src/app/models/M_SigningTime';

@Component({
  selector: 'app-edit-my-presence',
  templateUrl: './edit-my-presence.component.html',
  styleUrls: ['./edit-my-presence.component.css']
})
export class EditMyPresenceComponent {

  form: UntypedFormGroup;

  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: M_SigningTime, public dRef: MatDialogRef<EditMyPresenceComponent>) {
    this.form = this.fb.group({
      morningStart: [this.data.morningStart?.getHHmm() || ''],
      morningEnd: [this.data.morningEnd?.getHHmm() || ''],
      afternoonStart: [this.data.afternoonStart?.getHHmm() || ''],
      afternoonEnd: [this.data.afternoonEnd?.getHHmm() || '']
    });
  }

  save() {
    if (!this.data.scheduleHasMorining && !this.data.scheduleHasAfternoon) {
      this.dRef.close(undefined);
    }
    else {
      this.setUpNewSigningTime();
      this.dRef.close(this.data);
    }
  }

  setUpNewSigningTime() {
    let morningStart = this.form.get('morningStart')?.value;
    let morningEnd = this.form.get('morningEnd')?.value;
    let afternoonStart = this.form.get('afternoonStart')?.value;
    let afternoonEnd = this.form.get('afternoonEnd')?.value;
    this.data.editPresence(morningStart, morningEnd, afternoonStart, afternoonEnd);
  }

  get isAllOk() {
    let breakdown = this.isOkBreakdown;
    return breakdown.morningStart && breakdown.morningEnd && breakdown.afternoonStart && breakdown.afternoonEnd;
  }

  get isToday() {
    return this.data.day.isEquals(new Date());
  }

  get hasSchedule() {
    return (this.data.scheduleHasMorining || this.data.scheduleHasAfternoon);
  }

  get editable() {
    /** (!this.isToday || (this.isToday && this.data.finished)); */
    return this.hasSchedule;
  }

  get isOkBreakdown() {

    let isOk = {
      morningStart: true,
      morningEnd: true,
      afternoonStart: true,
      afternoonEnd: true,
    }

    if (!this.data.scheduleHasMorining && !this.data.scheduleHasAfternoon) {
      return isOk;
    }

    let morningStart = this.form.get('morningStart')?.value;
    let morningEnd = this.form.get('morningEnd')?.value;
    let afternoonStart = this.form.get('afternoonStart')?.value;
    let afternoonEnd = this.form.get('afternoonEnd')?.value;

    isOk.morningStart = morningEnd == "" || (morningStart != "" && morningEnd != "" && morningStart < morningEnd);
    isOk.morningEnd = morningStart == "" || (morningStart != "" && morningEnd != "" && morningStart < morningEnd);
    isOk.afternoonStart = afternoonEnd == "" || (morningStart != "" && morningEnd != "" && morningStart < morningEnd && morningEnd < afternoonStart);
    isOk.afternoonEnd = afternoonStart == "" || (morningStart != "" && morningEnd != "" && afternoonStart != "" && morningStart < morningEnd && morningEnd < afternoonStart && afternoonStart < afternoonEnd);

    return isOk;
  }

}
