<div class="df aic" [ngClass]="timerS.refOr != undefined ? 'or-time-appear' : 'or-time-disappear'">
    <div class="timer-container" *ngIf="timerS.refOr"  (click)="diffOnClick()" mat-ripple>
        <p class="first-text">{{timerS.refOr.id_to_show}} </p>
        <p class="elapsed-time cp nmb">{{timerS.elapsed}}</p>
    </div>
    <p class="more-or-text" *ngIf="timerS.hasMore" [matMenuTriggerFor]="otherOr">{{timerS.moreNumber}} más</p>
    <mat-menu #otherOr="matMenu">
        <div *ngFor="let or of timerS.moreOr" class="timer-container" style="margin: 5px;"
            (click)="timerS.goOr(or)">
            <p class="first-text">{{or.id_to_show}} </p>
            <p class="elapsed-time cp nmb">{{timerS.orElapsed(or)}}</p>
        </div>
    </mat-menu>
</div>