import { getAccentColor, getPrimaryColor, getWarnColor } from "../utils/FunctionUtils";

export enum BookMarkEnum {
    PRIMARY = 1,
    ACCENT = 2,
    GREEN = 3,
    ORANGE = 4,
    WARN = 5
}

function bookmarkColor(c : BookMarkEnum): string {
    switch (c) {
        case BookMarkEnum.PRIMARY:
            return getPrimaryColor();
        case BookMarkEnum.ACCENT:
            return getAccentColor();
        case BookMarkEnum.WARN:
            return getWarnColor();
        case BookMarkEnum.GREEN:
            return "var(--g)";
        case BookMarkEnum.ORANGE:
            return "var(--o)";
    }
}

export function getBookMarkColorStyle(c : BookMarkEnum) : string{
    return "color : " + bookmarkColor(c) + " !important;"
}