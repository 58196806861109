import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Company } from '../../models/M_Company';
import { M_User } from '../../models/M_User';
import { M_EinaData } from '../../models/M_EinaData';
import { M_Subscription } from '../../models/M_Subscription';
import { LoadingPanelService } from '../LoadingPanel/loading-panel.service';
import { environment } from 'src/environments/environment';
import { PRO } from './fakeSubscriptionLocal';
import { SessionService } from '../session.service';

/** Eina main data getter 🏠 */
@Injectable({
  providedIn: 'root'
})

export class EinaDataService {

  /** On change user, company or subscription */
  onEinaDataChange: Subject<M_EinaData> = new Subject();
  private data?: M_EinaData;
  private callInProgess: Promise<M_EinaData> | undefined;

  MAX_CHANGES = 20;
  countToRefresh = 0;

  constructor(router: Router, private apiS: ApiService, private loadingS: LoadingPanelService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.countToRefresh += 1;
      }
    });
  }

  /**  Get the main data of Eina.**/
  getEinaData(): Promise<M_EinaData | undefined> {
    return new Promise<M_EinaData | undefined>(resolve => {
      if ((!this.data || this.countToRefresh >= this.MAX_CHANGES)) {
        if (!this.callInProgess) {
          this.countToRefresh = 0;
          this.loadingS.show(true);
          this.callInProgess = this.apiS.einaData();
          this.callInProgess.then(res => {
            this.setNewEinaData(res);
            resolve(this.data!);
            this.callInProgess = undefined;
            this.loadingS.hide(true);
          })
        }
        else {
          this.callInProgess.then(res => {
            resolve(this.data!);
            this.callInProgess = undefined;
          })
        }
      }
      else {
        resolve(this.data);
      }
    })
  }

  /** Update the data on the service. Emits 'onEinaDataChange' event */
  setNewEinaData(d: M_EinaData) {
    this.data = d;

    /** Fake local subscription */
    if (environment.local && this.data) {
      this.data.subscription = PRO;
    }
    this.onEinaDataChange.next(this.data);
  }

  /** Update the company on the service. Emits 'onEinaDataChange' event */
  setNewCompany(d: M_Company) {
    if (this.data) {
      this.data.company = d;
      this.onEinaDataChange.next(this.data);
    }
  }

  /** Update the company on the service. Emits 'onEinaDataChange' event */
  setNewSubscription(d: M_Subscription) {
    if (this.data) {
      this.data.subscription = d;
      this.onEinaDataChange.next(this.data);
    }
  }

  destroyData() {
    this.data = undefined;
  }

  get hasData() {
    return this.data != undefined;
  }

  get user(): M_User { return this.data!.user };
  get company(): M_Company { return this.data!.company };
  get subscription(): M_Subscription { return this.data!.subscription };

}