import { Component, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_CompanyWelcome } from '../../models/M_CompanyWelcome';
import { VehicleType } from '../../enums/VehicleType';
import { ReferenceService } from '../../services/onboarding.service';
import { SubscriptionService } from '../../services/EinaMainData/subscription.service';
import { getAccentColor, getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent {
  primary = getPrimaryColor;
  accent = getAccentColor;
  warn = getWarnColor;
  companyName = new UntypedFormControl("", [Validators.required]);
  places = new UntypedFormControl(0, [Validators.required]);
  defaultVehicle = new FormControl<VehicleType | null>(null, [Validators.required]);
  vt = VehicleType;
  index = 0;
  pages = [0, 1, 2];

  @ViewChild(MatStepper) stepper?: MatStepper;

  constructor(private apiS: ApiService, private refS: ReferenceService, private responsiveS: ResponsiveService, public subS: SubscriptionService) { }

  ngAfterViewInit() {
    if (!this.responsiveS.isPhone()) {
      this.refS?.menuComponent?.toggleTo(true);
    }
  }

  updateCompanyData() {
    this.apiS.welcomeCompany(new M_CompanyWelcome(this.companyName.value, this.places.value, this.defaultVehicle.value!));
  }

  /**
   * Starts the 'hidden' onboarding process. Explains the user how to use the help button and start sections.
   * The hidden section, is the same as the first onboarding section but has 2 more previous steps to localize the
   * help button.
   * In this function, start the hidden section and when its finished, mark as compleated the first one (fill the company data).
   */
  openHelp() {
    if (this.refS.onboardingComponent) {
      this.refS.onboardingComponent.userOnboarding.sections[1].startOpened = true;
      let hiddenSection = this.refS.onboardingComponent.userOnboarding.sections[0];
      let realSection = this.refS.onboardingComponent.userOnboarding.sections[1];
      this.refS.onboardingComponent.startSection(hiddenSection);
      hiddenSection.onCompleteSection.subscribe(() => {
        realSection.completed = true;
      })
    }
  }

  calculateDay() {
    if (this.subS.status?.remaining_days) {
      return new Date().plusDays(this.subS.status?.remaining_days).dayMonthYearFormat(); 
    }
    else {
      return new Date().plusDays(14).dayMonthYearFormat();
    }
  }

}
