<app-card [noStyle]="true">
    <div class="df aic">
        <app-card-subtitle>{{filter.label}}</app-card-subtitle>
    </div>
    <mat-form-field appearance="outline">
        <mat-label>Seleccione una opción</mat-label>
        <mat-select #ms (selectionChange)="emitChange($event)" [value]="filter.selectedOption">
            <mat-option *ngIf="filter.nullable">--</mat-option>
            <mat-option *ngFor="let kv of filter.options | keyvalue" [value]="kv.key">
                <mat-icon *ngIf="kv.value.icon">{{kv.value.icon}}</mat-icon>
                {{kv.value.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</app-card>