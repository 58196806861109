import { NotificationType } from "../enums/NotificationType";
import { TICKET_STATUS } from "../enums/TicketStatus";
import { INotification } from "../interfaces/INotification";
import { M_CloudTicket } from "./M_CloudTicket";
import { M_CloudTicketEvent } from "./M_CloudTicketEvent";

/** This class is generate by a M_CloudTicket */
export class M_TicketNotificacion implements INotification {

    /**Datos de la bbdd */
    id: number;
    user_id: number;
    type: number;
    readed: boolean;
    time: Date;
    time_ago: string;

    /**Datos creardos a partir de la notificataionType */
    title!: string;
    text!: string;
    icon!: string;

    ct: M_CloudTicket;
    event: M_CloudTicketEvent;

    constructor(ct: M_CloudTicket, event: M_CloudTicketEvent) {
        this.ct = ct;
        this.event = event;
        this.id = event.id;
        this.user_id = event.user_id;
        this.readed = event.readed;
        this.type = event.event == "comment" ? NotificationType.TICKETCOMMENT : event.event == "status" ? NotificationType.TICKETSTATUS : event.event == "assign" ? NotificationType.TICKETASSIGNCHANGE: NotificationType.TICKETNEW;
        this.time = event.created_at;
        this.time_ago = this.time.isToday() ? this.time.hourFormat() : this.time.shortFormat();
        this.generatrNotificationsByTipo();
    }

    generatrNotificationsByTipo() {
        switch (this.type) {
            case (NotificationType.TICKETNEW): {
                this.title = "Se ha creado una nueva incidencia";
                this.text = "Se te ha asignado " + this.ct.technicalname;
                this.icon = "add_box";
                break;
            }
            case (NotificationType.TICKETCOMMENT): {
                this.title = "Nuevo comentario en " + this.ct.technicalname
                this.text = '"' + this.event.value.toString() + '"';
                this.icon = "chat";
                break;
            }
            case (NotificationType.TICKETASSIGNCHANGE): {
                this.title = "Te han asignado a una incidencia"
                this.text = this.event.value + " te ha asignado la incidencia " + this.ct.technicalname;
                this.icon = "change_circle";
                break;
            }
            case (NotificationType.TICKETSTATUS): {
                this.icon = "change_circle";
                this.title = "Cambio de estado en " + this.ct.technicalname
                var val = this.event.value;

                if (val == TICKET_STATUS.pending) {
                    this.text = "La incidencia se ha marcado como 'pendiente'";
                }
                else if (val == TICKET_STATUS.revision) {
                    this.text = "La incidencia se esta revisando";
                }
                else if (val == TICKET_STATUS.solved) {
                    this.text = "La incidencia se ha marcado como 'solucionada'";
                }
                else {
                    this.text = "Se ha efectuado un cambio en la incidencia";
                }
                break;
            }
        }
    }
}
