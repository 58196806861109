import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as XLSX from 'xlsx';
import { exelColumId } from './imports/storehouse-entry';
import { FileType } from 'src/app/enums/FileType';
import { isMobile } from 'src/app/utils/Browserutils';
import { CustomFile } from 'src/app/custom-classes/CustomFile';
import { match } from 'src/app/services/search.service';


type supported_types = "string" | "number" | "date"

/** When the 'generate' button is pressed, emits this value. The 'key' is the expected column id, the 'value'... well, it's the value*/
export type emittedRow = Record<number, number | string | Date>;

export type fromTo = {
  from: number
  to: number
}

/** Expected columns on the excel */
export type expectedColumns = {
  id: number,
  name: string,
  alternative_names: string[],
  val_type: supported_types,
  required: boolean,
  update: boolean,
  txtFromTo?: fromTo;
};

/** Uploaded document columns. The 'matchWith' propery determinates with colum is matched to. */
type documentColumn = {
  excelName: string,
  excelColNumber: number,
  matchWith: expectedColumns | undefined;
};

@Component({
  selector: 'app-excel-column-matcher',
  templateUrl: './excel-column-matcher.component.html',
  styleUrls: ['./excel-column-matcher.component.css']
})
export class ExcelColumnMatcherComponent {

  /** Expected columns */
  @Input({ required: true }) expectedColumns: expectedColumns[] = [];

  /** Emit a imported row. "Key-value" record. Example :
   *  Structure : expectedColumns.id : value
   *  Example : {
   *    0: "This references to the the expectedColum.id == 0"
   *    1: "This references the expectedColum.id == 1"
   *    2: 11 <-- Some number
   *    3: 22 <-- Some number
   * }
   */
  @Output() onRowEnter: EventEmitter<emittedRow> = new EventEmitter();
  @Output() onClickNext: EventEmitter<boolean> = new EventEmitter();
  @Output() onRemoveExcel: EventEmitter<boolean> = new EventEmitter();

  ft = FileType;
  mobile = isMobile();
  errors: string[] = []

  /** Excel uploaded data */
  data: [][] = [];
  /** Uploaded document columns */
  documentColumn: documentColumn[] = [];

  /** Add the header on the generation?  */
  headerIsData = true;
  page: 1 | 2 = 1;

  currentFileData: {
    file: CustomFile | undefined
    fileAsText: string
  } = {
      file: undefined,
      fileAsText: ""
    }


  onExcelUpload(f: CustomFile) {
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(f.file!);

    reader.onload = (e: any) => {

      const bstr: string = e.target.result;
      this.updateComponentFile(f, bstr);

      if (f.getType() == this.ft.excel) {
        this.parseXLSX(bstr);
      }
      else {
        this.txtSetup();
      }
    };
  }

  updateComponentFile(f: CustomFile, fileAsText: string) {
    this.currentFileData = {
      file: f,
      fileAsText: fileAsText
    }
  }

  parseXLSX(bstr: string) {
    console.log("📗 bstr:", bstr)
    const wb: XLSX.WorkBook = XLSX.read(bstr, {
      type: 'binary',
      cellDates: true,
      cellNF: true,
      cellText: false,
    });

    /* grab first sheet */
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];
    console.log("📗 WS:", ws)

    /* save data */
    this.data = (XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: "", raw: false, dateNF: 'dd/MM/yyyy' }));
    console.log("📗 Excel data : ", this.data)

    this.afterUpload();
  }

  txtSetup() {
    this.expectedColumns.forEach((exC, index) => {
      if (exC.txtFromTo) {
        this.documentColumn.push(
          {
            excelColNumber: index,
            excelName: exC.name,
            matchWith: exC
          }
        );
      }
    })
    console.log("📗 Txt setup finished");

  }

  /** Clled after upload al xlsx */
  afterUpload() {
    if (!this.data[0]) { this.setError("El documento esta vacío") }
    else if (this.data[0].length < this.minColumns) { this.setError("El documento no tiene el mínimo de columnas") }
    else {
      this.columnGeneration();
    }
  }

  /** Generate the colum distribution based on the upload files column names and the expected colums name*/
  columnGeneration() {
    let data: string[] = this.data[0]
    if (!Array.isArray(data)) { this.setError("Error al importar archivo.") }
    else {
      data.forEach((d, index) => {
        this.documentColumn.push(this.smartDistribution(d, index))
      })
      this.smartHeader();
    }
  }

  smartHeader() {
    /** If no column can auto-match... the first line it's a real data. */
    this.headerIsData = this.documentColumn.every(c => c.matchWith == undefined);
  }

  /** Distribute or try to distribute the columns */
  smartDistribution(d: string, index: number): documentColumn {
    console.log("📗 Smart dist")
    let excelColumn: documentColumn = {
      excelName: d ? d : "Col. " + (index + 1),
      excelColNumber: index,
      matchWith: undefined
    }
    this.expectedColumns.forEach(c => {
      if (!this.someExcelColumHas(c)) {
        if (match(excelColumn.excelName, c.name) || match(excelColumn.excelName, ...c.alternative_names)) {
          excelColumn.matchWith = c;
        }
      }
    })
    return excelColumn;
  }

  /** Resets the component */
  resetComponent() {
    this.page = 1;
    this.data = [];
    this.errors = [];
    this.documentColumn = [];
    this.currentFileData = {
      file: undefined,
      fileAsText: ""
    }
    this.onRemoveExcel.emit(true);
  }

  /** Set a error on the array of erros of the component */
  setError(error: string) {
    this.errors.push(error);
  }

  /** Check if some excelColumn already selected the param expectedColumn */
  someExcelColumHas(c: expectedColumns) { return this.documentColumn.some(excelColumn => { return excelColumn.matchWith == c }) }


  /** Emit the rows one by one. */
  next() {
    this.onClickNext.emit(true);
    this.isExcel ? this.processExcel() : this.processTxt();
    this.page = 2;
  }

  genericProcessData(filteredColumns: documentColumn[]) {
    this.data.forEach((row, rowIndex) => {
      if (rowIndex == 0 && !this.headerIsData) { return; } //remove header
      let logger: { name: string, value: number | string | Date }[] = [];
      let emitRow: emittedRow = {};
      filteredColumns.forEach(c => {
        const rowVal = this.getCleanValueOf(row, c.matchWith!.id);
        emitRow[c.matchWith!.id] = rowVal;
        logger.push(
          {
            name: c.matchWith!.name,
            value: rowVal
          });
      });
      this.onRowEnter.emit(emitRow);
      console.log("📗 Emmited Row : ", logger);
    });
  }

  processExcel() {
    let filteredColumns = this.documentColumn.filter(exC => exC.matchWith != undefined);
    this.genericProcessData(filteredColumns);
  }

  processTxt() {
    console.log("📗 Start txt processing")
    this.data = [];
    /** First of all, read the document line by line and then split by line and from-to */
    let bstr = this.currentFileData.fileAsText;
    if (bstr) {
      const lines = bstr.split('\n').map(line => line.trim()).filter(line => line.length > 0);
      for (const line of lines) {
        let columns: string[] = [];
        this.documentColumn.forEach(col => {
          let val = "";
          if (col.matchWith && col.matchWith.txtFromTo) {

            val = line.slice(col.matchWith.txtFromTo.from - 1, col.matchWith.txtFromTo.to)
          }
          columns.push(val);
        })
        this.data.push(columns as any);
      }

      /** Generic import */
      let filteredColumns = this.documentColumn.filter(exC => exC.matchWith != undefined && exC.matchWith.txtFromTo && exC.matchWith.txtFromTo.from != undefined && exC.matchWith.txtFromTo.to);
      this.genericProcessData(filteredColumns);

    }
    else {
      console.log("📗 No file");
    }
  }

  /** Get the value based on the param row and the expected column type */
  getCleanValueOf(row: any[], id: number) {
    let targetCol = this.documentColumn.find(eC => eC.matchWith?.id == id);
    if (targetCol) {
      let targetValue = row[targetCol.excelColNumber];
      switch (targetCol.matchWith!.val_type) {
        case 'string':
          let val = targetValue.toString();
          if (!val) { return "??" }
          return val;
        case 'number':
          if (typeof targetValue == "string") { return targetValue.getNumber(); }
          else if (typeof targetValue == "number") { return targetValue; }
          return 0;
        case 'date':
          console.log("📗 Date import to do")
          return new Date();
      }
    }
  }

  get showColumnDistribution() {
    let documentExist = this.currentFileData.file != undefined;
    let fileTpeDistinction: boolean = this.isExcel ? this.data.length != 0 : true;
    return documentExist && fileTpeDistinction && !this.errors.length;
  }
  /** The uploaded file is .txt? */
  get isTxt() { return this.currentFileData.file?.getType() == this.ft.txt; }
  /** The uploaded file is a excel? */
  get isExcel() { return this.currentFileData.file?.getType() == this.ft.excel; }
  /** The minimum columns, are the numer or required expected columns */
  get minColumns() { return this.expectedColumns.filter(c => c.required).length; }
  /** Check if the array of errors has some error */
  get hasErrors() { return this.errors.length }
  /** Check if all the required columns are matched or has the required values */
  get areColumnsOk() {
    if (this.isExcel) {
      return this.expectedColumns.every(expected => {
        return !expected.required ? true : this.someExcelColumHas(expected);
      })
    }
    else {
      return this.expectedColumns.every(expected => {
        if (expected.required) {
          return expected.txtFromTo &&
            expected.txtFromTo.from != undefined && expected.txtFromTo.from != 0 &&
            expected.txtFromTo.to != undefined && expected.txtFromTo.to != 0;
        }
        else { return true; }
      })
    }

  }

  devTest() {
    this.expectedColumns.forEach(c => {
      let id = c.id as exelColumId;
      switch (id) {
        case exelColumId.REF:
          c.txtFromTo!.from = 1;
          c.txtFromTo!.to = 12;
          break;
        case exelColumId.NAME:
          c.txtFromTo!.from = 13;
          c.txtFromTo!.to = 24;
          break;
        case exelColumId.QUANTITY:
          c.txtFromTo!.from = 25;
          c.txtFromTo!.to = 28;
          break;
        case exelColumId.COST:
          c.txtFromTo!.from = 29;
          c.txtFromTo!.to = 30;
          break;
        case exelColumId.PVP_T:
          c.txtFromTo!.from = 33;
          c.txtFromTo!.to = 34;
          break;
        case exelColumId.DISCOUNT:
          c.txtFromTo!.from = 37;
          c.txtFromTo!.to = 38;
          break;
        case exelColumId.DISCOUNT_GROUP:
          c.txtFromTo!.from = 41;
          c.txtFromTo!.to = 42;
          break;
      }
    })
  }


  get inProcess() {
    return this.currentFileData.file != undefined && this.page == 1;
  }

}
