import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { ParamsService } from 'src/app/services/params.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { environment } from 'src/environments/environment';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { getPrimaryColor } from 'src/app/utils/FunctionUtils';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { RouterService } from 'src/app/services/router.service';
import { CustomFile } from 'src/app/custom-classes/CustomFile';
import { DeleteService } from 'src/app/services/delete.service';
import { RolesEnum } from 'src/app/enums/RolesEnum';
import { MatDialog } from '@angular/material/dialog';
import { ModifyObservationsComponent } from '../client-details/modify-observations/modify-observations.component';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.css']
})
export class VehicleDetailsComponent extends ParameterStateComponent {

  primary = getPrimaryColor;
  loaded: boolean = false;
  env = environment;
  ve: M_Vehicle | undefined;
  v = ViewPath;
  R = RolesEnum;

  @ViewChild('documentation') documentation!: FileUploadComponent;
  @ViewChild('vehicleImages') vehicleImages!: FileUploadComponent;
  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService,
    public params: ParamsService, private chdRef: ChangeDetectorRef,
    public subS: SubscriptionService, public deleteS: DeleteService,
    private d: MatDialog) {
    super(routerS, route, ["vehicle"])
  }

  override onState(state: any) {
    if (state instanceof M_Vehicle) {
      this.ve = state;
      this.chdRef.detectChanges();
      this.loaded = true;
    }
  }

  override onParam(_k: string, v: any) {
    this.apiS.getVehicleById(v).then(res => {
      if (res == undefined) {
        this.ve = undefined;
      }
      if (res instanceof M_Vehicle) {
        this.ve = res;
        /** Ugly redirect. --> Contemplar hacer un ÚNICO componente de vehicle details. */
        if (this.ve.isVnVo) {
          this.params.go(ViewPath.vnvoDetails, this.ve.vehicle_id!, undefined, false, true);
          this.fillImages(this.ve);
        }
      }
      this.loaded = true;
      this.chdRef.detectChanges();
    })
  }

  onImgError(e: any) {
    e.srcElement.style.display = 'none'
  }

  fillImages(v: M_Vehicle) {
    this.documentation.setImagesFromDatabase(v.documentation);
    this.vehicleImages.setImagesFromDatabase(v.vehicleImages);
  }

  removeImage(c: CustomFile) {
    if (c.database_id) {
      this.apiS.deleteImage(c.database_id);
    }
  }

  uploadImages(c: CustomFile, key: "vehicle_img" | "docu_img") {
    this.apiS.uploadImage(c, key, ["client_id", this.ve!.client!.client_id!], ["vehicle_id", this.ve!.vehicle_id]).then(res => {
      if (res.image_id) {
        c.database_id = res.image_id;
      }
    })
  }

  hasDocuImages() {
    return this.documentation?.hasFiles();
  }

  hasVehicleImages() {
    return this.vehicleImages?.hasFiles();
  }

  modifyObservations() {
    if (this.ve) {
      this.d.open(ModifyObservationsComponent, { data: this.ve.observations }).beforeClosed().subscribe(val => {
        if (val != undefined) {
          let vehicleCOpy = new M_Vehicle(this.ve);
          vehicleCOpy.observations = val;
          this.apiS.vehicle.editVehicle(vehicleCOpy).then(_res => {
            this.ve!.observations = val;
          });
        }
      })
    }
  }

}