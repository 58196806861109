import { error_level } from "../enums/ErrorLevel";

export class CustomLog {
    level_name: error_level;
    message: string | undefined;
    context: string | undefined;
    stack: string | undefined;

    constructor(level_name: error_level, message?: string, stack?: string) {
        this.level_name = level_name;
        this.context = window.location.href;
        this.message = message;
        this.stack = stack;
    }

    isTheSame(lastError: CustomLog) {
        if (this.message != lastError.message) { return false }
        if (this.stack != lastError.stack) { return false };
        return true;
    }
}