<div *ngIf="appointment.client; else nodata">
    <app-card-subtitle [first]="first">Cliente y vehículo</app-card-subtitle>
    <app-vehicle-row [item]="appointment.vehicle!" [newTab]="isOnDialog"></app-vehicle-row>
    <app-client-row [item]="appointment.client!" [newTab]="isOnDialog"></app-client-row>
</div>
<ng-template #nodata>
    <app-card-subtitle [first]="first">Cliente no registrado</app-card-subtitle>
    <p>
        <mat-icon class="vam" *ngIf="appointment.vehicle; else wheel">
            {{appointment.vehicle.icon}}
        </mat-icon>
        <ng-template #wheel>
            <mat-icon class="vam" [svgIcon]="'wheel'"></mat-icon>
        </ng-template>
        {{appointment.vehicle_brand}}
        {{appointment.vehicle_model}}
        <span class="c_t2 ml10 mr10">|</span>
        {{appointment.license}}
    </p>
    <p>
        <mat-icon class="vam"> no_accounts </mat-icon>
        {{appointment.name_client}}
    </p>
</ng-template>

<!-- spacing -->
<p style="margin-bottom: 15px;"></p>