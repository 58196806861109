<app-page-structure [pageTitle]="'Grupos de descuento'" 
    [goBack]="v.products"
    [listTitleText]="'Grupos de descuento'"
    [displayedHeader]=" ['Identificador',   'Nombre']"
    [displayedColumns]="['id',              'product_group']"
    [specialText]="     [undefined,         undefined]"
    [specialClass]="    [undefined,         undefined]"
    [preIcon]="         [undefined,         undefined]"
    [createButton]="{
        icon: 'heap_snapshot_multiple',
        text: 'Crear grupo de descuento',
        view: v.createDiscountGroup,
    }"
    (onclickRow)="paramsS.go(v.createDiscountGroup, $event.id)">
</app-page-structure>