import { ChangeDetectorRef, Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Product } from 'src/app/models/Products/M_Product';
import { ParamsService } from 'src/app/services/params.service';
import { ProductmoveComponent } from './productmove/productmove.component';
import { ViewPath } from 'src/app/app-routing.module';
import { ProductCategory } from 'src/app/enums/ProductCategory';
import { feature } from 'src/app/utils/FeaturesController';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { M_Product_Move } from 'src/app/models/M_Product_Move';
import { M_Product_Invoice } from 'src/app/models/M_Product_Invoice';
import { M_Product_Move_Recambios } from 'src/app/models/M_Product_Move_Recambios';
import { MatDialog } from '@angular/material/dialog';
import { ManualFaultComponent } from 'src/app/components/manual-fault/manual-fault.component';
import { M_Fault } from 'src/app/models/M_Fault';
import { FaultProductTableComponent } from 'src/app/components/fault-product-table/fault-product-table.component';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { MoveFilters, FilterOption } from 'src/app/custom-classes/Filter';
import { getPrimaryColor, getAccentColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { RouterService } from 'src/app/services/router.service';
import { DeleteService } from 'src/app/services/delete.service';
import { ProductDetailsStockDialogComponent } from './product-details-stock-dialog/product-details-stock-dialog.component';
import { ProductDetailsReservesDialogComponent } from './product-details-reserves-dialog/product-details-reserves-dialog.component';
import { ProductDetailsFaultsDialogComponent } from './product-details-faults-dialog/product-details-faults-dialog.component';
import { ManualReservationComponent, manualReservationData } from 'src/app/components/manual-reservation/manual-reservation.component';
import { M_Reservation } from 'src/app/models/M_Reservation';
import { AddProviderToProductOrFaultComponent } from '../create-order/add-provider-to-product-or-fault/add-provider-to-product-or-fault.component';
import { M_Contact } from 'src/app/models/M_Contact';
import { UserService } from 'src/app/services/EinaMainData/user.service';

export interface MovementProduct {
  anim(): void
}

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent extends ParameterStateComponent {
  primary = getPrimaryColor;
  accent = getAccentColor;
  warn = getWarnColor;

  p: M_Product | undefined;
  moveFilters = new MoveFilters("Filtrar movimientos");
  selectedOption: FilterOption | undefined;
  loaded: boolean = false;
  v = ViewPath;
  @ViewChildren(ProductmoveComponent) pmc?: QueryList<ProductmoveComponent>;
  @ViewChild(MatButtonToggleGroup) toggle?: MatButtonToggleGroup;
  allMoves: (M_Product_Invoice | M_Product_Move)[] = [];
  allStockMoves: M_Product_Move_Recambios[] = [];
  pc = ProductCategory;
  features = feature;
  haveOrders: number | undefined
  blur = true;

  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService, public params: ParamsService,
    private chdRef: ChangeDetectorRef, public companyS: CompanyService, private d: MatDialog, public deleteS: DeleteService, private userS: UserService) {
    super(routerS, route, ["product"]);
  }

  goDataImpor() {
    this.routerS.goTo(ViewPath.dataupload);
  }

  override onParam(_k: string, v: any) {
    this.apiS.getProductById(v, this.userS.isSuperUser || this.userS.userIsRecambista).then(res => {
      if (res == undefined) {
        this.p = undefined;
      }
      if (res instanceof M_Product) {
        this.p = res;
        this.createAndOrderMoves();
        this.allStockMoves = this.p!.recProductsMov
        this.haveOrders = this.p?.orders?.length;
      }
      this.loaded = true;
    })
  }

  createAndOrderMoves() {
    this.allMoves.push(...this.p!.product_invoice);
    this.allMoves.push(...this.p!.product_move);
    this.allMoves.sort(
      (a, b) => b.created_at.getTime() - a.created_at.getTime(),
    );
  }

  isInvoiceMove(m: any): m is M_Product_Invoice {
    return m instanceof M_Product_Invoice;
  }

  isAddOrRemove(m: any): m is M_Product_Move {
    return m instanceof M_Product_Move;
  }

  isEtranceMove(m: any) {
    return m instanceof M_Product_Move && m.isAdd();
  }

  isRemoveMove(m: any) {
    return m instanceof M_Product_Move && m.isRemove();
  }

  get filteredMovements() {
    let moves_ = []
    for (let i = 0; i < this.allMoves.length; i++) {
      if (this.onFilters(this.allMoves[i])) {
        moves_.push(this.allMoves[i]);
      }
    }
    return moves_;
  }

  onFilters(m: M_Product_Invoice | M_Product_Move) {
    let v = this.toggle?.value;
    if (v == 0) {
      return true;
    }
    else if (v == 1) {
      return this.isInvoiceMove(m);
    }
    else if (v == 2) {
      return this.isEtranceMove(m);
    }
    else if (v == 3) {
      return this.isRemoveMove(m);
    }
    else {
      return true;
    }
  }

  saveNewStock(p: M_Product, stock: number) {
    this.apiS.editStock(p, stock).then(_res => {
      this.chdRef.detectChanges();
    })
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  stockDialog() {
    if (this.companyS.recambiosModule) {
      this.d.open(ProductDetailsStockDialogComponent, { data: this.p, autoFocus: false });
    }
  }

  reservesDialog() {
    this.d.open(ProductDetailsReservesDialogComponent, { data: this.p, autoFocus: false });
  }

  faultDialog() {
    this.d.open(ProductDetailsFaultsDialogComponent, { data: this.p, autoFocus: false });
  }

  openManualReservation() {
    this.d.open<ManualReservationComponent, manualReservationData>(ManualReservationComponent, { data: { product: this.p }, autoFocus: false }).afterClosed().subscribe(res => {
      if (res instanceof M_Reservation) {
        this.p!.reservations.push(res);
      }
    });
  }

  openAddProvider() {
    this.d.open(AddProviderToProductOrFaultComponent, { data: this.p }).afterClosed().subscribe(v => {
      if (v instanceof M_Contact) {
        this.p?.providers.push(v);
      }
    })
  }

}
