import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endpoints } from 'src/app/constants/Endpoints';
import { RolesEnum } from 'src/app/enums/RolesEnum';

@Injectable({
  providedIn: 'root'
})
export class ApiDevTestService {

  constructor(private http: HttpClient) { }

  changeRoleTest(r: RolesEnum) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.changeRoleTest.url, { role: r }).subscribe(
        data => { resolve(data); }
      );
    })
  }

}
