import { M_AccountingGroup, type_FAC } from "../models/AccountingGroups/M_AccountingGroup";
import { M_AccountingGroupRow } from "../models/AccountingGroups/M_AccountingGroupRow";

function row(name: string, type: type_FAC) {
    return new M_AccountingGroupRow({
        channel_title: name,
        type_FAC: type
    });
}

function rowF(name: string) { return row(name, "F") }
function rowA(name: string) { return row(name, "A") }
function rowC(name: string) { return row(name, "C") }


/** -------------------------------------- */
/** ------------- COMERCIAL -------------- */
/** -------------------------------------- */

const COMERCIAL_F = [
    rowF("V.N de Detalle"),
    rowF("V.N de Agente"),
    rowF("V.N de Cesión"),
    rowF("V.N de Flota"),
    rowF("V.N de Otros"),
    rowF("Varias"),
    rowF("V.O de Detalle"),
    rowF("V.O de Compra/Venta"),
    rowF("V.O de Desguace"),
]

const COMERCIAL_A = [
    rowA("V.N de Detalle"),
    rowA("V.N de Agente"),
    rowA("V.N de Cesión"),
    rowA("V.N de Flota"),
    rowA("V.N de Otros"),
    rowA("Varias"),
    rowA("V.O de Detalle"),
    rowA("V.O de Compra/Venta"),
    rowA("V.O de Desguace"),
]

const COMERCIAL_C = [
    rowC("Compras de V.N"),
    rowC("Compras de V.O"),
]

export const AC_COMERCIAL = new M_AccountingGroup({
    "type" : "C",
    "lines": [
        ...COMERCIAL_F,
        ...COMERCIAL_A,
        ...COMERCIAL_C,
    ]
})


/** -------------------------------------- */
/** --------------- TIME ----------------- */
/** -------------------------------------- */

const TIME_F = [
    rowF("OR Normales"),
    rowF("OR Preentrega Sección-8"),
    rowF("OR Siniestro Sección-9"),
    rowF("Garantías Fábrica Normales"),
    rowF("Garantías Fábrica Chapa/Pintura"),
    rowF("Facturas Mantenimientos Programodos"),
]

const TIME_A = [
    rowA("OR Normales"),
    rowA("OR Preentrega Sección-8"),
    rowA("OR Siniestro Sección-9"),
    rowA("Garantías Fábrica Normales"),
    rowA("Garantías Fábrica Chapa/Pintura"),
    rowA("Abonos Mantenimientos Programodos"),
]

const TIME_C: M_AccountingGroupRow[] = [
]

export const AC_TIME = new M_AccountingGroup({
    "type" : "T",
    "lines": [
        ...TIME_F,
        ...TIME_A,
        ...TIME_C,
    ]
})


/** -------------------------------------- */
/** -------------- PRODUCTS -------------- */
/** -------------------------------------- */

const PRODUCTS_F = [
    rowF("Clientes de Almacén"),
    rowF("Contados de Mostrador"),
    rowF("OR Normales"),
    rowF("OR Preentrega Sección-8"),
    rowF("OR Siniestro Sección-9"),
    rowF("Or Franquicia"),
    rowF("Tiquets"),
    rowF("Garantías Fábrica Normales"),
    rowF("Garantías Fábrica Chapa/Pintura"),
    rowF("Facturas Mantenimientos Programodos"),
]

const PRODUCTS_A = [
    rowA("Clientes de Almacén"),
    rowA("Contados de Mostrador"),
    rowA("OR Normales"),
    rowA("OR Preentrega Sección-8"),
    rowA("OR Siniestro Sección-9"),
    rowA("Or Franquicia"),
    rowA("Tiquets"),
    rowA("Garantías Fábrica Normales"),
    rowA("Garantías Fábrica Chapa/Pintura"),
    rowA("Abonos Mantenimientos Programodos"),
]

const PRODUCTS_C = [
    rowC("Compras proveedores"),
    rowC("Devoluciones proveedores"),
]

export const AC_PRODUCTS = new M_AccountingGroup({
    "type" : "A",
    "lines": [
        ...PRODUCTS_F,
        ...PRODUCTS_A,
        ...PRODUCTS_C,
    ]
})
