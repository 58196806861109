export class M_Smpt {
    username?: string;
    password?: string;
    port?: string;
    protocol: string;
    host?: string;
    footer : string;
    constructor(d: any) {
        this.username = d?.username ? d.username : undefined;
        this.password = d?.password ? d.password : undefined;
        this.protocol = d?.protocol ? d.protocol : null;
        this.host = d?.host ? d.host : undefined;
        this.port = d?.port ? d.port : undefined;
        this.footer = d?.footer ? d.footer : undefined;
    }

    get notEmpty() {
        return this.username && this.password && this.protocol && this.host && this.port;
    }
}