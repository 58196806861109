<form eForm [formGroup]="form">

    <div class="df jcc aic">
        <button type="button" mat-icon-button
            (click)="setNewLordIcon('less')"><mat-icon>chevron_left</mat-icon></button>
        <app-storehouse-lord-icon [iconClass]="'ts2'" [lordIcon]="formLordIcon"></app-storehouse-lord-icon>
        <button type="button" mat-icon-button
            (click)="setNewLordIcon('more')"><mat-icon>chevron_right</mat-icon></button>
    </div>

    <div eForm-section>
        <mat-form-field appearance="outline">
            <div matIconSuffix>
                <button type="button" mat-icon-button [matTooltip]="'Color identificativo'"
                    [matMenuTriggerFor]="bookmarkComponent.menu">
                    <mat-icon [filled]="true" [style]="getBookMarkColorStyle(formBookmark)">bookmark</mat-icon>
                </button>
                <app-sotrehouse-bookmark (onBookmark)="changeBookmark($event)"
                    #bookmarkComponent></app-sotrehouse-bookmark>
            </div>
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="name">
        </mat-form-field>
    </div>

    <app-card-subtitle>Contacto</app-card-subtitle>
    <div eForm-section>
        <mat-form-field appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="contact">
        </mat-form-field>
        <app-phone-input [label]="'Teléfono'" [formCName]="'tel'" [form]="form"
            [required]="false"></app-phone-input>
    </div>

    <!-- Locations -->
    <app-card-subtitle>Ubicaciones</app-card-subtitle>
    <div formArrayName="locations">
        <div *ngFor="let location of locations.controls!; let i = index" [formGroupName]="i">
            <mat-form-field class="w100" appearance="outline">
                <mat-label>Nombre</mat-label>
                <input #locationInputs matInput placeholder="Nombre" formControlName="name">
                <button mat-icon-button matSuffix *ngIf="i >= 1 && !location.value.default" (click)="removeLocation(i)"><mat-icon>delete</mat-icon></button>
            </mat-form-field>
        </div>
    </div>
    <button type="button" mat-stroked-button color="primary" class="w100" (click)="addLocation()">Añadir ubicación <mat-icon>add</mat-icon></button>

</form>