import { BaseModule, ModuleDetails } from "./Factory/BaseModule";
import { M_MTRModule } from "./M_MTRModule";

export class M_HondaModule extends BaseModule {
    get name() { return "Honda"; }
    get corename() { return "honda"; }
    get title() { return "Integración con Honda"; }
    get desc() { return "Con esta integración, podrás exportar a iCIS y consultar, entre otras opciones, los pasos del taller."; }
    override isMTR(): this is M_MTRModule { return false;}
    
    generateFuncionalities() {
        return [
            { icon: "export_notes", title: "Exportación a iCIS", tooltip: "Exportación a iCIS" },
            { icon: "table_view", title: "Pasos de taller", tooltip: "Pasos de taller" },
        ];
    }

    generateModuleDetails(): ModuleDetails[] {
        return [
            {
                text: "¡Accelera tu negocio con la integración de Honda! ¿Pasos de taller? ¿iCIS? ¡Todo a un clic! 🏍️",
                scrType: "img",
                src: `${this.basePath}/honda1.jpg`
            }
        ]
    }
}