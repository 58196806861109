import { M_Product } from "./Products/M_Product";

export class M_ProductProvider {
    id: number;
    company_id: number;
    product: M_Product | undefined;
    ref_prov: number;
    discount : number;
    discount_urg : number;
    usual: boolean;

    constructor(d: any) {
        this.id = d.id;
        this.company_id = d.company_id;
        this.ref_prov = d.ref_prov;
        this.discount = d.discount? d.discount : null;
        this.discount_urg = d.discount_urg? d.discount_urg : null;
        this.usual = d.usual;
        if (d.product) {
            this.product = new M_Product(d.product);
        }
    }

    getProduct() {
        return this.product;
    }

}