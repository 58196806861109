import { Views } from "../View";

export interface StepInterface {
    /** Element to focus on the step */
    target: string;

    /** Text to show on the speech bubble */
    info: string | string[];

    /** Device to show the step. All by deaful */
    device?: "phone" | "pc" | "all";

    /** Click the focusElement by clciking the next step. True by default */
    action?: boolean;

    /** Go to the next step if the focus element is clicked. True by default*/
    nextOnTargetClick?: boolean;

    /** View to go. Determinates where the user need to go */
    view?: Views;

    /** Remove the step if the screen width is smaller than this value 
     * bigger =  window.innerWidth >= value;
     * smaller = window.innerWidth < value;
    */
    showStepOn?: ["bigger" | "smaller", number]

    /** By default, all the steps close the side menu. True by default */
    closSideMenu?: boolean;
}

export class Step implements StepInterface {
    target: string;
    info: string[] = [];
    device: "phone" | "pc" | "all";
    action: boolean;
    view?: Views | undefined;
    showStepOn?: ["bigger" | "smaller", number]
    nextOnClickTarget: boolean = true;
    closSideMenu: boolean = true;

    constructor(data: StepInterface) {
        this.target = data.target;
        this.info = typeof data.info == "string" ? [data.info] : data.info;
        this.device = data.device ? data.device : "all"
        this.action = data.action != undefined ? data.action : true;
        this.view = data.view;
        this.showStepOn = data.showStepOn;
        this.nextOnClickTarget = data.nextOnTargetClick != undefined ? data.nextOnTargetClick : true;
        this.closSideMenu = data.closSideMenu != undefined ? data.closSideMenu : true;
    }
}