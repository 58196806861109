
<!--------------------------------------------------------------------------------------->
<!-- Este componente engloba los presupuestos de TALLER y los presupuestos de RECAMBIOS -->
<!--------------------------------------------------------------------------------------->

<div class="container" *ngIf="!actionFailedToLoad; else notfound">
    <!-- ACTION GENERAL INFO -->
    <div class="title" *ngIf="actionLoaded">
        <app-go-back class="title" [text]="'Listado presupuestos'" [v]="backView"
            [show]="action != undefined"></app-go-back>
        <app-view-title *ngIf="action == undefined">
            Crear presupuesto {{isRecambiosBudget ? 'de recambios' : 'de taller'}}
        </app-view-title>
        <app-view-title *ngIf="action != undefined  && !action.isClosed()">Editar presupuesto</app-view-title>
    </div>

    <div class="general_info">
        <app-card [contentLoaded]="actionLoaded" [userNeedSubscription]="true" class="bc_b1">
            <ng-container *ngIf="action == undefined; else created">


                <ng-container *ngIf="!isRecambiosBudget; else createRBudget">
                    <div class="df jcsb fww">
                        <app-card-title>Buscar vehículo o propietario</app-card-title>

                    </div>
                    <app-class-searcher class="w100 mla mra onboarding-budget-select-vehicle" #vehicleSearcher
                        *ngIf="action == undefined" [masterClass]="vehicle"
                        searchPlaceHolder="Buscar vehículo o propietario" (onSelect)="createBudget($event)"
                        [selectedOnLoad]="paramVehicle" [noSelectionPlaceHolder]="'Ningún vehículo seleccionado'"
                        [specialRow]="''" [required]="true" [createData]="{
                            text : 'Nuevo vehículo',
                            by : 'dialog'
                        }">
                    </app-class-searcher>
                </ng-container>

                <ng-template #createRBudget>
                    <div class="df jcsb fww">
                        <app-card-title>Buscar cliente</app-card-title>

                    </div>
                    <app-class-searcher class="w100 mla mra onboarding-recambios-budget-select-client" #clientSearcher
                        *ngIf="action == undefined" [masterClass]="client" searchPlaceHolder="Buscar cliente"
                        (onSelect)="createRecambiosBudget($event)"
                        [noSelectionPlaceHolder]="'Ningún cliente seleccionado'" [required]="true" [createData]="{
                            text : 'Nuevo cliente',
                            by : 'dialog'
                        }">
                    </app-class-searcher>
                </ng-template>

            </ng-container>

            <ng-template #created>
                <app-advanced-details *ngIf="action">

                    <app-advanced-title>
                        <div class="df fww aic gap5">
                            {{action.title_id}}
                            <div class="df">
                                <app-or-status-label [action]="action" class="ml5" [showText]="false"
                                    [status]="action? action.status : undefined"></app-or-status-label>
                                <app-or-type-label [wrapOn]="responsiveS.w < 400 ? 1 : 2" class="ml5" [showText]="false"
                                    [tipo]="action? action.type : undefined"></app-or-type-label>
                            </div>
                        </div>
                    </app-advanced-title>

                    <app-advanced-subtitle>
                        Creado el {{action.created_at.dayMonthYearFormat()}}
                    </app-advanced-subtitle>

                    <app-advanced-main-actions>

                        <div [matTooltip]="!action.hasGroupsAndTasks()? 'Debes cerrar alguna intervención para poder facturarla' : ''">
                            <button subscribed-user mat-flat-button [roles]="[R.ADMIN, R.GESTOR, R.ADVISER]"
                                color="primary" (click)="openInvoiceDialog()"
                                class="mr25on368" *ngIf="canInvoice">
                                Facturar
                            </button>
                        </div>


                        <button subscribed-user mat-raised-button
                            [disabled]="missingCompanyInfo || !action.isBudgetClosable()" (click)="showTemplateFields()"
                            color="primary" class="onboarding-budget-finish" *ngIf="isOpen()">
                            <mat-icon>lock</mat-icon>
                            Cerrar
                        </button>
                        <div *ngIf="isClosed()">
                            <div class="df fdc">
                                <button subscribed-user mat-raised-button [disabled]="!isClosed()"
                                    (click)="openTransferTo('or')" color="primary" class="w100">
                                    <mat-icon>build</mat-icon>
                                    {{action.has_or ? 'Generar otra OR' : 'Pasar a OR'}}
                                </button>
                                <p *ngIf="action.has_or" class="nmb tac" style="margin-top: 5px;">
                                    <span class="fsi">Ya se ha pasado a OR</span>
                                    <span class="ml5 c_b c_p underline_hover fw600"
                                        (click)="goOr(action.has_or!)">Ver</span>
                                </p>
                            </div>
                        </div>
                        <!-- DOCUMENTATION !-->
                        <app-documentation-pdf-menu *ngIf="action?.token" class="mla"
                            [action]="action"></app-documentation-pdf-menu>
                    </app-advanced-main-actions>

                    <app-advanced-menu-actions>
                        <button mat-menu-item (click)="openTransferTo('budget')"
                            [matTooltip]="'Se creará un presupuesto a partir de este'" [matTooltipPosition]="'left'">
                            <mat-icon>calculate</mat-icon>
                            Nuevo presupuesto
                        </button>
                        <button recambios mat-menu-item (click)="openTransferTo('albaran')"
                            [matTooltip]="!action.hasCustomTime ? 'Se generará un albarán a partir de este presupuesto' : 'Se va a omitir el tiempo imputado'"
                            [matTooltipPosition]="'left'">
                            <mat-icon>assignment</mat-icon>
                            Nuevo albarán
                        </button>
                        <app-layout-send-email [butontype]="'mat-menu-item'"
                            *ngIf="action.client && action.client && !action.client.deleted" [temaplte]="'budget'"
                            [endpoint]="reSendBudget.bind(this)" [disabled]="action.client.email == undefined">
                        </app-layout-send-email>
                        <button mat-menu-item [disabled]="action == undefined" (click)="deleteAction()"
                            [disabled]="!action.canDelete">
                            <mat-icon>delete</mat-icon> Eliminar
                        </button>
                    </app-advanced-menu-actions>

                </app-advanced-details>
            </ng-template>

            <app-advanced-details-footer [showTitle]="false" *ngIf="action">
                <app-footer-right>
                    <div class="df fww gap5">
                        <app-contact-client-rectangle [onlyIconOn]="400" *ngIf="action.client"
                            [client]="action.client"></app-contact-client-rectangle>
                        <app-vehicle-rectangle [onlyIconOn]="400" *ngIf="action.vehicle"
                            [vehicle]="action.vehicle"></app-vehicle-rectangle>
                    </div>
                </app-footer-right>
            </app-advanced-details-footer>
        </app-card>
    </div>

    <!-- GROUP TASKS -->
    <div class="groups">
        <app-card [locked]="action == undefined ? 'Crea un presupuesto para poder asignar intervenciones' : undefined"
            [userNeedSubscription]="true" [contentLoaded]="actionLoaded && drag.dragLoaded">
            <app-card-title>Intervenciones</app-card-title>
            <app-drag #drag class="onboarding-budget-tasks" [action]="action" [isBudgetPage]="true"
                [isRecambiosBudget]="isRecambiosBudget" (onRemoveChildGroupTask)="deleteGroupTask($event)">
            </app-drag>
        </app-card>
    </div>

    <app-floating-save-button *ngIf="!isClosed()" [show]="drag.hasChanges()" (click)="saveBudget()">
    </app-floating-save-button>


</div>



<ng-template #notfound>
    <app-model-not-found [title]="'No se ha encontrado el presupuesto'" [goText]="'Ir al listado de presupuestos'"
        [view]="backView">
    </app-model-not-found>
</ng-template>