import { Injectable } from '@angular/core';
import { ApiService } from './Api/api.service';
import { M_Signing } from '../models/M_Signing';

@Injectable({
  providedIn: 'root'
})
export class SigningTimeService {

  signingData: M_Signing | undefined;
  today: Date;

  /** Llamar a m  */
  constructor(private apiS: ApiService) {
    console.log("⏱️ Signing service")
    this.today = new Date();
    this.getData();
    this.ticTac();
  }

  getData() {
    this.apiS.signing.getMyPresence().then(res => {
      console.log(res);
      this.signingData = res;
    })
  }

  /** Refesh */
  ticTac() {
    setInterval(() => { }, 1000);
  }

  startStop() {
    if (!this.signingData?.now) { return; }
    const s = this.signingData.now.schedule;
    this.signingData?.now.startStop();
    this.apiS.signing.startEnd(this.signingData?.now).then(res => {
      this.signingData!.now = res;
      this.signingData!.now.schedule = s;
    })
  }

  get elapsed() { return this.signingData?.now.elapsed; }
  get canStop(): boolean { return this.signingData?.now.canEnd || false; }
  get canStart(): boolean { return this.signingData?.now.canStart || false; }
  get loaded(): boolean { return this.signingData != undefined; }

  get workedPercentage(): number | undefined {
    if (this.expectedMinutes && this.workedTime) {
      return (this.workedTime / this.expectedMinutes) * 100;
    }
    return undefined;
  }

  get todaySchedule() {
    return this.signingData?.schedule[this.today.weekDay()]
  }

  /** Expected time in string */
  get expectedTimeString(): string | undefined {
    return this.todaySchedule?.getTotalTimeString() || "Sin horario para hoy";
  }

  /** Expected time in minutes */
  get expectedMinutes(): number | undefined {
    return this.todaySchedule?.getTotalTimeInMinutes();
  }

  get workedTime(): number | undefined { return this.signingData?.now.workedMinutes }
  get mustSigning(): string { return " " }
  get mustLogout(): string { return " " }
}
