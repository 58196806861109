<app-close-dialog-button></app-close-dialog-button>
<p mat-dialog-title>Pasar cita a OR</p>

<div mat-dialog-content>
    <app-card [noStyle]="true" [contentLoaded]="orFormLoaded">
        <app-clientform *ngIf="!data.isClient" [onlyRequired]="true" [requiredFieldsTitle]="'Cliente'"></app-clientform>
        <app-vehicleform *ngIf="!data.isClient" [onlyRequired]="true"
            [requiredFieldsTitle]="'Vehículo'"></app-vehicleform>
        <app-card-subtitle [first]="data.isClient">Datos OR</app-card-subtitle>
        <app-or-form #orform [appointment]="data"></app-or-form>
    </app-card>
</div>

<div mat-dialog-actions *ngIf="orFormLoaded">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="primary" subscribed-user mat-flat-button cdkFocusInitial [disabled]="createOrDisabled"
        (click)="createOr()">Crear OR</button>
</div>