import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { dialogSchedule, WeekScheduleComponent } from 'src/app/components/week-schedule/week-schedule.component';
import { EditMyPresenceComponent } from './edit-my-presence/edit-my-presence.component';
import { M_SigningTime } from 'src/app/models/M_SigningTime';
import { UserService } from 'src/app/services/EinaMainData/user.service';

@Component({
  selector: 'app-my-presence',
  templateUrl: './my-presence.component.html',
  styleUrls: ['./my-presence.component.css']
})
export class MyPresenceComponent {

  fromDate: FormControl<Date | null> = new FormControl(new Date().firstMonthDay());
  toDate: FormControl<Date | null> = new FormControl(new Date().lastMonthDay());

  constructor(private d: MatDialog, private userS : UserService) { }

  openSchedule() {
    this.d.open<WeekScheduleComponent, dialogSchedule>(WeekScheduleComponent, {
      data: {
        prespective : "user",
        showCheckbox: true,
        userId : this.userS.userId
      }, autoFocus: false
      , panelClass: "week-schedule-panel"
    });
  }

  adjustMonth(val: "from" | "to") {
    const dateField = val == "from" ? this.fromDate : this.toDate;
    const otherField = val == "from" ? this.toDate : this.fromDate;

    let currentDate = dateField.value || otherField.value;

    if (!currentDate) {
      currentDate = new Date();
    }

    const adjustedDate = val === 'from'
      ? currentDate.minusMonths(1)
      : currentDate.plusMonths(1);

    dateField.setValue(adjustedDate);
  }

  openPresenceEdit(signing: M_SigningTime) {
    this.d.open(EditMyPresenceComponent, { data: signing, autoFocus : false }).afterClosed().subscribe(res => {
      
    })
  }

  abs(n: number) { return Math.abs(n) }


}
