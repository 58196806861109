
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterService } from '../../services/router.service';
import { MY_STRING } from 'src/app/app.module';

/**
 * Component to check state or param in another component
 * The other component needs to extend this component
 * On the constructor : 
 *  constructor(routerS : RouterService, route : ActivatedRoute) {
      super(routerS, route, [""]);  
    }
 */

@Component({
  template: ''
})

export class ParameterStateComponent {

  hasState = false;
  hasParameters = false;

  private _state: any;
  private _paramname: any = [];
  private _paramvalue: any = [];

  constructor(public routerS: RouterService, public route: ActivatedRoute, @Inject(MY_STRING) private param: string[]) {
    
    /** Check state */
    if (routerS.hasState()) {
      this._state = this.routerS.getState();
      this.hasState = true;
    }

    /** Find the constructor passed params */
    this.route.queryParams.subscribe(params => {
      for (let i = 0; i < this.param.length; i++) {
        if (params[this.param[i]]) {
          this.hasParameters = true;
          this._paramname.push(this.param[i]);
          this._paramvalue.push(params[this.param[i]]);
        }
      }
    });

  }

  ngAfterContentInit(): void {
    
    /** State emision */
    if (this._state != undefined) {
      this.onState(this._state);
    }
    else {
      this.noState();
    }

    /** Params emision */
    if (this.hasParameters && this._paramvalue.length != 0 && this._paramname.length != 0) {
      var paramArray: { param: string, value: string }[] = [];
      for (let i = 0; i < this._paramname.length; i++) {
        this.onParam(this._paramname[i], this._paramvalue[i]); //Single param emision
        paramArray.push({ param: this._paramname[i], value: this._paramvalue[i] })
      }
      this.onParams(paramArray) /** Emit all founded params  */
    }
    else {
      this.noParams();
    }

    /** No state and no params emision */
    if (!this.hasState && !this.hasParameters){
      this.noStateNoParams();
    }

  }

  /** Emit the state of the loaded route */
  onState(state: any) { }

  /** No state on the loaded route */
  noState() { }

  /** Emit a param and his value */
  onParam(param: string, value: string) { }

  /** Emit the array of {param : value} */
  onParams(params: { param: string, value: string }[]) { }

  /** No params on the loaded route */
  noParams() { }

  /** No params and no state on the loaded route */
  noStateNoParams(){}

}
