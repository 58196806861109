<div class="product-line-parent-div"> <!-- Provisional responsive -->
    <table cdkDropListConnectedTo="all" cdkDropList [cdkDropListData]="products"
        [cdkDropListEnterPredicate]="canEnterList" [cdkDropListDisabled]="!showDrag || !canModifyTable"
        (cdkDropListDropped)="drop($event)" class="scrollable-table eina-table"
        [ngClass]="{'no-pointer' : !canModifyTable}">
        <thead>
            <tr [ngClass]="{'dn' : hideHeaderIfNoProducts && !canModifyTable}">
                <th *ngIf="showSave" class="unsaved-action sticky-col"></th> <!-- Unsaved chagnes dot -->
                <th class="ref-header sticky-col" [ngClass]="{'is-first-row' :!showSave}">Referencia</th>
                <th *ngIf="showNewOrPriceChange">Estado</th>
                <th *ngIf="showProviders">Prov.</th>
                <th *ngIf="showLocation && companyS.recambiosModule" class="small-action">Ubi.</th>
                <th>Cantidad</th>
                <th *ngIf="showCost">Coste</th>
                <th *ngIf="showPVP">PVP</th>
                <th *ngIf="showPVPtarifa">PVP (tarifa)</th>
                <th *ngIf="showDiscount">Descuento</th>
                <th *ngIf="showEnterByCost" class="single-checkbox-th">Entrar por coste</th>
                <th>Total</th>
                <th *ngIf="showReserves && companyS.recambiosModule" class="small-action"></th> <!-- Reserves -->
                <th *ngIf="showPrio">Tipo</th> <!-- Create Order prio-->
                <th *ngIf="showDest">Destino</th> <!-- StoreHouse entry destination-->
                <!-- <th *ngIf="showEntryTo">Entrar en</th> StoreHouse entry destination -->
                <th *ngIf="canModifyTable" class="small-action"></th> <!-- Delete -->
                <th *ngIf="canModifyTable && showDrag" class="small-action"></th> <!-- Drag -->
                <th *ngIf="showLineActions" class="small-action"></th>
                <!-- Line actions -->
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let product of products | slice: (itemsPerPage ? (currentPage - 1) * itemsPerPage : 0) : (itemsPerPage ? currentPage * itemsPerPage : products.length)"
                cdkDrag cdkDragLockAxis="y" [cdkDragData]="product" #productRow
                [cdkDragDisabled]="!showDrag || !canModifyTable" [ngClass]="{'' : isHighlighted(product, productRow) }"
                [ngClass]="{'isComment':product.isComment}">

                <!-- Unsaved chagnes dot -->
                <td *ngIf="showSave" class="sticky-col unsaved-action">
                    <mat-icon class="unsavedDot" *ngIf="product.line_hasChanges" [filled]="true">circle</mat-icon>
                </td>


                <!-- Comment like this? -->
                <!-- table-layout: auto !important; -->
                <!-- [attr.colspan]="product.isComment ? '100%' : null" -->

                <!-- Reference-->
                <td class="ref-header sticky-col" [ngClass]="{'is-first-row' :!showSave}">
                    <app-product-line-title [addRemove]="ar" [product]="product"
                        [clickable]="clickableTitle"></app-product-line-title>
                </td>

                <!-- Show if the product is new or the price has changed-->
                <td *ngIf="showNewOrPriceChange">
                    <div *ngIf="product.instanceofProduct()">
                        <p class="new-tag" *ngIf="!product.product_id; else priceChange">NUEVO</p>
                        <ng-template #priceChange>
                            <p class="price-change"
                                [ngClass]="{'up' : product.pvp_t_ant < product.pvp_t, 'down' : product.pvp_t_ant > product.pvp_t, 'equal' : product.pvp_t_ant == product.pvp_t}"
                                [mat-menu-trigger-for]="priceChangeInfo" [matTooltip]="'Ver más'"
                                [matTooltipPosition]="'above'">
                                <mat-icon>euro</mat-icon>
                                <mat-icon class="arrow">{{product.pvp_t_ant < product.pvp_t ? 'arrow_upward' :
                                        product.pvp_t_ant> product.pvp_t ? 'arrow_downward' : 'remove' }}</mat-icon>
                            </p>
                        </ng-template>
                    </div>
                    <mat-menu #priceChangeInfo="matMenu">
                        <p class="tac fw600">PVP (tarifa)</p>
                        <p class="price-change-menu" *ngIf="product.instanceofProduct()">
                            <span money [val]="product.pvp_t_ant"></span>
                            <mat-icon class="vam">arrow_right_alt</mat-icon>
                            <span money [val]="product.pvp_t"></span>
                        </p>
                    </mat-menu>
                </td>

                <!-- Provider -->
                <td *ngIf="showProviders && product.instanceofProduct()">
                    <app-provider-selector class="df jcfs ts09" [p]="product"></app-provider-selector>
                </td>

                <!-- Location -->
                <td *ngIf="showLocation && companyS.recambiosModule">
                    <app-storage-selector [p]="product" [disabled]="locationDisabled(product)"
                        [locationOnlyInformative]="locationOnlyInformative" [showStock]="showProductsStock">
                    </app-storage-selector>
                </td>

                <!-- Quantity -->
                <td class="add-remove-td">
                    <app-add-remove #ar [showSaveIcon]="false" class="add-remove-add-product"
                        [canModify]="canModifyTable && !isLineBlocked(product)" [required]="false"
                        [formClass]="'small-form-field'" [initValue]="product.quantity" [apparence]="'outline'"
                        [showLabel]="false" [label]="'Cantidad'" [isTime]="product.isTime" [product]="product"
                        [minValue]="getMinValue(product)" [maxValue]="getMaxValue(product)" [forceMinMaxValue]="true"
                        (onChange)="product.line_hasChanges = true" [addRemoveOutside]="false"
                        [hintclass]="getHintClass(product)" [hint]="getStockText(product)"
                        [stockMinimo]="getStockMin(product)">
                    </app-add-remove>
                </td>

                <!-- Cost -->
                <td *ngIf="showCost">
                    <mat-form-field appearance="outline" class="small-form-field">
                        <button *ngIf="costbyTotalPreffix" [mat-menu-trigger-for]="costByTotalMenu"
                            #costByTotalMenuTrigger="matMenuTrigger" matPrefix mat-icon-button
                            [disabled]="isCostDisabled(product)" [matTooltip]="'Calcular el coste según el total'"
                            (mouseup)="focusMenuInput(PVPByTotalInput)">
                            <mat-icon>calculate </mat-icon>
                        </button>

                        <!-- Normal case-->
                        <input *ngIf="!product.isTime" matInput [(ngModel)]="product.buy_price" type="number"
                            (input)="product.line_hasChanges = true" [disabled]="isCostDisabled(product)">

                        <!-- Special case (TIME) : In this case, we take the 'price' not te 'buy_price' -->
                        <input *ngIf="product.isTime" matInput [(ngModel)]="product.price" type="number"
                            (input)="product.line_hasChanges = true"
                            [disabled]="!canModifyTable || isLineBlocked(product)">

                        <span matSuffix>€</span>

                        <!-- Discount hint -->
                        <mat-hint *ngIf="isDiscountActiveFor(product, 'buy_price')">
                            <ng-container *ngTemplateOutlet="discountHint"></ng-container>
                        </mat-hint>

                    </mat-form-field>
                </td>

                <!-- Cost by total menu-->
                <mat-menu #costByTotalMenu="matMenu">
                    <div class="df fdc aic jcc p10" prevent>
                        <p class="nmb fw600">¿Qué total te ha llegado?</p>
                        <mat-form-field appearance="outline" class="small-form-field" [floatLabel]="'always'">
                            <input #PVPByTotalInput type="number" matInput>
                            <span matSuffix>€</span>
                        </mat-form-field>
                        <mat-hint>El nuevo coste será : <span money
                                [val]="getCostByTotal(product,PVPByTotalInput.valueAsNumber)"></span></mat-hint>
                        <button class="w100 mt10" mat-flat-button [color]="'primary'"
                            (click)="setCostByTotal(product, PVPByTotalInput.valueAsNumber, costByTotalMenu);">Establecer</button>
                    </div>
                </mat-menu>

                <!-- PVP -->
                <td *ngIf="showPVP">
                    <mat-form-field appearance="outline" class="small-form-field">
                        <input matInput [(ngModel)]="product.price" type="number"
                            (input)="product.line_hasChanges = true" [disabled]="isPVPDisabled(product)">
                        <span matSuffix>€</span>
                        <!-- Discount hint -->
                        <mat-hint *ngIf="isDiscountActiveFor(product, 'price')">
                            <ng-container *ngTemplateOutlet="discountHint"></ng-container>
                        </mat-hint>
                    </mat-form-field>
                </td>

                <!-- PVP (tarifa) -->
                <td *ngIf="showPVPtarifa">
                    <mat-form-field appearance="outline" class="small-form-field">
                        <input matInput [(ngModel)]="product.pvp_t" type="number"
                            (input)="product.line_hasChanges = true; PVPtAt('pvp_t', product)"
                            [disabled]="isPVPTarifaDisabled(product)">
                        <span matSuffix>€</span>
                        <!-- Discount hint -->
                        <mat-hint *ngIf="isDiscountActiveFor(product, 'pvp_t')">
                            <ng-container *ngTemplateOutlet="discountHint"></ng-container>
                        </mat-hint>
                    </mat-form-field>
                </td>

                <!-- Discount hint -->
                <ng-template #discountHint>
                    <span [ngClass]="product.discount ? 'slash' : ''" money [val]="getDiscountHint(product)"></span>
                </ng-template>


                <!-- Discount -->
                <td *ngIf="showDiscount">
                    <mat-form-field appearance="outline" class="small-form-field" [floatLabel]="'always'">
                        <mat-label *ngIf="false">Descuento</mat-label>
                        <input #input type="number" matInput [(ngModel)]="product.discount"
                            (input)="max100Disc(input, product); PVPtAt('pvp_t', product)"
                            (keydown)="preventMinus($event)" [disabled]="isDiscountDisabled(product)">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </td>


                <td *ngIf="showEnterByCost">
                    <div class="df jcc aic">
                        <mat-checkbox [(ngModel)]="product.enter_by_cost"></mat-checkbox>
                    </div>
                </td>

                <!-- Total -->
                <td>
                    <mat-form-field appearance="outline" class="small-form-field">
                        <mat-label *ngIf="false">Total</mat-label>
                        <input [disabled]="true" matInput [value]="totalCurrency(product)">
                        <mat-hint *ngIf="showHintTotal(product)">
                            {{productExtraFieldName(product)}} :
                            <span money [val]="getTotalExtraField(product)"></span>
                        </mat-hint>
                    </mat-form-field>
                </td>

                <!-- Reserve -->
                <td *ngIf="showReserves && companyS.recambiosModule">
                    <button *ngIf="!product.custom && product.control_stock == true" mat-icon-button
                        [matTooltip]="!product.reserve ? 'Reservar' : 'Quitar reserva'"
                        (click)="product.reserve = !product.reserve; product.line_hasChanges = true;"
                        [disabled]="isLineBlocked(product) || !canModifyTable ">
                        <mat-icon smartIcon *ngIf="!product.reserve">reserve</mat-icon>
                        <mat-icon smartIcon *ngIf="product.reserve">reserve_active</mat-icon>
                    </button>
                </td>

                <!-- Priority-->
                <td *ngIf="showPrio && product.instanceofProduct()">
                    <mat-radio-group [(ngModel)]="product.typeOrder" class="df jcc mat-radio-order-create">
                        <mat-radio-button [value]="0"><mat-icon>shopping_cart_checkout</mat-icon></mat-radio-button>
                        <mat-radio-button [value]="1"><mat-icon>bolt</mat-icon></mat-radio-button>
                    </mat-radio-group>
                </td>

                <!-- Destination -->
                <td *ngIf="showDest">
                    <ng-container *ngIf="product.instanceofProduct() && product.fault; else location">
                        <!-- If the fault is OR (salida) or Budget (reserva)-->
                        <div class="df aic jcc">

                            <!-- Faults that we can go to -->
                            <ng-container
                                *ngIf="product.fault.mov_type == FMT.OR || product.fault.mov_type == FMT.RP || product.fault.mov_type == FMT.AC; else faultNoGo">
                                <span (click)="goByFault(product.fault)" class="c_b underline_hover cp no-blue">
                                    {{product.fault.document}}
                                    <mat-icon class="vam c_b">open_in_new</mat-icon>
                                </span>
                            </ng-container>

                            <!-- Fault that we CAN'T go to -->
                            <ng-template #faultNoGo>
                                <span>
                                    {{product.fault.document}}
                                </span>
                            </ng-template>

                        </div>
                    </ng-container>

                    <ng-template #location>
                        <div class="df aic"
                            *ngIf="product.instanceofProduct() && product.location_dest; else noFaultNoLocation">
                            <mat-icon class="vam" [filled]="true" [style]="product.location_dest.bookmarkColorStyle">bookmark</mat-icon>
                            <mat-icon [filled]="true">warehouse</mat-icon>
                            <p class="nmb ml5"><span>{{product.location_dest.store_name}} </span> <span
                                    class="c_t2 fss">{{product.location_dest.name}}</span></p>
                        </div>
                    </ng-template>

                    <ng-template #noFaultNoLocation>
                        <p class="nmb c_t2 tac">Sin datos</p>
                    </ng-template>

                </td>
                <!--
                <td *ngIf="showEntryTo">
                    <ng-container *ngIf="product.instanceofProduct(); else noLeftovers">
                        <mat-form-field appearance="outline" class="small-form-field">
                            <mat-select [(ngModel)]="product.entry_to">
                                <mat-option [value]="ET.DEST" *ngIf="product.fault != undefined;">
                                     <mat-icon class="vam">rotate_left</mat-icon>
                                    Destino
                                </mat-option>
                                <mat-option [value]="ET.STOREHOUSE">
                                     <mat-icon class="vam">warehouse</mat-icon>
                                    Almacén
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <ng-template #noLeftovers>
                        <p class="c_t2 tac">Sin sobrantes</p>
                    </ng-template>
                </td> -->

                <!-- Delete -->
                <td *ngIf="canModifyTable" class="cell-delete">
                    <button (click)="destroy(product)" mat-icon-button [disabled]="!canDeleteProduct(product)">
                        <mat-icon [filled]="true">delete</mat-icon>
                    </button>
                </td>

                <!-- Drag handle -->
                <td *ngIf="canModifyTable && showDrag" class="cell-drag-and-drop">
                    <button style="cursor: move;" mat-icon-button cdkDragHandle [disabled]="isLineBlocked(product)">
                        <mat-icon>open_with</mat-icon>
                    </button>
                </td>

                <!-- Line actions (3 dots) -->
                <td *ngIf="showLineActions" class="cell-product-line-actions">
                    <app-product-line-actions [product]="product" [addRemove]="ar"></app-product-line-actions>
                </td>

            </tr>
        </tbody>
    </table>

    <div class="pagination-controls" *ngIf="itemsPerPage && totalPages > 1">
        <button mat-icon-button (click)="prevPage()" [disabled]="currentPage === 1" class="btn-nav">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <span class="page-info">{{currentPage}} de {{totalPages}}</span>
        <button mat-icon-button (click)="nextPage()" [disabled]="currentPage === totalPages" class="btn-nav">
            <mat-icon>chevron_right</mat-icon>
        </button>
    </div>

</div>

<!-- This div contains all the actions or information that are not part of the table.-->
<div class="eina-table-bottom">

    <!-- Add product searcher container -->
    <div #searchProductContainer></div>

    <!-- No product message -->
    <ng-container *ngIf="canModifyTable && !products.length && !searchers.length; else noProducts">
        <div>
            <p class="c_t2">{{noProductsMsg}}</p>
            <app-ctrl-space-shortcut [text]="'Atajo para agregar producto'"></app-ctrl-space-shortcut>
        </div>
    </ng-container>

    <ng-template #noProducts>
        <div *ngIf="!canModifyTable && !products.length">
            <p class="c_t2">Sin productos</p>
        </div>
    </ng-template>

    <!-- Table actions -->
    <div *ngIf="lineTypes.length && canModifyTable" class="table-line-bottom-acations onboarding-invoice-add-products">

        <ng-container *ngIf="lineTypes.includes('product')">
            <div (click)="appendProductSearcher()">
                <button mat-stroked-button color="primary" [moreThan]="460">
                    <mat-icon [svgIcon]="'barcode'" class="c_p"></mat-icon>
                    Añadir producto
                </button>
                <button mat-icon-button [matTooltip]="'Añadir producto'" color="primary" [lessOr]="460">
                    <mat-icon [svgIcon]="'barcode'" class="c_p"></mat-icon>
                </button>
            </div>
        </ng-container>

        <ng-container>
            <div (click)="newCustomProduct()" *ngIf="lineTypes.includes('custom')"
                class="onboarding-invoice-add-products-custom">
                <button mat-stroked-button color="primary" [moreThan]="800">
                    <mat-icon class="c_p">category</mat-icon>
                    Personalizado
                </button>
                <button mat-icon-button [matTooltip]="'Personalizado'" color="primary" [lessOr]="800">
                    <mat-icon class="c_p">category</mat-icon>
                </button>
            </div>
        </ng-container>

        <ng-container>
            <div (click)="appendTime()" *ngIf="lineTypes.includes('time')">
                <button mat-stroked-button color="primary" [moreThan]="800">
                    <mat-icon>schedule</mat-icon>
                    Imputar tiempo
                </button>
                <button mat-icon-button [matTooltip]="'Imputar tiempo'" color="primary" [lessOr]="800">
                    <mat-icon>schedule</mat-icon>
                </button>
            </div>
        </ng-container>
        <button class="comment-button" mat-icon-button [matTooltip]="'Añadir comentario'" (click)="appendComment()"
            color="primary" *ngIf="lineTypes.includes('comment')">
            <mat-icon>comment</mat-icon>
        </button>
    </div>
</div>