<app-card [noStyle]="true">
    <div class="df aic">
      <app-card-subtitle>{{filter.label}}</app-card-subtitle>
    </div>
    <div *ngIf="filter.loaded; else loading" class="onboarding-workload-workers">
        <div *ngIf="filter.users.length != 0; else noData">
            <div mat-ripple *ngFor="let u of filter.users" class="usersFilter cp df aic"
                [ngClass]="{'userSelected' : filter.isSelected(u)}" (click)="filter.addRemoveUserFilter(u);">
                <app-circular-letter [user]="u"></app-circular-letter>
                <span class="c_t2 ml10 mr10"></span>
                <p class="total-or-by-user">{{getTotalOrByUser(u)}}</p>
            </div>
            <div mat-ripple class="usersFilter df aic mt5 mb5 cp" [ngClass]="{'userSelected' : filter.userFilter.noOneFilter}"
                (click)="filter.addRemoveNoOneFilter();">
                <mat-icon class="mr5 ml5">person_off</mat-icon>
                <p class="ml5 nmb">Sin usuario</p>
                <span class="c_t2 ml10 mr10"></span>
               <p class="total-or-by-user">{{getTotalOrByUser(undefined)}}</p>
            </div>
        </div>
        <ng-template #noData>
            <p class="c_t2">Sin usuarios</p>
        </ng-template>
    </div>
    <ng-template #loading>
        <div class="df fdc gap5">
            <app-skeleton [fullW]="true" [height]="50"></app-skeleton>
            <app-skeleton [fullW]="true" [height]="50"></app-skeleton>
            <app-skeleton [fullW]="true" [height]="50"></app-skeleton>
        </div>
    </ng-template>
</app-card>
