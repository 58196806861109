<div class="wifi-indicator">
    <lord-icon *ngIf="offline" (click)="setOnlineAgain()" class="lord-medium"
        src="https://cdn.lordicon.com/lzlcrlfm.json" trigger="loop" delay="2000"
        colors="primary:#121331,secondary:#f34f43,tertiary:#f34f43">
    </lord-icon>

    <lord-icon *ngIf="onlineAgain" (click)="setOffline()" class="lord-medium fadeOut"
        src="https://cdn.lordicon.com/lzlcrlfm.json" trigger="in"
        colors="primary:#121331,secondary:#85c949,tertiary:#85c949">
    </lord-icon>
</div>