import { Component, Input} from '@angular/core';
/**
 * Shows a mat-mini-fab button with a phone icon.
 * On click, opens <a> link with the `@Input() phone` param.
 * Show the phone number on a tooltip 
 */
@Component({
  selector: 'app-phone-button',
  templateUrl: './phone-button.component.html',
  styleUrls: ['./phone-button.component.css']
})
export class PhoneButtonComponent{
  @Input() phone: string | undefined;
  constructor() { }
  get hasPhone(){
    return this.phone != undefined && this.phone != "";
  }
}
