import { templateTypedoc } from "../models/M_TemplateField";

export function nameByTemplateType(typeDoc: templateTypedoc, uppercase = true) {
    let name: string;
    switch (typeDoc) {
        case "B":
            name = "Presupuesto";
            break;
        case "A":
            name = "Abono";
            break;
        case "I":
            name = "Factura";
            break;
        case "OR":
            name = "Factura de OR";
            break;
        case "RA":
            name = "Apertura OR";
            break;
        case "AN":
            name = "Anticipo";
            break;
        case "AOR":
            name = "Albarán de OR";
            break;
        case "AC":
            name = "Albarán";
            break;
        case "FL":
            name = "Factura Flotas";
            break;
        case "EA":
            name = "Entrada de Almacén";
            break;
        default:
            name = "";
            break;
    }
    return uppercase ? name.toUpperCase() : name;
}
