import { environment } from "src/environments/environment";
import { Calls } from "../enums/Calls";

type EndpointData = {
    appendHeader?: boolean,
    showLoading?: boolean,
    customError?: boolean,
}

/**
 * App endpoints
 * @call : Endpoint route
 * @appendHeader : Append auth header token ? 
 * @showLoading : Show the loading panel ?
 * @customError : When set to true, the httpListener don't show any error dialog
 */
export class Endpoint {

    private baseUrl: string;
    public appendHeader: boolean = true;
    public showLoading: boolean = true;
    public customError: boolean = false
    public url: string;

    constructor(private call: Calls, endpointData?: EndpointData) {
        this.baseUrl = environment.apiUrl;
        if (endpointData) {
            if (endpointData.appendHeader != undefined) { this.appendHeader = endpointData.appendHeader }
            if (endpointData.showLoading != undefined) { this.showLoading = endpointData.showLoading }
            if (endpointData.customError != undefined) { this.customError = endpointData.customError }
        }
        this.url = this.baseUrl + this.call;
    }
}