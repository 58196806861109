import { ModulesEnum } from "../../../enums/ModulesEnum";
import { BaseModule } from "./BaseModule";
import { M_ComercialModule } from "../M_ComercialModule";
import { M_HondaModule } from "../M_HondaModule";
import { M_MTRModule } from "../M_MTRModule";
import { M_RecambiosModule } from "../M_RecambiosModule";
import { M_TemplatesModule } from "../M_TemplatesModule";

export class ModuleFactory {

    static createModule(d: any): BaseModule {
        switch (d.id as ModulesEnum) {
            case ModulesEnum.RECAMBIOS:
                return new M_RecambiosModule(d);
            case ModulesEnum.VNVO:
                return new M_ComercialModule(d);
            case ModulesEnum.HONDA:
                return new M_HondaModule(d);
            case ModulesEnum.TEMPLATES:
                return new M_TemplatesModule(d);
            case ModulesEnum.MTR:
                return new M_MTRModule(d);
            default:
                throw new Error("Unknown Module ID :(");
        }
    }

    static createModules(d: any): BaseModule[] {
        let modules: BaseModule[] = [];
        const arr = Array.isArray(d) ? d : undefined;
        arr?.forEach(module => {
            modules.push(this.createModule(module));
        })
        return modules;
    }
}