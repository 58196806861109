<div>
    <table mat-table [dataSource]="dataSource" *ngIf="incomingOrders; else noData">

        <ng-container matColumnDef="prov_name">
            <th mat-header-cell *matHeaderCellDef> Proveedor </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.prov_name; else noRow">
                    <mat-icon class="vam">{{element.typeIcon}}</mat-icon>
                    {{element.prov_name}}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Estado </th>
            <td mat-cell *matCellDef="let element">
                <app-order-status-label [status]="element.order_status"></app-order-status-label>
            </td>
        </ng-container>

        <ng-container matColumnDef="prov_phone">
            <th mat-header-cell *matHeaderCellDef> Teléfono </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.prov_phone; else noRow">{{element.prov_phone}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="prov_email">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{'width': this.responsiveS.w > 500 ? '14rem' : 'auto'}">
                Email </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.prov_email; else noRow">{{element.prov_email}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef> Pedido realizado </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.created_at">{{element.created_at}}</span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goOrder(row)"></tr>
        <!-- todo : go order-->
    </table>
</div>
<mat-paginator [length]="incomingOrders.length" [pageSizeOptions]="[5, 10, 20]"></mat-paginator>


<div style="margin-top: 10px;">
    <button class="w100" mat-stroked-button (click)="goCreateOrder()">
        <mat-icon>add</mat-icon>
        Crear pedido
    </button>
</div>
<ng-template #noRow>
    <span class="c_t2">Sin datos</span>
</ng-template>

<ng-template #noData>
    <p *ngIf="product?.providers">Este producto no tiene asociado ningún pedido en trámites. Puedes crear un
        nuevo pedido haciendo clic en el siguiente botón:</p>
</ng-template>