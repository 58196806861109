<app-close-dialog-button></app-close-dialog-button>
<p mat-dialog-title>Editar presencia</p>
<div mat-dialog-content>

    <p mat-dialog-subtitle>
        {{data.day.getDayName(true)}}
        {{data.day.dayMonthYearFormat()}}
    </p>

    <div *ngIf="editable; else noEditable">
        <div *ngIf="isOkBreakdown as breakdown">
            <form eForm eForm-wrapper [formGroup]="form">
                <div eForm-section *ngIf="data.scheduleHasMorining">
                    <mat-form-field appearance="outline"
                        [class.mat-form-field-custom-invalid]="!isOkBreakdown.morningStart">
                        <mat-label>Entrada mañana</mat-label>
                        <input type="time" matInput formControlName="morningStart">
                    </mat-form-field>
                    <mat-form-field appearance="outline"
                        [class.mat-form-field-custom-invalid]="!isOkBreakdown.morningEnd">
                        <mat-label>Salida mañana</mat-label>
                        <input type="time" matInput formControlName="morningEnd">
                    </mat-form-field>
                </div>
                <div eForm-section *ngIf="data.scheduleHasAfternoon">
                    <mat-form-field appearance="outline"
                        [class.mat-form-field-custom-invalid]="!isOkBreakdown.afternoonStart">
                        <mat-label>Entrada tarde</mat-label>
                        <input type="time" matInput formControlName="afternoonStart">
                    </mat-form-field>
                    <mat-form-field appearance="outline"
                        [class.mat-form-field-custom-invalid]="!isOkBreakdown.afternoonEnd">
                        <mat-label>Salida tarde</mat-label>
                        <input type="time" matInput formControlName="afternoonEnd">
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>

    <ng-template #noEditable>
        <p class="c_t2">Parece ser que no existe un horario establecido para el día seleccionado</p>
        <!-- <ng-template #checkToday>
            <p class="c_t2" *ngIf="isToday">¡No tan deprisa! Termina el fichaje de hoy para poder modificarlo</p>
        </ng-template> -->
    </ng-template>

</div>

<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="primary" mat-flat-button (click)="save()" [disabled]="!isAllOk">Ok</button>
</div>