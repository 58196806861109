import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-async-validator-spinner',
  template: `
    <div [ngClass]="{'vh': !isLoading}">
      <mat-spinner [strokeWidth]="6" [diameter]="25" mode="indeterminate" style="margin-right: 5px;"></mat-spinner>
    </div>
  `,
  styles: [`
    .vh {
      visibility: hidden;
    }
  `]
})
export class AsyncValidatorSpinnerComponent {
  @Input({ required: true }) control!: AbstractControl | null;
  constructor() { }
  get isLoading() {
    if (!this.control) { return false; }
    return this.control.status == "PENDING";
  }
}
