import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { ParamsService } from 'src/app/services/params.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { getPrimaryColor, getWarnColor, getAccentColor } from 'src/app/utils/FunctionUtils';
import { RouterService } from 'src/app/services/router.service';
import { FormService } from 'src/app/services/form.service';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { MASTER_CLIENT } from 'src/app/constants/masters';

@Component({
  selector: 'app-link-vehicle',
  templateUrl: './link-vehicle.component.html',
  styleUrls: ['./link-vehicle.component.css']
})
export class LinkVehicleComponent extends ParameterStateComponent implements OnInit {
  primary = getPrimaryColor;
  warn = getWarnColor;
  accent = getAccentColor;
  M_CLIENT = MASTER_CLIENT;
  @ViewChild(ClassSearcherComponent) clientSeracher?: ClassSearcherComponent<M_Contact>;
  ve: M_Vehicle | undefined;
  v = ViewPath;
  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService,
    private formS: FormService, public params: ParamsService) {
    super(routerS, route, ["vehicle"]);
  }

  ngOnInit(): void { }

  override onState(state: any) {
    if (state instanceof M_Vehicle) {
      this.ve = state;
    }
  }
  override onParam(_param: string, value: any) {
    this.apiS.getVehicleById(value).then(resp => {
      this.ve = resp;
    })
  }

  returnClient() {
    if (this.ve && this.ve.client) {
      this.routerS.goWithQueryParams(ViewPath.vehicleDetails, { vehicle: this.ve.vehicle_id })
    }
    else {
      this.routerS.goTo(ViewPath.contacts);
    }
  }

  disabledIf(contact: M_Contact) {
    if (!this.ve) {return true;}
    if (this.ve.client && this.ve.client.client_id == contact.client_id) {return true;}
    return false;
  }

  linkVehicle() {
    if (this.clientSeracher?.selected) {
      this.apiS.linkVechicle(this.ve!.vehicle_id, this.clientSeracher.selected!.client_id).then(_resp => {
        this.params.go(this.v.vehicleDetails, this.ve!.vehicle_id)
      })
    }
  }
}
