import { Section } from "./Section";

export class OnBoarding {
    constructor(public sections: Section[]) { }

    /** To do */
    getPercentageCompleated(){
        var compleated = 0;
        this.sections.forEach(s => {
            if (s.completed){
                compleated+=1;
            }
        })
        return (compleated / this.sections.length) * 100;
    }
}