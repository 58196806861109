export enum EnrtryTypeEnum {
    /** Weighted average price (Precio medio ponderado) */
    PMP = 0,
    /** Entrance by import  */
    IMPORT = 1,
    /** Entrance by coste */
    COST = 2
}


export function entranceTypeName(entryType : EnrtryTypeEnum) : string {
    switch (entryType) {
        case EnrtryTypeEnum.PMP: return "PVPt-dto"
        case EnrtryTypeEnum.IMPORT: return "Importe total a coste"
        case EnrtryTypeEnum.COST: return "Precio unitario a coste"
        default: return "??"
    }
}

export function entranceTypeIcon(entryType : EnrtryTypeEnum) : string {
    switch (entryType) {
        case EnrtryTypeEnum.PMP: return "local_offer"
        case EnrtryTypeEnum.IMPORT: return "local_atm"
        case EnrtryTypeEnum.COST: return "attach_money"
        default: return "local_offer"
    }
}