<form [formGroup]="form" eForm [ngClass]="{'no-wrapper' : !wrapper}">

    <app-card-subtitle [first]="true" *ngIf="!isEdit">Información general</app-card-subtitle>
    <div eForm-wrapper>

        <div eForm-section>
            <!-- VEHICLE OR -->
            <app-class-searcher *ngIf="(!isEdit && !appointment) || (!isEdit && appointment && appointment.isClient) "
                class="onboarding-or-select-vehicle" #vehicleSearcher [masterClass]="vehicle_minify"
                searchPlaceHolder="Buscar vehículo o propietario"
                [noSelectionPlaceHolder]="'Ningún vehículo seleccionado'" (onCreated)="onCreated($event)"
                (onRemove)="onVehicle.emit(undefined)" (onLoad)="onLoad.emit()"
                [required]="clientCreatedAppointment"
                (onSelect)="clientInvoice.remove(); clientInvoice.select($event.clientInvoice?.client_id); onVehicle.emit($event); onSelectOrInit.emit($event)"
                [createData]="{
                    text : 'Nuevo vehículo',
                    by : 'dialog'
                }">
            </app-class-searcher>
            <div eForm-section [ngClass]="{'dn_i' : isEdit || vehicleSearcher?.selected?.isVnVo}">
                <!-- INVOICE TO -->
                <app-class-searcher #clientInvoice class="onboarding-or-select-invoice-to" [masterClass]="client"
                    [searchPlaceHolder]="clientInvoicePlaceholder" [required]="false" [specialRow]="'Nuevo cliente'"
                    [hint]="vehicleSearcher?.selected && clientInvoice.selected ?
                     vehicleSearcher?.selected?.clientInvoice?.client_id == clientInvoice.selected?.client_id ? 
                    {hint :'Cliente a facturar por defecto', class : 'c_g'} :
                    {hint :'No es el cliente a facturar por defecto', class : 'c_r'} : 
                    'Sin selección, se usará el cliente de la OR.'" [createData]="{
                        text : 'Nuevo cliente',
                        by : 'dialog'
                    }">
                </app-class-searcher>
            </div>
        </div>
        <div eForm-section>
            <!--KM-->
            <mat-form-field *ngIf="!isEdit" [ngClass]="{'dn_i' : isEdit}" appearance="outline">
                <mat-label>{{this.vehicleSearcher?.selected?.km ? 'Última revisión '+ this.vehicleSearcher?.selected?.km
                    +'km' : 'Kilómetros del vehículo'}}</mat-label>
                <input type="number" matInput placeholder="Kilómetros del vehículo" formControlName="km">
                <mat-error>{{kmError}}</mat-error>
            </mat-form-field>
            <!-- WORKER-->
            <app-class-searcher #workerSearcher [masterClass]="user" searchPlaceHolder="Asignar operario"
                [ngClass]="{'dn_i' : !showWokerInput}" [noSelectionPlaceHolder]="'Ningún operario seleccionado'"
                [required]="false" (onSelect)="assignUser($event)" (onRemove)="assignUser($event)"
                (onLoad)="autoAssign()" [canChange]="userS.userIsAdminOrAdviser" [createData]="undefined">
            </app-class-searcher>
        </div>

        <div eForm-section>

            <!-- Estimated time-->
            <mat-form-field appearance="outline" (click)="!form.disabled ? openEstimatedTimeDialog() : {}">
                <mat-label>Tiempo estimado</mat-label>
                <input [readonly]="!form.disabled" [disabled]="form.disabled" type="string" matInput
                    [value]="formattedTime()">
                <button mat-icon-button matSuffix [disabled]="appointment?.isDone">
                    <mat-icon>schedule</mat-icon>
                </button>
            </mat-form-field>

            <div class="dn_i">
                <mat-form-field appearance="outline">
                    <input type="number" matInput formControlName="estimated_time_hours">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <input type="number" matInput formControlName="estimated_time_minutes">
                </mat-form-field>
            </div>

            <!-- SCHEDULE -->
            <mat-form-field appearance="outline">
                <mat-label>Realización</mat-label>
                <input matInput placeholder="dd/mm/aaaa" [matDatepicker]="picker_1"
                    [matDatepickerFilter]="cs.maxTodayAndSunday" (click)="picker_1.open()" formControlName="schedule">
                <mat-datepicker-toggle matSuffix [for]="picker_1"></mat-datepicker-toggle>
                <mat-datepicker #picker_1 [dateClass]="getDaysClass.bind(this)"></mat-datepicker> 
            </mat-form-field>

            <!-- DELIVERY -->
            <mat-form-field appearance="outline">
                <mat-label>Entrega</mat-label>
                <input placeholder="dd/mm/aaaa" [min]="minValueDelivery" matInput [matDatepicker]="picker_2"
                    [matDatepickerFilter]="cs.sundayFilter" (click)="picker_2.open()" formControlName="delivery">
                <mat-datepicker-toggle matSuffix [for]="picker_2"></mat-datepicker-toggle>
                <mat-datepicker #picker_2></mat-datepicker>
            </mat-form-field>

            <!-- COMBUSTIBLE -->
            <mat-form-field *ngIf="!isEdit" appearance="outline">
                <mat-label>Combustible</mat-label>
                <mat-select [value]="1" formControlName="fuel" (selectionChange)="fuelChange($event)">
                    <mat-option [value]="0">Vacío</mat-option>
                    <mat-option [value]="1">1/4</mat-option>
                    <mat-option [value]="2">2/4</mat-option>
                    <mat-option [value]="3">3/4</mat-option>
                    <mat-option [value]="4">Lleno</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div [dn_i]="isEdit">
        <app-card-subtitle>Datos adicionales</app-card-subtitle>
        <div eForm-wrapper>
            <app-or-notes-and-images [orFormComponent]="this"></app-or-notes-and-images>
        </div>
    </div>


    <!-- GROUP TASKS -->
    <div [dn_i]="isEdit">
        <app-card-subtitle>Intervenciones</app-card-subtitle>
        <div eForm-wrapper>
            <app-ra-groups></app-ra-groups>
        </div>
    </div>
</form>