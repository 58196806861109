import { Component } from '@angular/core';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Fault } from '../../models/M_Fault';
import { getRandomNoFaultMessage } from '../../constants/constants';

@Component({
  selector: 'app-faults-dialog',
  templateUrl: './faults-dialog.component.html',
  styleUrls: ['./faults-dialog.component.css']
})
export class FaultsDialogComponent {
  faults: M_Fault[] = []
  loaded = false;
  no_stock_message : string;
  constructor(private apiS: ApiService) {
    /** Este endoint no esta implementado (ahora es fake) !!  */
    this.apiS.getFaults().then(res => {
      this.faults = res;
      this.loaded = true;
    })
    this.no_stock_message = getRandomNoFaultMessage();
  }
}
