import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BrandModelInputComponent } from '../components/brand-model-input/brand-model-input.component';
import { M_Brand } from '../models/M_Brand';
import { ApiService } from '../services/Api/api.service';
import { RouterService } from '../services/router.service';
import { AccountingGroupSelectorComponent } from '../components/accounting-group-selector/accounting-group-selector.component';
import { M_Serie } from '../models/M_Serie';

@Component({
  selector: 'app-serie-form',
  templateUrl: './serie-form.component.html',
  styleUrls: ['./serie-form.component.css']
})
export class SerieFormComponent {
  public form: UntypedFormGroup;
  @Input() isDetails = false;
  @Input() loaded ?: boolean;
  @Input() serie ?: M_Serie;

  @ViewChild(BrandModelInputComponent, { static: true }) brandModelInputComponent!: BrandModelInputComponent;
  @ViewChild(AccountingGroupSelectorComponent, { static: true }) accGroup!: AccountingGroupSelectorComponent;
  constructor(private apiS: ApiService,private formBuilder: UntypedFormBuilder,route: ActivatedRoute, routerS: RouterService){
    
    this.form = this.formBuilder.group({
      id:[''],
      serie :['',[Validators.required]],
      name : ['',[Validators.required]],
      brand_id :[''],
      brand_name :[''],
      accounting_id : ['',],
      accounting : ['',],
      accounting_detail : [''],
      accounting_agent : [''],
      brand:['',[Validators.required]],
      model:[''],
    })
  }
  selectBrand(brand:M_Brand){
    console.log(brand);
    this.form.patchValue({brand_id:brand?.id,brand:brand});
  }
  get isSerie(){
    if(this.serie && this.serie.accounting){
      this.form.get('accounting')?.disable();
      return this.serie.accounting.id != undefined;
    }
    return false;
  }
}
