<!-- Optional close button-->
<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title>Detalles de {{product.name}}</p>

<!-- Dialog content -->
<div mat-dialog-content>

    <p class="title">Fecha alta</p>
    <p class="subtitle">{{product.created_at?.shortFormat() ?? '(Sin datos)'}}</p>

    <mat-divider></mat-divider>

    <p class="title">Última entrada</p>
    <p class="subtitle">{{this.product.last_entry?.shortFormat() ?? "(Sin datos)"}}</p>

    <mat-divider></mat-divider>

    <p class="title">Última salida</p>
    <p class="subtitle">{{product.last_exit?.shortFormat() ?? '(Sin datos)'}}</p>

    <mat-divider></mat-divider>

    <p class="title">Último calculo de stock</p>
    <p class="subtitle">{{product.last_stock_calc?.shortFormat() ?? '(Sin datos)'}}</p>

    <mat-divider></mat-divider>

    <p class="title">Último cambio de pvp</p>
    <p class="subtitle">{{this.product.last_price_change?.shortFormat() ?? '(Sin datos)'}}</p>

</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button color="primary" mat-flat-button mat-dialog-close cdkFocusInitial>Ok</button>
</div>