<app-card>
    <app-view-title>Calendario del centro</app-view-title>
    <app-card-title>{{hc.year}}</app-card-title>
    <app-holidays-schedule-calendar #hc 
        [prespective]="'center'" 
        [enabledFor]="enabledCalender"
        [showLegend]="false"
        [showLegendExceptionalDays]="true"
        [selfSave]="true"
        [showSaveButton]="false">
    </app-holidays-schedule-calendar>
</app-card>