<app-page-structure #ps [pageTitle]="'Compras'" [masterClass]="undefined"
    [autocompleteID]="'users'" searchLabel="Buscar compra" listTitleText="Listado de compras" [data]="[]"
    [displayedHeader]=" ['Número', 'Fecha facturación','Proveedor cliente','Total','Tipo']"
    [displayedColumns]="['num_purchase', 'date','contact','total','state']"
    [specialText]="[    undefined,         undefined,            contact,           'money',      typeName]"
    [preIcon]="[purchaseIcon, undefined]" [quickFilter]="quickFilter" [filter]="f" [filters]="filtersToShow"
    (onclickRow)="params.go(v.purchaseDetails, $event.id)" [createButton]="
        {
            text : 'Registrar compra' , 
            icon : 'shopping_basket',
            cssClass: 'onboarding-company-users-create',
            view : v.createPurchase, 
        }">
</app-page-structure>