<app-card [noStyle]="true">
    <div class="df aic" *ngIf="label">
        <app-card-subtitle>{{filter.label}}</app-card-subtitle>
      </div>
    <mat-form-field appearance="outline">
        <mat-label>Cualquier fecha</mat-label>
        <mat-select #ms (selectionChange)="emitChange($event)" [value]="filter.selectedOption">
            <mat-option [value]="-1" *ngIf="filter.showDefault">--</mat-option>
            <mat-option *ngFor="let kv of filter.options | keyvalue" [value]="kv.key">
                <mat-icon fontSet="material-icons-outlined" *ngIf="kv.value.icon">{{kv.value.icon}}</mat-icon>
                {{kv.value.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    
    <app-date-filter (onFilterChanges)="onFilterChanges.emit($event)" *ngIf="showDateRange()" [from]="filter.from" [to]="filter.to"></app-date-filter>
</app-card>

