<div class="df aic" *ngIf="!circularStatus; else circular">
    <app-card-subtitle *ngIf="showText" class="subtitleNoBottom" [first]="true">Estado</app-card-subtitle>
    <div>
      <mat-chip-set>
          <mat-chip [class]="'status status' + status_?.num" [ngClass]="{'cp_i' : menuIconOpen || pointer}">
              <mat-icon matChipAvatar class="status_icon" *ngIf="status_ && showIcon && status_.open">lock_open</mat-icon>
              <mat-icon matChipAvatar class="status_icon" *ngIf="status_ && showIcon && status_.pending">lock</mat-icon>
              <mat-icon matChipAvatar class="status_icon" *ngIf="status_ && showIcon && status_.invoiced">receipt_long</mat-icon>
            <span [class]="'status-text' + status_?.num">{{text_}}</span>
            <mat-icon style="pointer-events: none;" matChipRemove *ngIf="menuIconOpen" class="drop-down-icon">arrow_drop_down</mat-icon>
          </mat-chip>
      </mat-chip-set>
    </div>
</div>

<ng-template #circular>
  <div [class]=" 'df jcfs aic fss status ' + ' _circular' + status_?.num">
    <mat-icon matChipAvatar [filled]="true">circle</mat-icon> <span *ngIf="showText">{{status_?.name}}</span>
  </div>
</ng-template>


<!--<div *ngIf="status_" class="df jcc aic" [ngClass]="{'white' : white}">
  <div class="df jcc aic">

    <span class="ml5" *ngIf="showText">{{text_}}</span>
  </div>
</div>!-->