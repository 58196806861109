import { ModulesEnum, ModuleType } from "src/app/enums/ModulesEnum";
import { M_MTRModule } from "../M_MTRModule";
import { M_RecambiosModule } from "../M_RecambiosModule";
import { M_ComercialModule } from "../M_ComercialModule";
import { M_HondaModule } from "../M_HondaModule";
import { M_TemplatesModule } from "../M_TemplatesModule";
import { randomNumber } from "src/app/utils/FunctionUtils";

export type ModuleFuncionality = {
    icon: string;
    title: string;
    tooltip: string;
}

export type ModuleDetails = {
    text: string;
    scrType: "img" | "video";
    src: string;
}

export abstract class BaseModule {
    id: ModulesEnum;
    type: ModuleType;
    service_name: string;
    created_at: Date | undefined;
    updated_at: Date | undefined;
    price: number;
    functionalities: ModuleFuncionality[];
    moduleDetails: ModuleDetails[];
    link : string | undefined;

    constructor(d: any) {
        this.id = d.id;
        this.type = this.getType();
        this.functionalities = this.generateFuncionalities();
        this.moduleDetails = this.generateModuleDetails();
        this.service_name = d.service_name || "";
        this.price = this.isModule ? 0 : randomNumber(0, 100);
        this.created_at = d.created_at ? new Date(d.created_at) : undefined;
        this.updated_at = d.updated_at ? new Date(d.updated_at) : undefined;
    }

    abstract get name(): string;
    abstract get corename(): string;
    abstract get title(): string;
    abstract get desc(): string;

    /** Module selector rectangles */
    abstract generateFuncionalities(): ModuleFuncionality[];
    /** Advacned details features */
    abstract generateModuleDetails(): ModuleDetails[];

    get cardColor(): "blue" | "yellow" { return this.isModule ? "blue" : "yellow" };
    get basePath() { return `./assets/img/modules/module-${this.id.toString()}` }
    get img(): string { return `${this.basePath}/module-${this.id.toString()}.png`; }
    get isModule() { return this.type === ModuleType.MODULE; }
    get isIntegration() { return this.type === ModuleType.INTEGRATION; }
    getType(): ModuleType {
        return [ModulesEnum.RECAMBIOS, ModulesEnum.VNVO].includes(this.id)
            ? ModuleType.MODULE
            : ModuleType.INTEGRATION;
    }

    /** Instance distinction */
    // isRecambios(): this is M_RecambiosModule { return this instanceof M_RecambiosModule };
    // isComercial(): this is M_ComercialModule { return this instanceof M_ComercialModule };
    // isHonda(): this is M_HondaModule { return this instanceof M_HondaModule };
    // isTemplates(): this is M_TemplatesModule { return this instanceof M_TemplatesModule };
    abstract isMTR(): this is M_MTRModule;
}