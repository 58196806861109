import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TypePaymentService } from 'src/app/services/type-payment-service';

@Component({
  selector: 'app-payment-chips',
  templateUrl: './payment-chips.component.html',
  styleUrls: ['./payment-chips.component.css']
})
export class PaymentChipsComponent {
  
  @Input({ required: true }) fc!: AbstractControl<any, any>
  @Output() onSelect: EventEmitter<number> = new EventEmitter();
  
  constructor(public typePaymentS: TypePaymentService) { }

  select(v: number) {
    this.fc.setValue(v);
    this.onSelect.emit(v);
  }
}
