declare global {
    interface Array<T> {
        removeElement(elem: T): Array<T>;
        removeIndex(index: number): Array<T>;
        lastItem(): T;
    }
}

Array.prototype.removeElement = function <T>(this: T[], elem?: T): T[] {
    if (elem != null && elem != undefined) {
        let element = this.includes(elem)
        if (element) {
            let index = this.indexOf(elem)
            return this.splice(index, 1)
        }
        return this;
    }
    return this;
}

Array.prototype.removeIndex = function <T>(index: number) {
    if (index >= 0 && index < this.length) {
        this.splice(index, 1);
    }
    return this;
}
Array.prototype.lastItem = function <T>() {
    
    return this[this.length-1]
     
}

export { }