import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { SessionService } from "../services/session.service";
import { projectConfiguration } from "../app.module";

/**
 * In all those views that implement this guard, 
 * it is checked if they have a `session token` and if they have `permissions` according to their `role`
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private sessionS: SessionService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    /** If ther is no session, go to login */
    if (!this.sessionS.hasSession()) {
      this.router.navigate([projectConfiguration.landingView.path]);
      return false;
    }
    return true;
  }

}
