<form [formGroup]="form">
    <div *ngFor="let field of fields; let i = index;" [formGroupName]="i" class="extra-form-container"
        [ngClass]="{'form-disabled': isDisabled(fields[i]), 'company-field' : fields[i].add_general, 'company-view' : isCompanyView, 'steps-dialog-view' : !isCompanyView}">


        <!-- NO VISIBLE INPUT FIELDS-->
        <ng-container
            *ngIf="fields[i].add_type == 'title' || fields[i].add_type == 'freetext' || fields[i].add_type == 'divider'; else normalInputs"
            [ngSwitch]="fields[i].add_type">

            <!-- On no visible input fields, the value is always null-->
            <mat-form-field appearance="outline" style="display: none;">
                <input matInput [value]="null" formControlName="adv_value">
            </mat-form-field>

            <!-- TITLE-->
            <ng-container *ngSwitchCase="'title'">
                <p class="field-p" [ngClass]="fields[i].add_type">{{field.add_content}}</p>
            </ng-container>

            <!-- FREE TEXT-->
            <ng-container *ngSwitchCase="'freetext'">
                <p class="field-p" [ngClass]="fields[i].add_type">{{field.add_content}}</p>
            </ng-container>

            <!-- DIVIDER-->
            <ng-container *ngSwitchCase="'divider'">
                <mat-divider style="width: 100%;"></mat-divider>
            </ng-container>

        </ng-container>

        <!-- NORMAL CONTENT (NOT DIVIDER)-->
        <ng-template #normalInputs>
            <div *ngIf="field.add_general" class="start-label"></div>
            <p class="field-p" [ngClass]="fields[i].add_type">

                <!-- "FIRST" LINE TEXT -->
                <span class="add_content"
                    *ngIf="field.add_content && field.add_type != 'signature' && field.add_type != 'damages'"
                    [ngClass]="{'cp' : getAddContentPointer(fields[i])}" (click)="onClickAddContent(fields[i])">
                    {{field.add_content}}
                </span>

                <!-- VALUE DISPLAY-->
                <span [ngSwitch]="fields[i].add_type" class="radio-switch">

                    <!-- Boolean-->
                    <mat-radio-group #mrg="matRadioGroup" *ngSwitchCase="'boolean'" [(ngModel)]="fields[i].adv_value"
                        formControlName="adv_value">
                        <mat-radio-button [value]="true" (click)="deselect(mrg, true, i)">Si</mat-radio-button>
                        <mat-radio-button [value]="false" (click)="deselect(mrg, false, i)">No</mat-radio-button>
                    </mat-radio-group>

                    <!-- Signature -->
                    <span *ngSwitchCase="'signature'">
                        <ng-container *ngTemplateOutlet="signature"></ng-container>
                    </span>

                    <!-- Damages -->
                    <span *ngSwitchCase="'damages'">
                        <ng-container *ngTemplateOutlet="signature"></ng-container>
                    </span>


                    <ng-template #signature>

                        <!-- Menu trigger for company upload signature-->
                        <span #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="companyOptionsMennu"></span>

                        <!-- Menu options of company upload signature-->
                        <mat-menu #companyOptionsMennu="matMenu">
                            <button mat-menu-item
                                (click)="uploadFile.clickInputTrigger()"><mat-icon>upload_file</mat-icon>Subir
                                imágen</button>
                            <button mat-menu-item (click)="openSignatureDialog(signature, fields[i])">
                                <mat-icon>draw</mat-icon>
                                Firmar
                            </button>
                        </mat-menu>
                        
                        <div class="file-row">
                            <!-- File or signature trigger upload button -->
                            <span *ngIf="isCompanyView || (!isCompanyView && !fields[i].add_general)"
                                style="margin-right: 11px;" (click)="distinctClick(menuTrigger, signature, fields[i])"
                                [ngClass]="{'cp' : isCompanyView}">
                                <mat-icon class="c_p medium" *ngIf="fields[i].add_type =='signature'">draw</mat-icon>
                                <mat-icon class="c_p medium"
                                    *ngIf="fields[i].add_type =='damages' && (vehicleType == 2 || !vehicleType)">minor_crash</mat-icon>
                                <mat-icon class="c_p medium" *ngIf="fields[i].add_type =='damages' && vehicleType == 1"
                                    [svgIcon]="'damages_bike'"></mat-icon>

                            </span>
                            <span class="add_content" [ngClass]="{'cp' : isCompanyView}" *ngIf="field.add_content"
                                (click)="distinctClick(menuTrigger, signature, fields[i])">{{field.add_content}}</span>

                        </div>

                        <!-- COMPANY VIEW-->
                        <div [ngClass]="{'dn' : !isCompanyView}">
                            <!-- Image of signature, file...-->
                            <img *ngIf="fields[i].adv_value && isCompanyView" [matTooltip]="'Ampliar imagen'"
                                [matTooltipPosition]="'right'" class="form-field-file" [src]="fields[i].parsedFileURL"
                                (click)="showPreview(fields[i])">
                            <app-signature class="dn" #signature
                                (onSign)="onFileUpload(fields[i], $event)"></app-signature>
                            <!-- Hidden copmponent -->
                            <lib-file-upload class="dn" #uploadFile [uploadtype]="'single'" [crop]="true"
                                [maintainAspectRatio]="false" [maxSize]="6"
                                (stateChanged)="onFileUpload(fields[i], $event.file)"></lib-file-upload>
                        </div>


                        <!-- CLIENT VIEW !-->
                        <div *ngIf="!isCompanyView" class="client-side-signature">
                            <app-firma (onSign)="setFieldValue($event,fields[i])" [singatureData]="automaticSignatureData(fields[i])"></app-firma>
                        </div>




                    </ng-template>


                    <span *ngSwitchCase="'date'">
                        <ng-container *ngTemplateOutlet="dates"></ng-container>
                    </span>

                    <span *ngSwitchCase="'dateant'">
                        <ng-container *ngTemplateOutlet="dates"></ng-container>
                    </span>

                    <span *ngSwitchCase="'datepost'">
                        <ng-container *ngTemplateOutlet="dates"></ng-container>
                    </span>

                    <ng-template #dates>
                        <div class="date-form-field">
                            <mat-form-field appearance="outline" class="small-form-field no-wrapper">
                                <input #pickerInput matInput [(ngModel)]="fields[i].adv_value" [matDatepicker]="picker"
                                    placeholder="dd/mm/aaaa" formControlName="adv_value" [matDatepickerFilter]="
                                fields[i].add_type == 'date' ? dateFilter : 
                                fields[i].add_type == 'dateant' ? dateFilterAnt : 
                                dateFilterPost" (dateChange)="fields[i].parseDatabaseDate()">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-error class="tac fss" *ngIf="invalidDate(i)">Formato incorrecto</mat-error>
                        </div>
                    </ng-template>
                    <!-- Date -->


                    <mat-form-field appearance="outline" *ngSwitchDefault class="no-wrapper" [ngClass]="{'small-form-field' :  field.add_type != 'textarea','fontSize': field.add_type == 'textarea'}">
                        <ng-container [ngSwitch]="fields[i].add_type">
                            <!-- Decimal -->
                            <input *ngSwitchCase="'decimal'" matInput type="number" [(ngModel)]="fields[i].adv_value"
                                formControlName="adv_value" float [maxNumber]="9999">

                            <!-- Integer -->
                            <input *ngSwitchCase="'number'" matInput type="number" [(ngModel)]="fields[i].adv_value"
                                formControlName="adv_value" integer [maxNumber]="9999">

                            <!-- Text area -->
                            <textarea *ngSwitchCase="'largetext'" matInput formControlName="adv_value"
                                cdkTextareaAutosize [(ngModel)]="fields[i].adv_value" #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3"></textarea>

                            <!-- String -->
                            <input *ngSwitchCase="'text'" type="text" [(ngModel)]="fields[i].adv_value" matInput
                                formControlName="adv_value">

                            <textarea *ngSwitchCase="'textarea'" class="txtAr" matInput
                             formControlName="adv_value"  [(ngModel)]="fields[i].adv_value"></textarea>

                        </ng-container>
                    </mat-form-field>
                </span>
                <!-- END LINE TEXT. Boolea, signature and damages not show this text -->
                <span class="add_contentnext"
                    *ngIf="field.add_contentnext && field.add_type != 'boolean' && field.add_type != 'signature' && field.add_type != 'damages'">
                    {{field.add_contentnext}}
                </span>
                <span *ngIf="env.local && false">
                    <span style="color: red;">Type : {{fields[i].add_type}} | </span>
                    <span style="color: red;">Adv_value : {{fields[i].adv_value}} | </span>
                    <span style="color: blue;">Design_id : {{fields[i].design_id}} | </span>
                    <span style="color: blue;">Value_id : {{fields[i].value_id}}</span>
                </span>
            </p>
        </ng-template>
    </div>
</form>