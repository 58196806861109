import { WorkloadDay } from "./WorkloadDay";

export class Week {
    constructor(public days: WorkloadDay[]) { };
    get first() {
      return this.days[0];
    }
    get last() {
      return this.days[this.days.length - 1];
    }
  
    get containsToday() {
      let today = new Date();
      return this.days.some(d => d.v.isEquals(today))
    }
  
  }