<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>{{data.client ? 'Reasignar cliente' : 'Asignar cliente'}}</p>

<div mat-dialog-content>
    <app-card [contentLoaded]="csearcher.loaded" [noStyle]="true">
        <app-class-searcher [showLeftNumber]="false" #csearcher [masterClass]="client" [allData]="" [width100]="true"
            searchPlaceHolder="Buscar cliente" [extendedInfo]="false" [form_]="form" [formCname]="'client_id'"
            [required]="true" [disableIf]="disabledIf.bind(this)" (onLoad)="loaded = true" 
            [createData]="undefined">
        </app-class-searcher>
    </app-card>
</div>

<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="primary" mat-flat-button
        [disabled]="!form.valid || contact_asign?.selected?.client_id == data.client?.client_id"
        [mat-dialog-close]="csearcher.selected" cdkFocusInitial>Guardar</button>
</div>