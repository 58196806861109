import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getAccentColor, getPrimaryColor } from 'src/app/utils/FunctionUtils';

interface EmailSend{
  title: string;
  body : string;
}

@Component({
  selector: 'app-email-send',
  templateUrl: './email-send.component.html',
  styleUrls: ['./email-send.component.css']
})
export class EmailSendComponent implements OnInit {
  getPrimaryColor = getPrimaryColor;
  accent = getAccentColor;
  constructor(@Inject(MAT_DIALOG_DATA) public data: EmailSend) { }
  ngOnInit(): void {}
}
