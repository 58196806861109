import { Component, OnInit } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { RouterService } from 'src/app/services/router.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-page-not-found-eina',
  templateUrl: './page-not-found-eina.component.html',
  styleUrls: ['./page-not-found-eina.component.css']
})
export class PageNotFoundEinaComponent implements OnInit {
  v = ViewPath;
  constructor(private sessionS : SessionService, private routerS : RouterService) { }
  ngOnInit(): void {}
  dashBoardOrLogin(){
    this.routerS.goTo(this.sessionS.hasSession() ? this.v.dashboard : this.v.dashboard );
  }
}
