<app-page-structure #comercialBudget [masterClass]="'budget'"
    [pageTitle]="'Presupuestos de comercial'" [autocompleteID]="'budget_comercial'"
    listTitleText="Listado de presupuestos"
    searchLabel="Buscar presupuesto"
    noDataCreateNew="Crear nuevo presupuesto" [data]="[]"
    [displayedHeader]=" ['Número',  'Marca',    'Modelo',   'Precio',   'Estado'] "
    [displayedColumns]="['titleBudget',      'brand',    'model',    'price',    'status']"
    [specialText]="     [undefined, brand,      model,      'money',    undefined]"
    [preIcon]="         [preIcon, undefined,  undefined,  undefined,  undefined]" [filter]="f" [filters]="filters"
    [specialClass]="    [undefined, undefined,  undefined,  undefined]"
    [createButton]="
        {
            text : 'Crear presupuesto' ,
            icon : 'calculate',
            view : v.createComercialBudget,
            cssClass : 'onboarding-create-budget-comercial'
        }"
    (onclickRow)="params.go(v.createComercialBudget, $event.id)"
    [quickFilter]="quickFilter">
</app-page-structure>
