<form [formGroup]="form_.form">
    <mat-form-field>
        <mat-label>Notas</mat-label>
        <mat-icon matSuffix *ngIf="canEdit && webkitSpeechRecognitionSupported" class="cp vam" (click)="record($event)"
        [ngClass]="{'recording-icon' : isRecording}">{{isRecording ? 'graphic_eq' : 'mic'}}</mat-icon>
        <textarea matInput formControlName="notes"
        cdkTextareaAutosize
        cdkAutosizeMinRows="7" cdkAutosizeMaxRows="7">
        </textarea>
    </mat-form-field>
</form>
