import { Views } from "../custom-classes/View";
import { MovTypeEnum } from "../enums/MovTypeEnum";
import { M_Contact } from "./M_Contact";
import { M_Fault } from "./M_Fault";
import { M_QuantsProduct } from "./M_QuantsProduct";
import { M_Product } from "./Products/M_Product";
import { ViewPath } from "src/app/app-routing.module";

export class M_Reservation {

  product_id: number | undefined;
  mov_id: number | undefined; // action id 
  mov_type: MovTypeEnum | undefined; // type movement
  quantity: number | undefined;
  location_id: number | undefined;
  client_id: number | undefined;
  item_id: number | undefined;
  fault_id: number | undefined;
  document: string | undefined;
  fault: M_Fault | undefined;
  product: M_Product | undefined;
  quant_reserved: number | undefined;
  quant_fault: number | undefined;
  quant_delivered: number | undefined
  quant_available: number | undefined
  created_at: Date | undefined;
  productsQuants: M_QuantsProduct | undefined;
  client: M_Contact | undefined;

  constructor(d: any) {
    this.product_id = d.product_id;
    this.mov_id = d.mov_id;
    this.mov_type = d.mov_type;
    this.quantity = d.quantity;
    this.location_id = d.location_id;
    this.fault_id = d.fault_id;
    this.client_id = d.client_id;
    this.document = d.document;
    this.created_at = d.created_at ? new Date(d.created_at) : undefined;
    this.item_id = d.item_id;
    this.product = d.product ? new M_Product(d.product) : undefined;
    this.quant_reserved = d.quant_reserved;
    this.quant_delivered = d.quant_delivered;
    this.quant_fault = d.quant_fault;
    this.quant_available = d.quant_available;
    if (d.fault) {
      d.fault['product'] = this;
      this.fault = d.fault ? new M_Fault(d.fault) : undefined;
    }
    if (d.productItemQuants) {
      this.productsQuants = d.productItemQuants;
    }
    if (d.client) {
      this.client = d.client;
    }
  }

  get getDestView(): Views | undefined {
    if (this.mov_type == MovTypeEnum.OR) { return ViewPath.editOr }
    if (this.mov_type == MovTypeEnum.RP) { return ViewPath.createEditWorkshopBudget }
    if (this.mov_type == MovTypeEnum.RM) { return ViewPath.contactDetails }
    return undefined;
  }

  get getMasterId(): number | undefined {
    if (this.mov_type == MovTypeEnum.OR) { return this.mov_id }
    if (this.mov_type == MovTypeEnum.RP) { return this.mov_id }
    if (this.mov_type == MovTypeEnum.RM) { return this.client_id }
    return undefined;
  }

  get reservationWithFault() {
    return this.fault != undefined;
  }

  get faultRequested() {
    return this.fault != undefined && this.fault.requested
  }

  get faultRequestedAndRecived() {
    return this.faultRequested && this.fault?.quantity == this.fault?.received;
  }

}
