<app-card class="sub" [contentLoaded]="subS.status != undefined"
    [ngClass]="{'bc_b1' : subS.status.active || (subS.isPro && subS.status.will_cancel)}">

    <ng-container *ngIf="subS.status.first_payment">
        <div *ngIf="subS.status.active || (subS.isPro && subS.status.will_cancel)" class="member-since">
            Miembro desde {{subS.status.first_payment.getMonthName(true) + ' ' + subS.status.first_payment.getYear(true) }}
        </div>
    </ng-container>

    <app-advanced-details [showButton]="false">
        <app-advanced-title>
            <!-- ACTIVE -->
            <span *ngIf="subS.status.active || (subS.isPro && subS.status.will_cancel)">
                Subscripción PRO
            </span>

            <!-- FINISHED -->
            <span *ngIf="subS.isBasic && subS.someTimeHasPro">
                Sin subscripción
            </span>

        </app-advanced-title>

        <app-advanced-subtitle>
            <span
                *ngIf="subS.status && subS.status.next_payment; else nodate">Próximo pago: {{subS.status.next_payment.humanFormat()}}</span>
            <ng-template #nodate>
                <span class="c_t2">Sin próximo pago</span>
            </ng-template>
        </app-advanced-subtitle>

        <app-advanced-heading>
            {{subS.status.users}} ususarios
        </app-advanced-heading>

        <app-advanced-main-actions>
            <!-- THE PLAN IS GOING TO CANCEL AT THE END OF THE MONTH -->
            <div class="df jcc fww" style="gap: 10px;">
                <button *ngIf="subS.status.will_cancel || (subS.isBasic && subS.someTimeHasPro)" mat-flat-button
                    color="blue" (click)="reactivateSubscription()">
                    <mat-icon>published_with_changes</mat-icon>
                    Reactivar subscripción
                </button>
                <button mat-flat-button (click)="changeCard()" color="primary">
                    <mat-icon>credit_card</mat-icon>
                    Cambiar tarjeta
                </button>
            </div>
        </app-advanced-main-actions>
    </app-advanced-details>

    <p class="fss c_r" *ngIf="subS.isPro && subS.status.will_cancel">Las subscripción finaliza este mes</p>
    <p class="fss c_r" *ngIf="subS.isBasic && subS.someTimeHasPro">Subscripción finalizada</p>

</app-card>


<!-- IF HAS THE PRO PLAN, AND NOT GOING TO CANCEL AT THE END OF THE MONTH... -->
<app-card class="change" *ngIf="!subS.status?.will_cancel" [contentLoaded]="subS.status != undefined">
    <app-card-title>Cancelar subscripción</app-card-title>
    <p class="nmb">Podrás seguir usando el plan PRO hasta la fecha de facturación.</p>
    <div class="mt10">
        <span class="c_r underline_hover fw500" (click)="cancelSubscription()">Cancelar subscripción</span>
    </div>
</app-card>