<div class="order-breakdown">
    <div class="order-header">
        <div class="df">
            <mat-icon>{{section.provider.icon}}</mat-icon>
            <p class="nmb">{{section.provider.name}}</p>
            <span class="c_t2 ml5 mr5"> | </span>
            <mat-icon class="c_t2">{{section.type == TYPEORDER.NORMAL ? 'shopping_cart_checkout': 'bolt'}}</mat-icon>
        </div>
        <div class="order-header-total">
            <p class="nmb">Total : <span class="c_t nmb fw700" money [val]="section.total"></span></p>
        </div>
    </div>
    <div class="table-parent-div">

        <table *ngIf="section.products.length" class="order-breakdown-table">
            <tr>
                <th>Producto</th>
                <th>Cantidad</th>
                <th>Coste</th>
                <th>Importe</th>
            </tr>
            <tr *ngFor="let p of section.products">
                <!--Product-->
                <td>
                    <div class="df aic product-title">
                        <mat-icon smartIcon class="vam mr5">{{p.icon}}</mat-icon>{{p.name}}
                    </div>
                </td>

                <!-- Quantity-->
                <td>
                    <p>{{p.quantity}} <span plural [val]="[p.quantity, 'unidad', 'unidades']"></span></p>

                </td>

                <!-- Cost -->
                <td>
                    <p money [val]="p.buy_price"></p>
                </td>

                <!-- Total -->
                <td>
                    <p money [val]="p.quantity * p.buy_price"></p>
                </td>
            </tr>
        </table>
    </div>
</div>
<div class="mb20"></div>