import { Injectable } from '@angular/core';
import { CustomFile } from '../custom-classes/CustomFile';
import { FileType } from '../enums/FileType';
import { ConfirmDialogService } from './confirm-dialog.service';
import { IUploadComponent } from '../interfaces/IUploadComponent';

const bytesToMegaBytes = (bytes: number) => (bytes / (1024 * 1024));

/** Para usar corregente este servicio, es necesario que el componente que solicite sus funciones
 * implemente la interfaz 'UploadComponent'
 * @description
 * Se encarga de procesar las imágenes/videos subidos por el usuario
 */

@Injectable({
  providedIn: 'root'
})

export class UploadService {
  constructor(private confirmS: ConfirmDialogService) { }

  /** Comprueba que la/s imagen/es no superen el límite de MB establecido por el componente */
  public checkSize(files: File[], uc: IUploadComponent): boolean {
    if (uc.maxImgSize != undefined) {
      for (const file of files) {
        console.log("Current image size " + file.size + "KB");
        console.log("Current image size " + bytesToMegaBytes(file.size) + "KB");
        console.log("Checking if image size is > than " + uc.maxImgSize);
        if (bytesToMegaBytes(file.size) > uc.maxImgSize) {
          this.confirmS.showError("Error al subir la imagen" + file.name, "La imagen supera el tamaño máximo establecido (" + uc.maxImgSize + " MB)")
          return false;
        }
      }
    }
    return true;
  }

  /** Comprueba que no se suban más imagenes de las permitidas */
  public checkLength(f: File[], uc: IUploadComponent) {
    if (uc.maxImagesLength != undefined) {
      if (Array.isArray(uc.uploadedFiles)) {
        if (f.length + uc.uploadedFiles.length > uc.maxImagesLength) {
          this.confirmS.showError("Error al subir la imagen", "No se pueden subir más de " + uc.maxImagesLength + " imágenes");
          return false;
        }
      }
    }
    return true;
  }

  public checkFileType(files: File[], accepted: FileType[]) {
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let cf = new CustomFile("", file, undefined, undefined);
      if (!accepted.includes(cf.getType())) {
        this.confirmS.showError("Error", "Extensión no permitida");
        return false;
      }
    }
    return true;
  }

  /*Esta función se llama al subir una imagen*/
  processFile(imageInput: any, uc: IUploadComponent, accepted: FileType[]) {
    console.log("Starting upload");
    const files: File[] = imageInput.files;
    if (this.checkSize(files, uc) && this.checkLength(files, uc) && this.checkFileType(files, accepted)) {
      for (const file of files) {
        const reader = new FileReader();

        let fs: CustomFile = new CustomFile('', file, undefined, undefined , false, undefined);

        /** Don't push to the array if is phone feature */
        if (!uc.phoneMultiplUploadFeature) {
          if (Array.isArray(uc.uploadedFiles)) {
            uc.uploadedFiles?.push(fs);
          }
          else {
            uc.uploadedFiles = fs;
          }
        }


        /**Este listener, detecta la subida de una imagen. Si se ha subido, se añade por pantalla llamoando a la funcion 'appendOnScreen()' */
        reader.addEventListener('load', (event: any) => {
          fs.src = event.target.result;

          /** Not trigger te upload if is the phone feature */
          if (!uc.phoneMultiplUploadFeature) {
            this.incrementIdManager(fs, uc);
            uc.triggerFileUploaded(fs);
          }
          else {
            uc.triggerFileUploadedPhone(fs);
          }

        });

        reader.readAsDataURL(file,);

      }
    }
  }

  incrementIdManager(cf: CustomFile, uc: IUploadComponent) {
    cf.loaded = true;
    cf.id = uc.idManager;
    uc.idManager != undefined ? uc.idManager++ : '';
    console.log("Triggering the file upload event function");
  }

  /** Exlusive function form mutli phone upload compoenent !! 
   *  Only upload images with no ID.
   *  Pushes it to the array, set the id and increment it.
  */
  multiplePhoneUpload(cf: CustomFile, uc: IUploadComponent) {

    if (cf.id != undefined) {return;}
    if (cf.file == undefined){cf.file = cf.getFile();}

    if (Array.isArray(uc.uploadedFiles)) {
      uc.uploadedFiles?.push(cf);
    }
    this.incrementIdManager(cf, uc);
    uc.triggerFileUploaded(cf);
  }

  onFileDropped(fileList: any, uc: IUploadComponent, accepted: FileType[]) {
    /** Check file type */
    const files: File[] = fileList;
    if (this.checkSize(files, uc) && this.checkLength(files, uc) && this.checkFileType(files, accepted)) {
      for (const file of files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', (event: any) => {
          let fs = new CustomFile(event.target.result, file, uc.idManager);
          if (Array.isArray(uc.uploadedFiles)) {
            uc.uploadedFiles?.push(fs);
          }
          else {
            uc.uploadedFiles = fs;
          }
          uc.triggerFileUploaded(fs);
          uc.idManager != undefined ? uc.idManager++ : '';
        });
      }
    }
  }

  /*Elimina de la pantalla una imagen concreta*/
  removeFile(imgIndex: number, uc: IUploadComponent) {
    if (Array.isArray(uc.uploadedFiles)) {
      for (let i = 0; i < uc.uploadedFiles?.length!; i++) {
        if (uc.uploadedFiles![i].id == imgIndex) {
          uc.triggerRemoveImage(uc.uploadedFiles![i]);
          uc.uploadedFiles?.splice(i, 1);
        }
      }
    }
    else {
      uc.uploadedFiles = undefined;
    }
  }

  /* Reorder images */
  reorderFile(imageToMove: CustomFile, direction: "up" | "down", uc: IUploadComponent) {
    if (Array.isArray(uc.uploadedFiles)) {
      let dir = direction == "up" ? -1 : 1;
      let currentIndex = uc.uploadedFiles.indexOf(imageToMove);
      let targetIndex = currentIndex + dir;
      if (targetIndex >= 0 && targetIndex <= uc.uploadedFiles.length - 1) {
        let otherImage = uc.uploadedFiles[targetIndex];
        uc.uploadedFiles[currentIndex] = otherImage;
        uc.uploadedFiles[targetIndex] = imageToMove;
      }
    }
  }
}