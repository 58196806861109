import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { RouterService } from 'src/app/services/router.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-shared-access-login',
  templateUrl: './shared-access-login.component.html',
  styleUrls: ['./shared-access-login.component.css']
})
export class SharedAccessLoginComponent {

  v = ViewPath;

  public loginForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private apiS : ApiService, private sessoinS : SessionService, private routerS : RouterService) {
    this.loginForm = this.formBuilder.group({
      email: [''],
      password: ['', [Validators.required]],
    });
  }


  login(): void {
    this.apiS.noauth.doLogin(this.loginForm.value).then(resp => {
      this.sessoinS.setToken(resp.access_token);
      this.routerS.goTo(this.v.sharedAccesWorkload)
    })
  }
}
