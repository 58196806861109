<div [ngClass]="{'card' : !noStyle, 'noPadding': nopadding === true, 'bnd' : showNoData(), 'dardkMode': darkmode}"
    #card>
    <div class="fwl" [ngClass]="{'h100' : contentLoaded == false}">
        <div class="df fdc" [ngClass]="{'w100' : contentLoaded == false, 'h100' : contentLoaded == false}">
            <ng-content *ngIf="contentLoaded == undefined || contentLoaded == true; else skeletonLoader"></ng-content>
        </div>
    </div>
    <div class="df fdc aic jcc" style="height: 100%;" *ngIf="showNoData()">
        <div [ngTemplateOutlet]="noDataTemplate"></div>
    </div>
    <div *ngIf="(isSubscriptionLocked || locked) && (contentLoaded == undefined || contentLoaded == true)" class="locked-content" (click)="lockedAction()" [ngClass]="{'cp' : isSubscriptionLocked}">
        <p class="lock-text one-line" [matTooltip]="lockedText ? lockedText : ''">{{lockedText}}</p>
        <mat-icon class="lock" [filled]="true">
            {{isSubscriptionLocked ? 'rocket_launch' : 'lock'}}
        </mat-icon>
    </div>
    <div style="display: block;" class="corner-menu" *ngIf="cornerMatMenu">
        <div *ngIf=" contentLoaded == undefined || contentLoaded == true; else circularSkeleton"
            [ngTemplateOutlet]="cornerMatMenu"></div>
    </div>
</div>

<ng-template #circularSkeleton>
    <app-skeleton [circle]="true" [height]="24" [width]="24"></app-skeleton>
</ng-template>

<ng-template #skeletonLoader>
    <div *ngIf="customSkeleton; else skeleton" [ngTemplateOutlet]="customSkeleton"></div>
    <ng-template #skeleton>
        <app-skeleton [ngClass]="{'first-sekeleton' : nopadding}" [width]="150" [height]="30"></app-skeleton>
        <div class="skeleton-content" [ngClass]="{'lasts-sekeletons' : nopadding}">
            <app-skeleton [fullW]="true" [height]="50"></app-skeleton>
            <app-skeleton [fullW]="true" [height]="50"></app-skeleton>
        </div>
    </ng-template>
</ng-template>



<ng-template #noDataTemplate>
    <p class="c_t2 fsxxl fw300">Sin datos</p>
    <lord-icon src="https://cdn.lordicon.com/vuuletkx.json" colors="primary:#545454" delay="1000" trigger="loop"
        style="width:250px;height:250px"></lord-icon>
</ng-template>