import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { M_User } from 'src/app/models/M_User';
import { endpoints } from '../../../constants/Endpoints';


/**
 * User profile related endpoints
 */
@Injectable({
  providedIn: 'root'
})
export class ApiProfileService {

  constructor(private http: HttpClient) { }

  profile() {
    return new Promise<M_User>(resolve => {
      this.http.get<any>(endpoints.profile.url).subscribe(
        data => {
          resolve(new M_User(data));
        }
      );
    })
  }
  
  logout() {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.logout.url, {}).subscribe(
        data => { resolve(data); }
      );
    })
  }
}
