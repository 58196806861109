import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_TemplateField } from '../models/M_TemplateField';
import { TemplateFieldsService } from './template-fields.service';
import { M_ActiveReception } from '../models/M_ActiveReception';
import { forkJoin } from 'rxjs';
import { PreviewService } from './preview.service';
import { OrFormComponent } from '../components/or-form/or-form.component';
import { OrNotesAndImagesComponent } from 'src/app/views/or-create-edit/or-notes-and-images/or-notes-and-images.component';
import { M_RAGroup } from '../models/M_RAGroup';
import { M_Action } from '../models/M_Action';
import { MatDialogRef } from '@angular/material/dialog';
import { ClientformComponent } from '../components/clientform/clientform.component';
import { VehicleformComponent } from '../components/vehicleform/vehicleform.component';
import { M_Contact } from '../models/M_Contact';
import { M_Vehicle } from '../models/Vehicles/M_Vehicle';
import { CompanyService } from './EinaMainData/company.service';
import { ConfirmDialogService } from './confirm-dialog.service';
import { CustomFile } from '../custom-classes/CustomFile';


type createOrInterface = {
  /** Main OR form */
  orForm: OrFormComponent,
  /** Ra Groups */
  raGroups: M_RAGroup[],
  /** Notes and images Component */
  notesAndImages?: OrNotesAndImagesComponent,
  /** Dialog to close when OR is created */
  dialog?: MatDialogRef<any>,
  /** Client form component*/
  clientForm?: ClientformComponent,
  /** Vehicle form component */
  vehicleForm?: VehicleformComponent,
  /** Appointment ID */
  appoitnmentId?: number,
  /** Is copy action? */
  transferData?: {
    budget_id: number;
    to: "or" | "budget"
  }
}

@Injectable({
  providedIn: 'root'
})


/** Service to create OR */
export class OrService {

  constructor(private apiS: ApiService, private companyS: CompanyService,
    private confirmD: ConfirmDialogService, private templateService: TemplateFieldsService, private previewS: PreviewService) {
  }

  create(orComponents: createOrInterface) {
    if (this.companyS.companyMissingInfo == false) {
      if (orComponents.orForm.isFormOk) {
        orComponents.orForm.form.patchValue({ "assigned_to": orComponents.orForm.workerSearcher?.selected?.id })

        let preClient: M_Contact | undefined = undefined;
        let preVehicle: M_Vehicle | undefined = undefined;

        if (orComponents.clientForm && orComponents.vehicleForm) {
          preClient = new M_Contact(orComponents.clientForm.form.getRawValue())
          preVehicle = new M_Vehicle(orComponents.vehicleForm.form.getRawValue());
          preClient.vehicles = [preVehicle];
        }

        let ra = new M_ActiveReception(
          orComponents.orForm.vehicleSearcher?.selected?.client?.client_id,
          orComponents.orForm.clientInvoice?.selected?.client_id,
          orComponents.orForm.vehicleSearcher?.selected?.vehicle_id,
          orComponents.orForm.form.value,
          orComponents.raGroups,
          preClient,
          preVehicle,
          orComponents.orForm.clientInvoice?.selected?.client_id == undefined ? 2 : undefined
        );
        /** Creating OR from appointment */
        if (orComponents.appoitnmentId) { ra.appointment_id = orComponents.appoitnmentId };

        this.templateService.showTemplateSteps("RA", undefined, undefined, orComponents.orForm.vehicleSearcher?.selected?.type, ra).afterClosed().subscribe
          ((dialog_res: { models: M_TemplateField[], files: M_TemplateField[] } | false) => {
            if (dialog_res != false) {
              if (orComponents.transferData) {
                this.apiS.transferAction(orComponents.transferData.budget_id, orComponents.transferData.to, {
                  client : ra.client,
                  ra : ra
                }).then(res => {
                  this.afterOrCreation(ra, orComponents, dialog_res, res)
                })
              }
              else {
                this.apiS.action.createOR(ra).then(res_create_or => {
                    this.afterOrCreation(ra, orComponents, dialog_res, res_create_or)
                });
              }
            }
          });
      }
    }
    else {
      this.confirmD.showError("Error en el formulario", "Se requiere como mínimo introducir un vehículo, sus kilómetros y los datos de Empresa")
    }
  }

  /** When the OR is created, upload the images and save the template fields */
  afterOrCreation(ra : M_ActiveReception, orComponents : createOrInterface, dialog_res: { models: M_TemplateField[], files: M_TemplateField[] },  or_creation_res : { "or_id": number, "token": string, "action_id": number }){
    if (or_creation_res.or_id) {
      ra.id = or_creation_res.or_id;
      if (orComponents.notesAndImages) {
        let files = orComponents.notesAndImages.fileupload.getUploadedFiles();
        if (files) { this.uploadImage(ra, ...files) };
      }
      const a = this.apiS.saveTemplateFields(or_creation_res.action_id!, dialog_res.models);
      const b = this.apiS.saveTemplateFiles(or_creation_res.action_id!, dialog_res.files);
      forkJoin([a, b]).subscribe(res => {
        this.previewS.showPreview("RA", or_creation_res.token, undefined, ra.id, false, true);
        if (orComponents.dialog) { orComponents.dialog.close(); }
      })
    }
  }

  /** Upload images to some action */
  uploadImage(or: M_Action | M_ActiveReception, ...cf: CustomFile[] | File[]) {
    if (cf && or.client_id && or.vehicle_id) {
      cf.forEach(cf => {
        this.apiS.uploadImage(cf, "action_image", ["action_id", or.id!], ["client_id", or!.client_id!], ["vehicle_id", or!.vehicle_id!]).then(res => {
          if (res.image_id && cf instanceof CustomFile) {
            cf.database_id = res.image_id;
          }
        })
      })
    }
  }

}
