import { Component, HostListener, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import * as LogRocket from 'logrocket';
import { ReferenceService } from 'src/app/services/onboarding.service';
import { MenuComponent } from 'src/app/components/menu/menu.component';
import { feature } from 'src/app/utils/FeaturesController';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { EnumSubscription } from 'src/app/enums/EnumSubscription';
import { RolesEnum } from 'src/app/enums/RolesEnum';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { ModulesEnum } from 'src/app/enums/ModulesEnum';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/Api/api.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { StorageService } from 'src/app/services/storage.service';
import { SvgIconsService } from 'src/app/services/svg-icons.service';
import { RouterService } from 'src/app/services/router.service';
import { EntryPointService } from 'src/app/services/entry-point.service';
import { SessionService } from 'src/app/services/session.service';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { WelcomeComponent } from 'src/app/components/welcome/welcome.component';
import { HubspotService } from 'src/app/services/hubspot.service';
import { PRO, PRO_CANCELED, PRO_ENDED, TRIAL, TRIAL_END } from 'src/app/services/EinaMainData/fakeSubscriptionLocal';
import { DragTutorialComponent } from '../carga-taller/drag-tutorial/drag-tutorial.component';
import { DeveloperHelperComponent } from 'src/app/components/developer-helper/developer-helper.component';

@Component({
  selector: 'app-root',
  templateUrl: './entry-point.component.html',
  styleUrls: ['./entry-point.component.css']
})
export class EntryPointComponent {
  @ViewChild(DeveloperHelperComponent) developerHelper? : DeveloperHelperComponent;
  v = ViewPath;
  @HostListener('window:resize', ['$event'])

  onResize(event: any) {
    this.responsive.width = event.target.innerWidth;
  }
  re = RolesEnum;
  mod = ModulesEnum;
  displayToolbar = false;
  es = EnumSubscription;
  items = this.storageS.getAllStorage();
  allModules = this.getAllModules();

  /** Developer helper subscriptions */
  trial = TRIAL;
  trial_end = TRIAL_END;
  pro = PRO;
  pro_canceled = PRO_CANCELED;
  pro_ended = PRO_ENDED;

  userinput: string[] = []
  keyWorkds = ['help', 'ayuda', 'design', 'diseño', 'docu'];
  dHelper = ['developer'];
  dialog = ['onboarding', 'welcome'];
  workloadDrag = ['dragtuto']
  /** ONLY LOCAL or DEV ---> FRONTEND DOCUMENTATION */
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (environment.local) {
      if (this.userinput.length == 30) {
        this.userinput.splice(0, 10);
      }
      this.userinput.push(event.key.toLocaleLowerCase());
      for (let i = 0; i < this.userinput.length; i++) {
        for (let j = i + 1; j <= this.userinput.length; j++) {
          const subcadena = this.userinput.slice(i, j).join('');
          if (this.keyWorkds.includes(subcadena)) {
            this.routerS.goTo(ViewPath.frontend, true);
            this.userinput = [];
            return;
          }
          if (this.dialog.includes(subcadena)) {
            this.d.open(WelcomeComponent);
            this.userinput = [];
            return;
          }
          if (this.workloadDrag.includes(subcadena)) {
            this.d.open(DragTutorialComponent);
            this.userinput = [];
            return;
          }
          if (this.dHelper.includes(subcadena)) {
            this.developerHelper?.setMaximized();
            this.userinput = [];
            return;
          }
        }
      }
    }
  }

  constructor(public responsive: ResponsiveService, private routerS: RouterService, router: Router, private d: MatDialog,
    private sessionS: SessionService, entrypointS: EntryPointService, public refSercice: ReferenceService, private apiS: ApiService,
    public subS: SubscriptionService, private storageS: StorageService, private svgS: SvgIconsService, public userS: UserService,
    public companyS: CompanyService, private matIconReg: MatIconRegistry, private hubSpot: HubspotService) {

    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');
    this.svgS.addSvgIcons("wheel", "wheel_outline", "bizum", "damages_bike", "barcode", "file-pdf", "eina", 'no_provider', "reserve", "reserve_active");

    if (feature.logRocket) {
      LogRocket.init('rfqxuq/eina');
    }

    this.responsive.width = window.innerWidth;
    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        window.scroll(0, 0);
      }
      if (val instanceof NavigationEnd) {
        this.displayToolbar = entrypointS.currentViewNeedToolbar();
        if (this.displayToolbar && !this.sessionS.hasSession()) {
          this.routerS.goTo(this.v.login);
        }
      }
    });
  }



  /** ---------------- */
  /** Developer helper */
  /** ---------------- */
  toggleMenu(menu: MenuComponent, phonemenu: MenuComponent) {
    this.responsive.isPhone() ? phonemenu.toggle() : menu.toggle();
  }

  setRole(n: RolesEnum) {
    this.apiS.test.changeRoleTest(n).then(res => {
      this.userS.setRole(n);
      this.routerS.refresh();
    })
  }

  rIs(n: RolesEnum) {
    return this.userS.getRole() == n;
  }

  getAllModules(): [string, number][] {
    const enumKeys = Object.keys(ModulesEnum) as Array<keyof typeof ModulesEnum>;
    const enumValues = enumKeys
      .filter(key => !isNaN(Number(ModulesEnum[key])));
    return enumValues.map(key => [key, ModulesEnum[key]] as [string, number]);
  }

  onChangeModule(val: number) {
    this.apiS.toggleModule(val).then(res => {
      this.companyS.toggleModule(val);
    })
  }

}


