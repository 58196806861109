<app-close-dialog-button class="shared-close"></app-close-dialog-button>
<div class="dialog-background">
  <h2>¿Quién eres?</h2>
  <div class="user-list">
    <div class="user-card" *ngFor="let user of data">
      <app-circular-letter [checkIsMe]="false" [medium]="true" [showTooltip]="false" [showFullName]="false" [user]="user"
        (click)="selectUser(user)"></app-circular-letter>
      <p class="c_w tac">{{user.name}}</p>
    </div>
  </div>
</div>