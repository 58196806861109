import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[dropDown]',
})
export class DropdownDirective implements OnInit {

  /** Emits if the dropdown is opened */
  @Output() onToggle: EventEmitter<boolean> = new EventEmitter();

  /** Trigger */
  @Input({ required: true }) trigger!: HTMLElement;

  /** Content to toggle */
  @Input({ required: true }) content!: HTMLElement;

  /** Scale the trigger ? */
  @Input() scale: boolean = true;

  /** Is the drop down disabled ? */
  @Input() disabled: boolean = false;

  /** Star closed ? */
  @Input() starClosed: boolean = false;

  open = true;

  constructor(private el: ElementRef<HTMLElement>) {
    el.nativeElement.classList.add("toggle_arrow")
  }

  ngOnInit(): void {
    this.trigger.classList.add("toggle_trigger");
    this.trigger.onclick = () => {
      if (!this.disabled) {
        this.toggle();
      }
    }
    if (this.starClosed){
      this.toggle();
    }
  }

  toggle() {
    /** Show the content */
    if (!this.open) { this.openToggle(); }
    /** Hide the content */
    else { this.closeToggle(); }

  }

  private openToggle() {
    this.content.classList.remove("dn_i");
    this.el.nativeElement.classList.remove("rotated");
    this.trigger.classList.remove("rotated");
    if (this.scale) {
      this.el.nativeElement.classList.remove("scaled");
      this.trigger.classList.remove("scaled");
    }
    this.open = true;
    this.onToggle.emit(this.open)
  }

  private closeToggle() {
    this.content.classList.add("dn_i");
    this.el.nativeElement.classList.add("rotated");
    this.trigger.classList.add("rotated");
    if (this.scale) {
      this.el.nativeElement.classList.add("scaled");
      this.trigger.classList.add("scaled");
    }
    this.open = false;
    this.onToggle.emit(this.open)
  }

  openIfClosed() {
    if (!this.open) { this.openToggle(); }
  }


}
