<table class="today-breakdown" *ngIf="signingData?.now?.scheduleHasMorining || signingData?.now?.scheduleHasAfternoon">
    <tr>
        <th>Entrada</th>
        <th>Salida</th>
        <th>Motivo</th>
    </tr>
    <tr *ngIf="signingData?.now?.scheduleHasMorining" (click)="openPresenceEdit(signingData?.now)">
        <td>{{ signingData?.now?.morningStart?.hourMinuteSecond() ||
            "--:--:--"}}</td>
        <td>{{ signingData?.now?.morningEnd?.hourMinuteSecond() ||
            "--:--:--"}}</td>
        <td class="motivo">Oficina</td>
    </tr>
    <tr *ngIf="signingData?.now?.scheduleHasAfternoon" (click)="openPresenceEdit(signingData?.now)">
        <td>{{ signingData?.now?.afternoonStart?.hourMinuteSecond() ||
            "--:--:--"}}</td>
        <td>{{ signingData?.now?.afternoonEnd?.hourMinuteSecond() ||
            "--:--:--"}}</td>
        <td class="motivo">Teletrabajo</td>
    </tr>
</table>