import { Component, Input } from '@angular/core';
import { Filter, UserFilter } from 'src/app/custom-classes/Filter';
import { M_Report } from 'src/app/models/Reports/M_Report';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent {

  @Input({ required: true }) report!: M_Report;

  constructor(public companyS: CompanyService) {

  }
}
