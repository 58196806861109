import { Component, ElementRef, Input, OnInit, Optional } from '@angular/core';
import { Views } from '../../../custom-classes/View';
import { CardComponent } from '../card.component';
import { RouterService } from '../../../services/router.service';

/** Calass for (`app-card-title` and `app-card-subtitle`) */
@Component({
  selector: 'app-card-subtitle, app-card-title',
  templateUrl: './base-title-component.html',
  styleUrls: ['../card.component.css']
})
export class BaseTitleComponent implements OnInit {

  /** Sets the margin-bottom to 0px !imporant; */
  @Input() noMarginBottom = false;
  /**Show a pencil at the start of the title*/
  @Input() edit = false;
  /**Show an arrow `(-->)` the end of te title ?  */
  @Input() details = false;
  /** Show an (i) icon at the end of the title */
  @Input() info: string | undefined;
  /** Tooltip for the icon ?  */
  @Input() iconTooltip: string | undefined;
  /** View to go when the title is clicked */
  @Input() view: Views | undefined;
  /** Sets cursor pointer on hover the title */
  @Input() cursorPointer = false;
  /** Use the app primary color ? */
  @Input() primaryColor = false;
  /** Is the the first element (subtutitle)? */
  @Input() first = false;
  /** Right title icon */
  @Input() rightIcon? : string;

  /** Do not assign */
  isTitle = false;

  constructor(@Optional() public card: CardComponent, private routerS: RouterService, private elementRef: ElementRef) {
    const element = this.elementRef.nativeElement as HTMLElement;
    this.isTitle = element.matches('app-card-title');
  }

  ngOnInit(): void {
    if (this.edit == true || this.view != undefined || this.details == true) {
      this.cursorPointer = true;
    }
  }

  goTo() {
    this.routerS.goTo(this.view!);
  }

  get getIconTooltip() {
    return this.iconTooltip ? this.iconTooltip : '';
  }

  emitClick() {
    if (this.view != undefined) {
      this.goTo();
    }
  }
}
