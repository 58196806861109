import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { M_DiscountGroup } from 'src/app/models/M_DiscountGroupLine';
import { ParamsService } from 'src/app/services/params.service';

@Component({
  selector: 'app-discount-groups',
  templateUrl: './discount-groups.component.html',
  styleUrls: ['./discount-groups.component.css']
})
export class DiscountGroupsComponent implements OnInit {

  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_DiscountGroup>;

  v = ViewPath;

  constructor(private apiS: ApiService, public paramsS: ParamsService) { }

  ngOnInit(): void {
    this.apiS.getAllDiscountGroups().then(res => {
      this.ps.initTable(res);
    })
  }
}
