import { Component, Input} from '@angular/core';

/** Circular mat-mini-fab button */
@Component({
  selector: 'app-email-button',
  templateUrl: './email-button.component.html'
})
export class EmailButtonComponent {
  @Input() email : string | undefined;
  constructor() { }
}
