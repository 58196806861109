<div class="df fdc jcsa dialog">
    <app-close-dialog-button></app-close-dialog-button>
    <div class="m10"></div>
    <mat-dialog-content class="df fdc jcsa" style="scrollbar-width: none;">
        <div class="df vac p5">
            <app-card-title class="fwb jcc">{{pm.transac_name}}</app-card-title>
            <mat-icon class="ml10 c_b" [matTooltip]="pm.coment!"> info</mat-icon>
        </div>
        <div>
            <div>
                <div class="df jcsb">
                    <div class="df vac">
                        <mat-icon class="mr10">insert_drive_file</mat-icon>
                        <p> {{pm.document}} </p>
                    </div>
                    <div class="df vac" *ngIf="pm.location_name">
                        <mat-icon class="mr10">warehouse</mat-icon>
                        <p> {{pm.location}}</p>
                    </div>
                </div>
                <div class="df jcsb">
                    <div class="dg">
                        <p><span class="fwb">Referencia: </span>{{pm.ref_cli}} </p>
                        <p *ngIf="pm.ref_factory"><span class="fwb">Referencia fabricante: </span>{{pm.ref_factory}}
                        </p>
                    </div>
                    <div class="df jcc" style="aspect-ratio: 8rem;" *ngIf="pm.user">
                        <app-circular-letter class="mt10" style="min-width: 90px;" [user]="user" [showFullName]="true"
                            [showTooltip]="false" [big]="false"></app-circular-letter>
                    </div>
                </div>

            </div>
            <div class="m10"></div>
            <app-card class="bc_b0" [contentLoaded]="loaded">
                <app-card-title [first]="true">Detalles stock</app-card-title>
                <div class="df w100 jcsb">
                    <p>
                        <span class="fwb">Cantidad: </span>
                        <span prod-move-number [val]="pm.quanti_mov"></span>
                    </p>
                    <div *ngIf="this.provider">
                        <p><span class="fwb">Proveedor: </span>{{this.provider.name}}</p>
                    </div>

                </div>
                
                <!-- <div class="df w100 jcsb">
                    <p>
                        <span class="fwb">Stock anterior: </span>
                        <span prod-move-number [val]="pm.balance"></span>
                    </p>
                    <p>
                        <span class="fwb">Stock posterior: </span>
                        <span prod-move-number [val]="pm.available"></span>
                    </p>
                </div> -->

                <app-card-title class="mt10" [first]="true">Detalles gestión</app-card-title>
                <div class="df jcsb">
                    <p *ngIf="pm.pvp != undefined"><span class="fwb">PVP: </span><span money [val]="pm.pvp"></span></p>
                    <p *ngIf="pm.pmp != undefined"><span class="fwb">PMP: </span><span money [val]="pm.pmp"></span></p>
                    <p *ngIf="pm.discount != undefined"><span class="fwb">Descuento: </span><span money [val]="pm.discount"></span> </p>
                </div>


                <ng-container *ngIf="pm.porte != undefined || pm.amount != undefined">
                    <mat-divider></mat-divider>
                    <div class="w100 df aife mt5">
                        <div class="w100 df aife" style="gap: 1rem; justify-content: flex-end;">
                            <p *ngIf="pm.porte != undefined" ><span class="fwb">Coste: </span><span money [val]="pm.porte"></span></p>
                            <p *ngIf="pm.amount != undefined" ><span class="fwb">Importe: </span><span money [val]="pm.amount"></span></p>
                        </div>
                    </div>
                </ng-container>

            </app-card>
            <app-contact-buttons>
                <p class="c_t2">{{pm.created_at?.dayMonthYearFormat()}}</p>
            </app-contact-buttons>
        </div>
    </mat-dialog-content>
</div>