import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HourPickerComponent } from '../hour-picker/hour-picker.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TimeService } from '../../services/time.service';
import { M_Appointment } from '../../models/M_Appointment';

@Component({
  selector: 'app-modify-appointment-hour',
  templateUrl: './modify-appointment-hour.component.html',
  styleUrls: ['./modify-appointment-hour.component.css']
})

export class ModifyAppointmentHourComponent implements OnInit {
  @ViewChild(HourPickerComponent) hourpciker : HourPickerComponent | undefined;
  form: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<ModifyAppointmentHourComponent>, @Inject(MAT_DIALOG_DATA) public data: M_Appointment,
              public timeService : TimeService, private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      hour :  ['', [Validators.required]], //La hora y el dia, se juntan en una string al pasarla al servidor
      day: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  /**Establece la fecha y la hora del componente 'hour-picker' */
  ngAfterViewInit(){
    this.hourpciker?.setDay(this.data.date_appointment, undefined); // No hace falta passar el company_id
    this.hourpciker?.setHour(this.data.date_appointment);
  }

}
