import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { passwordValidator } from '../../validators/password-validator';
import { BaseInput } from './base-input';


/**
 * [Component]
 * Input para introducir una password con ciertos requisitos :
 *  * 1 mayúsculam 
 *  * 1 minúscula 
 *  * 1 número 
 *  * 8 caráteres
 */

@Component({
  selector: 'app-password-input',
  styleUrls: [],
  template: `<div class="{{class_}}" [formGroup]="form" class="input-full-width mb10">
              <mat-form-field  appearance="outline" class="customInput no-wrapper">
                    <mat-label>{{label ? label : 'Contraseña'}}</mat-label>
                    <input #passwRef autocomplete="new-password" matInput type="password" formControlName="{{formCName}}" required readonly onfocus="this.removeAttribute('readonly');" [attr.autocomplete]="disableAutocomplete ? 'off' : null">
                    <button type="button" (click)="passwRef.type=='text'? passwRef.type='password' : passwRef.type='text'" mat-icon-button matSuffix>
                        <mat-icon *ngIf="passwRef.type == 'password'">visibility</mat-icon>
                        <mat-icon *ngIf="passwRef.type == 'text'">visibility_off</mat-icon>
                    </button>
              </mat-form-field>
              <span *ngIf="hasPasswordError(); else ok">
                      <div [ngClass]=" getError().length > 1 ? 'errors': ''">
                        <div *ngFor="let error of getError(); first as isFirst">
                          <div class="df aic" [ngStyle]="isFirst? {'margin-bottom':'0px'} : {'margin-bottom':'0px'}">
                            <mat-icon style="height: auto;"  [ngClass]="error.hasError() ? 'inputError' : 'inputOk'">{{error.hasError()? 'close' : 'done'}}</mat-icon>
                            <span [ngClass]="error.hasError() ? 'inputError' : 'inputOk'">{{error.errorName}}</span>
                          </div>
                        </div>
                      </div>
                    </span>
                    <ng-template #ok >
                      <div *ngIf="touched" class="df aic">
                        <mat-icon style="height: auto;" class="inputOk">done</mat-icon>
                        <span class="inputOk">Contraseña válida</span>
                      </div>
                    </ng-template>
            </div>`
})
export class PasswordInputComponent extends BaseInput implements OnInit {
  @HostBinding('class') classes = 'passwordInput ' + this.classSelector;

  constructor(public changeDetectorr: ChangeDetectorRef) {
    super(changeDetectorr)
  }

  override ngAfterViewInit() {
    this.control?.setValidators([passwordValidator(), Validators.required])
    this.onValueChange(() => this.control!.markAllAsTouched())
  }

  /** Mira si en el formulario hay el error de la password */
  hasPasswordError() {
    const c = this.control;
    return (c?.errors != undefined && this.touched) &&
      (c?.errors[errors.may.keyError] != undefined ||
        c?.errors[errors.min.keyError] != undefined ||
        c?.errors[errors.num.keyError] != undefined ||
        c?.errors[errors.len.keyError] != undefined ||
        c?.errors[errors.req.keyError] != undefined)
  }

  getError(): ErrorValue[] {
    let allErrors: ErrorValue[] = []
    Object.keys(errors).forEach(key => {
      let currentError = errors[key as keyof typeof errors];
      if (this.control!.errors![currentError.keyError] != undefined) {
        allErrors.push(
          new ErrorValue(currentError, this.control!.errors![currentError.keyError]))
      }
    });
    return allErrors;
  }
}

interface errorInterface {
  keyError: string;
  errorName: string;
}

interface ErrorValueInterface {
  err: [errorInterface, boolean]
}

class ErrorValue implements ErrorValueInterface {
  err: [errorInterface, boolean];
  constructor(error: errorInterface, value: boolean) {
    this.err = [error, value];
  }
  get errorName() {
    return this.err[0].errorName;
  }

  hasError() {
    return this.err[1]
  }
}

const errors = {
  may: { keyError: "passMay", errorName: "1 mayúscula" },
  min: { keyError: "passMin", errorName: "1 minúscula" },
  num: { keyError: "passNum", errorName: "1 número" },
  len: { keyError: "passLen", errorName: "8 caráteres" },
  req: { keyError: "passRequired", errorName: "Este campo es requerido" }
}