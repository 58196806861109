<!-- Optional close button-->
<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title>Facturas generadas</p>

<!-- Dialog content -->
<div mat-dialog-content>
    <ng-container *ngFor="let i of invoices;">
        <p mat-dialog-subtitle>Factura {{i.invoice_name}}</p>
        <button mat-stroked-button (click)="showPreview(i.token)">Ver factura</button>
    </ng-container>
</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button color="primary" mat-flat-button mat-dialog-close cdkFocusInitial>Ok</button>
</div>