import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

/** Mat button positioned on top right of a dialog.
 * 
 *  It prevents you from closing a dialog by clicking outside of it.
 * 
 *  On click this component the dialog is closed with `false` or `@Input() onCloseData`
 */
@Component({
  selector: 'app-close-dialog-button',
  template: `
  <div style="position: relative;">
    <button mat-icon-button (click)="dialogRef.close(onCloseData)" class="dRef-close" >
      <mat-icon>close</mat-icon>
    </button>
  </div>`,
  styles: ['.dRef-close{ position: absolute; right: 0px;top: 0px; z-index:1;}']
})
export class CloseDialogComponent {
  @Input() onCloseData: any;
  constructor(public dialogRef: MatDialogRef<any>) {
    dialogRef.disableClose = true;
  }
}
