import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ApiService } from 'src/app/services/Api/api.service';
import { ViewPath } from 'src/app/app-routing.module';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { WelcomeComponent } from 'src/app/components/welcome/welcome.component';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { M_Subscription } from 'src/app/models/M_Subscription';
import { WorkloadService } from 'src/app/services/workload.service';
import { M_EinaData } from 'src/app/models/M_EinaData';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { EinaDataService } from 'src/app/services/EinaMainData/eina-data.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { StorageService } from 'src/app/services/storage.service';
import { SessionService } from 'src/app/services/session.service';
import { RouterService } from 'src/app/services/router.service';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends ParameterStateComponent {
  v = ViewPath;
  @ViewChild(MatStepper) stepper!: MatStepper;
  public loginForm: UntypedFormGroup;
  public forgotPassword: UntypedFormGroup;
  hash: string = "";
  email: string = "";
  constructor(private sessionService: SessionService, private formBuilder: UntypedFormBuilder, private formService: FormService,
    private apiS: ApiService, private d: MatDialog, private routers: RouterService, router: ActivatedRoute, private einaDataS: EinaDataService,
    private companyS : CompanyService,private storageS: StorageService, private workloadS: WorkloadService,
    private subscrptionS: SubscriptionService) {
    super(routers, router, ["hash", "email"]);
    this.loginForm = this.formBuilder.group({
      email: [''],
      password: ['', [Validators.required]],
    });

    this.forgotPassword = this.formBuilder.group({
      email: [''],
    });
  }

  override onParam(param: string, value: string): void {
    if (param == "email") {
      this.loginForm.patchValue({ email: value });
    }
  }

  makeCall(): void {
    if (this.formService.isOk(this.loginForm)) {
      this.apiS.noauth.doLogin(this.loginForm.value).then(resp => {
        /** Clear all the storage */
        this.storageS.clear();
        this.sessionService.setToken(resp.access_token);
        this.einaDataS.destroyData();
        this.einaDataS.setNewEinaData(new M_EinaData(resp));
        if (this.companyS.companyFirstLogin) {
          this.d.open(WelcomeComponent, { disableClose: true, panelClass: "welcome-dialog" })
        }
        this.subscrptionS.setSubscriptionStatus(new M_Subscription(resp.subscription));
        this.workloadS.refresh();
        this.routers.goTo(this.v.dashboard)
      })
    }
  }

  sendRecoverPassword() {
    const call = this.apiS.noauth.forgotPaswword(this.forgotPassword.get('email')?.value);
    call.then(res => { this.stepper.selectedIndex = 0; })
    return call;
  }

  isEmailBadFormetted() {
    const emailInput = this.loginForm.get('email');
    return (emailInput?.errors != undefined && emailInput?.errors['email'] != undefined && emailInput.dirty && emailInput.touched);
  }

  goForgetPassword() {
    this.stepper.next();
  }

  goRegister() {
    this.routers.goTo(this.v.register);
  }

  goLogin() {
    this.stepper.previous();
  }

  /**Evitar que el stepper marque los formularios en rojo */
  stepChanged(stepper: any) {
    stepper.selected.interacted = false;
  }
}