<ng-container *ngIf="isOnDialog">
  <app-close-dialog-button></app-close-dialog-button>
  <p mat-dialog-title>Editar OR</p>
</ng-container>


<div mat-dialog-content [ngClass]="!isOnDialog ? 'conditional-mat-dialog-content' : ''">

  <form class="form" style="max-width: unset;" *ngIf="!actionFailedToLoad; else notfound">

    <div class="container" [ngClass]="{'mech-container' : userS.userIsMechanic}">
      <div class="title">
        <app-go-back [text]="'Listado OR'" [v]="v.or" [show]="actionLoaded && allInvoiced"></app-go-back>
      </div>
      <div class="or_abstract">
        <app-card [contentLoaded]="actionLoaded" class="bc_b1 full-height-or">
          <div [ngClass]="{'dn_i' : !action}">

            <app-advanced-details *ngIf="action">

              <app-advanced-title>
                <div class="df fww aic gap5">
                  {{action.title_id}}
                  <div class="df aic">
                    <app-or-status-label class="ml5-or-status" [showText]="false" [action]="action"
                      [circularStatus]="responsiveS.w <= responsive.header"></app-or-status-label>
                    <app-or-type-label [wrapOn]="responsiveS.w < responsive.header ? 1 : 2" class="ml5"
                      [showText]="false" [tipo]="action? action.type : undefined"></app-or-type-label>
                  </div>
                </div>
              </app-advanced-title>

              <app-advanced-subtitle>
                Creada el {{action.created_at.dayMonthYearFormat()}}
              </app-advanced-subtitle>

              <app-advanced-main-actions>
                <div [moreThan]="responsive.invoiceButton"
                  [matTooltip]="!action.hasClosedGroups()? 'Debes cerrar alguna intervención para poder facturarla' : ''">
                  <button subscribed-user mat-flat-button [roles]="[R.ADMIN, R.GESTOR, R.ADVISER]" color="primary"
                    [disabled]="!action.hasClosedGroups()" (click)="openInvoiceDialog()" class="mr25on368">
                    Facturar
                  </button>
                </div>
                <app-documentation-pdf-menu class="mla" [action]="action"></app-documentation-pdf-menu>
              </app-advanced-main-actions>

              <app-advanced-menu-actions>

                <div *ngIf="userS.userIsAdminOrAdviser;">
                  <button mat-menu-item matTooltipPosition="left" matTooltip="Se creará una OR a partir de esta"
                    *ngIf="!this.dragComponent?.action?.isVnVoOr" (click)="trasnferTo(drag, 'or')">
                    <mat-icon>build</mat-icon>
                    Nueva OR
                  </button>

                  <div [matTooltipPosition]="'left'"
                    [matTooltip]="toBudgetButton.disabled ? 'La OR tiene grupos internos' : ''"
                    *ngIf="!this.dragComponent?.action?.isVnVoOr">
                    <button #toBudgetButton mat-menu-item (click)="trasnferTo(drag, 'budget')"
                      [disabled]="hasSomeInterno()">
                      <mat-icon>calculate</mat-icon>
                      Nuevo presupuesto
                    </button>
                  </div>
                </div>

                <div class="envioMails" [roles]="[R.ADMIN, R.GESTOR, R.ADVISER]">
                  <div class="w100" style="display: contents;">
                    <app-layout-send-email [butontype]="'mat-menu-item'" *ngIf="action.status.num == 1"
                      [temaplte]="'recogida'" [endpoint]="pickUpMail.bind(this)"
                      style="justify-content: start;"></app-layout-send-email>
                  </div>
                </div>

                <button mat-menu-item [disabled]="!action.canDelete" (click)="deleteAction()">
                  <mat-icon>delete</mat-icon> Eliminar
                </button>

              </app-advanced-menu-actions>
            </app-advanced-details>

            <app-advanced-details-footer>

              <app-footer-title>Ver más datos de OR</app-footer-title>

              <app-footer-right *ngIf="action">
                <div class="df gap5">
                  <app-contact-client-rectangle *ngIf="action.client" [onlyIconOn]="responsive.rectangle"
                    [client]="action.client"></app-contact-client-rectangle>
                  <app-vehicle-rectangle *ngIf="action.vehicle" [vehicle]="action.vehicle"
                    [onlyIconOn]="responsive.rectangle"></app-vehicle-rectangle>
                </div>
              </app-footer-right>

              <app-footer-content>

                <!-- NOTES AND IMAGES -->
                <app-section>
                  <app-section-title>Datos adicionales</app-section-title>
                  <app-section-content>
                    <app-or-notes-and-images [orFormComponent]="orForm"></app-or-notes-and-images>
                  </app-section-content>
                </app-section>

                <app-section [lessOr]="responsive.timelapse" class="timelapse-section">
                  <app-section-title>Paso de taller</app-section-title>
                  <app-section-content>
                    <div [ngTemplateOutlet]="timelapse"></div>
                  </app-section-content>
                </app-section>

                <app-section [lessOr]="responsive.time">
                  <app-section-title>Fichaje</app-section-title>
                  <app-section-content>
                    <div [ngTemplateOutlet]="time"></div>
                  </app-section-content>
                </app-section>

              </app-footer-content>
            </app-advanced-details-footer>
          </div>
        </app-card>
      </div>

      <div class="timelapse" [moreThan]="responsive.timelapse">
        <app-card [contentLoaded]="actionLoaded" class="different-padding-top">
          <app-card-subtitle [first]="true">
            <div class="df aic">
              Paso de taller
              <button mat-icon-button [disabled]="true"></button>
            </div>

          </app-card-subtitle>
          <div class="card-contents-220 card-wrapper">
            <div [ngTemplateOutlet]="timelapse"></div>
          </div>
        </app-card>
      </div>

      <div class="worker">
        <app-card [contentLoaded]="actionLoaded" class="different-padding-top">
          <app-card-subtitle [first]="true">
            <div class="df aic gap10">
              <span>Operario</span>
              <app-circular-letter [user]="orForm.workerSearcher?.selected"
                [showFullName]="false"></app-circular-letter>
              <mat-icon *ngIf="workerChange" class="unsavedDot vam" [filled]="true"
                [matTooltip]="'Cambio de operario no guardado'">circle</mat-icon>
              <div class="mla">
                <button [not-roles]="R.MECHANIC" mat-mini-fab color="primary" *ngIf="orForm.workerSearcher"
                  (click)="changeWorker(orForm.workerSearcher.allData, orForm.workerSearcher.selected)">
                  <mat-icon>change_circle</mat-icon>
                </button>
              </div>
            </div>
          </app-card-subtitle>
          <div [ngClass]="{'card-contents-220' : responsiveS.w > responsive.time}" class="card-wrapper">
            <app-or-form #orForm [isEdit]="true" [loaded]="actionLoaded" [showWokerInput]="false"
              [wrapper]="false"></app-or-form>
          </div>
        </app-card>
      </div>

      <div class="time" [moreThan]="responsive.time">
        <app-card [contentLoaded]="actionLoaded" class="different-padding-top">
          <div [ngTemplateOutlet]="time"></div>
        </app-card>
      </div>
    </div>
  </form>


  <div class="or" [ngClass]="{'dn' : actionFailedToLoad}">
    <app-card [contentLoaded]="actionLoaded" [userNeedSubscription]="true">
      <app-card-title>Intervenciones</app-card-title>
      <app-drag #drag [action]="action" [isBudgetPage]="false" (onRemoveChildGroupTask)="checkOthersInvoiced($event)"
        (onInvoiceChildTask)="openDialog($event)">
      </app-drag>
    </app-card>
  </div>

</div>


<app-floating-save-button [show]="drag.hasChanges() || unsavedChanges"
  (click)="saveScreen(drag)"></app-floating-save-button>

<ng-template #timelapse>
  <app-timelapse *ngIf="action" [timelapse]="action.timelapse" [currentItemId]="action.id"
    [currentItemType]="TLE.OR"></app-timelapse>
</ng-template>

<ng-template #time>
  <div *ngIf="action">
    <app-card-subtitle [first]="true">
      <div class="df aic">
        <span>Fichaje <span *ngIf="otd.someTimeFinished">{{otd.getTotalTime()?.format}}</span></span>
        <div class="or_time_actions">
          <div class="df jcc fww aic gap10" [ngClass]="{'vh' : !action.canAddTime()}">
            <!--Work button -->
            <button color="primary" (click)="startStopTime()" mat-flat-button class="work-or custom-button">
              <mat-icon>{{isOperatorWorking() ? 'stop_circle' : 'play_circle'}}</mat-icon>
              {{isOperatorWorking() ? isOperatorWorking()!.elapsed : 'Trabajar'}}
            </button>
            <!--Add manual time button -->
            <div (click)="addTime()">
              <button mat-mini-fab color="primary" [matTooltip]="'Añadir fichaje manualmente'"
                [matTooltipPosition]="'above'">
                <mat-icon>add_circle</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </app-card-subtitle>
    <div class="card-contents-220 card-wrapper">
      <app-history-time-or-dialog #otd [or]="action" [workers]="formComponent?.workerSearcher?.allData"
        [canRemove]="!action.isDone()">
      </app-history-time-or-dialog>
    </div>
  </div>
</ng-template>


<ng-template #notfound>
  <app-model-not-found [title]="'No se ha encontrado la orden de reparación'"
    [goText]="'Ir al listado de órdenes de reparación'" [view]="v.or">
  </app-model-not-found>
</ng-template>