import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Invoice as M_Invoice } from 'src/app/models/M_Invoice';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { M_Product } from 'src/app/models/Products/M_Product';
import { IvachangerComponent } from './ivachanger/ivachanger.component';
import { ViewPath } from 'src/app/app-routing.module';
import { MASTER_C_CLIENT } from 'src/app/constants/masters';
import { PreviewService } from 'src/app/services/preview.service';
import { CreateInvoiceDialogComponent, invoiceDialogData } from './create-invoice-dialog/create-invoice-dialog.component';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { IProductLineTableComponent } from 'src/app/interfaces/IProductLineTableComponent';
import { ProductLineTableComponent } from 'src/app/components/product-line-table/product-line-table/product-line-table.component';
import { IExitSaveChanges } from 'src/app/interfaces/IExitSaveChanges';
import { CanInvoiceService } from 'src/app/services/can-invoice.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { FormService } from 'src/app/services/form.service';
import { RouterService } from 'src/app/services/router.service';
import { SnackService } from 'src/app/services/snack.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { endpoints } from 'src/app/constants/Endpoints';
import { ParamsService } from 'src/app/services/params.service';

@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.css']
})
export class CreateInvoiceComponent extends ParameterStateComponent implements OnInit, IProductLineTableComponent, IExitSaveChanges {

  client = MASTER_C_CLIENT;

  @ViewChild(ProductLineTableComponent) productLineTable!: ProductLineTableComponent;
  get blocksLine(): boolean { return true; }

  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;
  @ViewChild(ClassSearcherComponent) searchUser?: ClassSearcherComponent<M_Contact>;
  e = endpoints;
  contentLoaded = false;
  v = ViewPath;
  public form: UntypedFormGroup;
  id = 0;
  iva: UntypedFormControl;
  currentBill: M_Invoice | undefined;
  recoveredDraft = false;
  onboardinSearchStepPassed = false;
  productIdOnLoad: number | undefined = undefined;
  updatingLine: Promise<any> | undefined;
  forceExit = false;
  formChanged = false;

  constructor(private formBuilder: UntypedFormBuilder, private fs: FormService, routerS: RouterService, public companyS: CompanyService,
    private apiS: ApiService, public d: MatDialog, public router: Router, private snackS: SnackService, route: ActivatedRoute,
    private confirmD: ConfirmDialogService, private previewS: PreviewService, public canInvoiceS: CanInvoiceService,
    private paramsS: ParamsService) {
    super(routerS, route, ['newproductid']);
    this.form = this.formBuilder.group({
      client_id: [''],
      comments: [''],
    });

    this.iva = new UntypedFormControl(21);

  }

  override onParam(k: string, v: string) {
    if (k == 'newproductid') {
      this.productIdOnLoad = Number(v);
    }
  }

  ngOnInit(): void {
    this.initPage();
  }

  showSaveExitDialog(): boolean {
    return this.currentBill != undefined && this.invoiceHasChanges && !this.currentBill.destroyed && !this.currentBill.created;
  }

  saveChangesBeforeExit(): void {
    this.saveInvoice();
  }

  get isDeleteDisaled() {
    return !this.searchUser || this.currentBill?.someProductRequested;
  }



  addProduct(p: M_Product | M_CustomProduct): void {
    if (p.instanceofProduct()) {
      this.currentBill?.breakdown.products.push(p)

    }
    else {
      this.currentBill?.breakdown.customs.push(p)
    }
  }

  removeProduct(p: M_CustomProduct | M_Product): void {
    if (!p.line_id) { this.removeProdFromModel(p); return; }

    this.apiS.delProdInvoice(p.line_id).then(res => {
      this.removeProdFromModel(p);
    })

  }

  private removeProdFromModel(p: M_CustomProduct | M_Product) {
    if (p.instanceofProduct()) {
      this.currentBill?.breakdown.products.removeElement(p)
    }
    else {
      this.currentBill?.breakdown.customs.removeElement(p)
    }
  }

  addTime(time: M_CustomProduct): void {
    throw new Error("Can't add time on invoice");
  }

  addComment(comment: M_CustomProduct): void {
    throw new Error('Method not implemented.');
  }

  getClientDiscount(p: M_Product | M_CustomProduct): number | null {
    let disc = this.searchUser?.selected?.getClientDiscount(p);
    return disc ? disc : null;
  }

  initPage() {
    this.apiS.createBill().then(res => {
      if (!this.isDraft(res)) {
        this.currentBill = new M_Invoice({ id: res.new });
        this.recoveredDraft = false;
      }
      else {
        this.currentBill = new M_Invoice(res.draft);
        this.form.patchValue({ 'comments': this.currentBill.comment });
        if (this.currentBill.breakdown) {
          this.searchUser?.select(this.currentBill!.client_id);
          this.recoveredDraft = true;
          this.snackS.show("Borrador de factura recuperado")
        }
      }
      /** If a product Id is on Params (productIdOnLoad) and the id is not on the current Invoice, add the product on the invoice */
      if (this.productIdOnLoad != undefined && this.currentBill.breakdown.products.filter(p => p.product_id == this.productIdOnLoad).length == 0) {
        this.apiS.getProductById(this.productIdOnLoad).then(product => {
          if (product) {
            product.initQuanitity(0);
            product.setQuantity(1);
            product!.discount = this.getClientDiscount(product);
            product.line_hasChanges = true;
            this.addProduct(product);
            this.contentLoaded = true;
          }
        })
      }
      else {
        this.contentLoaded = true;
      }
    })
  }

  disacrdDraft() {
    this.confirmD.show({
      title: "Eliminar borrador",
      body: "Se eliminará el borrador de factura. ¿Quieres proceder?",
      confirmTxt: "Ok",
      type: "danger"
    }).afterClosed().subscribe(res => {
      if (res == true) {
        if (this.currentBill?.id) {
          this.apiS.discardDraft(this.currentBill!.id!).then(_res => {
            this.currentBill!.destroyed = true;
            this.routerS.refresh();
          })
        }
      }
    })
  }

  isDraft(res: any) {
    return res.draft != undefined;
  }

  saveInvoice(): Promise<boolean> {
    if (!this.invoiceHasChanges || !this.currentBill) { return Promise.resolve(true); }
    return this.apiS.saveInvoice(this.currentBill.id!, this.form.get('comments')?.value, this.currentBill.breakdown.allWithChanges, this.form.get('client_id')?.value).then(res => {
      if (res != undefined) {
        res.forEach((line_id, index) => {
          let prod = this.currentBill!.breakdown.allWithChanges[index];
          if (prod) { prod.setLineId(line_id); }
        })
        this.currentBill!.breakdown.markSaved();
      }
      this.formChanged = false;
      return Promise.resolve(true);
    })
  }

  canCreateInvoice(): boolean {
    if (!this.currentBill || !this.fs.isOk(this.searchUser!.form) || this.companyS.companyMissingInfo || !this.isProductsOk() || this.currentBill.getTotalBreakdown(false).total < 0) { return false; }
    return true;
  }

  createBill() {
    this.saveInvoice().then(res => {
      this.d.open<CreateInvoiceDialogComponent, invoiceDialogData>(
        CreateInvoiceDialogComponent,
        {
          data: {
            contact: this.searchUser?.selected
          }
        }).afterClosed().subscribe(res => {
          if (res.date) {

            let date = new Date(res.date);
            let expedient = res.expedient;

            if (this.updatingLine) {
              this.updatingLine.then(res => {
                this.confirmInvoice(date, expedient);
              })
            }
            else {
              this.confirmInvoice(date, expedient);
            }
          }
        })
    })
  }

  confirmInvoice(date: Date, expedient: string | undefined) {
    this.apiS.confirmInvoice(this.currentBill!.id!, this.currentBill!.getTotalBreakdown(false).total, date, expedient).then(_res => {
      if (_res.token) {
        this.apiS.getInvoiceId(this.currentBill!.id!).then(res => {
          if (res) {
            let bill = new M_Invoice(res);
            this.previewS.showPreview("I", _res.token, undefined, undefined, undefined, undefined, bill);
          }
        });

      }
      this.currentBill!.created = true;
      this.paramsS.go(this.v.invoiceDetails, this.currentBill!.id!)
    });
  }

  isProductsOk() {
    let leastOneProduct = this.currentBill?.breakdown.all.length != 0;
    return leastOneProduct;
  }

  openChangeIva() {
    this.d.open(IvachangerComponent).afterClosed().subscribe(v => {
      if (typeof v == "number")
        this.iva.setValue(v);
    })
  }

  get invoiceHasChanges() {
    return this.currentBill != undefined &&
      (this.currentBill.breakdown.changes ||
        this.formChanged);
  }

  get isClientOk() {
    return !this.classSearcherRequired() || (this.classSearcherRequired() && this.form.get('client_id')?.value);
  }

  classSearcherRequired() {
    return this.currentBill != undefined && this.currentBill.getTotalBreakdown(false).total >= 400
  }

  getClient() {
    return this.searchUser?.selected;
  }

}
