import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { M_DiscountGroup, M_DiscountGroupLine } from 'src/app/models/M_DiscountGroupLine';
import { ApiService } from 'src/app/services/Api/api.service';
import { RouterService } from 'src/app/services/router.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-create-edit-discount-group',
  templateUrl: './create-edit-discount-group.component.html',
  styleUrls: ['./create-edit-discount-group.component.css']
})
export class CreateEditDiscountGroupComponent extends ParameterStateComponent {

  editing: M_DiscountGroup | undefined;
  creatringGroups: M_DiscountGroupLine[] = [];
  loadingGroup = false;
  fc: FormControl = new FormControl("");

  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService, private snackS: SnackService) {
    super(routerS, route, [""]);
    this.fc.addValidators(Validators.required)
  }

  override onParam(param: string, value: string): void {
    this.apiS.getDiscountGroupById(value.getNumber()).then(res => {
      this.editing = res;
      this.loadingGroup = false;
    })
  }

  get discoungGroups() {
    return this.editing ? this.editing.lines : this.creatringGroups;
  }

  get isEdit() {
    return this.editing != undefined;
  }

  deleteLine(line: M_DiscountGroupLine) {
    this.discoungGroups.removeElement(line)
  }

  appendLine() {
    this.discoungGroups.push(new M_DiscountGroupLine({}))
  }

  createEdit() {
    this.apiS.addLinesToDiscountGroup(this.editing?.id, this.fc.value, this.discoungGroups).then(res => {
      this.routerS.goTo(ViewPath.discountGroups);
      this.snackS.show("Groupo de descuento editado")
    })
  }

  get isOkDisabled() {
    const discoungGroups = this.discoungGroups;
    let groupsNotOk = discoungGroups.some(dg => dg.code_line == "" || dg.code_line == null || dg.code_line == undefined) ||
      discoungGroups.some(dg => dg.p_group == "" || dg.p_group == null || dg.p_group == undefined) ||
      discoungGroups.some(dg => dg.p_type_1 > 100 || dg.p_type_2 > 100 || dg.v_cat_1 > 100 || dg.v_cat_2 > 100 || dg.v_cat_3 > 100 || dg.v_cat_4 > 100 || dg.v_cat_5 > 100)
    return groupsNotOk || !this.fc.valid;
  }

}
