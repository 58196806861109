<mat-form-field appearance="outline" [formGroup]="form" class="w100 chassis-form-field">
    <app-async-validator-spinner matSuffix [control]="control"></app-async-validator-spinner>
    <mat-label>Chasis</mat-label>
    <input placeholder="El chasis debe ser de 7 o 17 caracteres" matInput formControlName="chassis"
        [maxlength]="MAX_LENGTH" (input)="upperCase()">
    <mat-hint align="end">
        {{controlLen + '/' + MAX_LENGTH}}
    </mat-hint>
    <mat-error>
        <span *ngIf="control?.hasError('pattern')">
            El chasis debe contener 7 o 17 caracteres. ({{ controlLen + '/' + MAX_LENGTH}})
        </span>
        <span *ngIf="control?.hasError('chassis-exists')">
            El chasis ya existe.
        </span>
    </mat-error>
</mat-form-field>