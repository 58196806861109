import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, finalize, Observable, tap, throwError } from "rxjs";
import { CustomLog } from "../custom-classes/CustomLog";
import { CustomErrorHandler } from "../custom-classes/CustomErrorHandler";
import { M_Parser } from "../custom-classes/ModelParser";
import { DeveloperService } from "../services/developer.service";
import { EndpointService } from "../services/endpoint.service";
import { SessionService } from "../services/session.service";
import { ConfirmDialogService } from "../services/confirm-dialog.service";
import { LoadingPanelService } from "./LoadingPanel/loading-panel.service";
import { Endpoint } from "../custom-classes/Endpoint";
import { environment } from "src/environments/environment";
import { projectConfiguration } from "../app.module";

/** 
 * Intercepts the http calls.
 * 
 * `Loading panel` : Shows at the start of the request and hides it at the end.
 * `Authorization header` : Included automatically if the endpoint requires it.
 * 
 * `Errors` : If the call returns an error :
 * - DEV AND PRE : Show the full stacktrace on a dialog.
 * - PRO : If the error has a message, only show the message. Otherwise, show a  generic error.
 *  
 */

@Injectable()
export class httpListenerService implements HttpInterceptor {
    constructor(private endpointS: EndpointService,
        private confirmD: ConfirmDialogService,
        private loadingPanel: LoadingPanelService,
        private sessionService: SessionService,
        private errorS: CustomErrorHandler,
        private developerS: DeveloperService) { }


    isDeployInfoEndpoint(req: HttpRequest<any>) {
        return req.url.includes('/assets/deploy-info.json');
    }

    /** Don't show loading if the route is assets/icons/something.svg
     * Example :http://localhost:4200/assets/icons/foo.svg
    */
    isSvgEndpoint(req: HttpRequest<any>) {
        let isSvg = req.url.includes("/assets/icons/") && req.url.includes(".svg");
        let isFlag = req.url.includes("assets/svg-country-flags/svg") && req.url.includes(".svg");
        return isSvg || isFlag;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.isDeployInfoEndpoint(req)) {
            return next.handle(req);
        }

        let endp: Endpoint | undefined = this.endpointS.getEnpointFromUrl(req.url);

        if ((endp && endp.showLoading) || (endp == undefined && !this.isSvgEndpoint(req))) {
            // console.log(endp != undefined ? "Interceptado el endpoint : " + endp.url : "El endpoint interceptado no se reconoce")
            this.loadingPanel.show();
        }

        let authReq: HttpRequest<any>;
        let finalreq: HttpRequest<any>;

        //Miramos si es necessario añadir el header de autorización
        if ((endp != undefined && endp.appendHeader)) {
            authReq = req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + this.sessionService.getToken()
                }
            });
            // console.log("Añadiendo el header de autorización al endpoint : " + endp.url)
        }
        else {  //En caso de que no sea necesario el header de autorización 
            authReq = req.clone();
        }

        if (req.method == "POST") {
            // ¡IMPORTANTE! Si el objeto que passamos al hacer 'post', implmenta la interfaz 'M_PARSER',
            // se modifican los atributos necessarios del objeto

            if (this.sessionService.isSharedAccessView) {
                req = this.addSharedUser(authReq);
                authReq = this.addSharedUser(authReq);
            }

            if (this.needsParse(req.body)) {
                // 'Parseamos' el objeto
                // console.log("El objeto necesita ser parseado antes de ser enviar la petición")
                // console.log("Objeto antes de ser 'parseado' : ")
                // console.log(req.body)
                if (Array.isArray(req.body)) {
                    let copy = { ...req.body };
                    for (let i = 0; i < req.body.length; i++) {
                        if (this.needsParse(copy[i])) {
                            copy[i] = copy[i].parse();
                        }
                    }
                    finalreq = authReq.clone({ body: copy })
                }
                else {
                    finalreq = authReq.clone({ body: req.body.parse() })
                }
                // console.log("Objeto despues de ser 'parseado' : ")
                // console.log(req.body)
            }
            else {
                finalreq = authReq.clone({})
            }

            if (endp) {
                this.developerS.onEndpointResponse.next([endp, "POST", new Date(), req.body]);
            }
        }
        else {
            finalreq = authReq.clone();
        }

        return next.handle(finalreq).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if (endp) {
                        if (endp && req.method == "GET") {
                            this.developerS.onEndpointResponse.next([endp, "GET", new Date(), evt.body]);
                        }
                        else {
                            this.developerS.onEndpointResponse.next([endp, "RESP", new Date(), evt.body]);
                        }
                    }
                }
            }),
            finalize(() => this.loadingPanel.hide()))
            .pipe(
                /**Gestión de errores de las peticiones http */
                catchError((error: HttpErrorResponse) => {
                    this.loadingPanel.hide();
                    console.log(error);
                    var coreErrorCodes = this.getErrorCode(error);

                    /** Show the panel only if the endpoint is undefined or the endpoint has the customError to false */
                    if (endp == undefined || endp.customError == false) {
                        /** NO SESSION */
                        if (this.isNoSessionError(error)) {
                            let redirect = true;
                            if (endp != undefined && !endp.appendHeader) { redirect = false; }
                            if (redirect) { this.sessionService.clearSession(true); }
                        }
                        /** ERROR CODES */
                        else if (coreErrorCodes != undefined) {
                            /** We KNOW what error is, this is becaouse we put 'Ha surgido un error' */
                            this.confirmD.showError("Ha surgido un error", coreErrorCodes.errorMessage);
                            if (coreErrorCodes?.errorCode == 1) {
                                let redirect = true;
                                if (endp != undefined && !endp.appendHeader) { redirect = false; }
                                if (redirect) { this.sessionService.clearSession(true); }
                            }
                        }
                        /**TIMEOUT */
                        else if (this.isTimeOutError(error)) {
                            this.confirmD.showError("Alcanzado tiempo de espera máximo", "Revise su conexión a internet");
                        }
                        else {
                            /** Show genereic HTTP error */
                            this.confirmD.showGenericHttpError(error);
                        }
                    }

                    /** On PROD, report the error to backend */
                    if (environment.production) {
                        this.errorS.report(new CustomLog("error", JSON.stringify(error)))
                    }

                    return throwError(() => new Error(this.confirmD.getMessageError(error)));
                }
                )
            )
    }

    private addSharedUser(req: HttpRequest<any>) {
        let sharedReq: HttpRequest<any> = req.clone();
        const currentUser = this.sessionService.sharedUserId;

        if (!currentUser) { return req; }

        if (sharedReq.body instanceof FormData) {
            sharedReq.body.append("shared_user", currentUser.toString());
        }
        else if (typeof sharedReq.body === 'object' && sharedReq.body !== null) {
            const modifiedBody = {
                ...sharedReq.body,
                shared_user: currentUser
            };
            sharedReq = sharedReq.clone({ body: modifiedBody });
        }
        return sharedReq;
    }

    private getErrorCode(resp: HttpErrorResponse): { errorCode: number, errorMessage: string } | undefined {
        var error_code = resp.error.error_code;
        if (projectConfiguration.errorCodes) {
            let error_message = projectConfiguration.errorCodes.get(error_code);
            if (typeof error_code == "number" && error_message) {
                return { errorCode: error_code, errorMessage: error_message };
            }
        }
        return undefined;
    }

    private isNoSessionError(error: HttpErrorResponse) {
        return error.error.message === this.sessionService.NO_SESSION;
    }

    private isTimeOutError(error: HttpErrorResponse) {
        return error.error.type && error.error.type == "timeout";
    }

    /**Comprueba si el objecto implmenta la interfaz {@link M_Parser} */
    needsParse(body: any): body is M_Parser {
        if (Array.isArray(body)) {
            let needparse = false;
            body.forEach(i => {
                needparse = "needsToBeParsed" in i;
            })
            return needparse;
        }
        else {
            return "needsToBeParsed" in body;
        }
    }

    parseDateFormat(obj: any): any {
        return (obj.parse());
    }
}