import { Component, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { CentersFilter, Filter, FilterOption, TagFilter } from 'src/app/custom-classes/Filter';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { M_AccountingGroup } from 'src/app/models/AccountingGroups/M_AccountingGroup';
import { ApiService } from 'src/app/services/Api/api.service';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { EinaDataService } from 'src/app/services/EinaMainData/eina-data.service';
import { ParamsService } from 'src/app/services/params.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-accounting-groups',
  templateUrl: './accounting-groups.component.html',
  styleUrls: ['./accounting-groups.component.css']
})
export class AccountingGroupsComponent {
  v = ViewPath;
  f = filter;
  
  filters: Filter[] = [
    new TagFilter("Tipo", undefined, new FilterOption("Comercial", "garage"), new FilterOption("Tiempo", "schedule"), new FilterOption("Productos", "barcode")),
    new CentersFilter(this.einaDataS)
  ]

  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_AccountingGroup>;
  constructor(public params: ParamsService, private apiS: ApiService, private einaDataS: EinaDataService, public companyS: CompanyService,
    public routerS: RouterService) {
    this.apiS.accountingGroups().then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
      }
    });
  }

  get quickFilter() {
    let f = this.filters.find(f => f instanceof TagFilter);
    return f instanceof TagFilter ? f : undefined;
  }
}
