import { Directive, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';

/** Interface for environment directives */
interface IEnvironmentDirective {
  get isEnvironmentOk(): boolean;
}

/** Funcion for all environment directives */
function environmentIsNotOk(el: ElementRef) {
  el.nativeElement.classList.add('dn_i');
  el.nativeElement.remove();
}

/** If is not LOCAL, hide the element */
@Directive({
  selector: '[local]'
})
export class LocalDirective implements IEnvironmentDirective {
  constructor(el: ElementRef) {if (!this.isEnvironmentOk) { environmentIsNotOk(el); }}
  get isEnvironmentOk(): boolean {return environment.local;}
}

/** If is not DEV, hide the element */
@Directive({
  selector: '[dev]'
})
export class DevDirective implements IEnvironmentDirective {
  constructor(el: ElementRef) {if (!this.isEnvironmentOk) { environmentIsNotOk(el); }}
  get isEnvironmentOk(): boolean {return environment.dev;}
}

/** If is not PRE, hide the element */
@Directive({
  selector: '[pre]'
})
export class PreDirective implements IEnvironmentDirective {
  constructor(el: ElementRef) {if (!this.isEnvironmentOk) { environmentIsNotOk(el); }}
  get isEnvironmentOk(): boolean {return environment.preproduction;}
}

/** If is not PROD, hide the element */
@Directive({
  selector: '[prod]'
})
export class ProdDirective implements IEnvironmentDirective {
  constructor(el: ElementRef) {if (!this.isEnvironmentOk) { environmentIsNotOk(el); }}
  get isEnvironmentOk(): boolean {return environment.production;}
}


/** ------ COMBINATION ------- */

/** If is not LOCAL or DEV, hide the element */
@Directive({
  selector: '[local-dev]'
})
export class LocalDevDirective implements IEnvironmentDirective {
  constructor(el: ElementRef) {if (!this.isEnvironmentOk) { environmentIsNotOk(el); }}
  get isEnvironmentOk(): boolean {return environment.local || environment.dev;}
}

/** If is not LOCAL, DEV or PRE, hide the element */
@Directive({
  selector: '[dev-pre]'
})
export class DevPreDirective implements IEnvironmentDirective {
  constructor(el: ElementRef) {if (!this.isEnvironmentOk) { environmentIsNotOk(el); }}
  get isEnvironmentOk(): boolean {return environment.dev || environment.preproduction;}
}

/** If is not LOCAL, DEV or PRE, hide the element */
@Directive({
  selector: '[local-dev-pre]'
})
export class LocalDevPreDirective implements IEnvironmentDirective {
  constructor(el: ElementRef) {if (!this.isEnvironmentOk) { environmentIsNotOk(el); }}
  get isEnvironmentOk(): boolean {return environment.local || environment.dev || environment.preproduction;}
}