import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { M_Contact } from 'src/app/models/M_Contact';
import { validatorTax } from '../validators/tax';
import { MASTER_CLIENT_MINIFIY, MASTER_VECHILE_MINIFY } from '../constants/masters';
import { PdfDocumentsUploadComponent } from '../components/pdf-documents-upload/pdf-documents-upload.component';
import { ClassSearcherComponent } from '../components/class-searcher/class-searcher.component';

@Component({
  selector: 'app-sell-vehicle-form',
  templateUrl: './sell-vehicle-form.component.html',
  styleUrls: ['./sell-vehicle-form.component.css']
})
export class SellVehicleFormComponent implements OnChanges, OnInit  {
  @Input() notVehicle: boolean = false;
  @Input() purchaseType: number = 1;
  @Output() formSubmitted = new EventEmitter<FormData>();
  client = MASTER_CLIENT_MINIFIY;
  vehicle = MASTER_VECHILE_MINIFY;
  form!: FormGroup;
  minDate: Date;
  client_select : M_Contact | undefined
  vehicle_select : M_Vehicle | undefined;
  @ViewChild(PdfDocumentsUploadComponent) pdfUploadComponent!: PdfDocumentsUploadComponent;
  @ViewChild('csearcher') clientSearcherComponent?: ClassSearcherComponent<M_Contact>
  @ViewChild('vehicleSearcher') vehicleSearcherComponent?: ClassSearcherComponent<M_Vehicle>
  constructor(private formBuilder: UntypedFormBuilder,private chdRef: ChangeDetectorRef) {
    this.minDate = new Date();
    
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['purchaseType']) {
      this.vehicleSearcherComponent?.remove();
      this.form.patchValue({vehicle_id:undefined});
      this.form.patchValue({client_id:undefined});
      this.initializeForm();  // Vuelve a inicializar el formulario si cambia la pestaña
     }
  }
  ngOnInit(): void {
    this.initializeForm();
  }
  initializeForm() {
    this.vehicleSearcherComponent?.remove();
    this.clientSearcherComponent?.remove();
    if (this.notVehicle) {
      // Formulario para compra de recambio
      this.form = this.formBuilder.group({
        client_id: ['',Validators.required],
        price: ['', Validators.required],
        tax: ['', Validators.required],
        comment: [''],
        pdf_file: [''],
        docum_prov_num: ['', Validators.required],
        date: [''],
        token: ['CV'], 
        type:[],
        buy_transac_id: [''],
      });
    } else {
      // Formulario para compra de vehículo
      this.form = this.formBuilder.group({
        vehicle_id: ['',Validators.required],
        client_id: ['',Validators.required],
        price: ['', Validators.required],
        tax: ['', Validators.required],
        comment: [''],
        pdf_file: [''],
        docum_prov_num: [''],
        date: [''],
        token: ['CV'],
        type:[''],
        mode:[''],
        buy_transac_id: [''],
      });
    }
    if (this.notVehicle) {
      // Formulario para compra de recambio, no se necesita vehicle_id
      this.form.get('vehicle_id')?.clearValidators();
    }
    else {
      // Formulario para compra de vehículo
      this.form.get('vehicle_id')?.setValidators(Validators.required); // Obligatorio si es un vehículo
  
      // Verifica el tipo de cliente y ajusta los validadores
      this.form.get('client_id')?.valueChanges.subscribe(clientId => {
        if (clientId) {
          if (this.client_select) {
            // Aquí puedes definir tus condiciones según el tipo de cliente
            if (this.client_select.type === 2 || this.client_select.type === 3 ) {
              // Si el tipo de cliente es 2 o 3, podrías requerir otro campo
              this.form.get('vehicle_id')?.setValidators(Validators.required); // Por ejemplo
              this.form.get('docum_prov_num')?.setValidators(Validators.required); // Por ejemplo

            } else {
              // Si no es 2 o 3, no lo requiere
              this.form.get('vehicle_id')?.clearValidators(); 
              this.form.get('docum_prov_num')?.clearValidators(); 
            }
          }
        }
  
        // Actualiza la validez del formulario después de cambiar validadores
        this.form.get('vehicle_id')?.updateValueAndValidity();
      });
    }
  }
  handleClientSelection(selectedClient: M_Contact) {
    this.client_select = selectedClient;
    if(this.client_select != undefined && this.client_select.type === 2 || this.client_select?.type === 3){
      this.chdRef.detectChanges();
    }
  }
  changeClient(v: M_Vehicle) {
    
     this.vehicle_select = v;
     this.chdRef.detectChanges(); //
  }
  disabledIf(contact : M_Contact){
    if(contact.type == 2 && this.notVehicle){
      return false
    }else if(contact.type == 3 && this.notVehicle){
      return false
    }
    else if(!this.notVehicle){
      return false
    }
    return true
   }
   getform(){
    return this.form.value;
   }
}
