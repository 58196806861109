import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Appointment } from '../../models/M_Appointment';
import { M_Company } from '../../models/M_Company';
import { UserService } from '../../services/EinaMainData/user.service';
import { RolesEnum } from 'src/app/enums/RolesEnum';
import { CustomDateByGranularity, DateByGranularity } from 'src/app/custom-classes/CustomDateByGranularity';
import { DateGranularity } from 'src/app/enums/DateGranularity';
import { SnackService } from 'src/app/services/snack.service';
import { AppointmentStatus } from 'src/app/enums/AppointmentStatus';
import { CALENDAR_COMPANY, CALENDAR_MINI_COMPANY, CALENDAR_MINI_SCHEDULE, CALENDAR_USER_HOLIDAY } from 'src/app/constants/constants';
import { MatDialog } from '@angular/material/dialog';
import { ExceptionsData, ExceptionsRes, HolidaysScheduleDayDialogComponent } from './holidays-schedule-day-dialog/holidays-schedule-day-dialog.component';
import { M_Schedule } from 'src/app/models/M_Schdeule';

export type initialHolidayData = {
  companyH: Date[];
  userH: Date[];
  exceptions: M_Schedule[];
  userID?: number;
};

@Component({
  selector: 'app-holidays-schedule-calendar',
  templateUrl: './holidays-schedule-calendar.component.html',
  styleUrls: ['./holidays-schedule-calendar.component.css', './holiday-colors.css'],
})
export class HolidaysScheduleCalendarComponent implements OnInit, OnChanges {
  @ViewChild('datePicker', { static: false }) caledar?: MatCalendar<Date>;
  @ViewChildren('anually') caledarAnual?: QueryList<MatCalendar<Date>>;
  /** The component can also be initied by param. If the aparams changes, the component re-init itselef */
  @Input() initData?: initialHolidayData;
  /** Array of role numbers that can edit the calendar */
  @Input() enabledFor: RolesEnum[] = [];
  /** Show a save button on the bottom of the calendar. By default false */
  @Input() selfSave: boolean = false;
  /** Prespective of the calendar */
  @Input() prespective: 'center' | 'user' = 'center';
  /** Show legend */
  @Input() showLegend: boolean = true;
  /** Show legend but only exceptional days */
  @Input() showLegendExceptionalDays: boolean = false;
  /** Show unaved changes label? */
  @Input() showUnsaved: boolean = true;
  /** Show save button */
  @Input() showSaveButton: boolean = true;
  /** When the changes are saved */
  @Output() onSave: EventEmitter<initialHolidayData> =
    new EventEmitter<initialHolidayData>();

  /** ---------- MAIN ARRAYS ---------- */
  /** Holidays to show on the calendar */
  companyHolidays: Date[] = [];
  /** User holidays */
  userHolidays: Date[] = [];
  /** Schedule exceptions */
  exceptions: M_Schedule[] = [];

  /** The id of the user on which the holidays will be set */
  userID: number | undefined = undefined;
  /** Is the calendar enbled for the current user? */
  enabled: boolean = false;
  /** Bottom contents of the caledar. Shows all months that have holidays with the respective number of days */
  holidayBreakdown: DateByGranularity[] = [];
  /** If ther is some changes on calendar, show unsaved changes */
  somethingChanges = false;
  /** Current user role */
  userRole: number;
  /** Is calendar loading ? */
  loading = true;
  year: number = new Date().getFullYear();
  months = [
    { number: 0, name: 'Enero' },
    { number: 1, name: 'Febrero' },
    { number: 2, name: 'Marzo' },
    { number: 3, name: 'Abril' },
    { number: 4, name: 'Mayo' },
    { number: 5, name: 'Junio' },
    { number: 6, name: 'Julio' },
    { number: 7, name: 'Agosto' },
    { number: 8, name: 'Septiembre' },
    { number: 9, name: 'Octubre' },
    { number: 10, name: 'Noviembre' },
    { number: 11, name: 'Diciembre' },
  ];
  startDate: Date;
  selectedDates: Date[] = [];
  constructor(private apiService: ApiService, public snackS: SnackService, private cdr: ChangeDetectorRef, userS: UserService,
    private d: MatDialog) {
    this.userRole = userS.getRole()!;
    this.startDate = new Date(this.year, 0, 1);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initData']) {
      this.initComponent(changes['initData'].currentValue);
    }
  }

  ngOnInit(): void {
    this.enabled = this.enabledFor.some((r) => r == this.userRole);
  }

  get prespectiveArrray() {
    return this.prespective == 'center'
      ? this.companyHolidays
      : this.userHolidays;
  }

  get isCompanyPrespective() {
    return this.prespective == 'center';
  }

  get isUserPrespective() {
    return this.prespective == 'user';
  }

  /** Set the holiday dates to the caledar. */
  initComponent(data: initialHolidayData) {
    this.companyHolidays = [...data.companyH]; // --> Important to lose the reference with the input
    this.userHolidays = [...data.userH]; // --> Important to lose the reference with the input
    this.exceptions = [...data.exceptions]
    this.userID = data.userID;
    this.caledar?.updateTodaysDate();
    this.generateBreakdown();
    this.loading = false;
    this.somethingChanges = false;
    this.refreshCalendar();
  }

  onDateSelected(selectedDate: Date): void {
    // Guardar la fecha seleccionada en el array
    this.selectedDates.push(selectedDate);
  }

  /** Determines if a day is selected or not. */
  dateClass = (d: Date) => {
    let date_ = new Date(d);

    let finalClass = '';

    if (this.isCompanyPrespective) {
      if (this.companyHolidays.some((d) => d.isEquals(date_))) {
        finalClass += CALENDAR_COMPANY;
      }
    } else {
      if (this.userHolidays.some((d) => d.isEquals(date_))) {
        finalClass += CALENDAR_USER_HOLIDAY;
      }
      if (this.companyHolidays.some((d) => d.isEquals(date_))) {
        finalClass += CALENDAR_MINI_COMPANY;
      }
    }

    if (this.exceptions.some((d) => d.day_exceptional?.isEquals(date_))) {
      finalClass += CALENDAR_MINI_SCHEDULE;
    }

    return finalClass;
  };

  /** It is used to go to a specific day on the calendar.
   * It is useful to go to the months that have been established as holidays
   **/
  goToMonth(d: Date) {
    this.caledar?._goToDateInView(d, 'month');
  }
  getStartDate(monthNumber: number): Date {
    return new Date(this.year, monthNumber, 1);
  }
  getLastDate(monthNumber: number): Date {
    return new Date(this.year, monthNumber + 1, 0);
  }

  /** Refresh the caledar to re-paint the holiday dates with its respective color */
  refreshCalendar() {
    this.refreshCalendarAnual();
    this.refreshCalendarSingle();
    this.cdr.detectChanges();
  }
  refreshCalendarAnual() {
    this.caledarAnual?.forEach((calendar) => {
      this.refreshCalendarSingle(calendar);
    });
  }
  refreshCalendarSingle(anualCalendar?: MatCalendar<Date>) {
    let calendar = anualCalendar ? anualCalendar : this.caledar;
    calendar?.updateTodaysDate();
    this.generateBreakdown();
  }


  /** The breakdown is only showed on phone */
  generateBreakdown() {
    let d = this.caledar?.activeDate;
    let finalYear = d ? d.getFullYear() : new Date().getFullYear();
    this.holidayBreakdown = new CustomDateByGranularity(this.prespectiveArrray, DateGranularity.MONTH, finalYear).getData();
  }

  nextYear(): void {
    // Incrementa el año en 1
    this.year++;
    // Actualiza el año en los calendarios
    this.updateCalendarsYear();
    // Refresca los calendarios para reflejar el cambio de año
    this.refreshCalendar();

  }
  previousYear(): void {
    this.year--;
    // Actualiza el año en los calendarios
    this.updateCalendarsYear();
    // Refresca los calendarios para reflejar el cambio de año
    this.refreshCalendar();
  }
  updateCalendarsYear(): void {
    // Actualiza el año en todos los calendarios anuales
    this.caledarAnual?.forEach((calendar, index) => {
      if (calendar) {
        // Asegúrate de que 'calendar' no sea 'null'. Oke
        calendar?._goToDateInView(this.getStartDate(index), 'month');
        calendar?.updateTodaysDate();
      }
    });
    this.cdr.detectChanges();
  }
  /** On click a day on the caledar */
  public dateChanged(event: Date | null) {

    if (!this.enabled || this.loading || !event) { return; }

    let previousVal = this.prespectiveArrray.some(d => d.isEquals(event));
    this.d.open<HolidaysScheduleDayDialogComponent, ExceptionsData, ExceptionsRes>(HolidaysScheduleDayDialogComponent,
      {
        autoFocus: false,
        data: {
          day: event,
          holiday: previousVal,
          prespective : this.prespective,
          userHolidays : this.userHolidays,
          comapnyHolidays : this.companyHolidays,
          schedule: this.exceptions.find(d => d.day_exceptional?.isEquals(event))
        }
      })
      .afterClosed().subscribe(res => {
        console.log("dialogito response")
        if (res) {
          /** Setting holidays */
          if (res.holiday != null && res.holiday != previousVal) {
            if (res.holiday) {
              this.prespectiveArrray.push(event);
              this.somethingChanges = true;
            } else {
              /** Remove existing holiday */
              let removedDay = new Date(event);
              this.removeDayOfArray(this.prespectiveArrray, removedDay);
              this.somethingChanges = true;
            }
          }

          /** Setting exceptions */
          const index = this.exceptions.indexOf(this.exceptions.find((d) => d.day_exceptional?.isEquals(event))!, 0);
          if (res.schedule == null) {
            if (index != -1) {
              this.exceptions.removeIndex(index);
              this.somethingChanges = true;
            }
          }
          else {
            if (index != -1){
              this.exceptions[index] = res.schedule;
              this.somethingChanges = true;
            }
            else{
              this.exceptions.push(res.schedule);
              this.somethingChanges = true;
            }
          }


          this.refreshCalendar();
        }
      })
  }

  /** Removes specific day on specific array */
  private removeDayOfArray(array: Date[], day: Date): boolean {
    const index = array.indexOf(array.find((d) => d.isEquals(day))!, 0);
    if (index > -1) {
      array.splice(index, 1);
      return true;
    }
    return false;
  }

  /** Check if some appointment of array is on "pending" status*/
  dayWithAppointment(citas: M_Appointment[]): boolean {
    for (let i = 0; i < citas.length; i++) {
      if (citas[i].state == AppointmentStatus.pending) {
        return true;
      }
    }
    return false;
  }

  /** Triggered when click the self save button. The 'userID'is needed to save. */
  save() {
    if (this.prespective != 'center') {
      this.apiService
        .saveHolidaysByUserId(this.userHolidays, this.exceptions, this.userID)
        .then((res) => {
          this.onSave.emit({
            companyH: this.companyHolidays,
            exceptions: res.exceptions,
            userH: res.holidays,
            userID: this.userID,
          });
          this.somethingChanges = false;
          this.snackS.show('Calendario actualizado correctamente');
        });
    } else {
      this.apiService
        .saveHolidayByCompanies(this.companyHolidays, this.exceptions, undefined)
        .then((res) => {
          this.onSave.emit({
            companyH: this.companyHolidays,
            exceptions: this.exceptions,
            userH: [],
            userID: this.userID,
          });
          this.snackS.show('Calendario actualizado correctamente');
          this.somethingChanges = false;
        });
    }
  }

  /** Check if a breakdown month is the same month selected in the calendar  */
  isCurrentMonth(d: Date) {
    var selected = this.caledar?.activeDate;
    if (selected) {
      return d.isMonthEqual(new Date(selected));
    }
    return false;
  }
}
