import { M_Product } from "./Products/M_Product";
import { M_CustomProduct } from "./Products/M_CustomProduct";
import { M_TotalsBreakdown, calculateTotalsBreakdown } from "../services/totals-calculator.service";
import { M_Contact } from "./M_Contact";
import { or_status, or_status_close, or_status_open } from "../custom-classes/or_states";
import { or_types, or_types_normal } from "../custom-classes/or_types";

export const notitle = "Nueva Intervención";

export class M_GroupTask {

    action_id: number;
    /** Client who has been invoiced */
    client: M_Contact | undefined;
    id: number;
    invoice_id: number | undefined;
    state: or_status;
    state_id: number;
    products: (M_Product | M_CustomProduct)[] = []
    title: string;
    type: or_types;
    created_at: Date;
    updated_at: Date;
    token?: string; //invoice token
    invoice_name?: string; //invoice name

    /** Group details */
    invoice_contact: M_Contact | undefined;
    invoice_contact_id: number | undefined;
    invoice_doc_number: string | undefined;
    invoice_state_id: number | undefined;

    /** Massive invoice */
    total: number = 0;
    client_id: number | undefined;
    OR: string | undefined;

    constructor(d: any) {
        this.id = d.id || d.group_id;
        this.OR = d.OR;
        this.action_id = d.action_id;
        this.state = d.state_id ? new or_status(d.state_id) : or_status_open;
        this.state_id = d.state_id;
        if (d.tasks) {
            this.products = this.getProductsFromApiResponse(d);
        }
        this.title = d.title ? d.title : notitle;
        this.type = d.type ? new or_types(d.type) : or_types_normal;

        /** Group details */
        this.invoice_contact = d.invoice_contact ? new M_Contact(d.invoice_contact) : undefined;
        this.invoice_contact_id = d.invoice_contact_id;
        this.invoice_doc_number = d.invoice_doc_number;
        this.invoice_state_id = d.invoice_state_id;

        this.created_at = new Date(d.created_at);
        this.updated_at = new Date(d.updated_at);
        if (d.invoice) {
            this.token = d.invoice.token;
            this.invoice_name = d.invoice_name;
        }
        else {
            this.token = d.token ? d.token : undefined;
        }

        if (d.client) {
            this.client = new M_Contact(d.client);
        }

        this.total = d.total; //massive
        this.client_id = d.client_id; //massive
    }


    getTitle(): string {
        return this.title;
    }

    changeStatus(status: or_status) {
        this.state = status;
        this.generateStatus()
    }

    generateStatus() {
        return this.state;
    }

    addProduct(p: M_Product | M_CustomProduct) {
        p.group_id = this.id;
        this.products.push(p);
    }

    getTasksWithId() {
        return this.products.filter(p => { return p.task_id != undefined }).map(t => t.task_id!);;
    }

    removeProduct(p: M_Product | M_CustomProduct) {
        this.products.removeElement(p);
    }

    hasTasks() {
        return this.products.length != 0;
    }

    getAllProducts() {
        return this.products;
    }

    hasChanges() {
        let c = false;
        this.products.forEach(p => {
            if (p?.line_hasChanges) {
                c = true;
            }
        })
        return c;
    }

    markTaskAsSaved() {
        this.products.forEach(p => {
            p.line_hasChanges = false;
        })
    }

    isOpen() {
        return this.state.num == or_status_open.num;
    }
    
    
    isClosed() {
        return this.state.num == or_status_close.num;
    }


    isInvoiced() {
        return this.state.invoiced;
    }



    setNewDetails(formValue: Partial<{ invoice_contact_id: number | null; invoice_doc_number: string | null; invoice_state_id: number | null; }>) {
        this.invoice_contact_id = formValue.invoice_contact_id || undefined;
        this.invoice_doc_number = formValue.invoice_doc_number || undefined;
        this.invoice_state_id = formValue.invoice_state_id || undefined;
    }

    getTotalBreakdown(client?: M_Contact): M_TotalsBreakdown {

        if (this.type.interno) { return calculateTotalsBreakdown(this.getAllProducts(), undefined, true); }

        if (this.state.invoiced && this.client) {
            return calculateTotalsBreakdown(this.getAllProducts(), this.client)
        }
        return calculateTotalsBreakdown(this.getAllProducts(), client)
    }
    
    hasfoultTask() {
        return false;
    }

    getProductsFromApiResponse(d: any) {
        let prods: (M_Product | M_CustomProduct)[] = []
        for (let i = 0; i < d.tasks.length; i++) {
            let task = d.tasks[i];
            if (task.details) { task.details['is_hours'] = task.is_hours; }
            let prod = task.details ? task.custom ? new M_CustomProduct(task.details) : new M_Product(task.details) : undefined;
            if (prod) {
                prod.group_id = this.id;
                prod.task_id = task.id;
                prod.reserve = task.reserve;
                prod.discount = task.discount;
                if (prod.instanceofProduct()) {
                    prod.selected_location = task.loc_out;
                }
                prod.initQuanitity(task.quantity ? task.quantity : 0);
                prods.push(prod);
            }
        }
        return prods;
    }
}