<app-close-dialog-button *ngIf="isOnDialog"></app-close-dialog-button>
<p mat-dialog-title *ngIf="isOnDialog">{{getTitle()}}</p>

<div mat-dialog-content [ngClass]="!isOnDialog ? 'conditional-mat-dialog-content' : ''">
    <div class='create-item'>
        <app-go-back *ngIf=" ve !=undefined" [show]="state == 'edit'" [text]="ve!.getName()" (click)="goBackVehicle()">
        </app-go-back>
        <app-card [contentLoaded]="state != undefined && vfc.loaded" class="section" [noStyle]="isOnDialog">
            <app-view-title *ngIf="!isOnDialog">{{getTitle()}}</app-view-title>
            <div *ngIf="state == 'alta_vehiculo'">
                <div *ngIf="c != undefined">
                    <p class="mt10">Puedes crear un vehículo y vincularlo con el cliente
                        <span class="c_b fwb cp"
                            (click)="params.go(v.contactDetails, c.client_id)">{{c.getName()}}</span>
                    </p>
                </div>
            </div>


            <app-vehicleform #vfc></app-vehicleform>

            <!-- Owner -->
            <div *ngIf="state == 'create'" class="section">
                <app-card-subtitle>Propietario del vehículo</app-card-subtitle>
                <app-class-searcher #clientSearcher [masterClass]="M_CLIENT" [createData]="{
                    text : 'Crear cliente',
                    by : 'dialog'
                }">
                </app-class-searcher>
                <div *ngIf="!isOnDialog" class="df jcc">
                    <div [ngTemplateOutlet]="submitButton"></div>
                </div>
            </div>


            <div class="df jcc">
                <button [disabled]="!vehicleForm.form.valid" subscribed-user mat-flat-button color="primary"
                    *ngIf="state == 'alta_vehiculo' || state == 'edit'" (click)="create()" style="margin-top: 20px;">
                    <span>{{getButton()}}</span>
                </button>
            </div>
        </app-card>
        <div *ngIf="state != 'alta_vehiculo'" class="mb20"></div>
    </div>
</div>

<div mat-dialog-actions *ngIf="isOnDialog">
    <div [ngTemplateOutlet]="submitButton"></div>
</div>

<ng-template #submitButton>
    <button [disabled]="buttonDisabled" subscribed-user mat-flat-button color="primary"
        class="onboarding-vehicle-finish" (click)="create()" style="margin-top: 20px;">
        <span>{{getButton()}}</span>
    </button>
</ng-template>