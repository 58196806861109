import {AbstractControl, ValidatorFn} from '@angular/forms';
/**
 * Phone validator.
 * 
 * Accepts : A 9 length string. The string also needs tos tart with 6, 7, 8 or 9.
 * 
 * @param req is control required ?
 */
export function phoneValidator(req: boolean): ValidatorFn {  
    return (control: AbstractControl): { [key: string]: boolean } | null =>  { 
        if (control.value){
            let val : string = control.value!.toString();
            if (val.length == 0 && !req){
                return null;
            }
            else if (val.length < 9){
                return {phoneMin: true}
            }
            else if (val.toString().length > 9){
                return {phoneMax: true}
            }
            else {
                if (val.charAt(0)  == "6" || val.charAt(0) == "7" || val.charAt(0) == "8" || val.charAt(0) == "9"){
                    return null;
                }
                else{
                    return {phonePrefix : true}
                }
            }
        }
        else{
            return req ?  {required: true} : null ;
        }
    }
}

//https://es.stackoverflow.com/questions/67041/validar-pasaporte-y-dni-espa%C3%B1oles