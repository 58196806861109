<app-close-dialog-button></app-close-dialog-button>


<p mat-dialog-title>Reservas actuales</p> 
<div class="df"><mat-icon smartIcon>{{prod.icon}}</mat-icon><p class="">{{prod.name}}</p></div>
<div mat-dialog-content>
    <form class="form">
        <div class="df w100">
        <p mat-dialog-subtitle>Clientes con Reservas</p>
        <div *ngFor="let reserve of reservations">
            <app-client-row [item]="reserve.client!"></app-client-row>
        </div>
        
        </div>
        <div class="df">
            <app-add-remove class="manual-fault" [initValue]="1" [label]="'Cantidad'" [minValue]="1"
                [addRemoveOutside]="false" [forceMinMaxValue]="true" [canModify]="true" [showSaveIcon]="false"
                [form]="form" [formCName]="'quantity'"></app-add-remove>
        </div>
    </form>

</div>

<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="primary" mat-flat-button [disabled]="!form.valid" (click)="AssignedTo()">Guardar</button>
</div>
