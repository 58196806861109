<div class="tphoto"
[@cardAnimator]="animationState"
(@cardAnimator.done)="resetAnimationState($event)"
(swipeleft)='startAnimation("swipeleft")' 
(swiperight)='startAnimation("swiperight")'
>
<div>
<img [src]="users[index].picture" width="100%" title="tphoto" alt="" />
<div class="tname">{{users[index].name}}, <span class="age">{{users[index].age}}</span></div>
</div>
</div>