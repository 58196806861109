<div [userCanGo]="v.createappointment">
    <ng-container *ngIf="show">

        <ng-container *ngIf="style == 'flat'">
            <div *ngIf="singleVehicle">
                <button mat-flat-button color="primary" [go]="v.createappointment"
                    [param]="{'vehicle' : singleVehicle.vehicle_id}">
                    <mat-icon>event</mat-icon>
                    Dar cita
                </button>
            </div>
            <div *ngIf="multipleVehicles">
                <button mat-flat-button [color]="'primary'" [mat-menu-trigger-for]="makeAppoitmentMenu">
                    <mat-icon>event</mat-icon>
                    Dar cita
                </button>
            </div>
        </ng-container>

        <ng-container *ngIf="style == 'menu-option'">
            <div *ngIf="singleVehicle">
                <button mat-menu-item [go]="v.createappointment" [param]="{'vehicle' : singleVehicle.vehicle_id}">
                    <mat-icon>event</mat-icon>
                    Dar cita
                </button>
            </div>
            <div *ngIf="multipleVehicles">
                <button mat-menu-item [mat-menu-trigger-for]="makeAppoitmentMenu">
                    <mat-icon>event</mat-icon>
                    Dar cita
                </button>
            </div>
        </ng-container>

    </ng-container>
</div>

<mat-menu #makeAppoitmentMenu="matMenu">
    <button mat-menu-item *ngFor="let veh of multipleVehicles"
        (click)="paramsS.go(v.createappointment, veh.vehicle_id, 'vehicle')">
        {{veh.getName()}}
    </button>
</mat-menu>