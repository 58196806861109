import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { acType, M_AccountingGroup } from 'src/app/models/AccountingGroups/M_AccountingGroup';
import { ApiService } from 'src/app/services/Api/api.service';

@Component({
  selector: 'app-accounting-group-selector',
  templateUrl: './accounting-group-selector.component.html',
  styleUrls: ['./accounting-group-selector.component.css']
})
export class AccountingGroupSelectorComponent implements OnInit {

  @Input({ required: true }) form!: UntypedFormGroup;
  @Input({ required: true }) type!: acType;

  acc_groups: M_AccountingGroup[] | undefined;

  constructor(private apiS: ApiService) {}

  ngOnInit(): void {
    this.apiS.accountingGroups().then(res => {
      this.acc_groups = res.filter(g => g.type == this.type);
    })
  }
  

  get loaded() { return this.acc_groups != undefined }

}
