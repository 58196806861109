import { Component, Inject } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Product } from '../../models/Products/M_Product';
import { ManualFaultComponent } from '../manual-fault/manual-fault.component';
import { M_Reservation } from '../../models/M_Reservation';

@Component({
  selector: 'app-remove-reservation',
  templateUrl: './remove-reservation.component.html',
  styleUrls: ['./remove-reservation.component.css']
})
export class RemoveReservationComponent {
  reservations : M_Reservation[]=[];
  form: UntypedFormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public prod: M_Product, public dialogRef: MatDialogRef<ManualFaultComponent>,
  formBuilder: FormBuilder, private apiS: ApiService){
    this.form = formBuilder.group({
      reservation_id: [prod.product_id, Validators.required],
      client_id : ['',Validators.required],
      quantity: ['', [Validators.required]],
      assigned_to : ['',Validators.required]
    });
  }
  ngOnInit(){
    if(this.prod && this.prod.product_id){

      this.apiS.getRecanviosClient(this.prod.product_id).then(res=>{
        this.reservations = res;
      });

    }
  }
  AssignedTo(){
    
  }
}
