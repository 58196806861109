import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CustomFile } from '../../custom-classes/CustomFile';
import { TICKET_STATUS } from '../../enums/TicketStatus';
import { M_CloudTicket } from '../../models/M_CloudTicket';
import { ImagePreviewService } from '../../services/image-preview.service';
import { FileUploadComponent } from '../file-upload/file-upload.component';

/**
 * Cloud ticket component
 * Has the view from client and the view from crm
 * If the cloud ticket needs to be highlighted (a notification has been clicked), the following query params need to be passed:
 * ct_id : The cloud ticket id
 * ct_project : The cloud ticket project
 * 
 * Highlight example : 
 *       this.routerS.goWithQueryParams (ViewPath.client, { client_code: not.ct.client_id, ct_id : not.ct.id, ct_proj : not.ct.project });
 */
@Component({
  selector: 'app-cloud-ticket',
  templateUrl: './cloud-ticket.component.html',
  styleUrls: ['./cloud-ticket.component.css' , './attached-image.css']
})
export class CloudTicketComponent {

  @ViewChild("element") element!: ElementRef;
  @ViewChild("commentInput") commentInput?: ElementRef;
  @ViewChild(FileUploadComponent) fileUpload?: FileUploadComponent;
  @Input() cloudTicket!: M_CloudTicket;
  @Input() clientSide: boolean = true;
  @Input() large: boolean = false;

  /**Only for Sincicloud tickets! */
  @Input() showClient: boolean = false;
  @Input() showCompany: boolean = false;

  /** Output functions */
  @Output() onAddComment: EventEmitter<[M_CloudTicket, string, CustomFile?]> = new EventEmitter();
  @Output() onClickCompany: EventEmitter<M_CloudTicket> = new EventEmitter();
  @Output() onChangeStatus: EventEmitter<[M_CloudTicket, TICKET_STATUS]> = new EventEmitter();
  @Output() onPassToJira: EventEmitter<[M_CloudTicket, string]> = new EventEmitter();
  @Output() onMoreJiraInfo: EventEmitter<M_CloudTicket> = new EventEmitter();
  @Output() onClickUser: EventEmitter<M_CloudTicket> = new EventEmitter();

  comment = new UntypedFormControl('');
  saveCancel = false;
  ts = TICKET_STATUS;
  needsHighlight: boolean = false;

  paramid: any = undefined;
  paramproj: any = undefined;

  constructor(private route: ActivatedRoute, private chdRef: ChangeDetectorRef, public previewS: ImagePreviewService) {
    this.route.queryParams.subscribe((params: any) => {
      var id = params['ct_id'];
      if (id && typeof id == "string") {
        this.paramid = id.getNumber();
      }
      this.paramproj = params['ct_proj'];
    })
  }

  ngAfterViewInit(): void {
    if (this.paramid) {
      console.info("State detected");
      if (this.paramid == this.cloudTicket.id && (this.paramproj == this.cloudTicket.project || this.cloudTicket.project == undefined || this.paramproj == undefined)) {
        this.needsHighlight = true;
        this.chdRef.detectChanges();
        //The screen and components are loaded, there is no other way to do this...
        setTimeout(() => {
          this.element.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        }, 1000); //Await
      }
    }
  }

  cancelComment() {
    this.comment.patchValue("");
    this.commentInput?.nativeElement?.blur();
    this.saveCancel = false;
  }

  preiewImg(img: CustomFile) {
    this.previewS.openPreview(this.cloudTicket.images, img)
  }

  addComment() {
    if (this.comment.value && this.cloudTicket.you) {
      this.onAddComment.emit([this.cloudTicket, this.comment.value, this.fileUpload?.getCustomFile()])
      this.cloudTicket.addUserComment(this.cloudTicket.you, this.comment.value, this.fileUpload?.getCustomFile());
      this.comment.patchValue("");
      this.commentInput?.nativeElement?.blur();
      this.saveCancel = false;
      if (this.fileUpload) {
        this.fileUpload.uploadedFiles = undefined;
      }
    }
  }

  prevent(e?: Event | undefined) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  emitStatusChange(status: TICKET_STATUS) {
    this.onChangeStatus.emit([this.cloudTicket, status]);
    this.cloudTicket.status = status;
  }

  get showInput() {
    if (!this.clientSide) {
      return true;
    }
    return !this.cloudTicket.solved;
  }

  emitCompany(e?: Event | undefined) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.onClickCompany.emit(this.cloudTicket);
  }

  getMoreJiraInfo(e?: Event | undefined) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.onMoreJiraInfo.emit(this.cloudTicket);
    this.cloudTicket.showingJiraIssue = true;
  }

  passTicketToJira(isSiniwin?: boolean, e?: Event | undefined) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.cloudTicket.showingJiraIssue = true;
    this.onPassToJira.emit([this.cloudTicket, isSiniwin ? "siniwin" : this.cloudTicket.project!]);
  }

  openJira(url: string, e?: Event | undefined) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    window.open(url, '_blank');
  }

  emitChangeUser() {
    if (!this.cloudTicket.solved) {
      this.onClickUser.emit(this.cloudTicket);
    }
  }

}
