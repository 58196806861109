import { Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSelect } from '@angular/material/select';
import { M_BookedHours } from 'src/app/models/M_BookedHours';
import { BookedHoursTime, TimeService } from 'src/app/services/time.service';
import { ApiService } from 'src/app/services/Api/api.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { CustomTime } from 'src/app/custom-classes/CustomTime';

/**
 *  [Component]
 *  Combinación de selector de fecha y tiempo para poder guardar las citas con el formato correspondiente.
 *  Se usa tanto al pedir cita por parte del rider, como al crear una cita por parte del mecáncio
 */

@Component({
  selector: 'app-hour-picker',
  templateUrl: './hour-picker.component.html',
  styleUrls: ['./hour-picker.component.css']
})
export class HourPickerComponent implements OnInit {
  @HostBinding('class') classes = 'hourpicker';
  @Input() isClientSide: boolean = false;
  @Input() isEdit?: boolean;
  @Input() public form!: UntypedFormGroup;
  @Input() matFormFieldsClasses: string | undefined;

  centerId: number | undefined;
  /**Selector de las horas */
  @ViewChild('select') select!: MatSelect;
  selected?: number;
  disableSelect = true;
  hoursavailable?: BookedHoursTime[];
  noHoursAvailableError: string = "";
  holiDayDates: Date[] = [];
  firstDay: Date | undefined;
  loadedHours: boolean = false;
  forceInclude: Date | undefined;

  constructor(private timeService: TimeService, private apiS: ApiService, public cs: CalendarService) { }

  ngOnInit(): void { }
  ngAfterViewInit() {
    if (!this.isClientSide && !this.isEdit) {
      this.apiS.getFirstDay().then(resp => {
        if (!this.form.get('day')?.value) {
          this.setFirstDay(resp, undefined)
        }
      });
    }

    if (!this.isClientSide) {
      this.setUpHoliDays();
    }
  }

  /**
   * Función que se lanza al cambiar la fecha
   * Si se selecciona una dia diferene al que ya esta seleccionado, el desplegable de la hora se 'resetea' 
   * */
  addEvent(event: MatDatepickerInputEvent<Date>) {
    this.form.get('hour')?.patchValue("");
    this.select.disabled = event.value == null;
    if (this.select.disabled) {
      this.form.controls['hour'].enable();
    }

    if (event.value != null) {
      if (this.isClientSide) {
        if (this.centerId) {
          /** Si estamos en el lado del cliente, llamamos a la API pasando el ID de la compañía */
          this.apiS.getBookedHoursByCenterId(new Date(event.value), this.centerId).then(response => this.generateHoursAvailabe(response));
        }
        else {
          throw Error("No company id")
        }
      }
      else {
        /** Si estamos en el lado del mecánico, la compañía la deduce el backend con el token */
        this.apiS.getBookedHours(new Date(event.value)).then(response => this.generateHoursAvailabe(response));
      }
    }

  }

  /**Devuelve la fehca en el formato que espera la base de datos */
  getDate(): Date | undefined {
    if (this.form.get('hour')?.value) {
      let t: CustomTime = this.timeService.parseNumberToHour(this.form.get('hour')?.value);
      let d = new Date(this.form.value.day);
      /** Une el valor del tiempo (desplegable select) y el valor del calendario para formar una fecha */
      d.setCustomTime(t);
      return d;
    }
    return undefined;
  }

  dateClass = (d: Date) => {
    let date_ = new Date(d);
    for (let i = 0; i < this.holiDayDates.length; i++) {
      if (this.holiDayDates[i].isEquals(date_)) {
        return 'holiday-hour-picker'
      }
    }
    return '';
  }

  filterByDayMaxTodayDisableSunday: (date: Date | null) => boolean =
    (date: Date | null) => {
      if (date) {
        var onRange = this.cs.maxTodayAndSunday(date);
        let date_ = new Date(date);
        var isHoliDay = false;
        if (this.holiDayDates.length == 0) {
          isHoliDay = false;
        }
        else {
          this.holiDayDates.forEach(d => {
            if (!isHoliDay) {
              isHoliDay = d.isEquals(date_);
            }
          })
        }

        if (isHoliDay) {
          return false;
        }
        else {
          return onRange;
        }
      } else {
        return false;
      }
    };

  /**A partir de una string en formato de fecha, se assigna esta al selector de fecha (calendario) de la pantalla */
  setDay(day: Date, center_id: number | undefined) {
    this.firstDay = day;
    this.form.get('day')?.patchValue(day.datePickerFormat());
    /**TODO */
    if (center_id) {
      this.centerId = center_id;
      this.apiS.getBookedHoursByCenterId(day, this.centerId).then(response => this.generateHoursAvailabe(response));
    }
    else {
      this.apiS.getBookedHours(day).then(response => this.generateHoursAvailabe(response));
    }
  }

  setFirstDay(day: Date, center_id: number | undefined) {
    this.firstDay = day;
    this.recalculateFirstDay(false, center_id);
    this.setDay(this.firstDay, center_id)
  }

  recalculateFirstDay(setDay: boolean = false, center_id: number | undefined) {
    if (this.firstDay != undefined && this.holiDayDates.length != 0) {
      this.getFirstDayBuHolyDays(this.firstDay, this.holiDayDates);
      if (setDay) {
        this.setDay(this.firstDay, center_id);
      }
    }
  }

  getFirstDayBuHolyDays(firstDay: Date, holyDays: Date[]) {
    for (let i = 0; i < holyDays.length; i++) {
      if (holyDays[i].isEquals(firstDay)) {
        firstDay.plusDays(1);
      }
    }
  }

  setUpHoliDays(center_id?: number) {
    this.apiS.getHoliDays(center_id).then(res => {
      this.holiDayDates = res;
      this.recalculateFirstDay(true, center_id);
    })
  }

  generateHoursAvailabe(response: M_BookedHours | string) {
    /**
     * En caso de que llegue "-1". No se pueden generar las horas disponibles.
     * El -1 indica que es un día festivo para la empresa
     * El -2 indica que no hay suficiente margen para pedir la cita
     * Des de pantalla ya se comprueva que, en caso de que la array de 'hoursavailable' esté vacía,
     * eso indica que es un día festivo
     */
    if (response instanceof Object) {
      if (response.settings == undefined) {
        this.hoursavailable = [];
      }
      else {
        this.hoursavailable = this.timeService.genereteHoursbyCompany(
          response.settings.morning_work_schedule_start,
          response.settings.morning_work_schedule_finish,
          response.settings.afternoon_work_schedule_start,
          response.settings.afternoon_work_schedule_finish,
          response.settings.granularity,
          response.booked, this.forceInclude)
      }
    }
    else {
      this.hoursavailable = [];
      if (response == "-1") { this.noHoursAvailableError = "Día festivo para la empresa" }
      else if (response == "-2") { this.noHoursAvailableError = "Margen de días insuficiente" }
      this.form.controls['hour'].patchValue(undefined);
    }
    if (this.form.enabled) {
      this.form.controls['hour'].enable({ emitEvent: false });
    }
    this.loadedHours = true;
  }

  /**A partir de una fecha, extrae la hora y la guarda */
  setHour(date: Date) {
    if (this.isEdit) {
      this.disableSelect = false;
      this.form.get('hour')?.patchValue(this.timeService.getTimeFromDate(date).getValue());
      if (this.loadedHours && this.hoursavailable) {
        this.timeService.forceIncludeHour(this.hoursavailable, date);
      }
      else {
        this.forceInclude = date;
      }
    }
  }

}
