import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { M_Schedule } from 'src/app/models/M_Schdeule';
import { ApiService } from 'src/app/services/Api/api.service';

export type ExceptionsRes = {
  holiday: boolean | null,
  schedule: M_Schedule | null
}

export type ExceptionsData = {
  day: Date,
  holiday: boolean,
  prespective: "user" | "center"
  userHolidays: Date[],
  comapnyHolidays: Date[],
  schedule?: M_Schedule
}

/**
    this.d.open<HolidaysScheduleDayDialogComponent, ExceptionsData, ExceptionsRes>(HolidaysScheduleDayDialogComponent,
      {
        autoFocus : false,
        data: {
          day: ,
          holiday: ,
          schedule: new M_Schedule({})
        }
      })
      .afterClosed().subscribe(res => {
        if (res) {
          console.log("Something")
        }
      })
 */

@Component({
  selector: 'app-holidays-schedule-day-dialog',
  templateUrl: './holidays-schedule-day-dialog.component.html',
  styleUrls: ['./holidays-schedule-day-dialog.component.css']
})
export class HolidaysScheduleDayDialogComponent {

  toggleControl = new FormControl(false);
  toggleError: string | undefined;
  horario: M_Schedule[];
  loaded = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ExceptionsData, public dRef: MatDialogRef<HolidaysScheduleDayDialogComponent>,
    private apiS: ApiService) {

    this.toggleControl.setValue(data.holiday);
    this.horario = [data.schedule || this.defaultDate]

    this.apiS.checkHolidays(data.day, undefined).then(res => {
      this.loaded = true;
      if (typeof res == "string") {
        this.toggleError = res;
      }
    })
  }

  get scheduleORNull() {
    let s = this.horario[0];
    return s.morningStart && s.morningEnd ? s : null;
  }
  get defaultDate() { return new M_Schedule({ day_exceptional: this.data.day }); }
  get isOk() { return this.horario.every(h => h.isOk); }

  get closeData(): ExceptionsRes {
    console.log("Closing date")
    return {
      holiday: this.toggleControl.getRawValue(),
      schedule: this.scheduleORNull,
    }
  }

  get disabledText() {
    if (this.data.prespective == "user") {
      if (this.toggleControl.getRawValue() == true) {
        return "Festivo para el usuario"
      }
      if (this.data.comapnyHolidays.some(h => h.isEquals(this.data.day))) {
        return "Festivo para la empresa"
      }
    }
    else {
      if (this.toggleControl.getRawValue() == true) {
        return "Festivo para la empresa"
      }
    }
    return undefined;
  }

  close() {
    this.dRef.close(this.closeData);
  }

}
