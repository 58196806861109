<button mat-mini-fab class="top-pannel" (click)="close()">
    <mat-icon>close</mat-icon>
</button>


<!-- VIDEO ELEMENT -->
<video class="videoPreview" autoplay="true" #videoElement></video>

<div class="pictures-pannel">
    <app-single-img *ngFor="let img of [].constructor(data.fuc.maxImagesLength); let i = index" (click)="selectedIndex = i"
        [selected]="selectedIndex == i" (onDeleteImg)="onDeleteImg($event)"></app-single-img>
</div>

<div class="bottom-pannel">
    <button mat-icon-button color="white"
        (click)="this.data.fuc.input.nativeElement.click();"><mat-icon>collections</mat-icon></button>
    <button mat-icon-button color="white" (click)="takePicture()"
        class="take-picture"><mat-icon>camera</mat-icon></button>
    <button mat-icon-button color="primary" (click)="userPressOk()"><mat-icon>done</mat-icon></button>
</div>