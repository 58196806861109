<app-close-dialog-button *ngIf="isOnDialog"></app-close-dialog-button>

<p mat-dialog-title *ngIf="isOnDialog">{{isEdit && this.brand ? this.brand.name : "Crear marca"}} <mat-icon class="vam">{{isEdit && this.brand ? this.brand.icon:''}}</mat-icon>  </p>
<div class="advanced-details-container" mat-dialog-content>
    <app-card [noStyle]="isOnDialog">
        <form [formGroup]="form" eForm>
            <!-- tener en cuenta al actualizar -->
            <app-card-subtitle [first]="true">Datos obligatorios</app-card-subtitle>

            <div eForm-wrapper>
                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Nombre marca</mat-label>
                        <input matInput formControlName="name" placeholder="Nombre marca" [disabled]="noEdit">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo vehículo</mat-label>
                        <mat-select formControlName="type" [value]="0">
                            <mat-option [value]="0">Moto</mat-option>
                            <mat-option [value]="1">Coche</mat-option>
                        </mat-select>
                    </mat-form-field>    
                </div>
            </div>
        </form>
        <div class="df aic jcc mt10">
            <button mat-flat-button [disabled]="!brand?.original" color="primary" (click)="create()">Crear
                Marca
            </button>
        </div>
    </app-card>
</div>