<div>
    <!-- SINICLOUD -->
    <div *ngIf="generalStatus.status == undefined; else weberp">
        <p *ngIf="generalStatus.numOfTasks != -1; else noGroups" class="nmb fsxs c_t2">Tienes <span
                class="c_p">{{generalStatus.numOfTasks}} tareas </span> en la OR</p>
    </div>
    <!-- WEBERP -->
    <ng-template #weberp>
        <div [ngSwitch]="generalStatus.status?.num" *ngIf="generalStatus.numOfTasks != -1; else noGroups">
            <p *ngSwitchCase="0" class="nmb fsxs c_t2">Tienes <span class="c_p">{{generalStatus.numOfTasks}} tareas
                </span> por hacer</p>
            <p *ngSwitchCase="1" class="nmb fsxs c_t2">Tienes <span class="c_o">{{generalStatus.numOfTasks}}
                    tareas</span> por facturar</p>
            <p *ngSwitchCase="2" class="nmb fsxs c_p">¡OR facturada!</p>
        </div>
    </ng-template>
    <ng-template #noGroups>
        <p class="nmb fss fw300 c_t2">OR sin grupos</p>
    </ng-template>
    <div class="groups-dir df" [ngClass]="{'fdr fww' : preferWidth , 'fdc' : !preferWidth}">
        <div class="padding-group" *ngFor="let g of groups">
            <p *ngIf="g.type" class="nmb">
                <span class="df aifs">
                    <span class="df aic">
                        <app-or-status-label [circularStatus]="true" [status]="g.state"></app-or-status-label>
                        <app-or-type-label style="margin-left: 3px;" [showText]="false" [wrapOn]="1" [tipo]="[g.type]"></app-or-type-label>
                    </span>
                    <span style="margin-left: 3px; overflow: hidden; text-overflow: ellipsis;">
                        {{g.getTitle()}}
                    </span>
                </span>
            </p>

        </div>
    </div>
</div>