import { Component, Input, Optional } from '@angular/core';
import { M_Appointment } from '../../models/M_Appointment';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-client-side-details-appointment',
  templateUrl: './client-side-details-appointment.component.html',
  styleUrls: ['./client-side-details-appointment.component.css']
})

export class ClientSideDetailsAppointmentComponent {

  @Input({ required: true }) appointment!: M_Appointment;
  @Input({ required: true }) first!: boolean;

  constructor(@Optional() public dialogRef: MatDialogRef<any>) { }

  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }
}
