<app-close-dialog-button></app-close-dialog-button>
<p mat-dialog-title>Contacta con nosotros</p>

<div mat-dialog-content class="mt10">
    <form [formGroup]="form" style="max-width: 400px" eForm>
        <p class="info-title" *ngIf="data.showGoIssues">Podras hacer un seguimiento de tu incidencia en
            <span class="underline_hover c_p" (click)="goIssues()">la pantalla de incidencias</span> .
        </p>

        <div eForm-wrapper>
            <div eForm-section>
                <mat-form-field class="w100" appearance="outline">
                    <mat-label class="mt10">Resumen</mat-label>
                    <input matInput placeholder="Resumen" formControlName="summary">
                </mat-form-field>
            </div>

            <div eForm-section>
                <mat-form-field appearance="outline" class="w100">
                    <mat-label>Descripción del problema</mat-label>
                    <textarea matInput formControlName="description" cdkTextareaAutosize cdkAutosizeMinRows="5"
                        cdkAutosizeMaxRows="5">
                    </textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="mb20"></div>

        <lib-file-upload [maxImagesLength]="3" [acceptedTypes]="[ft]" [uploadtype]="'drag'"></lib-file-upload>

        <p class="c_r tac nmb">Al proceder, nos enviarás una incidencia</p>
        <p class="c_t2 tac">¡La revisaremos lo antes posible, por supuesto!</p>

    </form>
</div>

<div mat-dialog-actions>
    <button [disabled]="sendDisabled" mat-flat-button color="primary" (click)="sendProblem()">Enviar</button>
</div>