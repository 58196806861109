<!-- Optional close button-->
<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title>Enlace QR</p>

<!-- Dialog content -->
<div mat-dialog-content>
    <a [href]="data" target="_blank" rel="noopener noreferrer" class="c_a">
        <p class="c_b" mat-dialog-subtitle>{{data}}</p>
    </a>
    <qrcode id="qrcode-tag-ref" [qrdata]="data" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cerrar</button>
    <button mat-flat-button [color]="'primary'" (click)="downloadQR()">
        <mat-icon>file_download</mat-icon>
        Descargar
    </button>
</div>