import { Component } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserFilter } from 'src/app/custom-classes/Filter';
import { M_Report } from 'src/app/models/Reports/M_Report';
import { ApiService } from 'src/app/services/Api/api.service';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent {

  loaded = false;
  form: UntypedFormGroup;

  rTaller: M_Report;

  constructor(public companyS: CompanyService, private fb: FormBuilder, private apiS: ApiService) {
    this.form = this.fb.group({
      center: ['all', Validators.required],
      dataIni: [new Date().firstMonthDay(), Validators.required],
      dataEnd: [new Date().lastMonthDay(), Validators.required],
    });

    this.rTaller = new M_Report(
      "Taller",
      [
        new UserFilter("Usuario")
      ],
      [
        {
          title: "Productividad 1",
          key: "productivity",
          value: "100",
          ismoney : true
        },
        {
          title: "Productividad 2",
          key: "productivity",
          value: "200",
          ismoney : true
        },
        {
          title: "Productividad 3",
          key: "productivity",
          value: "300",
          ismoney : true
        },
        {
          title: "Productividad 4",
          key: "productivity",
          value: "400",
          ismoney : true
        }
      ]
    )


    this.refresh();
  }

  refresh() {
    this.loaded = false;
    const c = this.form.get('center')?.value;
    const dataIni = this.form.get('dataIni')!.value;
    const dataEnd = this.form.get('dataIni')!.value;

    this.apiS.report(c, dataIni, dataEnd).then(res => {
      console.log(res);
      this.loaded = true;
    })
  }

  get dataIni() { return this.form.get('dataIni')!.value }
  get dataEnd() { return this.form.get('dataEnd')!.value }
}
