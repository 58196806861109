export interface M_Parser {
    needsToBeParsed: boolean;
    /** Se espero el el modelo que implementa esta classe con el formate correcto de todos los campos de la base de datos 
     * En todas las llamadas http de tipo 'POST', se llama a esta función. Cada classe que la implementa, debe implementara a su manera.
    **/
    parse(): any;
}

/**
 * Para ayudar a 'parsear' una classe a un nuevo objeto JSON, esta función retorna la classe que se le passe en formato 'key-value'.
 * Se puede modificar los 'vlaue' de las 'keys'
 */
export function parseOBJ(obj: any, exludeKey?: string): any {
    let newObj: any = {};
    Object.keys(obj).forEach(key => {
        if (key != "needsToBeParsed" && key != exludeKey) {
            newObj[key] = obj[key];
        }
    });
    return newObj;
}
