import { Component, Input, ViewChild } from '@angular/core';

import { ViewPath } from 'src/app/app-routing.module';
import { Filter, DayFilter, TagFilter, FilterOption } from 'src/app/custom-classes/Filter';
import { M_Brand } from 'src/app/models/M_Brand';
import { PageBrandEnum } from '../brands/brands.component';
import { PageStructureComponent } from '../page-structure/page-structure.component';
import { M_Color } from 'src/app/models/Vehicles/M_Color';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/Api/api.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ParamsService } from 'src/app/services/params.service';
import { SessionService } from 'src/app/services/session.service';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { Views } from 'src/app/custom-classes/View';
import { M_Model } from 'src/app/models/M_Model';
import { MatTableDataSource } from '@angular/material/table';
import { match } from 'src/app/services/search.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { FiltersComponent } from '../filters/filters.component';
import { CreateModelsComponent } from '../create-models/create-models.component';
export enum PageModelEnum {
  VEHICLE_TYPE = 1,
  DATA_SERIE = 2,
  MODEL = 3,
}
@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.css']
})
export class ModelsComponent {
  filtersToShow: Filter[] = [
    new TagFilter("Vehículo", undefined, new FilterOption("Moto", "two_wheeler "), new FilterOption("Coche", "directions_car_filled")).setId(PageModelEnum.VEHICLE_TYPE),
    new DayFilter("Fecha de creación").setId(PageModelEnum.DATA_SERIE)
  ];
  f = filter;
  v = ViewPath
  @Input() brand?: M_Brand | undefined;
  @ViewChild(FiltersComponent) filter_html!: FiltersComponent;
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Model>;
  constructor(public apiS: ApiService, private d: MatDialog, public subS: SubscriptionService,
    public sessionS: SessionService, public params: ParamsService, public responsiveS: ResponsiveService) { }


  ngOnInit(): void {
    this.apiS.models().then(res => {
      console.log(res);
      this.ps.initTable(res);
    })
  }
  get quickFilter() {
    let f = this.filtersToShow.find(f => f.id == PageModelEnum.VEHICLE_TYPE);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }
  getName(model: M_Model) { return model.getName }
  viewClick(event: any): Views {

    return this.v.createModel;
  }
  getIds(event: any): number {

    return event.id;
  }
  onDialogRef(model:M_Model | undefined){
    return this.d.open(CreateModelsComponent,{data:model}).afterClosed().subscribe(res=>{});
    // this.params.go(viewClick($event),getIds($event)) call front
  }
}
