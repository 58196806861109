<app-card>
    <app-card-title class="df">{{title}}</app-card-title>
    <div class="df fdc" [class]="class_">
        <div class="df fdr aic jcc">
            <ng-content></ng-content>
            <div class="drag-and-drop-excel">
                <p class="tac fw500">Usa 
                    <a [href]="layoutURL" [class]="importclass_"><span class="c_b tdu cp">este excel</span></a>
                    como plantilla
                </p>
                <lib-file-upload [maxImagesLength]="1" [dragPlaceHolder]="mobile ? 'Aquí puede subir su excel' : 'Arrastre aquí su excel'"
                    [uploadtype]="'drag'" [acceptedTypes]="[ft.excel]" (stateChanged)="onExcelUpload($event)"
                    (onRemoveImage)="resetCompoenent()">
                </lib-file-upload>
            </div>
        </div>
        <div class="df fdc">
            <app-card-subtitle>Previsualización</app-card-subtitle>
            <div *ngIf="data; else noData">
                <button subscribed-user style="margin-bottom: 10px" [disabled]="hasErrors()" mat-stroked-button
                    (click)="openDialog()">Importar</button>
                <p class="c_r" *ngFor="let e of errors">{{e}}</p>
                <div style="overflow-x: auto;">
                    <table class="sjs-table" style="table-layout: auto !important;">
                        <tbody>
                            <tr *ngFor="let row of data">
                                <td *ngFor="let val of row; let i = index">
                                    <span class="two_lines">{{formatVal(val, i)}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <ng-template #noData>
                <div *ngIf="loadingPreview; else nothingUploaded">
                    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
                </div>
                <ng-template #nothingUploaded>
                    <p class="c_t2">Una vez subas el archivo, podrás ver su previsualización</p>
                </ng-template>
            </ng-template>
        </div>
    </div>
</app-card>