import { M_Action } from "../M_Action";
import { M_Invoice } from "../M_Invoice";
import { M_Contact } from "../M_Contact";
import { IClassSearcher } from "../../interfaces/IClassSearcher";
import { endpoints } from "../../constants/Endpoints";
import { VehicleType } from "../../enums/VehicleType";
import { environment } from "src/environments/environment";
import { M_Brand } from "../M_Brand";
import { M_Model } from "../M_Model";
import { ComercialVehicleType } from "../../enums/ComercialVehicleType";
import { M_Concept } from "../M_Concept";
import { RegistrationTaxEnum } from "../../enums/RegistrationTaxEnum";
import { M_Location } from "../M_Location";
import { M_Product } from "../Products/M_Product";
import { M_CustomProduct } from "../Products/M_CustomProduct";
import { IPageStructureItem } from "../../interfaces/IPageStructureItem";
import { getArrayOf } from "../../utils/FunctionUtils";
import { CustomFile } from "../../custom-classes/CustomFile";
import { M_Parser, parseOBJ } from "../../custom-classes/ModelParser";
import { match } from "../../services/search.service";
import { M_Purchase } from "../M_Purchase";
import { R_Zip } from "../R_Zip";
import { M_Address } from "../M_Address";
import { M_ComercialBudget } from "../M_ComercialBudget";

export class M_Vehicle implements M_Parser, IPageStructureItem, IClassSearcher<M_Vehicle> {
    needsToBeParsed = true;
    vehicle_id: number;
    type: VehicleType;
    brand: string | undefined;
    model: string | undefined;
    description: string;
    license: string | undefined;
    serie: number | null;
    chassis: string;
    insurance: string;
    cc: string;
    engine_type: string;
    license_date: Date | undefined;
    hire_date: Date | undefined;
    km: string;
    observations: string | undefined;
    client: M_Contact | undefined = undefined;
    client_id: number | undefined;
    electric: boolean;
    last_itv: Date | undefined;
    next_itv: Date | undefined;

    documentation: CustomFile[] = [];
    vehicleImages: CustomFile[] = [];
    battery_1_no: string | undefined;
    battery_2_no: string | undefined;
    actions: M_Action[] = [];
    invoices: M_Invoice[] = [];
    last_reception: Date | undefined;
    deleted: boolean = false;
    clientInvoice: M_Contact | undefined;
    clientInvoice_id: number | undefined;
    /** Calls */
    minify = false;
    vnvoCall = false;
    id_electric: number | undefined;

    /** New fields vehicle */
    color_id: number;
    end_warranty: Date;
    number_warranty: string;
    gears_type: string;


    /** Comercial module */
    comercialType: ComercialVehicleType | undefined;
    concepts: M_Concept[] = [];
    products: (M_Product | M_CustomProduct)[] = [];
    price: number | undefined;
    cost: number | undefined;
    budget_id: number | undefined;
    registration_tax: RegistrationTaxEnum | undefined;
    serie_vehicle: string | undefined;
    num_motor: string | undefined;
    workshop_model: string | undefined;
    seller: string | undefined;
    deposit: string | undefined;
    location: M_Address | undefined;
    exp_documentation: Date | undefined;
    num_prov: string | undefined;
    purchases: M_Purchase[] = [];
    brand_id: number | undefined;
    model_id: number | undefined;
    budget_reserve: M_ComercialBudget | undefined;

    /** New fields VN */
    in_deposit: boolean;
    days_exceeded: number | undefined;
    financial_expenses: number;
    delivery_num: string;
    documentation_expiration: Date | undefined;
    accounting: string;
    year: number;
    version: string | undefined;

    /** New fields VO */
    client_origin: M_Contact | undefined;

    constructor(d: any) {
        this.vehicle_id = d.id ? d.id : d.vehicle_id;
        this.type = d.type;
        this.brand = d.brand && d.brand instanceof M_Brand ? d.brand.name : d.brand;
        this.model = d.model && d.model instanceof M_Model ? d.model.name : d.model;
        this.description = d.description;
        this.license = d.license;
        this.serie = this.getSerieFromString(d.serie);
        this.chassis = d.chassis;
        this.insurance = d.insurance;
        this.cc = d.cc;
        this.engine_type = d.engine_type;
        this.deleted = d.deleted;
        this.license_date = d.license_date ? new Date(d.license_date) : undefined;
        this.hire_date = d.hire_date ? new Date(d.hire_date) : undefined;
        this.km = d.km;
        this.observations = d.observations;
        this.clientInvoice = d.clientInvoice ? new M_Contact(d.clientInvoice) : undefined;
        this.clientInvoice_id = d.clientInvoice_id;
        this.id_electric = d.id_electric;

        /** New field vehicle */
        this.color_id = d.color_id;
        this.model_id = d.model_id;
        this.end_warranty = d.end_warranty;
        this.number_warranty = d.number_warranty;
        this.gears_type = d.gears_type;

        if (d.brand_id) {
            this.brand_id = d.brand_id
        } if (d.model_id) {
            this.model_id = d.model_id
        }
        if (d.client) {
            this.client = new M_Contact(d.client)
            if (d.client.address) {
                this.location = new M_Address(d.client);
            }

        }
        if (d.budget_reserve) {
            this.budget_reserve = new M_ComercialBudget(d.budget_reserve);
        }

        this.last_itv = d.last_itv ? new Date(d.last_itv) : undefined;
        this.next_itv = d.next_itv ? new Date(d.next_itv) : undefined;

        if (d.images) {
            for (let i = 0; i < d.images.length; i++) {
                if (d.images[i].name == "docu_img") {
                    var cf = new CustomFile(d.images[i].file, undefined, undefined, d.images[i].id);
                    this.documentation.push(cf)
                }
                else if (d.images[i].name == "vehicle_img") {
                    this.vehicleImages.push(new CustomFile(d.images[i].file, undefined, undefined, d.images[i].id))
                }
            }
        }


        this.battery_1_no = d.battery_1_no ? d.battery_1_no : undefined;
        this.battery_2_no = d.battery_2_no ? d.battery_2_no : undefined;


        this.electric = this.id_electric != 0 || this.engine_type == "1";


        if (d.actions) {
            this.actions = [];
            for (let i = 0; i < d.actions.length; i++) {
                this.actions.push(new M_Action(d.actions[i]));
            }
        }
        if (d.invoices) {
            for (let i = 0; i < d.invoices.length; i++) {
                this.invoices.push(new M_Invoice(d.invoices[i]));
            }
        }
        if (d.purchases) {
            for (let i = 0; i < d.purchases.length; i++) {
                this.purchases.push(new M_Purchase(d.purchases[i]));
            }
        }
        this.last_reception = this.getLastReceptionByActions();


        /** Comercial module */
        this.comercialType = d.comercialType ? d.comercialType : d.new ? ComercialVehicleType.VN : d.used ? ComercialVehicleType.VO : undefined;
        this.concepts = d.concepts ? getArrayOf(M_Concept, d.concepts) : [];
        let prods = d.products;
        if (Array.isArray(prods)) {
            prods.forEach(p => {
                this.products.push(p.custom ? new M_CustomProduct(p) : new M_Product(p))
            })
        }
        this.price = d.price;
        this.cost = d.cost;
        this.budget_id = d.budget_id;
        this.registration_tax = d.registration_tax;
        if (d.num_motor) {
            this.num_motor = d.num_motor;
        }
        if (d.workshop_model) {
            this.workshop_model = d.workshop_model;
        }
        if (d.seller) {
            this.seller = d.seller;
        }
        if (d.deposit) {
            this.deposit = d.deposit;
        }
        if (d.location) {
            this.location = d.location;
        }
        if (d.Expiration_documentation) {
            this.exp_documentation = d.exp_documentation;
        }
        if (d.num_prov) {
            this.num_prov = d.num_prov;
        }

        /** New fields VN */
        this.in_deposit = d.in_deposit;
        this.financial_expenses = d.financial_expenses;
        this.delivery_num = d.delivery_num;
        this.documentation_expiration = d.documentation_expiration ? new Date(d.documentation_expiration) : undefined;
        this.accounting = d.accounting;
        this.year = d.year;
        this.version = d.version;

        /** New fields VO */
        this.client_origin = d.client_origin ? new M_Contact(d.client_origin) : undefined;

    }

    get brandAndModel() {
        return this.brand && this.model;
    }

    getSerieFromString(serie: any) {
        if (typeof serie == "number") {
            return serie;
        }
        else if (serie && typeof serie == "string" && serie.isNumber()) {
            return serie.getNumber();
        }
        return null;
    }

    get lastOr() {
        return this.actions.filter(action => action.isOr())
            .sort((a, b) => a.created_at > b.created_at ? -1 : 1)[0];
    }


    /** TODO : Esto se deberia poner en un enum... */
    get getSerieName(): string | undefined {
        if (!this.serie) { return undefined }
        if (this.isCar) {
            if (this.serie == 0) { return "Microcoche" }
            if (this.serie == 1) { return "Urbano" }
            if (this.serie == 2) { return "Sedan" }
            if (this.serie == 3) { return "Descapotable" }
            if (this.serie == 4) { return "Coupé" }
            if (this.serie == 5) { return "Deportivo" }
            if (this.serie == 6) { return "Monovolúmen" }
            if (this.serie == 7) { return "Todo Terreno" }
            if (this.serie == 8) { return "SUV" }
            if (this.serie == 9) { return "Camioneta" }
        }
        else {
            if (this.serie == 0) { return "Sport" }
            if (this.serie == 1) { return "Touring" }
            if (this.serie == 2) { return "Trail" }
            if (this.serie == 3) { return "Scooter" }
            if (this.serie == 4) { return "Offroad" }
            if (this.serie == 5) { return "Custom" }
            if (this.serie == 6) { return "Naked" }
        }
        return undefined;
    }

    generateRandomInteger(min: number, max: number) {
        return Math.floor(min + Math.random() * (max - min + 1))
    }

    parse() {
        let obj: any = parseOBJ(this);
        if (this.license_date) {
            obj['license_date'] = new Date(obj['license_date']).dataBaseFormat();
        }
        if (this.hire_date) {
            obj['hire_date'] = new Date(obj['hire_date']).dataBaseFormat();
        }
        if (this.last_itv) {
            obj['last_itv'] = new Date(obj['last_itv']).dataBaseFormat();
        }
        if (this.next_itv) {
            obj['next_itv'] = new Date(obj['next_itv']).dataBaseFormat();
        }
        if (this.km) {
            this.km += '';
            let test = this.km.replace(/\./g, '');
            obj['km'] = test;
        }
        return obj;
    }

    getName(defaultPlate: boolean = false) {
        return this.brand && this.model ? this.brand + " " + this.model :
            this.brand || this.model || (defaultPlate ? this.license || "" : "");
    }

    get engineType() {
        let v = this.engine_type;
        return v == "0" ? "Gasolina" : v == "1" ? "Eléctrico" : ""
    }

    get isCar() {
        return this.type == VehicleType.car;
    }

    get isBike() {
        return this.type == VehicleType.bike;
    }

    get typeName() {
        return this.isCar ? "Coche" : "Moto";
    }

    getLastReceptionByActions() {
        if (!this.actions) { return undefined; }
        return this.actions
            .filter(a => a.type_id.isOr)
            .sort((a, b) => a.created_at > b.created_at ? -1 : 1)[0]?.created_at;
    }

    defaultSearchFilter(text: string): boolean {
        text = text.toLocaleLowerCase();
        return match(text,
            this.getName(),
            this.license,
            this.brand,
            this.model,
            this.chassis,
            this.license_date ? this.license_date.dayMonthYearFormat() : '',
            this.client ? this.client.getName() : '');
    }

    /** Vehicle brand image */
    getBrandImageUrl() {
        return environment.apiUrl + "/" + this.getVehicleFolder() + "/" + this.getBrandFormatted() + ".png"
    }

    private getBrandFormatted() {
        if (this.brand) {
            /** Remove spaces and dashes */
            return this.brand.toLocaleLowerCase().replace(/\-/g, "").replace(/\ /g, "");
        }
        return "";
    }

    private getVehicleFolder() {
        return this.isCar ? "car-logos" : "bike-logos";
    }

    get isVnVo() {

        return this.isVn || this.isVo;
    }

    get isVn() { return this.comercialType == ComercialVehicleType.VN }
    get isVo() { return this.comercialType == ComercialVehicleType.VO }

    /** CLASS SEARCHER INTERFACE */
    get endpoint() { return this.minify ? endpoints.vehiclesminify : this.vnvoCall ? endpoints.vehiclesminify2 : endpoints.vehicles }
    get itemId() { return this.vehicle_id; }
    get icon() { return this.isCar ? "directions_car_filled" : "two_wheeler"; }
    get typeof() { return M_Vehicle }
    get extraOptionText() {
        if (!this.isVnVo) { return this.client?.getName() }
        return this.isVn ? "VN" : this.isVo ? "VO" : "";
    }

    createNew(d: any) { return new M_Vehicle(d) }
    getInputText(): string {
        return this.getName(true);
    }
    getOptionText(): [string, string] {
        if (!this.brand && !this.model) {
            return ([this.license || "", ""]);
        }
        return ([this.getName(), this.license || ""])
    }
    getTaxTotal(total: number, ivaPorcentaje: number) {

        const iva = total * (ivaPorcentaje / 100);
        return iva;
    }
}