import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/Api/api.service';
import { RouterService } from 'src/app/services/router.service';
import { SnackService } from 'src/app/services/snack.service';
import { BrandModelInputComponent } from '../brand-model-input/brand-model-input.component';
import { M_Brand } from 'src/app/models/M_Brand';
import { ParameterStateComponent } from '../parameter-state/parameter-state.component';
import { M_Model } from 'src/app/models/M_Model';
import { M_Serie } from 'src/app/models/M_Serie';
import { ViewPath } from 'src/app/app-routing.module';
import { PS_HIGHLIGHT } from 'src/app/constants/constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { YearDatapikerComponent } from '../year-datapiker/year-datapiker.component';
import { AccountingGroupSelectorComponent } from '../accounting-group-selector/accounting-group-selector.component';

@Component({
  selector: 'app-create-models',
  templateUrl: './create-models.component.html',
  styleUrls: ['./create-models.component.css']
})
export class CreateModelsComponent extends ParameterStateComponent implements OnInit{
  public form: UntypedFormGroup;
  loaded: boolean = false;
  isEdit: boolean = false;
  minDate: Date;
  model: M_Model | undefined;
  serie: M_Serie | undefined;
  v =ViewPath;
  IsChange: boolean = false;
  @ViewChild(BrandModelInputComponent, { static: true }) brandModelInputComponent!: BrandModelInputComponent;
  @ViewChild(YearDatapikerComponent, { static: true }) yearPiker!: YearDatapikerComponent;
  @ViewChild(AccountingGroupSelectorComponent, { static: true }) accGroup!: AccountingGroupSelectorComponent;
  constructor(@Optional() public dialogRef: MatDialogRef<CreateModelsComponent>,
  @Optional() @Inject(MAT_DIALOG_DATA) public data: M_Model | undefined,
  private apiS: ApiService, private formBuilder: UntypedFormBuilder, route: ActivatedRoute, public override routerS: RouterService, public snackS: SnackService) {
    super(routerS, route, ["models"]);
    this.form = this.formBuilder.group({
      id: [''],
      model: [''],
      name: ['', [Validators.required]],
      brand_id: [''],
      serie_id: [''],
      brand_name: [''],
      serie_name: [''],
      accounting_id:[],
      accounting:[],
      version: [''],
      date_version: [''],
      brand: ['', [Validators.required]],
      serie: ['', [Validators.required]],
    })
    this.minDate = new Date();
    if (!this.isEdit) {
      this.loaded = true;
    }else{

      this.form.valueChanges.subscribe(() => {
        this.IsChange = this.isEdit && this.form.dirty;
      });
    }
   if(this.data != undefined && this.isOnDialog){
    this.model = new M_Model(this.data);
    console.log(this.model,this.data);
    this.form.patchValue(this.model);
    if(this.model.accounting != undefined){
      this.form.patchValue({accounting_id:this.model?.accounting?.id});
    }
    if(this.model.serie){
      this.form.patchValue({serie:this.model.serie.name});

      this.serie = new M_Serie(this.model.serie);
      console.log('*-----serieeees')
      console.log(this.model.brand);

    }
    if(this.model.brand){
      this.apiS.getBrandById(this.model.brand?.id).then(res => {
        if (res) {
          this.brandModelInputComponent.showingSeries = res.series;
        }
       });
    }
    this.isEdit = true;
   }
  }
  ngOnInit(): void {
    if(this.model != undefined){
      this.yearPiker.valuePicker?.setValue(this.model.date_version);
    }
  }
  override onParam(_k: string, v: any) {
    console.log(v);
    this.apiS.getModelById(v).then(res => {
      if (res) {
        this.form.patchValue({ date_version: res.date_version });
        this.form.patchValue({ id: res.id });this.form.patchValue({ name: res.name });
        this.form.patchValue({ version: res.version });
        
        // this.brandModelInputComponent.form.patchValue({brand:res.brand});
        if(res.serie){
          this.serie = new M_Serie(res.serie);
          this.form.patchValue({serie:res.serie.name});
          this.form.patchValue({serie_id:res.serie.id});
          this.form.patchValue({serie_name:res.serie.name});
        }
        if (res.brand) {
          this.form.patchValue({ brand: res.brand });
          console.log(res);
          this.apiS.getBrandById(res.brand?.id).then(res => {
            if (res) {
              this.brandModelInputComponent.showingSeries = res.series;
            }
           });
          }
        }
        this.model = res;
        this.loaded = true;
        this.isEdit = true;
      })
    }
    get isOnDialog() {
      return Object.keys(this.dialogRef).length != 0;
    }
  selectBrand(brand: M_Brand) {
    this.form.patchValue({ brand_id: brand?.id, brand: brand });
  }
  selectSerie(serie: M_Serie) {
    this.form.patchValue({ serie_id: serie?.id,serie:serie});
  }

  createUpdate() {
    if (this.form.valid) {
      if(this.serie){
        this.form.patchValue({serie_id:this.serie.id})
        this.form.patchValue({serie:this.serie})
      }
      console.log(this.form.getRawValue());
      this.model = new M_Model(this.form.getRawValue());
      console.log(this.model);
      this.apiS.createModel(this.form.getRawValue()).then(res=>{
        if(res){
          this.model = res;
          if(!this.isEdit){
            this.snackS.show('Creado correctamente el modelo.')
          }else{
            this.snackS.show('Actualizado correctamente el modelo.')
          }
          this.routerS.goWithQueryParams(this.v.models, {[PS_HIGHLIGHT] : res.id});
          this.dialogRef.close(res);
        }
      })
      
    }
  }
}
