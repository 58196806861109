<app-close-dialog-button></app-close-dialog-button>
<p mat-dialog-title>{{data.title}}</p>

<div mat-dialog-content>
  <p>{{data.message}} <strong>{{data.param}}</strong></p>
  <p *ngIf="data.message2">{{data.message2}}.</p>
</div>

<div mat-dialog-actions style="justify-content: center;">
  <button color="primary" mat-flat-button [mat-dialog-close]="true" (click)="edit()">{{data.error ? 'Ok'
    :'Editar'}}</button>
</div>