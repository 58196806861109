<div *ngIf="ps.loaded_; else loading" [matTooltip]="
    ps.disabledExcelMax(ps.dataOnScreen) ? 'Máximo ' + ps.MAX_DOWNLOAD_NUMBER + ' ' + namePlural :  
    ps.disabledExcelMin(ps.dataOnScreen) && ps.loaded_ ? 'Mínimo una ' + nameSingular : ''">

    <button [disabled]="ps.disabledExcelDownload()" mat-menu-item color="secondary"
        (click)="genericExport(ps.dataOnScreen)">
        <mat-icon>library_books</mat-icon>
        <to-do [txt]="'Exportación a excel en desarrollo'"></to-do>
        Exportar a excel
    </button>
</div>

<ng-template #loading>
    <div class="ml10 mr10 mb5">
        <app-skeleton [fullW]="true" [height]="36"></app-skeleton>    
    </div>
</ng-template>