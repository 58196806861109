<!-- Optional close button-->
<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title>Cambio</p>

<!-- Dialog content -->
<div mat-dialog-content>
    <!-- Fist section -->
    <p mat-dialog-subtitle>Debes devolver al cliente : </p>
    <div class="df jcc">
        <mat-icon class="vam mr5 c_r">keyboard_return</mat-icon> 
        <span class="liquidation " money [val]="this.data.changeAmount"></span>
        
    </div>
</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button color="primary" mat-flat-button mat-dialog-close cdkFocusInitial>Ok</button>
</div>