<div class="module-card" [ngClass]="module.cardColor == 'blue' ? 'blue-module' : 'yellow-module'" [go]="v.moduleDetails" [param]="{id : module.id}">
    <div class="module-header">
        
        <div class="df aic">
            <img class="module-img medium" [src]="module.img">
            <p class="ml5_i nmb fw600 fsm">{{module.name}}</p>
        </div>

        <div class="df aic mla" prevent>
            <app-module-price-tag [module]="module"></app-module-price-tag>
            <button [disabled]="companyS.companyMissingInfo" *ngIf="!companyS.hasModule(module.id)" mat-flat-button color="primary"
                (click)="companyS.toggleModule(this.module.id)">Activar ahora</button>
            <button [disabled]="companyS.companyMissingInfo" *ngIf="companyS.hasModule(module.id)" mat-flat-button color="warn"
                (click)="companyS.toggleModule(this.module.id)">Quitar</button>
        </div>
    </div>
    <div class="module-desc">
        <p class="fw600 nmb">{{module.title}}</p>
        <p class="fss">{{module.desc}}</p>
    </div>
    <div class="module-bottom">
        <div class="module-funcionality" *ngFor="let funcionality of module.functionalities" [matTooltip]="funcionality.tooltip">
            <mat-icon class="mb10">{{funcionality.icon}}</mat-icon>
            <p class="module-funcionality-title">{{funcionality.title}}</p>
        </div>
    </div>
</div>