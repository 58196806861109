
export class M_ORTime {
    id: number;
    start_time!: Date;
    or_id: number;
    user_id: number;
    created_at!: Date;
    updated_at!: Date;
    custom: boolean = false;
    end_time?: Date;
    total_time?: string | null;

    constructor(d: any) {
        this.id = d.id;
        if (d.start_time) {
            this.start_time = new Date(d.start_time);
        }

        if (d.end_time) {
            this.end_time = new Date(d.end_time);
        }

        this.total_time = d.total_time;
        this.or_id = d.or_id;
        this.user_id = d.user_id;
        if (d.created_at) { this.created_at = new Date(d.created_at); }
        if (d.updated_at) { this.updated_at = new Date(d.updated_at); }
        this.custom = d.custom;
    }

    get elapsed() {
        var e = this.start_time.differenceWith(this.end_time, { prefix: "short" });
        return e;
    }
}