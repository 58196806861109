import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { M_Schedule } from 'src/app/models/M_Schdeule';

@Component({
  selector: 'app-scheudle-table',
  templateUrl: './scheudle-table.component.html',
  styleUrls: ['./scheudle-table.component.css']
})
export class ScheudleTableComponent implements OnChanges {

  @Input({ required: true }) disabled!: string | undefined;
  @Input({ required: true }) showDay!: boolean;
  @Input({ required: true }) horario!: M_Schedule[];
  @Input() fullW!: boolean | undefined;

  daysOfWeek: string[] = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disabled) {
      this.horario.forEach(h => h.reset());
    }
  }

  dayName(i: number): string {
    return this.daysOfWeek[i];
  }
}
