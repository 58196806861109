import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CustomColor } from 'src/app/custom-classes/CustomColor';

@Component({
  selector: 'app-documentation-color-picker',
  templateUrl: './documentation-color-picker.component.html',
  styleUrls: ['./documentation-color-picker.component.css']
})
export class DocumentationColorPickerComponent implements OnInit {

  @ViewChild('primary') primary!: ElementRef<HTMLInputElement>;
  @Input() noStyle: boolean = false;
  touched: boolean = false;

  constructor(private chdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  refresh() {
    this.touched = true;
    this.chdRef.detectChanges();
  }

  fillValues(primary: string | undefined, secondary: string | undefined) {
    if (primary) {
      this.primary.nativeElement!.value! = primary;
    }
  }

  defaultColor() {
    return new CustomColor("#f4564a").value
  }

  defaultColorSecondary() {
    return new CustomColor("#ffffff").value;
  }

  getPrimary(): CustomColor {
    return this.primary ? new CustomColor(this.primary.nativeElement.value) : new CustomColor();
  }

  getSecondary(): CustomColor {
    /** #2F4858 --> black */
    /** #EFEFEF --> white */
    return new CustomColor(this.getPrimary().contrastValue);
  }

}
