import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { M_Action } from 'src/app/models/M_Action';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { isSomethingMissing } from 'src/app/utils/recambiosFuntions';
import { ApiService } from 'src/app/services/Api/api.service';
import { PreviewService } from 'src/app/services/preview.service';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { DayFilter, Filter, FilterOption, SliderFilter, TagFilter } from 'src/app/custom-classes/Filter';
import { or_status } from 'src/app/custom-classes/or_states';
import { action_type_budget } from 'src/app/custom-classes/action_types';
import { ParamsService } from 'src/app/services/params.service';

export enum BudgetPageFiltesrEnum {
  BUDGET_DELIVEY = 0,
  BUDGET_TOTAL = 1,
  BUDGET_STATUS = 2,
}

/** Get the budget filters.
 * @param invoiceStatus Show the invoiced status?
 */
export function GET_BUDGET_FILTERS(invoiceStatus: boolean): Filter[] {
  return [
    new DayFilter("Fecha creación").setId(BudgetPageFiltesrEnum.BUDGET_DELIVEY),
    new SliderFilter("Total", 500).setId(BudgetPageFiltesrEnum.BUDGET_TOTAL),
    new TagFilter("Estado", or_status, new FilterOption("Abierto"), new FilterOption("Cerrado"), new FilterOption("Facturado", undefined, !invoiceStatus)).setId(BudgetPageFiltesrEnum.BUDGET_STATUS),
  ]
}


  @Component({
    selector: 'app-workshop-budget',
    templateUrl: './workshop-budget.component.html',
    styleUrls: ['./workshop-budget.component.css']
  })
  export class WorkshopBudgetComponent implements OnInit {

    @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Action>;
    v = ViewPath;
    f = filter;
    filters = GET_BUDGET_FILTERS(false);

    constructor(public subS: SubscriptionService, public companyS: CompanyService, private apiS: ApiService, private previewS: PreviewService, public paramsS: ParamsService) { }

    ngOnInit(): void {
      this.load();
    }

    get quickFilter() {
      let f = this.filters.find(f => f.id == BudgetPageFiltesrEnum.BUDGET_STATUS);
      if (f instanceof TagFilter) { return f };
      return undefined;
    }

    load() {
      this.apiS.action.actions(action_type_budget).then(resp => {
        if (this.ps) {
          this.ps.initTable(resp);
          let f = this.filters.find(f => f.id == BudgetPageFiltesrEnum.BUDGET_TOTAL);
          if (f instanceof SliderFilter) {
            f.changeMax(Math.max(...resp.map(o => o.total || 0)))
          }
        }
      });
    }

    client(action: M_Action) { return action.client?.getName() }
    preicon(action: M_Action) { return isSomethingMissing(action.faults); }
  }
