<div *ngFor="let t of or.timers" class="timers-list">
    <ng-container *ngIf="t.custom">
        <div class="df aic" [matTooltip]="'Manual'" [matTooltipPosition]="'left'">
            <!--<mat-icon class="starting-icon manually-added" [matTooltip]="'Añadido manualmente'">add_circle</mat-icon>!-->
            <app-circular-letter class="mr5" [showFullName]="false" [small]="true"
                [user]="getUserByTime(t)"></app-circular-letter>
            <div> {{t.start_time.dayMonthYearFormat()}}</div>
        </div>
    </ng-container>

    <!-- SAME DAY START AND END-->
    <ng-container *ngIf="t.end_time && t.start_time.isEquals(t.end_time) && !t.custom">
        <div class="df aic" [matTooltip]="t.start_time.hourMinuteSecond() + ' - ' + t.end_time.hourMinuteSecond()"
            [matTooltipPosition]="'left'">
            <app-circular-letter class="mr5" [showFullName]="false" [small]="true"
                [user]="getUserByTime(t)"></app-circular-letter>
            <div> {{t.start_time.dayMonthYearFormat()}}</div>
        </div>
    </ng-container>

    <!-- DIFFERENT DAYS-->
    <ng-container *ngIf="t.end_time && !t.start_time.isEquals(t.end_time) && !t.custom">
        <div class="df aic" [matTooltip]="'El fichaje transcurre en distintos días'" [matTooltipPosition]="'left'">
            <mat-icon [filled]="true" class="starting-icon error-icon c_r">
                error
            </mat-icon>
            <div class="df aic">
                <app-circular-letter class="mr5" [showFullName]="false" [small]="true"
                    [user]="getUserByTime(t)"></app-circular-letter>
                <div> {{t.start_time.dayMonthYearFormat()}}</div>
            </div>
        </div>
    </ng-container>

    <!-- USER IS ACTUALLY WORKING -->
    <ng-container *ngIf="!t.end_time && !t.custom">
        <div class="df aic" [matTooltip]="t.start_time.hourMinuteSecond() + ' - ' + '??'" [matTooltipPosition]="'left'">
            <app-circular-letter class="mr5" [showFullName]="false" [small]="true"
                [user]="getUserByTime(t)"></app-circular-letter>
            <div>{{t.start_time.dayMonthYearFormat()}}</div>
        </div>
    </ng-container>

    <!------------------------------------------------------------------------->

    <!-- ELAPSED TIME AND REMOVE BUTTON IF USER HAS PERMISSIONS-->
    <div class="pr0 df aic">
        <div class="c_p mr10" *ngIf="!t.end_time">Trabajando</div>
        <div class="c_p mr10" *ngIf="t.end_time">{{t.elapsed.format}}</div>
        <button color="primary" mat-icon-button (click)="deleteTimeLine(t.id)" *ngIf="canDelete(t.user_id)">
            <mat-icon [filled]="true">delete</mat-icon>
        </button>
    </div>

</div>

<p *ngIf="or.timers.length == 0" class="c_t2">Sin fichajes</p>