<app-close-dialog-button></app-close-dialog-button>
<p mat-dialog-title>Modificar fecha</p>
<div mat-dialog-content>

  <app-client-side-details-appointment [appointment]="data" [first]="true"></app-client-side-details-appointment>

  <form [formGroup]="form">
    <app-hour-picker #hourpciker [isEdit]="true" [form]="form"></app-hour-picker>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-flat-button color="primary" [disabled]="!hourpciker.loadedHours || !form.valid"
    [mat-dialog-close]="hourpciker.getDate()">Guardar</button>
</div>