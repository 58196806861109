import { ChangeDetectorRef, Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Vehicle } from '../../models/Vehicles/M_Vehicle';
import { AlreadyExistsService } from '../../services/already-exists.service';
import { VehicleType } from '../../enums/VehicleType';
import { ImageToggleComponent } from '../image-toggle/image-toggle.component';
import { MASTER_CLIENT_MINIFIY } from '../../constants/masters';
import { DialogConfirmPlateComponent } from './dialog-confirm-plate/dialog-confirm-plate.component';
import { MatDialog } from '@angular/material/dialog';
import { BrandModelInputComponent } from '../brand-model-input/brand-model-input.component';
import { asyncPlate } from '../../validators/plateAsyncValidator';
import { RegistrationTaxEnum, registrationText } from '../../enums/RegistrationTaxEnum';
import { ComercialVehicleType } from '../../enums/ComercialVehicleType';
import { M_Concept } from '../../models/M_Concept';
import { M_Model } from '../../models/M_Model';
import { CreateVNVOcomponent } from 'src/app/views/create-vn-vo/create-vn-vo.component';
import { IConceptLineTableComponent } from '../../interfaces/IConceptLineTableComponent';
import { ConceptsLineTableComponent } from '../concepts-line-table/concepts-line-table.component';
import { RouterService } from 'src/app/services/router.service';
import { ClassSearcherComponent } from '../class-searcher/class-searcher.component';
import { M_Contact } from 'src/app/models/M_Contact';
import { SteppFormAndFileComponent } from 'src/app/stepp-form-and-file/stepp-form-and-file.component';
import { PdfDocumentsUploadComponent } from '../pdf-documents-upload/pdf-documents-upload.component';
import { validatorTax } from 'src/app/validators/tax';
import { M_Color } from 'src/app/models/Vehicles/M_Color';
import { M_Brand } from 'src/app/models/M_Brand';

@Component({
  selector: 'app-vehicleform',
  templateUrl: './vehicleform.component.html',
  styleUrls: ['./vehicleform.component.css']
})
export class VehicleformComponent implements OnInit, IConceptLineTableComponent {

  client = MASTER_CLIENT_MINIFIY;
  RTE = RegistrationTaxEnum;
  CVT = ComercialVehicleType;

  registrationText = registrationText;

  @Input() onlyRequired = false;
  @Input() isOnDialog = false;
  @Input() isVoVn = false;
  @Input() isStep = false;
  @Input() concepts : M_Concept[] = [];
  @Input() requiredFieldsTitle?: string
  provisionalConcepts: M_Concept[] = [];

  @ViewChild(ImageToggleComponent) serieToggle!: ImageToggleComponent;
  @ViewChild(BrandModelInputComponent, { static: true }) brandModelInputComponent!: BrandModelInputComponent;
  @ViewChild(ConceptsLineTableComponent) conceptLineTable!: ConceptsLineTableComponent;
  @ViewChild('csearcher') clientSearcherComponent?: ClassSearcherComponent<M_Contact>

  v = ViewPath;
  validators = Validators;
  public form: UntypedFormGroup;
  lastb1 = "";
  lastb2 = "";
  vehicleTypeApi: number = 1;
  initialChasisValue: string | undefined;
  VT = VehicleType;
  minDate: Date;
  vehicle: M_Vehicle | undefined
  client_select: M_Contact | undefined
  freePlateEedit = false;
  selectedFile: File | null = null;
  availableColors: M_Color[] = [];

  @ViewChild(PdfDocumentsUploadComponent) pdfUploadComponent!: PdfDocumentsUploadComponent; // Referencia al componente hijo

  constructor(private formBuilder: UntypedFormBuilder, private existsS: AlreadyExistsService,
    private apiS: ApiService, private chdRef: ChangeDetectorRef, private routerS: RouterService, private d: MatDialog,
    @Optional() public createVnVoComponent_: CreateVNVOcomponent,
    @Optional() public createVnVoComponent: SteppFormAndFileComponent) {

    this.form = this.formBuilder.group({

      /** Normal vehicle */
      vehicle_id : [],
      type: [this.VT.bike, Validators.required],
      license: ['', {
        validators: [Validators.required],
        asyncValidators: [asyncPlate(this.apiS, this.existsS, () => this.getCurrentVehicle())],
      }],
      brand: ['', Validators.required],
      model: ['', Validators.required],
      chassis: [''],
      serie: [],
      color: ['', []],
      insurance: ['', []],
      cc: ['', []],
      engine_type: ['', []],
      license_date: ['', []],
      fabrication_date: ['', []],
      hire_date: ['', []],
      km: ['', []],
      observations: ['', []],
      last_itv: ['', []],
      next_itv: ['', []],
      electric: ['', []],
      battery_1_no: ['', []],
      battery_2_no: ['', []],
      clientInvoice_id: ['', []],
      version: [''],

      /** New vehicle fields */
      color_id: [''],
      end_warranty: [''],
      number_warranty: [''],
      gears_type: [''],

      /** Comercial */
      comercialType: [],
      registration_tax: [''],
      price: [''],
      cost: [''],
      budget_id: [],
      serie_vehicle: [],
      num_motor: [''],
      workshop_model: [''],
      seller: [''],
      deposit: [''],
      exp_documentation: [''],
      num_prov: [''],
      brand_id:[''],
      model_id:[''],
      serie_id:[''],
      client:[''],
      client_id:[''],
      tax:['',validatorTax()],
      date:[''],
      doc_prov_num:[''],

      /** New comercial fields */
      /** Vn */
      in_deposit: [''],
      financial_expenses: [''],
      delivery_num: [''],
      documentation_expiration: [''],
      accounting: [''],
      year: [''],
      /** Vo */
      client_origin: ['']

    });

    this.minDate = new Date();
    this.form.get("battery_1_no")?.disable();
    this.form.get("battery_2_no")?.disable();


    /** When the engine_type 'select' changes, diable or enable some fields */
    this.form.get('engine_type')?.valueChanges.subscribe(val => {
      let b1 = this.form.get("battery_1_no")!;
      let b2 = this.form.get("battery_2_no")!;
      /** If the motor type is gas, disable the electric vehicle fields */
      if (val == 0) {

        this.lastb1 = b1.value;
        b1.setValue('');
        b1.disable();
        this.lastb2 = b2.value;
        b2.disable();
        b2.setValue('');
      }
      else {
        b1.enable();
        b1.patchValue(this.lastb1)
        b2.enable();
        b2.patchValue(this.lastb2)
      }
    })

    this.form.get("type")?.valueChanges.subscribe(val => {
      this.form.patchValue({ serie: null });
    })

    this.form.get('model')?.valueChanges.subscribe(val => {
      if (val instanceof M_Model && val.details) {
        this.form.patchValue({ price: val.details.price })
        this.form.patchValue({ cost: val.details.cost })
        this.form.patchValue({ registration_tax: val.details.tax_registration })
        this.form.patchValue({ brand_id: val.id_brand })
        this.form.patchValue({ model_id: val.id })
      }
      else if(val instanceof M_Model){
        this.form.patchValue({ brand_id: val.id_brand })
        this.form.patchValue({ model_id: val.id })
      }
    })
    this.form.get('brand')?.valueChanges.subscribe(val => {
      if(val instanceof M_Brand){
        this.form.patchValue({brand_id:val.id});
      }
    });

    /** Set up comercial vehicle form */
    this.form.get('comercialType')?.valueChanges.subscribe(v => {
      this.requiredIf(this.form.get('registration_tax'), "vn");
      this.requiredIf(this.form.get('license'), "v", 'vo');
      this.requiredIf(this.form.get('brand'), "v",'vn','vo');
      this.requiredIf(this.form.get('model'), "v",'vn','vo');
      this.requiredIf(this.form.get('chassis'), "vn");
    })


    if (this.createVnVoComponent_) {
      this.form.get('comercialType')?.setValue(this.CVT.VN);
    }
    else if(this.createVnVoComponent){
      this.form.get('comercialType')?.setValue(this.CVT.VN);
    }

    this.requiredIf(this.form.get('price'), "vn", "vo");
    this.requiredIf(this.form.get('cost'), "vn", "vo");
    this.requiredIf(this.form.get('client_id'), "vn", "vo");
    this.requiredIf(this.form.get('cost'), "vn", "vo");
  }

  onFileSelected(file: File | null) {
    if (file) {
      this.selectedFile = file;
      // Aquí puedes agregar la lógica para enviar el archivo a la API
      this.uploadFile(file);
    } else {
      this.selectedFile = null;
    }
  }

  handleClientSelection(selectedClient: M_Contact) {
    this.client_select = selectedClient;
    if(this.client_select != undefined && this.client_select.type === 2 || this.client_select?.type === 3){
      this.requiredIf(this.form.get('doc_prov_num'), "vn", 'vo');
      this.chdRef.detectChanges();
    }
  }

  uploadFile(file: File) {
    const formData = new FormData();
    if (this.selectedFile) {
      formData.append('pdf_file', this.selectedFile);
    }
  }

  addConcept(c: M_Concept): void {
    this.getConcepts().push(c);
  }

  getConcepts() {
    if (this.vehicle) { return this.vehicle.concepts };
    if(this.concepts){return this.concepts};  
    return this.provisionalConcepts;
  }

  getCurrentVehicle(): M_Vehicle | undefined {
    return this.vehicle;
  }

  enablePlateEdition() {
    this.d.open(DialogConfirmPlateComponent, {
      data: {
        title: "Edición de matrícula",
        message: "¿Estas seguro que quieres editar la matrícula?",
      }
    }).afterClosed().subscribe(res => {
      if (res != true) {
        this.form.get('license')?.disable();

      } else {
        this.freePlateEedit = true;
        this.form.get('license')?.enable();
      }
    }
    );
  }

  showMe(v: VehicleType): boolean {
    let value = this.form.get('type')?.value;
    if (value) {
      return this.form.get('type')?.value == v;
    }
    return false;
  }

  ngOnInit(): void {
    this.availableColors = [
      // {
      //   id: 1,
      //   brand_id: 1,
      //   brand_name: 'This is fake color',
      //   serie_id: 101,
      //   serie_name: 'Serie 1',
      //   version: 'v1',
      //   model_name: 'Modelo A',
      //   percentage_cost: 5,
      //   cost: 100,
      //   accounting: 'Grupo A',
      //   effective_date: new Date(),
      //   manufacturer_id: 5001
      // },
      // {
      //   id:2,
      //   brand_id: 2,
      //   brand_name: 'This is super fake color',
      //   serie_id: 102,
      //   serie_name: 'Serie 2',
      //   version: 'v2',
      //   model_name: 'Modelo B',
      //   percentage_cost: 7,
      //   cost: 150,
      //   accounting: 'Grupo B',
      //   effective_date: new Date(),
      //   manufacturer_id: 5002
      // }
    ];
  }

  goCreateClient() {
    this.routerS.goTo(this.v.createContact);
  }

  /** Patch the current form with a vehicle. */
  patchFormWithVehicle(vehicle: M_Vehicle) {
    this.vehicle = vehicle;
    this.chdRef.detectChanges();
    if (this.vehicle != undefined) {
      this.form.patchValue(this.vehicle)
    }

    let licenseControl = this.form.get('license');
    if (licenseControl?.value) {
      this.form.get('license')?.disable();
    }
  }
  
  get formVnVo(){
    if(this.createVnVoComponent_){return true;}
     else if(this.createVnVoComponent){ return true;}
     else{return false;}
  }

  get licenseBlocked() {
    return this.vehicle != undefined && this.form.get('license')?.value && !this.freePlateEedit;
  }

  get loaded() {
    return this.brandModelInputComponent.loaded;
  }

  get isNormalVehicle() {
     
    if(this.createVnVoComponent){
      return false;
    }
    else if(this.createVnVoComponent_){
      return false;

    }
    return true;
  }
  get clientProvAndCompany(){
    return this.clientSearcherComponent?.selected;
  }
  get isVn() {
    
    if(this.createVnVoComponent){
      return this.form.get('comercialType')?.value == this.CVT.VN;
    }
    else if(this.createVnVoComponent_){
      return this.form.get('comercialType')?.value == this.CVT.VN;

    }
    return false;
  }

  get isVo() {
    if(this.createVnVoComponent){
      return this.form.get('comercialType')?.value == this.CVT.VO;
    }
    else if(this.createVnVoComponent_){
      return this.form.get('comercialType')?.value == this.CVT.VO;

    }
    return false
  }

  requiredIf(control: AbstractControl<any, any> | null, ...is: ("v" | "vn" | "vo")[]) {
    if (!control) { return; }

    if (this.vehicleIs(...is)) {
      control.addValidators(Validators.required);
      console.log("Setting ", control, " as required field")
    }
    else {
      control.removeValidators(Validators.required);
      console.log("Setting ", control, " as NO required field")
    }
    control.updateValueAndValidity();
  }

  vehicleIs(...is: ("v" | "vn" | "vo")[]) {
    let ok = true;
    if (is.includes("v")) { ok = this.isNormalVehicle; if (ok) { return true; } }
    if (is.includes("vn")) { ok = this.isVn; if (ok) { return true; } }
    if (is.includes("vo")) { ok = this.isVo; if (ok) { return true; } }
    return ok;
  }
}
