import { Component } from '@angular/core';
import { ApiService } from 'src/app/services/Api/api.service';
import { ProductCategory } from 'src/app/enums/ProductCategory';
import { M_Reservation } from 'src/app/models/M_Reservation';
import { M_Product } from 'src/app/models/Products/M_Product';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-dialog-reserve',
  templateUrl: './dialog-reserve.component.html',
  styleUrls: ['./dialog-reserve.component.css']
})
export class DialogReserveComponent {
  reservations: M_Reservation[] = [];
  loaded: boolean = false;
  constructor(public apiS: ApiService, public responsiveS: ResponsiveService) { }
  get displayedColumns() {
    return this.responsiveS.w > 514
      ? ['Ref', 'Cantidad', 'Faltas', 'Reservar']
      : ['Ref', 'Cantidad', 'Faltas', 'Reservar'];
  }

  ngOnInit(): void {
    this.apiS.reservation().then(res => {
      if (res) {
        this.reservations = res
        this.loaded = true;
      }
    })
  }

  icon(product: M_Product) {
    switch (product.category) {
      case ProductCategory.GENERAL:
        return "barcode"
      case ProductCategory.WRENCH:
        return "build_circle"
      case ProductCategory.WHEEL:
        return "tire_repair"
      case ProductCategory.LIGHT:
        return "lightbulb"
      case ProductCategory.LIQUID:
        return "water_drop"
      case ProductCategory.BOUTIQUE:
        return "checkroom"
      default:
        return "barcode"
    }
  }
}
