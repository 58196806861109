import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppointmentStatus } from '../../enums/AppointmentStatus';
import { M_Appointment } from 'src/app/models/M_Appointment';
import { M_User } from 'src/app/models/M_User';

/**
 * Workload WEEK card for appointemnts
 */
@Component({
  selector: 'lib-appointment-week-card',
  templateUrl: './appointment-week-card.component.html',
  styleUrls: ['./appointment-week-card.component.css', '../carga-taller/drag-and-drop-grid/grid-card/grid-card.component.css']
})
export class AppointmentWeekCardComponent implements OnInit {

  app_status = AppointmentStatus;

  /** The apoointment object itself */
  @Input() item!: M_Appointment;
  /** Emitted when user click on the card */
  @Output() onGoAppointment : EventEmitter<any> = new EventEmitter();
  /** Emitted when the appointment is modified */
  @Output() onModifyAppointment: EventEmitter<[M_Appointment, ("hour")]> = new EventEmitter();
  @Output() onAssignUser: EventEmitter<M_User> = new EventEmitter();


  @Input() allUsers!: M_User[];
  @Input() day!: Date;
  @Input() canAssign!: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  preventCardClick(e: Event) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

}
