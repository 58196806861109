import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientformComponent } from 'src/app/components/clientform/clientform.component';
import { OrFormComponent } from 'src/app/components/or-form/or-form.component';
import { VehicleformComponent } from 'src/app/components/vehicleform/vehicleform.component';
import { M_Appointment } from 'src/app/models/M_Appointment';
import { OrService } from 'src/app/services/or.service';

@Component({
  selector: 'app-appointment-to-or',
  templateUrl: './appointment-to-or.component.html',
  styleUrls: ['./appointment-to-or.component.css']
})
export class AppointmentToOrComponent implements AfterViewInit {

  @ViewChild(OrFormComponent, { static: true }) orComponent?: OrFormComponent;
  @ViewChild(ClientformComponent) clientComponent?: ClientformComponent;
  @ViewChild(VehicleformComponent) vehicleComponent?: VehicleformComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: M_Appointment, private orS: OrService, public dialogRef: MatDialogRef<AppointmentToOrComponent>, private chdRef: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.chdRef.detectChanges();
    if (this.orComponent) {
      if (this.data.vehicle) {
        this.orComponent.vehicleSearcher?.select(this.data.vehicle.vehicle_id);
      }
      else if (this.clientComponent && this.vehicleComponent) {
        this.clientComponent.form.patchValue({ 'name': this.data.clientName() });
        this.vehicleComponent.form.patchValue(this.data);
      }
    }
  }

  createOr() {
    if (!this.orComponent) { return; }
    this.orS.create({
      orForm : this.orComponent,
      raGroups : this.orComponent.raGroups.groups,
      dialog: this.dialogRef,
      clientForm : this.clientComponent,
      vehicleForm : this.vehicleComponent,
      appoitnmentId : this.data.id
    })
  }

  get createOrDisabled() {
    if (this.data.isClient && this.orComponent) {
      return !this.orComponent.isFormOk;
    }
    else if (this.clientComponent && this.vehicleComponent && this.orComponent) {
      return !this.clientComponent.form.valid || !this.vehicleComponent.form.valid || !this.orComponent.isFormOk;;
    }
    return true;
  }

  get orFormLoaded(){
    return this.orComponent?.vehiclesAndWorkersLoaded;
  }
}
