import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { M_Model } from '../../models/M_Model';
import { VehicleType } from '../../enums/VehicleType';
import { M_Brand } from '../../models/M_Brand';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_ModelDetails } from '../../models/M_ModelDetails';
import { MatDialogRef } from '@angular/material/dialog';
import { RegistrationTaxEnum, registrationText } from '../../enums/RegistrationTaxEnum';
import { SnackService } from 'src/app/services/snack.service';
import { BrandModelInputComponent } from '../brand-model-input/brand-model-input.component';
import { validatorTax } from 'src/app/validators/tax';
import { M_Concept } from 'src/app/models/M_Concept';
import { ConceptsLineTableComponent } from '../concepts-line-table/concepts-line-table.component';
import { IConceptLineTableComponent } from 'src/app/interfaces/IConceptLineTableComponent';
import { M_Serie } from 'src/app/models/M_Serie';

@Component({
  selector: 'app-brand-model-edit',
  templateUrl: './brand-model-edit.component.html',
  styleUrls: ['./brand-model-edit.component.css']
})
export class BrandModelEditComponent  implements IConceptLineTableComponent{

  public form: UntypedFormGroup;
  public editForm: UntypedFormGroup;
  onEdit = false;
  RTE = RegistrationTaxEnum;
  selectedYear: number | null = null;
  provisionalConcepts: M_Concept[] = [];
  showSeries : M_Serie[] = [];
  loadingModels = false;
  registrationText = registrationText;
  @ViewChild(BrandModelInputComponent, { static: true }) brandModelInputComponent!: BrandModelInputComponent;
  @ViewChild(ConceptsLineTableComponent) conceptLineTable!: ConceptsLineTableComponent;
  constructor(public dialogRef: MatDialogRef<BrandModelEditComponent>, private formBuilder: UntypedFormBuilder,
    private apiS: ApiService, private snackS: SnackService,private chdRef: ChangeDetectorRef) {
    this.form = this.formBuilder.group({
      type: [VehicleType.bike],
      brand: [''],
      model: [''],
      serie: ['']
    });

    this.editForm = this.formBuilder.group({
      model_id : [''],
      brand:[],
      model:[],
      price: ['', Validators.required],
      cost: ['', Validators.required],
      tax: [''],
      tax_registration : [],
      accounting: [''],
      
      /** New fields */
      serie_id: [''],
      serie_name: [''],
      version: [''],
      gears_type: [''],
      gears: [''],
      cylinder: [''],
      percentage_cost: ['',validatorTax],
      chassis_prefix: [''],
      tax_type: [''],
      license_tax: [''],
      weight: [''],
      intrastat: [''],
      description: [''],
      year: ['',this.yearValidator]
    });

  }
  yearValidator(control: any) {
    const value = control.value;
    // Validar que sea un número de 4 dígitos (entre 1000 y 9999)
    const valid = /^[0-9]{4}$/.test(value);
    return valid ? null : { invalidYear: true };
  }
  addConcept(c: M_Concept): void {
    this.getConcepts().push(c);
  }

  goNext() {
    this.editForm.reset();
    this.onEdit = true;
    let model = this.model;
    let serie = this.serie;
    let brand = this.brand;
    if (model instanceof M_Model) {
      if (model.details){
        this.editForm.patchValue(model.details);
      }
      this.editForm.patchValue({'model_id' : model.id});
    }
    if(brand){
      if(serie != undefined){
        this.editForm.patchValue({'serie_id' : serie.serie});
        this.editForm.patchValue({'serie_name' : serie.name});
      }else{
        this.showSeries = brand.series;
      }
    }
  }
  getConcepts() {
    return this.model && this.model.details ? this.model.details.concepts : this.provisionalConcepts;
  }
  clearSerie(){
    this.form.patchValue({serie_id:undefined});
    this.form.patchValue({serie_name:undefined});
  }
  onSerieSelected(selectedSerie : M_Serie){
    this.editForm.patchValue({'serie_id' : selectedSerie.serie});
    this.editForm.patchValue({'serie_name' : selectedSerie.name});
  }
  goBack() {
    this.form.reset();
    this.onEdit = false;
    this.editForm.reset();
    this.showSeries = [];
  }
  get isEdit(){
    return this.onEdit;
  }
  get isNextDisabled() {
   
    let brand = this.brand instanceof M_Brand;
    let model = this.model instanceof M_Model;
    return !brand || !model;
  }
  // get validBrand(){
  //   return this.brandModelInputComponent.isBrandValid;
  // }
  // get validModel(){
  //   return this.brandModelInputComponent.isModelValid;
  // }
  get brand() {
    return this.form.get('brand')?.value;
  }

  get model() {
    return this.form.get('model')?.value;
  }
  get serie(){
    return this.form.get('serie')?.value;
  }

  get editTitle() {
    let brand = this.brand instanceof M_Brand ? this.brand.name : this.brand;
    let model = this.model instanceof M_Model ? this.model.name : this.model;
    return brand + " " + model;
  }


  update() {
    console.log(this.model,this.brand);
    if(!(this.model instanceof M_Model)){
      this.editForm.patchValue({model:this.model});
    }
    if(!(this.brand instanceof M_Model)){
      this.editForm.patchValue({brand:this.brand});
    }
    let md: M_ModelDetails = new M_ModelDetails(this.editForm.value);
    md.concepts = this.getConcepts();
    this.apiS.vnvo.updateModel(this.editForm.value,md.concepts).then(res => {
      this.dialogRef.close();
      this.snackS.show("Marca y modelo actualizados con éxito")
    })
  }
    onYearChange(year: number) {
    this.selectedYear = year; // Captura el año seleccionado
    console.log('Año seleccionado:', year);
    this.editForm.patchValue({year:this.selectedYear});
    this.chdRef.detectChanges();
  }

}
