import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImagePreviewComponent } from '../components/image-preview/image-preview.component';
import { CustomFile } from '../custom-classes/CustomFile';

@Injectable({
  providedIn: 'root'
})

export class ImagePreviewService {

  constructor(private d: MatDialog) { }

  openPreview(all: string[] | CustomFile[] | string | CustomFile, selected?: string | CustomFile) {
    var newAll = !Array.isArray(all) ? [all] : all;
    var sel = selected ? selected : newAll[0];
    this.d.open(ImagePreviewComponent,
      {
        panelClass: 'image-preview-dialog',
        width: '100vw',
        maxWidth: '100vw',
        data: { selected: sel, all: newAll },
        autoFocus: false
      })
  }
}
