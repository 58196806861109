import { Component, Input, OnInit } from '@angular/core';
import { Section } from '../../../custom-classes/Onboarding/Section';
import { M_CloudTicket } from '../../../models/M_CloudTicket';
import { M_CloudTicketEvent } from '../../../models/M_CloudTicketEvent';
import { ImagePreviewService } from '../../../services/image-preview.service';
import { IRefService } from 'src/app/interfaces/IRefService';
import { M_User } from 'src/app/models/M_User';

@Component({
  selector: 'app-cloud-ticket-comment',
  templateUrl: './cloud-ticket-comment.component.html',
  styleUrls: ['./cloud-ticket-comment.component.css', '../attached-image.css']
})
export class CloudTicketCommentComponent implements OnInit {

  @Input() event!: M_CloudTicketEvent;
  @Input() onboarding!: IRefService;

  @Input() cloudTicket!: M_CloudTicket;
  @Input() clientSide: boolean = true;

  clientAttention = new M_User({
    id: -1,
    name: "Atención al cliente",
    email: "sinigual@sinigual.com",
    image: "https://cloud.sinigual.com/assets/img/logo-small.png",
  })

  userToShow: M_User | undefined;

  constructor(public previewS : ImagePreviewService) { }

  ngOnInit(): void {
    if (this.clientSide && this.event.user_id != this.cloudTicket.user_id) {
      this.userToShow = this.clientAttention;
    }
    else {
      this.userToShow = this.event.user;
    }
  }

  doAction(id: number | undefined) {
    if (id != undefined && this.onboarding.onboardingComponent != undefined) {
      var s: Section[] = this.onboarding.onboardingComponent.userOnboarding.sections;
      id = id - 1;
      if (id <= s.length - 1) {
        this.onboarding.onboardingComponent?.startSection(this.onboarding.onboardingComponent.userOnboarding.sections[id]);
      }
    }
  }
}
