import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { UserService } from '../services/EinaMainData/user.service';
import { RolesEnum } from '../enums/RolesEnum';


/** Interface for roles directives */
interface I_RolesDirective {
  get isRoleOk(): boolean;
}


/** Funcion for all role directives */
function roleIsNotOk(el: ElementRef) {
  el.nativeElement.classList.add('dn_i');
  el.nativeElement.remove();
}

/** Only show the element to the admin */
@Directive({
  selector: '[super-user]'
})
export class RolesSuperUserDirective implements I_RolesDirective {

  constructor(el: ElementRef, private userS: UserService) {
    if (!this.isRoleOk) { roleIsNotOk(el); }
  }

  get isRoleOk(): boolean {
    return this.userS.isSuperUser;
  }
}


/** Only show the element to the admin */
// @Directive({
//   selector: '[admin]'
// })
// export class RolesAdminDirective implements I_RolesDirective {

//   constructor(el: ElementRef, private userS: UserService) {
//     if (!this.isRoleOk) { roleIsNotOk(el); }
//   }

//   get isRoleOk(): boolean {
//     return this.userS.userIsAdmin;
//   }
// }


// /** Only show the element to the gestor */
// @Directive({
//   selector: '[gestor]'
// })
// export class RolesGestorDirective implements I_RolesDirective {

//   constructor(el: ElementRef, private userS: UserService) {
//     if (!this.isRoleOk) { roleIsNotOk(el); }
//   }

//   get isRoleOk(): boolean {
//     return this.userS.userIsGestor;
//   }
// }

/** Only show the element to the adviser */
@Directive({
  selector: '[adviser]'
})
export class RolesAdviserDirective implements I_RolesDirective {
  constructor(el: ElementRef, private userS: UserService) {
    if (!this.isRoleOk) { roleIsNotOk(el); }
  }
  get isRoleOk(): boolean {
    return this.userS.userIsAdviser;
  }
}

/** Only show the element to the comercial */
@Directive({
  selector: '[comercial]'
})
export class RolesComercialDirective implements I_RolesDirective {
  constructor(el: ElementRef, private userS: UserService) {
    if (!this.isRoleOk) { roleIsNotOk(el); }
  }
  get isRoleOk(): boolean {
    return this.userS.userIsComercial;
  }
}

/** Only show the element to the recambista */
@Directive({
  selector: '[recambista]'
})
export class RolesRecambistaDirective implements I_RolesDirective {
  constructor(el: ElementRef, private userS: UserService) {
    if (!this.isRoleOk) { roleIsNotOk(el); }
  }
  get isRoleOk(): boolean {
    return this.userS.userIsRecambista;
  }
}

/** Only show the element to the mechanic */
@Directive({
  selector: '[mechanic]'
})
export class RolesMechanincDirective implements I_RolesDirective {
  constructor(el: ElementRef, private userS: UserService) {
    if (!this.isRoleOk) { roleIsNotOk(el); }
  }
  get isRoleOk(): boolean {
    return this.userS.userIsMechanic;
  }
}

/** Show th element if the user is some one of the roles of the "roles" input */
@Directive({
  selector: '[roles]'
})
export class RolesDirective implements I_RolesDirective, OnInit {
  @Input('roles') roles!: RolesEnum[] | RolesEnum;
  constructor(private el: ElementRef, private userS: UserService) { }
  
  ngOnInit() {
    if (!this.isRoleOk) { roleIsNotOk(this.el); }
  }
  get isRoleOk(): boolean {
    if (Array.isArray(this.roles)) { return this.roles.some(r => r == this.userS.getRole()) }
    return this.roles == this.userS.getRole();
  }
}

/** Show the element only if the user is NOT one of the roles of the "roles" input */
@Directive({
  selector: '[not-roles]'
})
export class NotRolesDirective implements I_RolesDirective, OnInit {
  @Input('not-roles') roles!: RolesEnum[] | RolesEnum;
  constructor(private el: ElementRef, private userS: UserService) { }

  ngOnInit() {
    if (!this.isRoleOk) { roleIsNotOk(this.el); }
  }

  get isRoleOk(): boolean {
    if (Array.isArray(this.roles)) { return this.roles.every(r => r != this.userS.getRole()) }
    return this.roles != this.userS.getRole();
  }
}

