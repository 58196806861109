<div class="df aic or-status-label-parent">
  <app-card-subtitle *ngIf="showText" class="subtitleNoBottom" [first]="true">Tipo</app-card-subtitle>
  <mat-chip-set [ngClass]="{'matchipSmall' : needWrap}">
    <div *ngFor="let t of tipo; let last = last; let i = index">
      <!-- Mat chips -->
      <mat-chip #mc="matChip" [class]="'type-label type' + t.num" *ngIf="!verticalLines" [selectable]="selectable"
        [ngClass]="{'selected-type' : mc.highlighted, 'cp_i' : menuIconOpen || pointer}" (click)="onSelect(mc)">
        <span [ngClass]="{'fsxs' : needWrap, 'singleLetter' : needWrap}">
          {{needWrap ? t.name[0] : !shortMantenimiento(i) ? t.name : "MANT."}}
        </span>
        <mat-icon style="pointer-events: none;" matChipRemove *ngIf="menuIconOpen">arrow_drop_down</mat-icon>
      </mat-chip>
      <!-- Vertical line-->
      <div [class]="'type-label type' + t.num + ' vertical-line'" [ngClass]="{'mr5' : last}" *ngIf="verticalLines">
      </div>
    </div>
  </mat-chip-set>
</div>