import { IPageStructureItem } from "src/app/interfaces/IPageStructureItem";
import { match } from "src/app/services/search.service";
import { M_Brand } from "../M_Brand";
import { M_AccountingGroup } from "../AccountingGroups/M_AccountingGroup";

export class M_Color implements IPageStructureItem {
    id:number;
    code: string;
    name:string;
    brand_id: number;
    brand_name: string;
    serie_id: number;
    serie_name: string;
    version: string;
    model_name: string;
    percentage_cost: number;
    cost: number;
    accounting: M_AccountingGroup | undefined;
    accounting_id: number;
    effective_date: Date;
    manufacturer_id: number;
    brand: M_Brand | undefined
    constructor(d: any) {
        this.id = d.id;
        this.code = d.code,
        this.name = d.name,
        this.brand_id = d.brand_id;
        this.brand_name = d.brand_name;
        this.serie_id = d.serie_id;
        this.serie_name = d.serie_name;
        this.version = d.version;
        this.accounting_id = d.accounting_id;
        this.model_name = d.model_name;
        this.percentage_cost = d.percentage_cost;
        this.cost = d.cost;
        this.effective_date = new Date(d.effective_date);
        this.manufacturer_id = d.manufacturer_id;
        if(d.brand){
            this.brand = new M_Brand(d.brand);
        }
        if(d.acc_groups){
            this.accounting = new M_AccountingGroup(d.acc_groups);
        }
    }
    defaultSearchFilter(text: string): boolean {
        return match(text, this.code, this.name, this.brand_name);
    }
    get itemId(): number | undefined {
        return this.id;
    }

}
