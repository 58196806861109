import { Component, Input } from '@angular/core';
import { M_Invoice } from 'src/app/models/M_Invoice';

@Component({
  selector: 'app-invoice-totals-table',
  templateUrl: './invoice-totals-table.component.html',
  styleUrls: ['./invoice-totals-table.component.css']
})
export class InvoiceTotalsTableComponent {
  @Input({ required: true }) invoice!: M_Invoice;
  /** Get the numbers from the values seved on backend */
  @Input({ required: true }) fixedTotals: boolean = false;
  @Input() alignment!: "right" | undefined;
}
