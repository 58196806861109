<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>Anticipos</p>

<div mat-dialog-content>
    <button class="w30" mat-stroked-button (click)="addAdvancePayment()">
        <mat-icon>add</mat-icon>
        Nuevo anticipo
    </button>
    <div class="df aic" *ngIf="data?.last_movement?.length">
        <app-advances-array *ngIf="this.data" [typePayment]="this.typePayment"
            [dataMostrada]="data.last_movement"></app-advances-array>
    </div>
    <div *ngIf="!data?.last_movement?.length">
        <p class="c_t2 tac">Este cliente aún no tiene anticipos</p>
    </div>
</div>