import { BaseModule, ModuleDetails } from "./Factory/BaseModule";
import { M_MTRModule } from "./M_MTRModule";

export class M_TemplatesModule extends BaseModule {
    get name() { return "Impresión Personalizada"; }
    get corename() { return "impresión personalizada"; }
    get title() { return "La integración perfecta para personalizar las plantillas"; }
    get desc() { return "Templates desc."; }
    override isMTR(): this is M_MTRModule { return false;}

    generateFuncionalities() {
        return [
            { icon: "flag", title: "To do", tooltip: "To do" },
        ];
    }
    generateModuleDetails(): ModuleDetails[] {
        return []
    }
}