import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { MASTER_C_CLIENT } from 'src/app/constants/masters';
import { M_Albaran } from 'src/app/models/M_Albaran';
import { M_Contact } from 'src/app/models/M_Contact';
import { Location } from '@angular/common';
import { M_Product } from 'src/app/models/Products/M_Product';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { MatDialog } from '@angular/material/dialog';
import { IExitSaveChanges } from 'src/app/interfaces/IExitSaveChanges';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { IProductLineTableComponent } from 'src/app/interfaces/IProductLineTableComponent';
import { ProductLineTableComponent } from 'src/app/components/product-line-table/product-line-table/product-line-table.component';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { PreviewService } from 'src/app/services/preview.service';
import { CanInvoiceService } from 'src/app/services/can-invoice.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { albaran_status, albaran_status_closed, albaran_status_invoiced, albaran_status_open } from 'src/app/custom-classes/albaran_status';
import { RouterService } from 'src/app/services/router.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { SnackService } from 'src/app/services/snack.service';
import { or_types } from 'src/app/custom-classes/or_types';
import { CreateMasterInDialogService } from 'src/app/services/create-master-in-dialog.service';


@Component({
  selector: 'app-create-edit-albaran',
  templateUrl: './create-edit-albaran.component.html',
  styleUrls: ['./create-edit-albaran.component.css']
})

export class CreateEditAlbaranComponent extends ParameterStateComponent implements AfterViewInit, IProductLineTableComponent, IExitSaveChanges {

  al_o = albaran_status_open;
  al_c = albaran_status_closed;
  form: UntypedFormGroup
  validForm: boolean = false;
  forceExit = false;
  client = MASTER_C_CLIENT;
  loaded: boolean = false;
  albaranFialedToLoad: boolean = false;
  albaran: M_Albaran | undefined;
  formChanges = false;
  v = ViewPath;
  initializationError = false;


  @ViewChild(ProductLineTableComponent) productLineTable!: ProductLineTableComponent;
  get blocksLine(): boolean { return true; }


  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService,
    private confirmD: ConfirmDialogService, private snackS: SnackService, private location: Location, public companyS: CompanyService,
    public d: MatDialog, public router: Router, public subS: SubscriptionService, private formBuilder: UntypedFormBuilder,
    private previewS: PreviewService, public canInvoiceS: CanInvoiceService) {
    super(routerS, route, ["albaran", "client"]);
    this.form = this.formBuilder.group({
      comment: [''],
      date_user: [new Date(), Validators.required],
      contact_address: [0, Validators.required],
      extra_addres: [null, Validators.required],
      block: [''],
    });
  }

  override onParam(k: string, v: string) {
    if (k == "albaran") {
      let val = v.getNumber();
      this.apiS.albaranById(val).then(res => {
        this.albaran = res;
        this.activateFormByAlbaranStatus()
        this.form.patchValue(res, { emitEvent: false });
        this.loaded = true;
      })
    }
    if (k == "client") {
      this.loaded = true;
    }
  }

  activateFormByAlbaranStatus() {
    if (!this.albaran?.opened) {
      this.form.disable();
    }
    else {
      this.form.enable();
    }
    this.formChanges = false;
  }


  override noParams(): void {
    this.loaded = true;
  }

  ngAfterViewInit(): void {
    this.form.valueChanges.subscribe(v => {
      if (this.loaded) {
        this.formChanges = true;
      }
    })
  }

  addProduct(p: M_Product | M_CustomProduct): void {
    this.albaran?.products.push(p);
  }

  removeProduct(p: M_CustomProduct | M_Product): void {
    if (p != undefined) {
      /** If the line is not saved */
      if (!p.line_id) {
        this.albaran!.deleteProduct(p);
      }
      /** If the line is already saved */
      else {
        this.apiS.removeAlbaranProduct(p!).then(res => {
          if (p != undefined) {
            this.albaran!.deleteProduct(p);
          }
        })
      }
    }
  }

  addTime(time: M_CustomProduct): void {
    throw new Error("Can't add time to albaran!");
  }

  addComment(comment: M_CustomProduct): void {
    throw new Error('Method not implemented.');
  }

  /** ExitSaveChanges interface */
  showSaveExitDialog(): boolean {
    if (!this.albaran || this.albaran.deleted) { return false; }
    return this.screenChanges;
  }

  saveChangesBeforeExit() {
    this.saveAlbaran();
  }

  createAlbaran(client: M_Contact) {
    if (!client) { return }
    this.apiS.createAlbaran(client, this.form.get('date_user')?.value).then(res => {
      this.albaran = res;
      this.albaran.setUpAlbaran(client!, this.form.get('date_user')?.value);
      this.form.patchValue(this.albaran);
      this.location.replaceState("/" + this.v.createEditAlbaran.path + "?albaran=" + this.albaran.id);
    })
  }

  changeStatus(status: albaran_status) {
    if (!this.albaran) { return; }
    this.albaran.status = status;
    this.activateFormByAlbaranStatus();
  }

  onTypeChange(val: number) {
    let futureType = new or_types(val);
    if (!this.albaran) { return; }
    this.apiS.changeAlbaranType(this.albaran.id!, futureType).then(res => {
      this.albaran!.type = futureType;
      this.activateFormByAlbaranStatus();
    })
  }

  //Funcion cambio de type
  preventDropDownOpening(e?: Event | undefined) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  /**
   * Checks if the form or the products have changes and saves the albaran.
   * @returns A Promise that resolves to a boolean indicating whether the albaran was saved successfully.
   */
  saveAlbaran(): Promise<boolean> {

    if (!this.albaran) {
      //Can't save an undefined albaran
      return Promise.resolve(true);
    }

    if (!this.screenChanges) {
      //Nothing to save
      return Promise.resolve(true);
    }

    return new Promise((resolve, reject) => {
      forkJoin([
        this.saveAlbaranForm(),
        this.saveAlbaranProducts()
      ]).subscribe(([formResult, productsResult]) => {
        //Saved albaran form and products
        resolve(true);
      }, error => {
        //Something went wrong saving the albaran
        reject(false);
      });
    });
  }


  /** Save the form if it has changes.  */
  private saveAlbaranForm(): Promise<boolean> {

    if (!this.formChanges || !this.albaran) {
      //No form changes
      return Promise.resolve(true);
    }

    this.formChanges = false;
    this.albaran.comment = this.form.get('comment')?.value;
    this.albaran.date_user = this.form.get('date_user')?.value;
    this.albaran.block = this.form.get('block')?.value;

    this.apiS.saveAlbaranInfo(this.albaran.id!, this.albaran).then(res => {
      console.log("Saved albaran form!", this.albaran!)
    })

    return Promise.resolve(true);
  }

  /** Save the products on a delivery note if they have changes.  */
  private saveAlbaranProducts(): Promise<boolean> {
    if (!this.albaran || !this.albaran.hasChanges) {
      //No product changes
      return Promise.resolve(true);
    }

    return this.apiS.saveAlbaranProducts(this.albaran.id!, this.albaran).then(res => {
      res.forEach((line_id, index) => {
        let currentProducts = this.albaran?.products[index]
        if (currentProducts) {
          currentProducts.setLineId(line_id);
          currentProducts.line_hasChanges = false;
        }
      })
      console.log("Saved albaran products!", this.albaran?.products)
      return Promise.resolve(true);
    })
  }

  /** Close the albaran */
  changeAlbaranStatus(status: albaran_status) {
    if (!this.albaran) { return; }
    this.saveAlbaran().then(res => {
      if (res) {
        this.apiS.changeAlbaranStatus(this.albaran!.id!, status).then(res => {
          this.albaran!.status = status;
          this.activateFormByAlbaranStatus();
        })
      }
    })
  }

  deleteAlbaran() {
    if (!this.albaran) { return; };
    this.confirmD.show({
      title: "Eliminar albarán",
      body: "¿Está seguro de que quieres eliminar el albarán?",
      type: "danger"
    }).afterClosed().subscribe(res => {
      if (res) {
        this.albaran!.deleted = true;
        this.apiS.deleteAlbaran(this.albaran!.id!).then(res => {
          this.routerS.goTo(ViewPath.albaranes);
          this.snackS.show("Albarán eliminado");
        })
      }
    })
  }

  getClientDiscount(p: M_Product | M_CustomProduct): number | null {
    return this.albaran?.client?.getClientDiscount(p) || null;
  }

  invoiceAlbaran() {
    if (this.albaran && this.albaran.id) {
      this.apiS.invoiceAlbaran(this.albaran.id).then(res => {
        if (this.albaran) {
          this.albaran.status = albaran_status_invoiced;
          this.albaran.urlpdf = res;
        }
      })
    }
  }

  billPreview() {
    if (!this.albaran || !this.albaran.invoicePdf) { return; }
    this.previewS.showPreview(this.albaran.invoicePdf.type, this.albaran.invoicePdf.token, undefined);
  }

  getClient(): M_Contact | undefined {
    return this.albaran?.client;
  }

  get missingDir() {
    return !this.form.get('extra_addres')?.valid
  }
  get opened() { return this.albaran ? this.albaran.opened : false; }
  get closed() { return this.albaran ? this.albaran.closed : false; }
  get showSave() { return this.albaran?.status.closed; }
  get showClose() { return this.albaran?.status.closed; }
  get screenChanges() { return (this.albaran?.hasChanges || this.formChanges) && this.form.valid; }
  get closeDisabled() { return !this.albaran?.canClose || !this.form.valid; }
}
