<div *ngIf="product != undefined">
    <div class="w100 df jcfe">
<!--         
        <span class="c_b mr20" style="cursor: pointer; text-decoration: underline;" (click)="openDialog()">Estadisticas</span> -->

        <span class="c_b mr20" style="cursor: pointer; text-decoration: underline;" (click)="openDialog()">Ver Detalles</span>
    </div>
    <div *ngIf="groupedLocation">
        <div *ngFor="let loc of groupedLocation">
            <app-card-subtitle [first]="true">
                <mat-icon class="vam" [filled]="true" [style]="loc.storeLocations[0].bookmarkColorStyle">bookmark</mat-icon>
                {{loc.storeName}} 
            </app-card-subtitle>
            <table mat-table [dataSource]="loc.storeLocations" class="mb20 no-pointer">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Ubicación </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.name; else noRow">{{element.name}}</span>
                    </td>
                </ng-container>

                <!-- Physical Column -->
                <ng-container matColumnDef="physical">
                    <th mat-header-cell *matHeaderCellDef> Físico </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.physical; else noStock">{{element.physical > 0 ? element.physical : 0}}</span>
                    </td>
                </ng-container>

                <!-- Disp Column -->
                <ng-container matColumnDef="disp">
                    <th mat-header-cell *matHeaderCellDef> Disponible </th>
                    <td mat-cell *matCellDef="let element"> 
                        <span *ngIf="element.disp; else noStock">{{element.disp > 0 ? element.disp : 0}}</span>
                    </td>
                </ng-container>

                <!-- Engaged Column -->
                <ng-container matColumnDef="engaged">
                    <th mat-header-cell *matHeaderCellDef> Pendiente </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.engaged > 0 ? element.engaged : 0}}</span>
                    </td>
                </ng-container>

                <!-- Reserved Column -->
                <ng-container matColumnDef="reserved">
                    <th mat-header-cell *matHeaderCellDef> Reservado</th>
                    <td mat-cell *matCellDef="let element"> 
                        <span>{{element.reserved > 0 ? element.reserved : 0}}</span>
                    </td>
                </ng-container>

                <!-- Faults Column -->
                <ng-container matColumnDef="fault">
                    <th mat-header-cell *matHeaderCellDef> Faltante</th>
                    <td mat-cell *matCellDef="let element"> 
                        <span>{{element.fault > 0 ? element.fault  : 0}}</span>
                    </td>
                </ng-container>

                <!-- Last_in Column -->
                <ng-container matColumnDef="last_in">
                    <th mat-header-cell *matHeaderCellDef> Última entrada</th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.last_in; else noRow">{{element.last_in?.dayMonthYearFormat()}}</span>
                    </td>
                </ng-container>

                <!-- Last_out Column -->
                <ng-container matColumnDef="last_out">
                    <th mat-header-cell *matHeaderCellDef> Última salida</th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.last_out; else noRow">{{element.last_out?.dayMonthYearFormat()}}</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <div class="m10"></div>

            </table>
        </div>
    </div>

    <div>
        <app-card-subtitle>
            <mat-icon class="vam">warehouse</mat-icon>
            Total almacenes
        </app-card-subtitle>
        <div>
            <table mat-table [dataSource]="totalData" class="mb10 no-pointer">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Ubicación </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.name; else noRow">{{element.name}}</span>
                    </td>
                </ng-container>                
                
                <!-- Physical Column -->
                <ng-container matColumnDef="physical">
                    <th mat-header-cell *matHeaderCellDef> Físico </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.physical; else noStock">{{element.physical > 0 ? element.physical : 0}}</span>
                    </td>
                </ng-container>

                <!-- Disp Column -->
                <ng-container matColumnDef="disp">
                    <th mat-header-cell *matHeaderCellDef> Disponible </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.disp; else noStock">{{element.disp > 0 ? element.disp : 0}}</span>
                    </td>
                </ng-container>

                <!-- Engaged Column -->
                <ng-container matColumnDef="engaged">
                    <th mat-header-cell *matHeaderCellDef> Pendiente </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.engaged}}</span>
                    </td>
                </ng-container>

                <!-- Reserved Column -->
                <ng-container matColumnDef="reserved">
                    <th mat-header-cell *matHeaderCellDef> Reservado</th>
                    <td mat-cell *matCellDef="let element"> 
                        <span>{{element.reserved}}</span>
                    </td>
                </ng-container>

                <!-- Faults Column -->
                <ng-container matColumnDef="fault">
                    <th mat-header-cell *matHeaderCellDef> Faltante</th>
                    <td mat-cell *matCellDef="let element"> 
                        <span>{{element.fault}}</span>
                    </td>
                </ng-container>

                <!-- Last_in Column -->
                <ng-container matColumnDef="last_in">
                    <th mat-header-cell *matHeaderCellDef> Última entrada</th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.last_in; else noRow">{{element.last_in.dayMonthYearFormat()}}</span>
                    </td>
                </ng-container>

                <!-- Last_out Column -->
                <ng-container matColumnDef="last_out">
                    <th mat-header-cell *matHeaderCellDef> Última salida</th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.last_out; else noRow">{{element.last_out.dayMonthYearFormat()}}</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </div>
    </div>


</div>

<ng-template #noRow>
    <span class="c_t2">Sin datos</span>
</ng-template>

<ng-template #noStock>
    <span>0</span>
</ng-template>