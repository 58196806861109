import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-wifi-checker',
  templateUrl: './wifi-checker.component.html',
  styleUrls: ['./wifi-checker.component.css']
})
export class WifiCheckerComponent {

  onlineAgain = false;
  offline = false;

  @HostListener("window:online", ["$event"])
  onlineEvent(event: any) {
    this.processOnline(event);
  }
  processOnline(event: any) {
    if (event.type == "online") {
      this.setOnlineAgain();
    }
  }

  @HostListener("window:offline", ["$event"])
  offlineEvent(event: any) {
    this.processOfflineEvent(event);
  }
  processOfflineEvent(event: any) {
    if (event.type == "offline") {
      this.setOffline();
    }
  }

  constructor() {}

  setOnlineAgain() {
    this.onlineAgain = true;
    this.offline = false;
  }


  setOffline() {
    this.onlineAgain = false;
    this.offline = true;
  }

}
