<div class="footer-fields-container">

    <p *ngIf="pdf_type == 'B'" class="fsi fss">
        <mat-icon color="blue" class="vam">info</mat-icon>
        La legislación de tu comunidad autónoma podría requerir que completes estos campos de forma obligatoria.
    </p>

    <!-- FOOTER !-->
    <form [formGroup]="form" class="footer-form">
        <mat-form-field appearance="outline">
            <mat-label>Pie de página</mat-label>
            <textarea rows="5" #footer_ref matInput placeholder="Pie de página" formControlName="footer"></textarea>
        </mat-form-field>
    </form>

    <!-- EXTRA FIELDS -->
    <app-template-fields-form [isCompanyView]="true" (onChange)="pending = true" #templateFieldsForm></app-template-fields-form>

    <!-- PREVIEW BUTTON -->
    <button class="w100"  mat-stroked-button style="margin-top: 10px;"(click)="showPreview()">
            <mat-icon>find_in_page</mat-icon>
            Vista previa
    </button>
</div>