<app-page-structure #ps [pageTitle]="'Marcas'" [masterClass]="undefined" [autocompleteID]="'marca'"
    searchLabel="Buscar marca" listTitleText="Listado de marcas" [data]="[]"
    [displayedHeader]=" ['Id', 'Nombre','Marca registrada','type']"
    [displayedColumns]="['id','name','nothing','nothing2']" [quickFilter]="quickFilter"
    [preIcon]="[undefined,undefined,icon,typeVehicle]"
    [filter]="f" [filters]="filtersToShow" (onclickRow)="onDialogRef($event)" [createButton]="
        {
            text : 'Crear marca' , 
            icon : 'style',
            tooltip: 'No se puede crear marcas',
            disabled:true
        }"  [quickFilter]="quickFilter"
    (onClickCreateButton)="onDialogRef($event)">
</app-page-structure>