<div class="df aic">
  <app-menu-trigger *ngIf="!responsiveS.isPhone()" [mc]="this" (onclick)="onclicktrigger.emit()"
    [isOn]="'menu'"></app-menu-trigger>
  <img *ngIf="!responsiveS.isPhone()" [go]="v.dashboard" class="logoToolbar cp" [ngClass]="{'dn' : !opened}" src="./assets/img/logos/eina_logo.png">
</div>

<div #menu class="menu" (mouseleave)="onMouseLeaveComponent()" [ngClass]="!opened && isDesktop ? 'menu-closed' : ''">
  <div (mouseenter)="onMouseEnterComponent()">


    <app-menu-item [data]="panel" [mc]="this" *ngIf="!isWorkloadDashboard"></app-menu-item>
    <app-menu-item [data]="cargataller" [mc]="this" *ngIf="isWorkloadDashboard"></app-menu-item>
    
    <app-multiple-menu-item [id]="mms.MANAGEMENT" [data]="BManagement" [parent]="this"></app-multiple-menu-item>
    <app-multiple-menu-item [id]="mms.WORKSHOP" [data]="BWorkshop" [parent]="this"></app-multiple-menu-item>
    <app-multiple-menu-item [id]="mms.COMERCIAL" [data]="BComercial" [parent]="this"></app-multiple-menu-item>
    <app-multiple-menu-item [id]="mms.STOREHOUSE" [data]="BStorehouse" [parent]="this"></app-multiple-menu-item>
    <app-multiple-menu-item [id]="mms.FINANCE" [data]="BFinance" [parent]="this"></app-multiple-menu-item>
    
    <app-menu-item [data]="reports" [mc]="this" *ngIf="features.reports" ></app-menu-item>
    <app-menu-item [data]="settings" [mc]="this" [badge]="companyS.companyMissingInfo ? '!' : ''"></app-menu-item>

    <!-- <app-multiple-menu-item [id]="mms.CONFIGURATION" [data]="BConfiguration" [parent]="this"></app-multiple-menu-item> -->

    <!-- <app-multiple-menu-item [id]="mms.GENERAL" [data]="generalVision" [parent]="this"></app-multiple-menu-item>
    <app-multiple-menu-item [id]="mms.GESTION" [data]="gestion" [parent]="this"
      *ngIf="userS.userIsAdminOrAdviser"></app-multiple-menu-item>
    <app-multiple-menu-item [id]="mms.WORK" [data]="work" [parent]="this"></app-multiple-menu-item>
    <app-multiple-menu-item [id]="mms.STORE" [data]="storehouse" [parent]="this"></app-multiple-menu-item>
    <app-multiple-menu-item [id]="mms.COMERCIAL" [data]="comercial" [parent]="this"></app-multiple-menu-item>
    <app-multiple-menu-item [id]="mms.ADMIN" [data]="administration" [parent]="this"></app-multiple-menu-item> -->
  </div>
</div>