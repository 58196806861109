import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MenuComponent } from '../menu.component';
import { ViewPath } from 'src/app/app-routing.module';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { BurgerOption, BurgerService } from 'src/app/services/burger.service';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {
  @ViewChild('item') item!: ElementRef<HTMLDivElement>;
  @Input() data!: BurgerOption;
  @Input() mc!: MenuComponent;
  @Input() matmenu: boolean = false;
  @Input() class_: string | undefined;
  @Input() badge: string | undefined;
  @Input() righticon: { icon: string, color: string } | undefined = undefined;
  @Input() svgIcon = false;
  constructor(public bS: BurgerService, private responsive: ResponsiveService, private userS: UserService) { }
  ngOnInit(): void { }

  animation() {
    this.item.nativeElement.classList.toggle('selected')
  }

  get isShowing() {
    if (this.data) { return this.bS.havePermisisons(this.data); }
    return false;
  }

  dataCalssPhoneDesktop() {

    var isPhone = this.responsive.isPhone();
    if ((this.mc.isDesktop && isPhone) || (!this.mc.isDesktop && !isPhone)) {
      return "";
    }

    return this.data.class_;
  }
}
