<!-- MONTH GRID -->
<app-card [nopadding]="true" [contentLoaded]="grid.parent.loaded_" cdkDropListGroup (scroll)="grid.onScroll($event)"
    class="workload-main-card no-border">
    <div class="df fdr fww">
        <!-- WEEK ROW -->
        <div *ngFor="let week of grid.parent.currentMonth?.weeks; let first = first; let last = last"
            class="df fdr week" style="width: 100%">

            <!-- DAYS OF THE WEEK-->
            <div *ngFor="let day of week.days; let final = last;" style="min-width: 0; width: 100%;">
                <div class="monthDay" cdkDropList (cdkDropListDropped)="grid.drop($event, day.v)"
                    [cdkDropListData]="day.data" (mouseover)="cnc.style.visibility = 'visible'"
                    [cdkDropListSortingDisabled]="!grid.parent.workloadData.isOrView"
                    (mouseleave)="cnc.style.visibility = 'hidden'" [ngClass]="
                    {
                        'othermonth' : grid.parent.isOtherMonth(day.v), 
                        'nobottom' : last, 
                        'noright' : final,
                        'today' : day.v.isToday()
                    }">

                    <div class="temporal-line">
                        <div *ngIf="grid.getTemporalLineClass(day.v) as temporalClass">
                            <div [class]="temporalClass">
                                <div *ngIf="temporalClass == 'temporal-line-mixed'" class="df">
                                    <span class="temporal-line-schedule" style="width: 50%;">Realización</span>
                                    <span class="temporal-line-delivery" style="width: 50%;">Entrega</span>
                                </div>
                                <span *ngIf="temporalClass == 'temporal-line-schedule'" style="width: 100%;">
                                    Realización
                                </span>
                                <span *ngIf="temporalClass == 'temporal-line-delivery'" style="width: 100%;">
                                    Entrega
                                </span>
                            </div>
                        </div>
                    </div>


                    <div class="df fdc aic">
                        <div class="dayname" *ngIf="first">{{day.v.getDayName()}}</div>
                        <div class="w-d-container">
                            <div class="daynumber cp fs14 mb20"
                                [ngClass]="{'mini-companyDay' : grid.isCompanyHolidays(day.v)}"
                                (click)="grid.parent.goToSpecificWeek(day.v)">
                                {{day.v.getDate()}}
                                <ng-container *ngIf="grid.parent.workloadData.AppoPermisison">
                                    <div *ngIf="grid.parent.getItemOfDay(day.v, true).length as total"
                                        class="other-item-number month"
                                        [ngClass]="!grid.parent.workloadData.isOrView ? 'or' : 'appointment'"
                                        (click)="grid.parent.changeView(!grid.parent.workloadData.isOrView)">
                                        <mat-icon [filled]="true">{{!grid.parent.workloadData.isOrView ? "build" :
                                            "event"}}</mat-icon>
                                        +{{total}}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <p class="c_r tac fw500"
                        [ngClass]="{'fss text-overflow' : respoisiveS.w < 818, 'fs9' : respoisiveS.w < 500}"
                        *ngIf="grid.getOrUserAssignedHolidays(day.v)">
                        {{respoisiveS.w < 818 ? 'Vacaciones' : 'Vacaciones para el usuario' }} </p>

                            <!-- TODO : Prefiltered on appointments ? -->
                            <div *ngIf="grid.parent.loaded_ && filterOrByWorker(day.data) as prefiltered">
                                <div *ngFor="let item of prefiltered; let index = index">
                                    <app-grid-card class="no-blue" *ngIf="index <= 2"
                                        [workloadData]="grid.parent.workloadData"
                                        [searchFilter]="grid.parent.searchFilter" [period]="grid.parent.selectedPeriod"
                                        [item]="item" (onStartDragging)="grid.draggingCard = $event"
                                        [day]="day.v"></app-grid-card>
                                </div>
                                <div *ngIf="prefiltered.length > 3">
                                    <button style="padding: 0px; width: 100%; min-width: 0;" [matMenuTriggerFor]="menu"
                                        [matMenuTriggerData]="{items : prefiltered, day : day}" mat-button
                                        color="primary" class="c_p w100">{{prefiltered.length-3}} más</button>
                                </div>

                            </div>
                            <div #cnc style="visibility: hidden" class="addNewOr">
                                <div
                                    *ngIf="day.v.getDay() != 0 && grid.parent.workloadData.createView && day.v.todayOrBigger()">
                                    <button mat-button (click)="grid.goCreateOnDay(day.v)">
                                        {{grid.parent.workloadData.isOrView ? "Nueva OR" : "Nueva cita"}}
                                    </button>
                                </div>
                            </div>
                </div>
            </div>
            <mat-menu #menu="matMenu">
                <ng-template matMenuContent let-items="items" let-day="day">
                    <div class="moth-menu">
                        <div class="tac">
                            <p class="c_t2 nmb">{{day.v.getDayName()}}</p>
                            <div class="df jcc">
                                <p class="daynumber fsxl"
                                    [ngClass]="{'mini-companyDay' : grid.isCompanyHolidays(day.v)}"
                                    (click)="grid.parent.goToSpecificWeek(day.v)">
                                    {{day.v.getDate()}}</p>
                            </div>
                        </div>
                        <div *ngFor="let item of items; let index = index">
                            <app-grid-card class="no-blue" (onModifyAppointment)="grid.onModifyAppointment.emit($event)"
                                [workloadData]="grid.parent.workloadData" [filters]="grid.parent.currentFilters"
                                [searchFilter]="grid.parent.searchFilter" [draggable]="false"
                                [period]="grid.parent.selectedPeriod" [item]="item" [day]="day.v" [isOnMatMenu]="true">
                            </app-grid-card>
                        </div>
                    </div>
                </ng-template>
            </mat-menu>
        </div>
    </div>
</app-card>