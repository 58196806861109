<!-- Page 1 -->
<div [ngClass]="{'dn_i' : page != 1}">

    <p>
        <span class="c_t2">Se esperan las siguientes columnas: </span>
        <span class="c_t" *ngFor="let expCol of expectedColumns; let last = last">
            <span>
                {{expCol.name}}<span *ngIf="expCol.required" class="c_r">*</span>
            </span>
            <span *ngIf="!last">,</span>
        </span>
    </p>
    <p *ngFor="let error of errors" class="c_r">{{error}}</p>
    <div local-dev>
        <p class="tac c_r fw600">👨🏻‍💻 Las plantillas de test solo son visibles en local y dev 👨🏻‍💻</p>
        <p class="tac fw600">
            📗Plantilla de test (.xlsx):
            <a href="./assets/layouts/storehouse-entry/entrada_almacen_test.xlsx" download="entrada_almacen_test.xlsx">
                <span class="c_b tdu cp">Descargar</span>
            </a>
        </p>
        <p class="tac fw600">
            📄Plantilla de test (.txt):
            <a href="./assets/layouts/storehouse-entry/entrada_almacen_test.txt" download="entrada_almacen_test.txt">
                <span class="c_b tdu cp">Descargar</span>
            </a>
        </p>
        <p class="tac fw600" *ngIf="showColumnDistribution && isTxt">
            📄Has importado la plantilla de test (.txt) ?
            <span class="c_b tdu cp" (click)="devTest()">✨Establecer automáticamente <span class="fw600">"Desde -
                    hasta"</span></span>
        </p>
    </div>

    <lib-file-upload [maxImagesLength]="1" [multiple]="false"
        [dragPlaceHolder]="mobile ? 'Aquí puede subir su excel o .txt' : 'Arrastre aquí su excel o .txt'"
        [uploadtype]="'drag'" [acceptedTypes]="[ft.excel, ft.txt]" (stateChanged)="onExcelUpload($event)"
        (onRemoveImage)="resetComponent()">
    </lib-file-upload>

    <!-- Table -->
    <ng-container *ngIf="showColumnDistribution">
        <div>
            <table>
                <thead>
                    <tr>
                        <th class="excel-col">Columna {{isExcel ? 'excel' : '.txt'}}</th>
                        <th class="value-col">{{isExcel ? '¿Qué valor es?' : 'Desde - hasta'}}</th>
                        <th class="update-col">¿Actualizar?</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let excelColumn of documentColumn">
                        <td>
                            {{excelColumn.excelName}}
                        </td>
                        <td [ngClass]="{'dn_i' : isTxt}">
                            <mat-form-field appearance="outline" class="small-form-field">
                                <mat-select [(ngModel)]="excelColumn.matchWith">
                                    <mat-select-trigger>
                                        {{excelColumn.matchWith ? excelColumn.matchWith.name : '--'}}
                                    </mat-select-trigger>
                                    <mat-option [value]="undefined">--</mat-option>
                                    <mat-option [value]="column" [disabled]="someExcelColumHas(column)"
                                        *ngFor="let column of expectedColumns">{{column.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td *ngIf="!isExcel">
                            <p>
                                Del carácter
                                <mat-form-field class="small-form-field from-to" appearance="outline">
                                    <input [(ngModel)]="excelColumn.matchWith!.txtFromTo!.from" matInput type="number"
                                        [required]="excelColumn.matchWith!.required" [min]="1">
                                </mat-form-field>
                                al
                                <mat-form-field class="small-form-field from-to" appearance="outline">
                                    <input [(ngModel)]="excelColumn.matchWith!.txtFromTo!.to" matInput type="number"
                                        [required]="excelColumn.matchWith!.required" [min]="1">
                                </mat-form-field>
                            </p>
                        </td>
                        <td>
                            <mat-checkbox *ngIf="!excelColumn.matchWith" [disabled]="true"></mat-checkbox>
                            <mat-checkbox *ngIf="excelColumn.matchWith"
                                [(ngModel)]="excelColumn.matchWith.update"></mat-checkbox>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="df jcfe">
            <button [disabled]="!areColumnsOk" (click)="next()" *ngIf="showColumnDistribution" mat-button
                color="primary">Siguiente
                <mat-icon iconPositionEnd>navigate_next</mat-icon> </button>
        </div>
    </ng-container>
</div>


<!-- Page 2 (ng-content) -->
<div [ngClass]="{'dn_i' : page != 2}">
    <ng-content></ng-content>
    <button (click)="page = 1;" mat-button color="primary">
        <mat-icon>navigate_before</mat-icon>
        Atrás
    </button>
</div>