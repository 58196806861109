<div class="df aic gap5 card-wrapper-fit" 
    [ngClass]="clickable ? 'rectangle-clickable' : 'rectangle-no-clickable'"
    mat-ripple [matRippleDisabled]="!clickable" [matTooltip]="tooltip" [matTooltipPosition]="'above'"
    [go]="!clickable ? undefined : !vehicle.isVnVo ? v.vehicleDetails : v.vnvoDetails" [param]="vehicle.vehicle_id"
    [newTab]="true">
    <mat-icon class="vam">{{vehicle.vehicle_id ? vehicle.icon : "car_crash"}}</mat-icon>

    <ng-container *ngIf="!onlyIconOn || onlyIconOn < responsiveS.w">
        <div class="user-name">
            <span [ngClass]="{'c_t2' : !vehicle.license}">{{ vehicle.license || 'Sin matrícula' }}</span>
            <ng-container *ngIf="vehicle.getName() as name">
                <span class="ml5">|</span>
                {{name}}
            </ng-container>
        </div>
    </ng-container>

</div>