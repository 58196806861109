import { getArrayOf } from "src/app/utils/FunctionUtils";
import { M_Action } from "./M_Action";
import { M_User } from "./M_User";

export enum ActivityEnum {
    OR,
    BUDGET,
    INVOICE,
    CLIENT,
    VEHICLE,
    CLOUDTICKET,
    DELETE,
    ARTICLE
}

export enum ActivityColorEnum {
    PRIMARY = 0,
    BLUE = 1,
    RED = 3,
    ORANGE = 4
}

export class M_LastActivity {
    object_id: number | undefined;
    activity_type: ActivityEnum = ActivityEnum.OR;
    activity_color: ActivityColorEnum = ActivityColorEnum.PRIMARY;
    message: string;
    subtitle: string;
    related_object: any;
    by_user?: M_User;

    constructor(d: any) {
        this.setUpObectById(d);
        this.message = d.message;
        this.subtitle = d.subtitle;
        this.related_object = d.related_object;
        this.by_user = d.by_user ? new M_User(d.by_user) : undefined;
    }

    setUpObectById(d: any) {
        if (d.action_id) { this.object_id = d.action_id; this.activity_type = ActivityEnum.OR }
        if (d.budget_id) { this.object_id = d.budget_id; this.activity_type = ActivityEnum.BUDGET }
        else if (d.client_id) { this.object_id = d.client_id; this.activity_type = ActivityEnum.CLIENT }
        else if (d.id) { this.object_id = d.id; this.activity_type = ActivityEnum.DELETE }
        else if (d.product_id) { this.object_id = d.product_id; this.activity_type = ActivityEnum.ARTICLE }
        this.setUpActivityColor();
    }

    setUpActivityColor() {
        switch (this.activity_type) {
            case ActivityEnum.OR:
            case ActivityEnum.INVOICE:
            case ActivityEnum.BUDGET:
                this.activity_color = ActivityColorEnum.PRIMARY;
                break
            case ActivityEnum.CLIENT:
            case ActivityEnum.VEHICLE:
                this.activity_color = ActivityColorEnum.BLUE;
                break
            case ActivityEnum.CLOUDTICKET:
                this.activity_color = ActivityColorEnum.RED;    
                break
            case ActivityEnum.DELETE:
                this.activity_color = ActivityColorEnum.RED;
                break
            case ActivityEnum.ARTICLE:
                this.activity_color = ActivityColorEnum.ORANGE;
                break
        }
    }

    get action() {
        return this.object_id != undefined;
    }
}

export class M_New {
    constructor(public id : number, public title: string, public description: string, public date: Date) { }
}
export class M_Dashboard {

    /** What's new */
    whats_new: M_New[] = [];

    /** Top cards */
    open_or: number; //Total number of open OR
    bill_pending: number; //Total number of closed OR
    total_ORToInvoice : number; // Total € of OR to invoice
    total_ToCollect : number; // Total € of OR to collect

    /** Workload percentage chart */
    workshop_workload: number;

    /** Distribution chart */
    normal: number;
    interna: number;
    garantia: number;
    peritaje: number;
    companyPlacesDis : number;

    /** OR prevision */
    previsionDays: { day: Date, ors: number }[] = [];

    /** Latest Activity */
    latestActivity: M_LastActivity[] = [];

    /** OR table */
    priorityOr: M_Action[] = [];

    constructor(d: any) {
        this.open_or = d.open_or;
        this.bill_pending = d.bill_pending;
        this.workshop_workload = d.workshop_workload;
        this.normal = d.or_distribution.normal;
        this.interna = d.or_distribution.interna;
        this.garantia = d.or_distribution.garantia;
        this.peritaje = d.or_distribution.peritaje;
        this.companyPlacesDis = d.companyPlacesDis;
        this.total_ORToInvoice = d.total_ORToInvoice;
        this.total_ToCollect = d.total_ToCollect

        if (d.or_priority) {
            this.priorityOr = getArrayOf(M_Action, d.or_priority)
        }

        if (d.latest_activity) {
            this.latestActivity = getArrayOf(M_LastActivity, d.latest_activity)
        }

        this.generatePrevisionChart(d);

        this.generateWhatsNew(d);


    }

    generateWhatsNew(d: any) {
        var news = d.whats_new
        if (news && Array.isArray(news)) {
            news.forEach(n => {
                this.whats_new.push(new M_New(n.id, n.title, n.description, new Date(n.created_at)));
            })
        }
    }

    generatePrevisionChart(d: any) {
        var prevision = d.prevision;
        if (prevision && Array.isArray(prevision)) {
            prevision.forEach((p, index) => {
                if (index <= 4) {
                    this.previsionDays.push({
                        day: new Date(p.day),
                        ors: p.ors,
                    })
                }
            })
        }
    }

    hasOr() {
        return this.priorityOr.length != 0;
    }

    getDistributedChart() {
        return this.hasdistributionData ? [this.normal, this.interna, this.peritaje, this.garantia] : [];
    }

    private get hasdistributionData() {
        return this.normal != 0 || this.interna != 0 || this.peritaje != 0 || this.garantia != 0;

    }
}