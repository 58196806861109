import './utils/DateUtils';
import './utils/NumberUtils';
import './utils/Arrayutils';
import './utils/StringUtils';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, InjectionToken, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AppRoutingModule, ViewPath } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FAB_DEFAULT_OPTIONS, MAT_FAB_DEFAULT_OPTIONS_FACTORY, MatButtonModule } from '@angular/material/button';
import { EntryPointComponent } from './views/entry-point/entry-point.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { BigMamaComponent } from './components/global-searcher/big-mama.component';
import { MenuComponent } from './components/menu/menu.component';
import { AppContentsComponent } from './components/app-contents/app-contents.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MenuItemComponent } from './components/menu/menu-item/menu-item.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ClientsComponent } from './views/clients/clients.component';
import { VehiclesComponent } from './views/vehicles/vehicles.component';
import { ProductsComponent } from './views/products/products.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { PageNotFoundEinaComponent } from './views/page-not-found/page-not-found.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ClientDetailsComponent } from './views/client-details/client-details.component';
import { AddRemoveComponent } from './views/products/add-remove/add-remove.component';
import { ProductDetailsComponent } from './views/product-details/product-details.component';
import { VehicleDetailsComponent } from './views/vehicle-details/vehicle-details.component';
import { LinkVehicleComponent } from './views/link-vehicle/link-vehicle.component';
import { VehicleContainerComponent } from './views/link-vehicle/vehicle-container/vehicle-container.component';
import { CreateVehicleComponent } from './views/create-vehicle/create-vehicle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateClientComponent } from './views/create-client/create-client.component';
import { CreateProductComponent } from './views/create-product/create-product.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DatePipe, registerLocaleData } from '@angular/common';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import es from '@angular/common/locales/es';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ClientformComponent } from './components/clientform/clientform.component';
import { VehicleformComponent } from './components/vehicleform/vehicleform.component';
import { UserformComponent } from './components/userform/userform.component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { CardRowContentComponent } from './components/card-row-content/card-row-content.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CreateOr } from './views/or-create-edit/create-or/create-or.component';
import { DragComponent } from './components/drag/drag.component';
import { DragGroupComponent } from './components/drag/drag-group/drag-group.component';
import { MatSelectModule } from '@angular/material/select';
import { ImageToggleComponent } from './components/image-toggle/image-toggle.component';
import { ImgToggleItemComponent } from './components/image-toggle/img-toggle-item/img-toggle-item.component';
import { ItemRowComponent } from './components/vehicle-row/item-row.component';
import { CreateRecoverPasswordComponent } from './views/create-recover-password/create-recover-password.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CifComponent } from './components/cif/cif.component';
import { AlreadyExistsService } from './services/already-exists.service';
import { ProductmoveComponent } from './views/product-details/productmove/productmove.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TermsAndConditionsComponent } from './views/register/terms-and-conditions/terms-and-conditions.component';
import { OrComponent } from './views/or/or.component';
import { CreateEditBudgetComponent } from './views/create-budget/create-edit-budget.component';
import { EditOrComponent } from './views/or-create-edit/edit-or/edit-or.component';
import { SignatureComponent } from './components/signature/signature.component';
import { ActionCreateEditParentComponent } from './views/or-create-edit/or-create-edit-parent/or-create-edit-parent.component';
import { InvoiceDialogComponent } from './views/or-create-edit/edit-or/invoice-dialog/invoice-dialog.component';
import { GroupCardInvoiceGroupComponent } from './views/or-create-edit/edit-or/invoice-dialog/group-card-invoice-group/group-card-invoice-group.component';
import { MatChipsModule } from '@angular/material/chips';
import { ModifyObservationsComponent } from './views/client-details/modify-observations/modify-observations.component';
import { ClientConsentsComponent } from './components/client-consents/client-consents.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatBadgeModule } from '@angular/material/badge';
import { OrFormComponent } from './components/or-form/or-form.component';
import { MatTreeModule } from '@angular/material/tree';
import { BeforeDeleteGroupComponent } from './components/drag/before-delete-group/before-delete-group.component';
import { DataUploadComponent } from './components/data-upload/data-upload.component';
import { DataUploadItemComponent } from './components/data-upload-item/data-upload-item.component';
import { DataUploadDialogComponent } from './components/data-upload-item/data-upload-dialog/data-upload-dialog.component';
import { ActionHistoryComponent } from './components/or-history/action-history.component';
import { ConfirmExitDialogComponent } from './components/confirm-exit-dialog/confirm-exit-dialog.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { DragTutorialComponent } from './views/carga-taller/drag-tutorial/drag-tutorial.component';
import { SubscriptionDaysLeftComponent } from './components/subscription-days-left/subscription-days-left.component';
import { TextWithLinkComponent } from './components/text-with-link/text-with-link.component';
import { ContactUsTextComponent } from './components/contact-us-text/contact-us-text.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { SubscribedUserDirective } from './directives/subscribed-user.directive';
import { FooterComponent } from './components/footer/footer.component';
import { IssuesViewComponent } from './views/issues-view/issues-view.component';
import { TryProDialogComponent } from './components/try-pro-dialog/try-pro-dialog.component';
import { CargaTallerParentComponent } from './views/carga-taller-parent/carga-taller-parent.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AbonoInvoiceComponent } from './views/abono-invoice/abono-invoice.component';
import { SelectAllOfTypeComponent } from './views/or-create-edit/edit-or/invoice-dialog/select-all-of-type/select-all-of-type.component';
import { VehicleRowContainerComponent } from './components/vehicle-row-container/vehicle-row-container.component';
import { MatRadioModule } from '@angular/material/radio';
import { AddTimeComponent } from './components/add-time/add-time.component';
import { OrTypeSelectorComponent } from './components/or-type-selector/or-type-selector.component';
import { LastActivityComponent } from './views/dashboard/last-activity/last-activity.component';
import { WhatsNewComponent } from './views/dashboard/whats-new/whats-new.component';
import { DashboardOrTableComponent } from './views/dashboard/dashboard-or-table/dashboard-or-table.component';
import { DialogWhatsNewsComponent } from './views/dashboard/whats-new/dialog-whats-news/dialog-whats-news.component';
import { DeactivateGuard } from './guards/deactivate-guard-';
import { CancelationAndReturnPolicyComponent } from './components/cancelation-and-return-policy/cancelation-and-return-policy.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TemplateFieldsFormComponent } from './components/template-fields-form/template-fields-form.component';
import { FloatDirective } from './directives/float.directive';
import { IntegerDirective } from './directives/integer.directive';
import { TemplateFieldsDialog } from './components/template-fields-dialog/template-fields-dialog.component';
import { PreviewDialog } from './components/preview-dialog/preview-dialog-component';
import { PdfComponent } from './components/pdf/pdf.component';
import { MatInputModule } from '@angular/material/input';
import { DialogAdvancePayComponent } from './views/client-details/dialog-advance-pay/dialog-advance-pay.component';
import { AdvancesArrayComponent } from './components/advances-array/advances-array.component';
import { ShowAdvanceClientComponent } from './components/show-advance-client/show-advance-client.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { DialogHistoryAdvancesComponent } from './components/advances-array/dialog-history-advances/dialog-history-advances.component';
import { StandardDialogComponent } from './components/standard-dialog/standard-dialog.component';
import { DecimalInput } from './directives/decimal-input';
import { ChangeDeliveryOrDialogComponent } from './views/carga-taller-parent/change-delivery-or-dialog/change-delivery-or-dialog.component';
import { TrasnferActionComponent } from './components/trasnfer-action/trasnfer-action.component';
import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import { ProTipsButtonComponent } from './components/pro-tips/pro-tips-button/pro-tips-button.component';
import { ProTipsDialogComponent } from './components/pro-tips/pro-tips-dialog/pro-tips-dialog.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { InvoiceMovementsComponent } from './components/invoice-movements/invoice-movements.component';
import { InvoicePayementFormComponent } from './components/invoice-payement-form/invoice-payement-form.component';
import { InvoicedSiniestroDialogComponent } from './views/or-create-edit/edit-or/invoice-dialog/invoiced-siniestro-dialog/invoiced-siniestro-dialog.component';
import { FrontendDocumentation } from './views/frontend-documentation/frontend-documentation.component';
import { DeleteCompanyComponent } from './views/profile/delete-company/delete-company.component';
import { ActionTotalsComponent } from './components/action-totals/action-totals.component';
import { RaGroupsComponent } from './views/or-create-edit/create-or/ra-groups/ra-groups.component';
import { InvoiceTotalsComponent } from './components/invoice-totals/invoice-totals.component';
import { MissingCompanyInfoComponent } from './components/missing-company-info/missing-company-info.component';
import { ModelNotFoundComponent } from './components/model-not-found/model-not-found.component';
import { DocumentationPdfMenuComponent } from './components/documentation-pdf-menu/documentation-pdf-menu.component';
import { MultipleMenuItemComponent } from './components/menu/multiple-menu-item/multiple-menu-item.component';
import { GarageComponent } from './views/garage/garage.component';
import { AppointmentsComponent } from './views/appointments/appointments.component';
import { CreateVNVOcomponent } from './views/create-vn-vo/create-vn-vo.component';
import { SellVehicleComponent } from './views/sell-vehicle/sell-vehicle.component';
import { UserDetailsComponent } from './views/user-details/user-details.component';
import { CreateEditUserComponent } from './components/create-edit-user/create-edit-user.component';
import { AppointmentFormComponent } from './views/appointment-form/appointment-form.component';
import { WorkshopAndAppointmentsComponent } from './views/settings/company-tab/workshop-and-appointments/workshop-and-appointments.component';
import { QrDialogComponent } from './components/qr-dialog/qr-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { AlbaranesComponent } from './views/albaranes/albaranes.component';
import { HourPickerComponent } from './components/hour-picker/hour-picker.component';
import { HolidaysScheduleCalendarComponent } from './components/holidays-schedule-calendar/holidays-schedule-calendar.component';
import { ModifyAppointmentHourComponent } from './components/modify-appointment-hour/modify-appointment-hour.component';
import { AdvancesClientDetailsDialogComponent } from './views/client-details/advances-client-details-dialog/advances-client-details-dialog.component';
import { LastInterventionOrComponent } from './components/last-intervention-or/last-intervention-or.component';
import { ClientDetailsVehicleTableComponent } from './views/client-details/client-details-vehicle-table/client-details-vehicle-table.component';
import { PendingPaymentDialogComponent } from './views/client-details/pending-payment-dialog/pending-payment-dialog.component';
import { OrdersComponent } from './views/orders/orders.component';
import { ProductProvidersRowComponent } from './views/product-details/product-providers-row/product-providers-row.component';
import { DialogSetComponent } from './views/appointments/dialog-set/dialog-set.component';
import { HourAppointmentTndrComponent } from './views/appointments/dialog-set/hour-appointment-tndr/hour-appointment-tndr.component';
import { CreateAppointmentClientSideComponent } from './views/create-appointment-client-side/create-appointment-client-side.component';
import { WifiCheckerComponent } from './components/wifi-checker/wifi-checker.component';
import { NoteComponent } from './components/note/note.component';
import { OrNotesAndImagesComponent } from './views/or-create-edit/or-notes-and-images/or-notes-and-images.component';
import { InvoiceDayCheckerDirective } from './directives/invoice-day-checker.directive';
import { AppointmentToOrComponent } from './views/appointment-form/appointment-to-or/appointment-to-or.component';
import { NotRolesDirective, RolesAdviserDirective, RolesComercialDirective, RolesDirective, RolesMechanincDirective, RolesRecambistaDirective, RolesSuperUserDirective } from './directives/roles.directive';
import { RolesExplanationComponent } from './components/userform/roles-explanation/roles-explanation.component';
import { AddTimeOrDialogComponent } from './views/or-create-edit/edit-or/add-time-or-dialog/add-time-or-dialog.component';
import { HistoryTimeOrDialogComponent } from './views/or-create-edit/edit-or/history-time-or-dialog/history-time-or-dialog.component';
import { ClientSideDetailsAppointmentComponent } from './components/client-side-details-appointment/client-side-details-appointment.component';
import { ShowAppointmentsCompanyComponent } from './views/show-appointments-company/show-appointments-company.component';
import { CreateOrderComponent } from './views/create-order/create-order.component';
import { StorehousesComponent } from './views/storehouses/storehouses.component';
import { ModuleDirective } from './directives/module.directive';
import { SotreHouseFormComponent } from './components/sotre-house-form/sotre-house-form.component';
import { SotrehouseBookmarkComponent } from './components/sotrehouse-bookmark/sotrehouse-bookmark.component';
import { StoreHouseCreateDialogComponent } from './components/store-house-create-dialog/store-house-create-dialog.component';
import { StorehouseLordIconComponent } from './components/storehouse-lord-icon/storehouse-lord-icon.component';
import { FaultsDialogComponent } from './components/faults-dialog/faults-dialog.component';
import { ProvidersDetailsProductTableComponent } from './components/providers/providers-details-product-table/providers-details-product-table.component';
import { ProviderSelectorComponent } from './components/provider-selector/provider-selector.component';
import { StorageSelectorComponent } from './components/storage-selector/storage-selector.component';
import { OrderBreakdownSectionComponent } from './views/create-order/order-breakdown-section/order-breakdown-section.component';
import { PluralPipe } from './pipes/plural.pipe';
import { ProductStorehouseControlstockComponent } from './views/product-details/product-storehouse-controlstock/product-storehouse-controlstock.component';
import { LandingComponent } from './views/landing/landing.component';
import { DemoVideoComponent } from './views/landing/demo-video/demo-video.component';
import { IncomingOrdersComponent } from './views/product-details/incoming-orders/incoming-orders.component';
import { FaultComponent } from './components/fault/fault.component';
import { ProduproductmoveRecambiosModuleComponent } from './views/product-details/produproductmove-recambios-module/produproductmove-recambios-module.component';
import { AddProviderToProductOrFaultComponent } from './views/create-order/add-provider-to-product-or-fault/add-provider-to-product-or-fault.component';
import { MovesDetailDialogComponent } from './views/product-details/produproductmove-recambios-module/moves-detail-dialog/moves-detail-dialog.component';
import { PluralDirective } from './directives/plural.directive';
import { ManualReservationComponent } from './components/manual-reservation/manual-reservation.component';
import { ManualStockMovesComponent } from './views/product-details/manual-stock-moves/manual-stock-moves.component';
import { CreateEditAlbaranComponent } from './views/create-edit-albaran/create-edit-albaran.component';
import { SelectLocationComponent } from './components/select-location/select-location.component';
import { errorCodes } from './constants/ErrorCodes';
import { EditOrderComponent } from './views/edit-order/edit-order.component';
import { InfoReserveOrdersComponent } from './views/budget/info-reserve-orders/info-reserve-orders.component';
import { InvoicesComponent } from './views/invoices/invoices.component';
import { DialogCheckoutControlComponent } from './views/invoices/dialog-checkout-control/dialog-checkout-control.component';
import { PayAllInvoiceDialogComponent } from './views/invoices/pay-check-control/pay-all-invoice-dialog.component';
import { DialogCashTotalReturnComponent } from './views/invoices/dialog-cash-total-return/dialog-cash-total-return.component';
import { CreateInvoiceDialogComponent } from './views/create-invoice/create-invoice-dialog/create-invoice-dialog.component';
import { CreateInvoiceComponent } from './views/create-invoice/create-invoice.component';
import { IvachangerComponent } from './views/create-invoice/ivachanger/ivachanger.component';
import { CtrlSpaceShortcutComponent } from './components/ctrl-space-shortcut/ctrl-space-shortcut.component';
import { CreateCustomProductComponent } from './components/product-line-table/create-custom-product/create-custom-product.component';
import { ProductLineTableComponent } from './components/product-line-table/product-line-table/product-line-table.component';
import { ProductLineTitleComponent } from './components/product-line-table/product-line-title/product-line-title.component';
import { ProductSearcherComponent } from './components/product-line-table/product-line-table/product-searcher/product-searcher.component';
import { DevDirective, DevPreDirective, LocalDevDirective, LocalDevPreDirective, LocalDirective, PreDirective, ProdDirective } from './directives/environmnet.directive';
import { DialogReserveComponent } from './views/products/dialog-reserve/dialog-reserve.component';
import { ReservesProductTableComponent } from './components/reserves-product-table/reserves-product-table.component';
import { ManualFaultComponent } from './components/manual-fault/manual-fault.component';
import { DropdownDirective } from './directives/DropDown/dropdown.directive';
import { StoreHouseEntryAddProductComponent } from './views/create-storehouse-entry/storehouse-entry-add-product/storehouse-entry-add-product.component';
import { CreateStorehouseEntryComponent } from './views/create-storehouse-entry/create-storehouse-entry.component';
import { StorehouseEntrySectionComponent } from './views/create-storehouse-entry/storehouse-entry-section/storehouse-entry-section.component';
import { RemoveReservationComponent } from './components/remove-reservation/remove-reservation.component';
import { StorehouseEntryComponent } from './views/storehouse-entry/storehouse-entry.component';
import { FaultProductTableComponent } from './components/fault-product-table/fault-product-table.component';
import { ExcelColumnMatcherComponent } from './components/excel-column-matcher/excel-column-matcher.component';
import { ContactSendingAddressSelectorComponent } from './components/contact-sending-address-selector/contact-sending-address-selector.component';
import { ContanctSendingAdressSelectorDialogComponent } from './components/contact-sending-address-selector/contanct-sending-adress-selector-dialog/contanct-sending-adress-selector-dialog.component';
import { CreateEditSendingAdressComponent } from './components/contact-sending-address-selector/create-edit-sending-adress/create-edit-sending-adress.component';
import { ProdMoveNumberDirective } from './directives/prod-move-number.directive';
import { ProductStorehouseControlstockDetailsComponent } from './views/product-details/product-storehouse-controlstock/product-storehouse-controlstock-details/product-storehouse-controlstock-details.component';
import { ProductLineActionsComponent } from './components/product-line-table/product-line-table/product-line-actions/product-line-actions.component';
import { DiscountMonthlyComponent } from './components/toolbar/discount-monthly/discount-monthly.component';
import { SendMailCodePromotionalComponent } from './components/toolbar/send-mail-code-promotional/send-mail-code-promotional.component';
import { DialogConfirmPlateComponent } from './components/vehicleform/dialog-confirm-plate/dialog-confirm-plate.component';
import { AddCommentComponent } from './components/add-comment/add-comment.component';
import { ProviderSearcherComponent } from './views/create-product/provider-searcher/provider-searcher.component';
import { ChassisInputComponent } from './components/chassis-input/chassis-input.component';
import { ConceptsComponent } from './components/concepts/concepts.component';
import { BrandModelInputComponent } from './components/brand-model-input/brand-model-input.component';
import { ConceptSearcherComponent } from './components/concept-searcher/concept-searcher.component';
import { BrandModelEditComponent } from './components/brand-model-edit/brand-model-edit.component';
import { VnvoDetailsComponent } from './views/vnvo-details/vnvo-details.component';
import { CreateComercialBudgetComponent } from './views/create-comercial-budget/create-comercial-budget.component';
import { ConceptsLineTableComponent } from './components/concepts-line-table/concepts-line-table.component';
import { WorkshopBudgetComponent } from './views/budget/workshop-budget/workshop-budget.component';
import { ComercialBudgetComponent } from './views/budget/comercial-budget/comercial-budget.component';
import { GoDirective } from './directives/go.directive';
import { AsyncValidatorSpinnerComponent } from './components/async-validator-spinner/async-validator-spinner.component';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { ExampleFormComponent } from './views/frontend-documentation/example-form/example-form.component';
import { SellVehicleClientComponent } from './components/sell-vehicle-client/sell-vehicle-client.component';
import { PdfDocumentsUploadComponent } from './components/pdf-documents-upload/pdf-documents-upload.component';
import { AssignClientVnVoComponent } from './components/assign-client-vn-vo/assign-client-vn-vo.component';
import { CreateConceptComponent } from './components/create-concept/create-concept.component';
import { ConfirmDialogService } from './services/confirm-dialog.service';
import { LoadingPanelService } from './services/LoadingPanel/loading-panel.service';
import { AlbaranStatusLabelComponent } from './components/albaran-status-label/albaran-status-label.component';
import { AppointmentStatusLabelComponent } from './components/appointment-status-label/appointment-status-label.component';
import { AppointmentWeekCardComponent } from './components/appointment-week-card/appointment-week-card.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { BaseTitleComponent } from './components/card/base-title/base-title-component';
import { ContactButtonsComponent } from './components/contact-buttons/contact-buttons.component';
import { CardComponent } from './components/card/card.component';
import { CoreCargaTallerComponent } from './components/carga-taller/core-carga-taller.component';
import { CircularLetterComponent } from './components/circular-letter/circular-letter.component';
import { ClassSearcherComponent } from './components/class-searcher/class-searcher.component';
import { CloseDialogComponent } from './components/close-dialog/close-dialog.component';
import { CloudTicketCommentComponent } from './components/cloud-ticket/cloud-ticket-comment/cloud-ticket-comment.component';
import { CloudTicketComponent } from './components/cloud-ticket/cloud-ticket.component';
import { ComentariosComponent } from './components/comentarios/comentarios.component';
import { CreateTicketDialog } from './components/create-ticket-dialog/create-ticket-dialog';
import { DeveloperHelperComponent } from './components/developer-helper/developer-helper.component';
import { DobiComponent } from './components/dobi/dobi.component';
import { EmailButtonComponent } from './components/email-button/email-button.component';
import { EmailSendComponent } from './components/email-send/email-send.component';
import { EndpointLineComponent } from './components/endpoint-viewer/endpoint-line/endpoint-line.component';
import { EndpointViewerComponent } from './components/endpoint-viewer/endpoint-viewer.component';
import { ErrorViewerComponent } from './components/error-viewer/error-viewer.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { CropImageComponent } from './components/file-upload/crop-image/crop-image.component';
import { FileUploadComponent, UploadedFileComponent } from './components/file-upload/file-upload.component';
import { ButtonToggleFilterComponent } from './components/filters/button-toggle-filter/button-toggle-filter.component';
import { CheckBoxFilterComponent } from './components/filters/checkbox-filter/checkbox-filter.component';
import { ClassSearcherFilterComponent } from './components/filters/class-searcher-filter/class-searcher-filter.component';
import { DateFilterComponent, DayFilterComponent } from './components/filters/day-and-date-filters/day-date-filter.component';
import { EnumFilterComponent } from './components/filters/enum-filter/enum-filter.component';
import { FilterDialogComponent } from './components/filters/filter-dialog/filter-dialog.component';
import { FiltersComponent } from './components/filters/filters.component';
import { SliderFilterComponent } from './components/filters/slider-filter/slider-filter.component';
import { TagfilterComponent } from './components/filters/tagfilter/tagfilter.component';
import { TextFilterComponent } from './components/filters/text-filter/text-filter.component';
import { UserFilterComponent } from './components/filters/user-filter/user-filter.component';
import { FirmaComponent } from './components/firma/firma.component';
import { FourdigitsComponent } from './components/fourdigits/fourdigits.component';
import { GoBackComponent } from './components/go-back/go-back.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { BaseInput } from './components/inputs/base-input';
import { EmailInputComponent } from './components/inputs/email-input.component';
import { PasswordInputComponent } from './components/inputs/password-input';
import { PhoneInputComponent } from './components/inputs/phone-input.component';
import { PlateInputComponent } from './components/inputs/plate-input.component';
import { RepeatPasswordInputComponent } from './components/inputs/repeat-password-input.component';
import { InvoiceStatusComponent } from './components/invoice-status/invoice-status.component';
import { LayoutSendEmailComponent } from './components/layout-send-email/layout-send-email.component';
import { MultiplePhoneImageComponent } from './components/multiple-phone-image/multiple-phone-image.component';
import { SingleImgComponent } from './components/multiple-phone-image/single-img/single-img.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { OnboardingComponent, SpeechBubbleComponent } from './components/onboarding/onboarding.component';
import { DobiDialogComponent } from './components/dobi/dobi-dialog/dobi-dialog.component';
import { OrChangeWorkerComponent } from './components/or-change-worker/or-change-worker.component';
import { GroupStatusTypeTextComponent } from './components/or-labels/group-status-type-text/group-status-type-text.component';
import { OrStatusLabelComponent } from './components/or-labels/or-status-label/or-status-label.component';
import { OrTypeLabelComponent } from './components/or-labels/or-type-label/or-type-label.component';
import { OrWeekCardComponent } from './components/or-week-card/or-week-card.component';
import { OrderStatusLabelComponent } from './components/order-status-label/order-status-label.component';
import { PageStructureComponent } from './components/page-structure/page-structure.component';
import { ParameterStateComponent } from './components/parameter-state/parameter-state.component';
import { PhoneButtonComponent } from './components/phone-button/phone-button.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { SpaceRemoverComponent } from './components/space-remover/space-remover.component';
import { TerminosCondicionesComponent } from './components/terminos-condiciones/terminos-condiciones.component';
import { VnVoLabelComponent } from './components/vn-vo-label/vn-vo-label.component';
import { WhatsappComponent } from './components/whatsapp/whatsapp.component';
import { CustomErrorHandler } from './custom-classes/CustomErrorHandler';
import { CustomPaginator } from './custom-classes/CustomPaginator';
import { MatDialogSubtitleDirective } from './directives/mat-dialog-subtitle.directive';
import { NumberManager, MoneyDirective, DecimalsDirective } from './directives/numbers.directive';
import { PreventDirective } from './directives/prevent.directive';
import { SmartIconDirective } from './directives/smart-icon.directive';
import { ViewTitleLordIconComponent } from './components/view-title/view-title-lord-icon.component';
import { ViewTitleComponent } from './components/view-title/view-title.component';
import { ComentariosMotoComponent } from './components/comentarios/comentarios-moto/comentarios-moto.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { DniInputComponent } from './components/inputs/dni-input.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ProjectConfiguration } from './utils/ProjectConfiguration';
import { endpoints } from './constants/Endpoints';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { httpListenerService } from './services/http-listener.service';
import { defineElement } from '@lordicon/element';
import lottie from 'lottie-web';
import { MenuTriggerComponent } from './components/menu/menu-trigger/menu-trigger.component';
import { FreeMonthComponent } from './components/free-month/free-month.component';
import { PurchasesComponent } from './views/purchases/purchases.component';
import { BalanceComponent } from './views/balance/balance.component';
import { IconFilledDirective } from './directives/icon-filled.directive';
import { PageStructureAddNewComponent } from './components/page-structure/page-structure-add-new/page-structure-add-new.component';
import { SettingsComponent } from './views/settings/settings.component';
import { CompanyTabComponent } from './views/settings/company-tab/company-tab.component';
import { SmtpComponent } from './views/settings/company-tab/smtp/smtp.component';
import { HolidaysCompanyComponent } from './views/settings/holidays-company/holidays-company.component';
import { DocumentationCompraVentaComponent } from './components/documentation-compra-venta/documentation-compra-venta.component';
import { DocumentationConfigComponent } from './views/settings/company-tab/documentation-config/documentation-config.component';
import { DocumentationColorPickerComponent } from './views/settings/company-tab/documentation-config/documentation-color-picker/documentation-color-picker.component';
import { DocumentationContentComponent } from './views/settings/company-tab/documentation-config/documentation-content/documentation-content.component';
import { TeamComponent } from './views/settings/team/team.component';
import { ModuleSelectorComponent } from './views/settings/module-selector/module-selector.component';
import { ModuleComponent } from './views/settings/module-selector/module/module.component';
import { CancelSubscriptionComponent } from './views/settings/subscription/cancel-subscription/cancel-subscription.component';
import { PayCometInvoicesComponent } from './views/settings/subscription/pay-comet-invoices/pay-comet-invoices.component';
import { PayCometResultComponent } from './views/settings/subscription/pay-comet-result/pay-comet-result.component';
import { PricingComponent } from './views/settings/subscription/pricing/pricing.component';
import { SubscriptionOptionComponent } from './views/settings/subscription/subscription-option/subscription-option.component';
import { SubscriptionStatusComponent } from './views/settings/subscription/subscription-status/subscription-status.component';
import { SubscriptionComponent } from './views/settings/subscription/subscription.component';
import { TrustedUserComponent } from './views/settings/subscription/trusted-user/trusted-user.component';
import { TrustedUsersComponent } from './views/settings/subscription/trusted-users/trusted-users.component';
import { CompanyFormComponent } from './views/settings/company-tab/company-form/company-form.component';
import { PurchaseDetailsComponent } from './purchase-details/purchase-details.component';
import { SectionComponent } from './components/advanced-details-footer/section/section.component';
import { AdvancedDetailsFooterComponent } from './components/advanced-details-footer/advanced-details-footer.component';
import { MainOrButtonComponent } from './views/client-details/main-actions/main-or-button/main-or-button.component';
import { MainAppointmentButtonComponent } from './views/client-details/main-actions/main-appointment-button/main-appointment-button.component';
import { MainBudgetComercialButtonComponent } from './views/client-details/main-actions/main-budget-comercial-button/main-budget-comercial-button.component';
import { MainNormalBudgetButtonComponent } from './views/client-details/main-actions/main-normal-budget-button/main-normal-budget-button.component';
import { MainAlbaranButtonComponent } from './views/client-details/main-actions/main-albaran-button/main-albaran-button.component';
import { MainStorehouseEntryButtonComponent } from './views/client-details/main-actions/main-storehouse-entry-button/main-storehouse-entry-button.component';
import { ChangePasswordComponent } from './views/user-details/change-password/change-password.component';
import { IncomingOrdersProviderComponent } from './views/client-details/incoming-orders-provider/incoming-orders-provider.component';
import { AllIncomingOrdersInDialogComponent } from './views/client-details/all-incoming-orders-in-dialog/all-incoming-orders-in-dialog.component';
import { ContactClientRectangleComponent } from './components/contact-client-rectangle/contact-client-rectangle.component';
import { MainManualMovementButtonComponent } from './views/product-details/main-manual-movement-button/main-manual-movement-button.component';
import { ProductDetailsStockDialogComponent } from './views/product-details/product-details-stock-dialog/product-details-stock-dialog.component';
import { ProductDetailsReservesDialogComponent } from './views/product-details/product-details-reserves-dialog/product-details-reserves-dialog.component';
import { ProductDetailsFaultsDialogComponent } from './views/product-details/product-details-faults-dialog/product-details-faults-dialog.component';
import { AllProductProvidersComponent } from './views/product-details/product-providers-row/all-product-providers/all-product-providers.component';
import { SectionTitleComponent } from './components/advanced-details-footer/section/section-title.component';
import { SectionContentComponent } from './components/advanced-details-footer/section/section-content.component';
import { FooterContentComponent } from './components/advanced-details-footer/footer-content.component';
import { FooterRightComponent } from './components/advanced-details-footer/footer-right.component';
import { FooterTitleComponent } from './components/advanced-details-footer/footer-title.component';
import { AdvancedDetailsComponent } from './components/advanced-details/advanced-details.component';
import { AdvancedTitleComponent } from './components/advanced-details/advanced-title.component';
import { AdvancedSubtitleComponent } from './components/advanced-details/advanced-subtitle.component';
import { AdvancedMainActionsComponent } from './components/advanced-details/advanced-main-actions.component';
import { AdvancedMenuActionsComponent } from './components/advanced-details/advanced-menu-actions.component';
import { AdvancedHeadingComponent } from './components/advanced-details/advanced-heading.component';
import { VehicleRectangleComponent } from './components/vehicle-rectangle/vehicle-rectangle.component';
import { TimelapseComponent } from './components/timelapse/timelapse.component';
import { WorkerSelectorComponent } from './components/worker-selector/worker-selector.component';
import { CentersComponent } from './views/settings/centers/centers.component';
import { CenterFormComponent } from './components/center-form/center-form.component';
import { FullAddressComponent } from './components/full-address/full-address.component';
import { SteppFormAndFileComponent } from './stepp-form-and-file/stepp-form-and-file.component';
import { MassiveInvoiceComponent } from './components/massive-invoice/massive-invoice.component';
import { InvoiceDetailsComponent } from './views/invoice-details/invoice-details.component';
import { ReserveBudgetComponent } from './reserve-budget/reserve-budget.component';
import { WeekGridComponent } from './components/carga-taller/drag-and-drop-grid/grid/week-grid/week-grid.component';
import { MonthGridComponent } from './components/carga-taller/drag-and-drop-grid/grid/month-grid/month-grid.component';
import { GridCardComponent } from './components/carga-taller/drag-and-drop-grid/grid-card/grid-card-component';
import { DragAndDropGridComponent } from './components/carga-taller/drag-and-drop-grid/grid/drag-and-drop-grid.component';
import { RecambiosBudgetComponent } from './views/budget/recambios-budget/recambios-budget.component';
import { CreatePurchaseComponent } from './create-purchase/create-purchase.component';
import { SellVehicleFormComponent } from './sell-vehicle-form/sell-vehicle-form.component';
import { EstimatedTimeComponent } from './components/estimated-time/estimated-time.component';
import { DnIDirective } from './directives/dn-i.directive';
import { MoreThanDirective } from './directives/screenWidth/more-than.directive';
import { LessOrDirective } from './directives/screenWidth/less-or.directive';
import { FloatingSaveButtonComponent } from './components/floating-save-button/floating-save-button.component';
import { AdvancedHeading2Component } from './components/advanced-details/advanced-heading2.component';
import { UserCanGoDirective } from './directives/user-can-go.directive';
import { TestComponent } from './components/test/test.component';
import { DayPercentageBarComponent } from './components/carga-taller/day-percentage-bar/day-percentage-bar.component';
import { PaymentChipsComponent } from './components/payment-chips/payment-chips.component';
import { EinaFormDirective } from './directives/EinaForms/eina-form.directive';
import { EinaFormWrapperDirective } from './directives/EinaForms/eina-form-wrapper.directive';
import { EinaFormSectionDirective } from './directives/EinaForms/eina-form-section.directive';
import { InvoiceTotalsTableComponent } from './components/invoice-totals-table/invoice-totals-table.component';
import { CreateEditDiscountGroupComponent } from './views/create-edit-discount-group/create-edit-discount-group.component';
import { DiscountGroupsComponent } from './views/discount-groups/discount-groups.component';
import { YearDatapikerComponent } from './components/year-datapiker/year-datapiker.component';
import { WeekScheduleComponent } from './components/week-schedule/week-schedule.component';
import { AccountingGroupTableComponent } from './components/accounting-groups-tables/accounting-group-table/accounting-group-table.component';
import { AccountingGroupTablesComponent } from './components/accounting-groups-tables/accounting-group-tables.component';
import { AccountingGroupsComponent } from './views/accounting-groups/accounting-groups.component';
import { CenterFilterComponent } from './components/filters/center-filter/center-filter.component';
import { MyPresenceComponent } from './views/my-presence/my-presence.component';
import { SigningTimerComponent } from './components/signing-timer/signing-timer.component';
import { CreateSerieComponent } from './core/components/create-serie/create-serie.component';
import { CreateColorComponent } from './core/components/create-color/create-color.component';
import { SeriesComponent } from './core/components/series/series.component';
import { ColorsComponent } from './core/components/colors/colors.component';
import { SerieDetailsComponent } from './core/components/serie-details/serie-details.component';
import { ColorDetailsComponent } from './core/components/color-details/color-details.component';
import { SerieFormComponent } from './serie-form/serie-form.component';
import { MtrComponent } from './views/mtr/mtr.component';
import { EditMyPresenceComponent } from './views/my-presence/edit-my-presence/edit-my-presence.component';
import { SharedAccesWorkloadComponent } from './views/shared-acces-workload/shared-acces-workload.component';
import { SharedAccessLoginComponent } from './views/shared-access-login/shared-access-login.component';
import { InfoPanelRectangleComponent } from './components/info-panel-rectangle/info-panel-rectangle.component';
import { ChangeSharedUserComponent } from './views/shared-acces-workload/change-shared-user/change-shared-user.component';
import { CreateEditBrandComponent } from './create-edit-brand/create-edit-brand.component';
import { BrandsComponent } from './components/brands/brands.component';
import { ModelsComponent } from './components/models/models.component';
import { TodaySigningBreakdownComponent } from './components/today-signing-breakdown/today-signing-breakdown.component';
import { WorkingOrTimerComponent } from './components/working-or-timer/working-or-timer.component';
import { CreateModelsComponent } from './components/create-models/create-models.component';
import { GenericExportComponent } from './components/generic-export/generic-export.component';
import { AccountingGroupSelectorComponent } from './components/accounting-group-selector/accounting-group-selector.component';
import { ModuleDetailsComponent } from './views/module-details/module-details.component';
import { ModulesExplanationComponent } from './views/settings/module-selector/modules-explanation/modules-explanation.component';
import { IntegrationsExplanationComponent } from './views/settings/module-selector/integrations-explanation/integrations-explanation.component';
import { ReportsComponent } from './views/reports/reports.component';
import { CenterAccountingComponent } from './components/center-form/center-accounting/center-accounting.component';
import { ReportComponent } from './views/reports/report/report.component';
import { MatDialogTitleImgDirective } from './directives/mat-dialog-title-img.directive';
import { ModulePriceTagComponent } from './views/settings/module-selector/module/module-price-tag/module-price-tag.component';
import { ToDoComponent } from './components/to-do/to-do.component';
import { StepperStepIndicatorComponent } from './components/stepper-step-indicator/stepper-step-indicator.component';
import { HolidaysScheduleDayDialogComponent } from './components/holidays-schedule-calendar/holidays-schedule-day-dialog/holidays-schedule-day-dialog.component';
import { ScheudleTableComponent } from './components/week-schedule/scheudle-table/scheudle-table.component';
import { DateFnsAdapter, MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { es as esFNS } from 'date-fns/locale';

export const MY_STRING = new InjectionToken<string[]>('myString');
defineElement(lottie.loadAnimation);
registerLocaleData(es);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const projectConfiguration = new ProjectConfiguration({
  landingView: ViewPath.landing,
  multiUserLanding: ViewPath.sharedAccesLogin,
  errorView: ViewPath.pageNotFound,
  dashboardView: ViewPath.dashboard,
  errorHandlerEndpoint: endpoints.reportError,
  checkVersionEndpoint: undefined
})


@NgModule({
  declarations: [
    EntryPointComponent,
    ToolbarComponent,
    BigMamaComponent,
    MenuComponent,
    AppContentsComponent,
    MenuItemComponent,
    DashboardComponent,
    ClientsComponent,
    VehiclesComponent,
    ProductsComponent,
    InvoicesComponent,
    SettingsComponent,
    PageNotFoundEinaComponent,
    ClientDetailsComponent,
    AddRemoveComponent,
    ProductDetailsComponent,
    VehicleDetailsComponent,
    LinkVehicleComponent,
    VehicleContainerComponent,
    CreateVehicleComponent,
    CreateClientComponent,
    CreateProductComponent,
    CreateInvoiceComponent,
    LoginComponent,
    RegisterComponent,
    CompanyTabComponent,
    DocumentationConfigComponent,
    DocumentationColorPickerComponent,
    DocumentationContentComponent,
    ClientformComponent,
    VehicleformComponent,
    UserformComponent,
    CardRowContentComponent,
    CreateOr,
    DragComponent,
    DragGroupComponent,
    CreateCustomProductComponent,
    ImageToggleComponent,
    ImgToggleItemComponent,
    ItemRowComponent,
    IvachangerComponent,
    PreviewDialog,
    CreateRecoverPasswordComponent,
    DeleteDialogComponent,
    CifComponent,
    AlreadyExistsService,
    ProductmoveComponent,
    SmtpComponent,
    TermsAndConditionsComponent,
    OrComponent,
    CreateEditBudgetComponent,
    EditOrComponent,
    SignatureComponent,
    ActionCreateEditParentComponent,
    InvoiceDialogComponent,
    GroupCardInvoiceGroupComponent,
    ModifyObservationsComponent,
    ClientConsentsComponent,
    OrFormComponent,
    BeforeDeleteGroupComponent,
    DataUploadComponent,
    DataUploadItemComponent,
    DataUploadDialogComponent,
    ActionHistoryComponent,
    ConfirmExitDialogComponent,
    LanguageSelectorComponent,
    PluralPipe,
    WelcomeComponent,
    DragTutorialComponent,
    SubscriptionComponent,
    SubscriptionDaysLeftComponent,
    SubscriptionOptionComponent,
    TrustedUserComponent,
    TextWithLinkComponent,
    ContactUsTextComponent,
    SubscribedUserDirective,
    FooterComponent,
    IssuesViewComponent,
    TryProDialogComponent,
    CargaTallerParentComponent,
    AbonoInvoiceComponent,
    SelectAllOfTypeComponent,
    VehicleRowContainerComponent,
    AddTimeComponent,
    PricingComponent,
    TrustedUsersComponent,
    OrTypeSelectorComponent,
    LastActivityComponent,
    WhatsNewComponent,
    DashboardOrTableComponent,
    DialogWhatsNewsComponent,
    DialogCheckoutControlComponent,
    CancelationAndReturnPolicyComponent,
    PayCometResultComponent,
    CancelSubscriptionComponent,
    PayCometInvoicesComponent,
    SubscriptionStatusComponent,
    TemplateFieldsFormComponent,
    FloatDirective,
    IntegerDirective,
    TemplateFieldsDialog,
    PdfComponent,
    StandardDialogComponent,
    DialogAdvancePayComponent,
    AdvancesArrayComponent,
    ShowAdvanceClientComponent,
    DialogHistoryAdvancesComponent,
    StandardDialogComponent,
    DecimalInput,
    PayAllInvoiceDialogComponent,
    DialogCashTotalReturnComponent,
    ChangeDeliveryOrDialogComponent,
    TrasnferActionComponent,
    CreateInvoiceDialogComponent,
    ProTipsButtonComponent,
    ProTipsDialogComponent,
    InvoiceMovementsComponent,
    InvoicePayementFormComponent,
    InvoicedSiniestroDialogComponent,
    FrontendDocumentation,
    DeleteCompanyComponent,
    ActionTotalsComponent,
    RaGroupsComponent,
    InvoiceTotalsComponent,
    ProductLineTitleComponent,
    MissingCompanyInfoComponent,
    ModelNotFoundComponent,
    DocumentationPdfMenuComponent,
    MultipleMenuItemComponent,
    GarageComponent,
    AppointmentsComponent,
    TeamComponent,
    CreateVNVOcomponent,
    SellVehicleComponent,
    UserDetailsComponent,
    CreateEditUserComponent,
    AppointmentFormComponent,
    WorkshopAndAppointmentsComponent,
    QrDialogComponent,
    AlbaranesComponent,
    HourPickerComponent,
    HolidaysCompanyComponent,
    HolidaysScheduleCalendarComponent,
    AdvancesClientDetailsDialogComponent,
    LastInterventionOrComponent,
    ClientDetailsVehicleTableComponent,
    PendingPaymentDialogComponent,
    ModifyAppointmentHourComponent,
    OrdersComponent,
    ProductProvidersRowComponent,
    DialogSetComponent,
    HourAppointmentTndrComponent,
    AppointmentFormComponent,
    CreateAppointmentClientSideComponent,
    WifiCheckerComponent,
    NoteComponent,
    OrNotesAndImagesComponent,
    LandingComponent,
    DemoVideoComponent,
    InvoiceDayCheckerDirective,
    AppointmentToOrComponent,
    RolesSuperUserDirective,
    // RolesAdminDirective,
    // RolesGestorDirective,
    RolesAdviserDirective,
    RolesMechanincDirective,
    RolesExplanationComponent,
    LocalDirective,
    DevDirective,
    PreDirective,
    ProdDirective,
    LocalDevDirective,
    DevPreDirective,
    LocalDevPreDirective,
    AddTimeOrDialogComponent,
    HistoryTimeOrDialogComponent,
    ClientSideDetailsAppointmentComponent,
    ShowAppointmentsCompanyComponent,
    CreateOrderComponent,
    StorehousesComponent,
    ModuleDirective,
    SotreHouseFormComponent,
    SotrehouseBookmarkComponent,
    StoreHouseCreateDialogComponent,
    StorehouseLordIconComponent,
    FaultsDialogComponent,
    ProvidersDetailsProductTableComponent,
    ProviderSelectorComponent,
    StorageSelectorComponent,
    OrderBreakdownSectionComponent,
    ProductStorehouseControlstockComponent,
    IncomingOrdersComponent,
    FaultComponent,
    ProduproductmoveRecambiosModuleComponent,
    AddProviderToProductOrFaultComponent,
    MovesDetailDialogComponent,
    PluralDirective,
    ManualReservationComponent,
    ManualStockMovesComponent,
    CreateEditAlbaranComponent,
    SelectLocationComponent,
    EditOrderComponent,
    InfoReserveOrdersComponent,
    CtrlSpaceShortcutComponent,
    ProductLineTableComponent,
    ProductSearcherComponent,
    ProdDirective,
    DialogReserveComponent,
    ReservesProductTableComponent,
    ManualFaultComponent,
    StoreHouseEntryAddProductComponent,
    CreateStorehouseEntryComponent,
    StorehouseEntrySectionComponent,
    DropdownDirective,
    RemoveReservationComponent,
    ModuleSelectorComponent,
    ModuleComponent,
    StorehouseEntryComponent,
    FaultProductTableComponent,
    ExcelColumnMatcherComponent,
    ContactSendingAddressSelectorComponent,
    ContanctSendingAdressSelectorDialogComponent,
    CreateEditSendingAdressComponent,
    ProdMoveNumberDirective,
    ProductStorehouseControlstockDetailsComponent,
    ProductLineActionsComponent,
    DiscountMonthlyComponent,
    SendMailCodePromotionalComponent,
    DialogConfirmPlateComponent,
    AddCommentComponent,
    ProviderSearcherComponent,
    ChassisInputComponent,
    ConceptsComponent,
    BrandModelInputComponent,
    CreateConceptComponent,
    ConceptSearcherComponent,
    BrandModelEditComponent,
    VnvoDetailsComponent,
    CreateComercialBudgetComponent,
    ConceptsLineTableComponent,
    WorkshopBudgetComponent,
    ComercialBudgetComponent,
    GoDirective,
    AsyncValidatorSpinnerComponent,
    ExampleFormComponent,
    SellVehicleClientComponent,
    PdfDocumentsUploadComponent,
    DocumentationCompraVentaComponent,
    AssignClientVnVoComponent,
    LoadingPanelService,
    DragAndDropDirective,
    BaseInput,
    DniInputComponent,
    EmailInputComponent,
    PasswordInputComponent,
    PhoneInputComponent,
    RepeatPasswordInputComponent,
    FileUploadComponent,
    UploadedFileComponent,
    CropImageComponent,
    PlateInputComponent,
    FirmaComponent,
    CardComponent,
    SkeletonComponent,
    BaseTitleComponent,
    TerminosCondicionesComponent,
    ComentariosComponent,
    ComentariosMotoComponent,
    ContactButtonsComponent,
    OnboardingComponent,
    SpeechBubbleComponent,
    ViewTitleComponent,
    ViewTitleLordIconComponent,
    ParameterStateComponent,
    DeveloperHelperComponent,
    CoreCargaTallerComponent,
    WeekGridComponent,
    MonthGridComponent,
    GridCardComponent,
    DragAndDropGridComponent,
    GroupStatusTypeTextComponent,
    OrStatusLabelComponent,
    OrTypeLabelComponent,
    DayFilterComponent,
    EnumFilterComponent,
    CheckBoxFilterComponent,
    FilterDialogComponent,
    SliderFilterComponent,
    TagfilterComponent,
    TextFilterComponent,
    UserFilterComponent,
    FiltersComponent,
    DateFilterComponent,
    ClassSearcherFilterComponent,
    CircularLetterComponent,
    EndpointViewerComponent,
    EndpointLineComponent,
    ErrorViewerComponent,
    ErrorViewerComponent,
    DobiComponent,
    DobiDialogComponent,
    ImagePreviewComponent,
    ConfirmDialogService,
    CloudTicketComponent,
    CloudTicketCommentComponent,
    CreateTicketDialog,
    NotificationsComponent,
    CloseDialogComponent,
    NumberManager,
    MoneyDirective,
    DecimalsDirective,
    PreventDirective,
    AppointmentStatusLabelComponent,
    PageStructureComponent,
    AutocompleteComponent,
    InvoiceStatusComponent,
    WhatsappComponent,
    PhoneButtonComponent,
    EmailButtonComponent,
    OrWeekCardComponent,
    AppointmentWeekCardComponent,
    GoBackComponent,
    FourdigitsComponent,
    ClassSearcherComponent,
    OrChangeWorkerComponent,
    LayoutSendEmailComponent,
    EmailSendComponent,
    SpaceRemoverComponent,
    FaqsComponent,
    MatDialogSubtitleDirective,
    MultiplePhoneImageComponent,
    SingleImgComponent,
    SmartIconDirective,
    OrderStatusLabelComponent,
    ButtonToggleFilterComponent,
    AlbaranStatusLabelComponent,
    VnVoLabelComponent,
    MenuTriggerComponent,
    FreeMonthComponent,
    PurchasesComponent,
    BalanceComponent,
    IconFilledDirective,
    PageStructureAddNewComponent,
    CompanyFormComponent,
    MainOrButtonComponent,
    PurchaseDetailsComponent,
    MainAppointmentButtonComponent,
    SectionComponent,
    AdvancedDetailsFooterComponent,
    MainBudgetComercialButtonComponent,
    MainNormalBudgetButtonComponent,
    MainAlbaranButtonComponent,
    MainStorehouseEntryButtonComponent,
    RolesComercialDirective,
    RolesRecambistaDirective,
    RolesDirective,
    NotRolesDirective,
    ChangePasswordComponent,
    IncomingOrdersProviderComponent,
    AllIncomingOrdersInDialogComponent,
    ContactClientRectangleComponent,
    MainManualMovementButtonComponent,
    ProductDetailsStockDialogComponent,
    ProductDetailsReservesDialogComponent,
    ProductDetailsFaultsDialogComponent,
    AllProductProvidersComponent,
    SectionTitleComponent,
    SectionContentComponent,
    FooterContentComponent,
    FooterTitleComponent,
    FooterRightComponent,
    AdvancedDetailsComponent,
    AdvancedTitleComponent,
    AdvancedSubtitleComponent,
    AdvancedMainActionsComponent,
    AdvancedMenuActionsComponent,
    AdvancedHeadingComponent,
    VehicleRectangleComponent,
    TimelapseComponent,
    WorkerSelectorComponent,
    CentersComponent,
    CenterFormComponent,
    FullAddressComponent,
    SteppFormAndFileComponent,
    MassiveInvoiceComponent,
    InvoiceDetailsComponent,
    ReserveBudgetComponent,
    RecambiosBudgetComponent,
    CreatePurchaseComponent,
    SellVehicleFormComponent,
    EstimatedTimeComponent,
    DnIDirective,
    MoreThanDirective,
    LessOrDirective,
    EinaFormDirective,
    EinaFormWrapperDirective,
    EinaFormSectionDirective,
    FloatingSaveButtonComponent,
    AdvancedHeading2Component,
    UserCanGoDirective,
    TestComponent,
    DayPercentageBarComponent,
    PaymentChipsComponent,
    InvoiceTotalsTableComponent,
    CreateEditDiscountGroupComponent,
    DiscountGroupsComponent,
    YearDatapikerComponent,
    WeekScheduleComponent,
    AccountingGroupTablesComponent,
    AccountingGroupTableComponent,
    AccountingGroupsComponent,
    CenterFilterComponent,
    MyPresenceComponent,
    SigningTimerComponent,
    SeriesComponent,
    ColorsComponent,
    CreateColorComponent,
    CreateSerieComponent,
    SerieDetailsComponent,
    ColorDetailsComponent,
    SerieFormComponent,
    MtrComponent,
    EditMyPresenceComponent,
    SharedAccesWorkloadComponent,
    SharedAccessLoginComponent,
    InfoPanelRectangleComponent,
    ChangeSharedUserComponent,
    CreateEditBrandComponent,
    BrandsComponent,
    TodaySigningBreakdownComponent,
    WorkingOrTimerComponent,
    ModelsComponent,
    CreateModelsComponent,
    GenericExportComponent,
    AccountingGroupSelectorComponent,
    ModuleDetailsComponent,
    ModulesExplanationComponent,
    IntegrationsExplanationComponent,
    ReportsComponent,
    CenterAccountingComponent,
    ReportComponent,
    MatDialogTitleImgDirective,
    ModulePriceTagComponent,
    ToDoComponent,
    StepperStepIndicatorComponent,
    HolidaysScheduleDayDialogComponent,
    ScheudleTableComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatBadgeModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatRippleModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatStepperModule,
    MatTabsModule,
    MatMenuModule,
    MatDividerModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatDateFnsModule,
    MatDialogModule,
    DragDropModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatButtonToggleModule,
    NgApexchartsModule,
    MatChipsModule,
    MatSliderModule,
    MatBottomSheetModule,
    MatExpansionModule,
    MatTreeModule,
    MatSnackBarModule,
    PdfViewerModule,
    NgxGoogleAnalyticsModule.forRoot(environment.analyticsId),
    NgxGoogleAnalyticsRouterModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatInputModule,
    QRCodeModule,
    MatSelectCountryModule.forRoot('es'),
    HighlightModule,
    ImageCropperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', color: 'primary', subscriptSizing: 'dynamic' } },
    { provide: MAT_FAB_DEFAULT_OPTIONS, useValue: { color: 'p3' } },
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    { provide: MAT_FAB_DEFAULT_OPTIONS_FACTORY, useValue: { color: 'p3' } },
    { provide: HTTP_INTERCEPTORS, useClass: httpListenerService, multi: true },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MY_STRING, useValue: '' },
    { provide: MAT_DATE_LOCALE, useValue: esFNS },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: DateAdapter, useClass: DateFnsAdapter },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: HIGHLIGHT_OPTIONS, useValue: { fullLibraryLoader: () => import('highlight.js'), themePath: 'assets/highlight/highlight_theme.css' } },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    MatDatepickerModule,
    DeactivateGuard,
    DatePipe
  ],
  bootstrap: [EntryPointComponent]
})

export class AppModule {
  constructor() { }
} 
