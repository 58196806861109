<div (click)="goItem()" class="clickable-item" [ngClass]="disabled ? 'disabled' : 'not-disabled'">
    <p>

        <!-- Client -->
        <ng-container *ngIf="isC(item)">
            <mat-icon class="row-icon">{{item.icon}}</mat-icon>
            <span class="row-main-name">{{item.getName()}}</span>
            <span class="c_t2 ml10 mr10">|</span>
            <span class="c_t2">{{ item.nif}}</span>
            <span [ngTemplateOutlet]="goArrow"></span>
        </ng-container>

        <!-- Vehicle -->
        <ng-container *ngIf="isV(item)">
            <mat-icon class="row-icon">{{item.icon}}</mat-icon>
            <span class="row-main-name">{{item.getName()}}</span>
            <span class="c_t2 ml10 mr10">|</span>
            <span class="c_t2">{{ item.isVnVo ? item.chassis : item.license}}
                <span [ngTemplateOutlet]="goArrow"></span>
            </span>
            <p class="fw500">Matriculación :
                <span *ngIf="item.license_date" class="fwn">{{item.license_date.humanFormat()}}</span>
                <span *ngIf="!item.license_date" class="c_t2 fwn">Sin datos</span>
            </p>
        </ng-container>

        <!-- Appoitnment-->
        <ng-container *ngIf="isA(item)">
            <mat-icon class="row-icon">event</mat-icon>
            <span class="row-main-name">{{this.item.date_appointment.shortFormat()}}</span>
            <span [ngTemplateOutlet]="goArrow"></span>
        </ng-container>


        <!-- Order-->
        <ng-container *ngIf="isO(item)">
            <span class="df aic gap10 nmb">
                <span class="row-main-name">{{item.order}}</span>
                <app-order-status-label [status]="item.order_status"></app-order-status-label>
                <span [ngTemplateOutlet]="goArrow"></span>
            </span>
            <p class="nmb c_t2" *ngIf="item.created_at">{{item.created_at}}</p>
        </ng-container>

        <!-- Deleted -->
        <span *ngIf="isItemDeleted" class="ml5 fw500 c_r"> (Eliminado) </span>

    </p>

</div>

<ng-template #goArrow>
    <mat-icon *ngIf="!disabled" class="c_t2 vam ml5">chevron_right</mat-icon>
</ng-template>