import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { DayFilter, Filter, TagFilter } from 'src/app/custom-classes/Filter';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { Views } from 'src/app/custom-classes/View';
import { M_LastMovements } from 'src/app/models/M_LastMovement';
import { M_Serie } from 'src/app/models/M_Serie';
import { ApiService } from 'src/app/services/Api/api.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ParamsService } from 'src/app/services/params.service';
import { SessionService } from 'src/app/services/session.service';
import { SerieDetailsComponent } from '../serie-details/serie-details.component';
import { CreateSerieComponent } from '../create-serie/create-serie.component';
export enum PageSerieEnum {
  DATA_SERIE = 1,
  BRAND = 2,
}
@Component({
  selector: 'app-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.css']
})
export class SeriesComponent implements OnInit {
  f = filter;
  v = ViewPath
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Serie>;
  filtersToShow: Filter[] = [
    new DayFilter("Fecha de creación").setId(PageSerieEnum.DATA_SERIE)
  ];
  constructor(public apiS: ApiService, private d: MatDialog, public subS: SubscriptionService,
    public sessionS: SessionService, public params: ParamsService) {
  }
  ngOnInit(): void {
    this.apiS.series().then(res=>{
      this.ps.initTable(res);
    })
  }
  get quickFilter() {
    let f = this.filtersToShow.find(f => f.id == PageSerieEnum.BRAND);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }
  
  openDialog() { }
  onCreate() { 
    return 
  }
  viewClick(event: any): Views {

    return this.v.serieDetails
  }
  getIds(event: any): number {

    return event.id;

  }
  codeAccounting(serie:M_Serie){
    return serie.accounting?.name;
  }
  onDialogRef(serie:M_Serie){
    if(serie != undefined){
      console.log(serie);
      return this.d.open(SerieDetailsComponent,{data:serie}).afterClosed().subscribe(res=>{});
    }
    return this.d.open(CreateSerieComponent,{data:serie}).afterClosed().subscribe(res=>{});
    //params.go(viewClick($event),getIds($event))
  }
}
