<table class="eina-table accounting-groups-table">
    <thead>
        <tr>
            <th>{{typeByFac}}</th>
            <th>Ventas</th>
            <th>Descuentos</th>

            <th *ngIf="showCI">Venta C.I.</th>
            <th *ngIf="showCI">Coste C.I.</th>

            <th>Inventario</th>
            <th>Costes</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let ag of data; let index = index">
            <!-- Chanel column -->
            <td>{{ ag.channel_title }}</td>

            <!-- Ventas (mat-form-field) -->
            <td>
                <mat-form-field appearance="outline" class="small-form-field">
                    <input [maxlength]="max" (input)="ag.updated = true" matInput [(ngModel)]="ag.Ventas">
                </mat-form-field>
            </td>

            <!-- Descuentos (mat-form-field) -->
            <td>
                <mat-form-field appearance="outline" class="small-form-field">
                    <input [maxlength]="max" (input)="ag.updated = true" matInput [(ngModel)]="ag.Descuentos">
                </mat-form-field>
            </td>

            <!-- Venta C.I (mat-form-field) -->
            <td *ngIf="showCI">
                <mat-form-field appearance="outline" class="small-form-field" [dn_i]="displayNone(index)">
                    <input [maxlength]="max" (input)="ag.updated = true" matInput [(ngModel)]="ag.VentasCI">
                </mat-form-field>
            </td>

            <!-- Coste C.I (mat-form-field) -->
            <td *ngIf="showCI">
                <mat-form-field appearance="outline" class="small-form-field" [dn_i]="displayNone(index)">
                    <input [maxlength]="max" (input)="ag.updated = true" matInput [(ngModel)]="ag.CosteCI">
                </mat-form-field>
            </td>

            <!-- Inventario (mat-form-field) -->
            <td>
                <mat-form-field appearance="outline" class="small-form-field">
                    <input [maxlength]="max" (input)="ag.updated = true" matInput [(ngModel)]="ag.Inventario">
                </mat-form-field>
            </td>

            <!-- Costes (mat-form-field) -->
            <td>
                <mat-form-field appearance="outline" class="small-form-field">
                    <input [maxlength]="max" (input)="ag.updated = true" matInput [(ngModel)]="ag.Costes">
                </mat-form-field>
            </td>
        </tr>
    </tbody>
</table>