import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-invoice-totals',
  templateUrl: './invoice-totals.component.html',
  styleUrls: ['./invoice-totals.component.css']
})
export class InvoiceTotalsComponent {
  @Input({required:true}) total_invoice! : number;
  @Input({required:true}) pending_total_invoice! : number;
  constructor(){

  }
  get total(){
    return this.total_invoice
  }
  get pending(){

    return this.pending_total_invoice
  } 
}
