import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[integer]'
})
export class IntegerDirective {

  @Input() maxNumber: number | undefined;

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(position), event.key].join('');
    const nextNumber = next.getNumber();
    if (event.key === '.' || event.key === ',' || event.key === '-' || (this.maxNumber && nextNumber && nextNumber > this.maxNumber)) {
      event.preventDefault();
    }
  }
}