export class R_Zip {
    id: number;
    internalid: number;
    cp: string;
    carrer: string | null;
    poblacio: string;
    provinciaid: string;
    provincia: string;
    paisid: string;
    pais: string;
    ccaa: {
        id: number;
        ccaaname: string;
        created_at: string | null;
        updated_at: string | null;
    };

    constructor(data: any) {
        this.id = data.id;
        this.internalid = data.internalid;
        this.cp = data.cp;
        this.carrer = data.carrer;
        this.poblacio = data.poblacio;
        this.provinciaid = data.provinciaid;
        this.provincia = data.provincia;
        this.paisid = data.paisid;
        this.pais = data.pais;
        this.ccaa = {
            id: data.ccaa.id,
            ccaaname: data.ccaa.ccaaname,
            created_at: data.ccaa.created_at,
            updated_at: data.ccaa.updated_at,
        };
    }
}
