import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { AlreadyExistsService } from "../services/already-exists.service";
import { ViewPath } from "src/app/app-routing.module";
import { M_Contact } from "../models/M_Contact";
import { EventEmitter } from "@angular/core";
import { ApiService } from "../services/Api/api.service";

export function dniNifAsync(
    apiS: ApiService,
    existsS: AlreadyExistsService,
    getCurrentContact: () => M_Contact | undefined,
    dialogBehavior: () => { canSelectClient: boolean },
    emitter: EventEmitter<M_Contact>): AsyncValidatorFn {
    console.info("Checking DNI");
    return (control: AbstractControl): Promise<ValidationErrors | null> => {

        const contactNif = getCurrentContact()?.nif;
        const behavior = dialogBehavior();

        if (contactNif == control.value || !control.value || control.disabled) { return Promise.resolve(null) }

        return apiS.checkDni(control.value).then(client => {
            if (client instanceof M_Contact) {
                existsS.show({
                    title: "¡Atención!",
                    message: "Ya existe un contacto con el identificador",
                    message2: "Recordamos que no se pueden crear dos contactos con el mismo identificador.",
                    value: control.value,
                    view: !behavior.canSelectClient ? ViewPath.contactDetails : undefined,
                    param_id: client.client_id,
                    accept_text: behavior.canSelectClient ? "Seleccionar cliente" : "Ver cliente"
                }).afterClosed().subscribe(res => {
                    if (res == true) {
                        if (behavior.canSelectClient) {
                            emitter.emit(client);
                        }
                    }
                })
                return { "nif-exists": true }
            }
            return null;
        })
    }


};