import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[eForm-wrapper]'
})
export class EinaFormWrapperDirective {

  constructor(elementRef: ElementRef<HTMLElement>) {
    elementRef.nativeElement.classList.add('eina-form-wrapper');
  }

}
