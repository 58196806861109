import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

/**
 * [Service]
 * Servicio que valida los formularios.
 */

@Injectable({
  providedIn: 'root'
})

export class FormService {
  canGoBack = true;
  forms?: HTMLCollectionOf<HTMLFormElement>;
  constructor() {
    this.addExitListener();
    this.addOnLoadListener();
  }

  /**Evitar que en los inputs de tipo 'number' se pueda poner la letra 'e' */
  addOnLoadListener() {
    window.addEventListener("beforeinput", (event) => {
      if (event.target instanceof HTMLInputElement) {
        if (event.target.type == "number") {
          event.target.setAttribute("onkeydown", "return event.keyCode !== 69")
          if (event.data == 'e' || event.data == "E") {
            event.preventDefault();
          }
        }
      }
    });
  }

  /**
   * Listener que detecta cuando el usuario ha abandonado la página actual
   */
  addExitListener() {
    window.addEventListener("beforeunload", (event) => {
      if (this.needToAdviseUser()) {
        event.preventDefault();
        event.returnValue = "Es posible que los cambios no se guarden.";
        return event;
      }
      else {
        return event;
      }
    });
  }

  checkErrorFields(){
    
  }


  /**
  * Si en un formulario hay algún campo que no esté correctamente rellenado, 
  * se desplaza la pantalla hacia el campo.
  */
  scrollToErrorInput() {
      /** Modifica el estilo de las imagenes*/
      const invalidImg = document.querySelector('.invalid-on-submit');
      if (invalidImg) { invalidImg.classList.add("mat-form-field-invalid" , "invalid-image") }

      const invalidControl = document.querySelector('.mat-form-field-invalid');
      if (invalidControl) {
        invalidControl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      }
  }

  getCurrentForm(): HTMLCollectionOf<HTMLFormElement> | undefined {
    return document.getElementsByTagName("form");
  }


  /**
   * Busca todos los formularios en el HTML actual
   * En caso de que alguno de los formularios haya sido modificado y el usuario abandone la página, se mostrara un aviso.
   */
  needToAdviseUser(): boolean {
    this.forms = this.getCurrentForm();
    if (this.forms != undefined) {
      for (let i = 0; i < this.forms.length; i++) {
        if (this.forms[i].classList.contains("ng-dirty")) {
          return true;
        }
      }
    }
    return false;
  }


  isOk(...fg: UntypedFormGroup[]): boolean {
    let ok = true;
    for (let i = 0; i < fg.length; i++) {
      if (ok) {
        ok = this.isOk_(fg[i]);
      }
      else{
        this.isOk_(fg[i]) // Marcamos como 'tocados' todos los inputs
      }
    }

    if(!ok){
      this.scrollToErrorInput();
    }

    return ok;
  }

  /**
   * Mira todas las 'keys' de un formulario y comprueba los errores
   * @param fg Formulario de la pantalla
   * @returns 
   */
  private isOk_(fg: UntypedFormGroup): boolean {
    if (fg == undefined) {
      return true;
    }
    fg.markAllAsTouched()
    Object.keys(fg.controls).forEach(key => {
      let controlErrors: ValidationErrors | null = fg.get(key)!.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          if (keyError === 'required') {
            //this.es.showError("Por favor, rellene los siguientes campos : " +  this.errors![key]!)
          }
        });
      }
    });
    return fg.valid;
  }

  /**
   * Si se requiere mostrar el campo que falta
   */
  errors: any = {
    actual_kms: "Kilómetros actuales",
  }
}


