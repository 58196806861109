<app-page-structure #normalBudget [masterClass]="'budget'" 
    [pageTitle]="'Presupuestos de recambios'" 
    [autocompleteID]="'recambios_budget'"
    listTitleText="Listado de presupuestos"
    searchLabel="Buscar presupuesto"
     noDataCreateNew="Crear nuevo presupuesto" [data]="[]"
    [displayedHeader]="['Número',          'Cliente',      'Fecha creación',     'Total',          'Estado', 'Tipo'] "
    [displayedColumns]="['title_id',          'client',       'created_at',         'total',          'status','type']"
    [specialText]="[undefined,     client,         undefined,            'money',           undefined,      undefined]"
    [specialClass]="[undefined,     undefined,      undefined,            undefined,       undefined,    undefined]"
    [preIcon]="!this.companyS.recambiosModule ?   
    [undefined,     undefined,      undefined,            undefined,       undefined,    undefined]:
    [preicon,     undefined,      undefined,            undefined,       undefined ,     undefined,      undefined]"
    [filters]="filters" [filter]="f"
    [createButton]="
        {
            text : 'Crear presupuesto' , 
            icon : 'calculate', 
            view : v.createRecambiosBudget,
            cssClass : 'onboarding-create-recambios-budget'
        }"
    (onclickRow)="paramsS.go(v.createRecambiosBudget, $event.id)"
    [quickFilter]="quickFilter">
</app-page-structure>