<p class="dropdown-label no-blue" [ngClass]="{'dn_i' : !someChildShowing, 'c_p' : true}" #ddTrigger>
    <mat-icon  [ngClass]="{'hidden_i' : !parent.opened}" dropDown [trigger]="ddTrigger" [content]="ddContent" [scale]="false" (onToggle)="saveToggle($event)"
    class="absolute_dropdown">expand_more</mat-icon>
    <span class="text" [ngClass]="{'hidden' : !parent.opened}">{{data.name}}</span>
    <span class="custom-divider" [ngClass]="{'hidden' : parent.opened || !parent.isDesktop}"></span>
</p>
<div #ddContent>
    <div *ngFor="let d of data.child">
        <app-menu-item [mc]="parent" [burgerMenu]="parent" [data]="d" [badge]="getBadge(d)"
            [svgIcon]="getSvgIcon(d)"></app-menu-item>
    </div>
</div>