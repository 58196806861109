<div class="df aic">
    <app-class-searcher [showLeftNumber]="true" class="w100" [separator]="'|'"
        (onSelect)="onSelectConcept.emit($event);" [disableIf]="disabledIf.bind(this)" [required]="false"
        [masterClass]="masterConcept" [width100]="true" searchPlaceHolder="Buscar conceptos"
        [extendedInfo]="true"
        [createData]="{
            text : 'Nuevo concepto',
            by : 'dialog'
        }"
        >
    </app-class-searcher>
    <button mat-icon-button (click)="onDestroy.emit()">
        <mat-icon>delete</mat-icon>
    </button>
</div>