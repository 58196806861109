import { ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { BaseInput } from './base-input';
import { PasswordInputComponent } from './password-input';


/**
 * [Component]
 * Input para repetir una contraseña que el usuario ya ha introducido anteriormente.
 * Para que este componente funcione, es necesario especificarle la otra contraseña con la que se debe comparar ([otherPass]).
 * Un ejemplo sería:
 * * <app-password-input #pass [form]="form" formCName="pass1"></app-password-input>
 * * <app-repeat-passowrd-input #pass2 [otherPass]="pass" [form]="form" formCName="pass2"></app-repeat-passowrd-input>
 */

@Component({
  selector: 'app-repeat-passowrd-input',
  styleUrls: [],
  template: `<div class="{{class_}}" [formGroup]="form" class="input-full-width">
              <mat-form-field  appearance="outline" class="customInput no-wrapper">
                    <mat-label>{{label ? label : 'Repite la contraseña'}}</mat-label>
                    <input #passwRef autocomplete="new-password" matInput type="password" formControlName="{{formCName}}" required [attr.autocomplete]="disableAutocomplete ? 'off' : null">
                    <button type="button" (click)="passwRef.type=='text'? passwRef.type='password' : passwRef.type='text'" mat-icon-button matSuffix>
                        <mat-icon *ngIf="passwRef.type == 'password'">visibility</mat-icon>
                        <mat-icon *ngIf="passwRef.type == 'text'">visibility_off</mat-icon>
                    </button>
              </mat-form-field>
              <span *ngIf="hasErrors(); else ok">
                      <div>
                        <p class="df aic" style="margin-bottom: 0px;">
                          <mat-icon style="height: auto;" class="inputError">close</mat-icon>
                          <span class="inputError">Las contraseñas no coinciden</span>
                        </p>
                      </div>
                    </span>
                    <ng-template #ok>
                      <div *ngIf="touched">
                          <p class="df aic" style="margin-bottom: 0px;">
                            <mat-icon style="height: auto;" class="inputOk">done</mat-icon>
                            <span class="inputOk">La contraseña coincide</span>
                          </p>
                      </div>
                    </ng-template>
            </div>`
})
export class RepeatPasswordInputComponent extends BaseInput implements OnInit {
  @HostBinding('class') classes = 'passwordRepeatInput' + this.classSelector;
  /**Input con el que tiene que conincidir */
  @Input() otherPass!: PasswordInputComponent;

  constructor(changeDetectorr: ChangeDetectorRef) {
    super(changeDetectorr)
  }

  override ngAfterViewInit() {
    this.onValueChange(() => this.control!.markAllAsTouched())
  }

  override ngOnInit(): void {
    this.otherPass.control!.valueChanges.subscribe(() => {
      if (this.value != this.otherPass.value) {
        this.setErrors({ "notMatch": true })
      }
      else {
        this.setErrors(null)
      }
    });
    this.control!.valueChanges.subscribe(() => {
      if (this.value != this.otherPass.value) {
        this.setErrors({ "notMatch": true })
      }
      else {
        this.setErrors(null)
      }
    });
  }

  getDisabled() {
    if (this.value != "" && this.otherPass.value != "") {
      if (this.value == this.otherPass.value && !this.otherPass.hasPasswordError()) {
        return false;
      }
    }
    return true;
  }

  passwordMatch() {
    return this.value == this.otherPass.value;
  }
}