<div class="df jcc w100">
    <app-card class="w100">
        <div class="storehouses-content">
            <app-card class="page-card normal-padding-top no-border add-new-card tac" (click)="createStoreHouse()">
                <div class="df jcc fdc" style="margin-top: -10px;">
                    <app-card-subtitle [first]="true">Crear almacén</app-card-subtitle>
                    <mat-icon class="c_p">add_circle</mat-icon>
                </div>
            </app-card>
            <app-card class="page-card normal-padding-top storehouse-card no-border"
                *ngFor="let storehouse of storehouses" (click)="editStoreHouse(storehouse)">
                <div class="df jcc fdc" style="margin-top: -10px;">
                    <app-card-subtitle [first]="true" class="tac">{{storehouse.name}}</app-card-subtitle>
                    <app-storehouse-lord-icon [iconClass]="'df lord-medium mla mra'"
                    [lordIcon]="storehouse.lordicon"></app-storehouse-lord-icon>
                </div>
            </app-card>
        </div>
    </app-card>
</div>