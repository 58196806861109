import { Component, Inject, Input } from '@angular/core';
import { PageStructureComponent } from '../page-structure/page-structure.component';
import { ApiService } from 'src/app/services/Api/api.service';
import { Endpoint } from 'src/app/custom-classes/Endpoint';

@Component({
  selector: 'app-generic-export',
  templateUrl: './generic-export.component.html',
  styleUrls: ['./generic-export.component.css']
})
export class GenericExportComponent {

  @Input({ required: true }) nameSingular!: string;
  @Input({ required: true }) namePlural!: string;
  @Input({ required: true }) endpoint!: Endpoint;

  constructor(@Inject(PageStructureComponent) public ps: PageStructureComponent<any>, private apiS: ApiService) { }

  async genericExport(data: any) {
    const n = this.namePlural.toLocaleLowerCase().capitalizeFirstLetter();
    this.apiS.genericExport(n, this.endpoint, data);
  }
}
