import { PorducMoveFactorEnum } from "../enums/PorducMoveFactorEnum";
import { match } from "../services/search.service";

export class M_Product_Move_Recambios {
    id: string;
    amount: number;
    balance: number;
    client_id: number | undefined;
    company_id: number;
    created_at: Date | undefined;
    discount: number;
    factor: PorducMoveFactorEnum;
    item_id: number;
    mov_id: number;
    mov_type: number;
    name: string
    pmp: string
    porte: string
    product_id: number
    provider_id: number | undefined
    pvp: string
    quanti_mov: number | undefined;
    ref_cli: string
    ref_factory: string
    typemov: string
    user: number;
    document: any
    location: string
    location_name: string | undefined
    transac_name: string
    available: number | undefined
    coment: string | undefined

    constructor(d: any) {
        this.id = d.id;
        this.available = d.available ? d.available : undefined
        this.coment = d.coment ? d.coment : undefined
        this.transac_name = d.transac_name
        this.name = d.name;
        this.amount = d.amount;
        this.balance = d.balance;
        this.client_id = d.client_id ? d.client_id : undefined;
        this.product_id = d.product_id;
        this.company_id = d.company_id;
        this.discount = d.discount;
        this.factor = d.factor;
        this.created_at = d.created_at ? new Date(d.created_at) : undefined;
        this.item_id = d.item_id;
        this.location = d.store_name + '-' + d.location_name
        this.mov_id = d.mov_id;
        this.mov_type = d.mov_type;
        this.pmp = d.pmp;
        this.porte = d.porte;
        this.provider_id = d.provider_id ? d.provider_id : undefined;
        this.pvp = d.pvp;
        this.ref_cli = d.ref_cli;
        this.ref_factory = d.ref_factory;
        this.typemov = d.typemov;
        this.user = d.user;
        this.product_id = d.product_id;
        this.document = d.document ? d.document : undefined;
        this.location_name = d.location_name ? d.location_name : undefined
        this.quanti_mov = d.quanti_mov;

    }

    defaultSearchFilter(v: string): boolean {
        return match(v, this.ref_factory, this.location, this.document, this.transac_name);
    }

}