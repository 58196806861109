import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endpoints } from '../../../constants/Endpoints';
import { M_Contact } from 'src/app/models/M_Contact';
import { ContactEnum } from 'src/app/enums/ContactEnum';
import { M_LastMovements } from 'src/app/models/M_LastMovement';

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {

  constructor(private http: HttpClient) { }

  /** TO DO : Return Client and Vehicle !! */
  createClient(body: any) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createUpdateClient.url, body).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  updateClient(body: M_Contact) {
    return new Promise<M_Contact>(resolve => {
      this.http.post<any>(endpoints.createUpdateClient.url, body).subscribe(
        data => {
          resolve(new M_Contact(data));
        }
      );
    })
  }

  createProvider(body: M_Contact) {
    body.type = ContactEnum.PROVIDER;
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createUpdateClient.url, body).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  updateProvider(body: M_Contact) {
    body.type = ContactEnum.PROVIDER;
    return new Promise<M_Contact>(resolve => {
      this.http.post<any>(endpoints.createUpdateClient.url, body).subscribe(
        data => {
          resolve(new M_Contact(data));
        }
      );
    })
  }

  clients() {
    return new Promise<M_Contact[]>(resolve => {
      this.http.get<any>(endpoints.clients.url).subscribe(
        data => {
          let data_: M_Contact[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Contact(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }

  c_clients() {
    return new Promise<M_Contact[]>(resolve => {
      this.http.get<any>(endpoints.c_clients.url).subscribe(
        data => {
          let data_: M_Contact[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Contact(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }

  clientsAdvances() {
    return new Promise<M_LastMovements[]>(resolve => {
      this.http.get<any>(endpoints.clientsAdvances.url).subscribe(
        data => {
          let data_: M_LastMovements[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_LastMovements(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }
}
