<app-page-structure [masterClass]="'or'" pageTitle="Órdenes de reparación" [autocompleteID]="'or'"
    searchLabel="Buscar or"
    noDataCreateNew="Crear una nueva or" [data]="[]"
    listTitleText="Listado de or"
    [displayedHeader]=" ['Número',     'Matrícula',                'Marca y modelo',    'Estado',       'Tipo',     'Fecha de entrega']"
    [displayedColumns]="['title_id',   'vehicle.license',          'vehicle.brand' ,    'status',       'type',     'delivery']"
    [specialText]="     [undefined,     license,                   brand,                undefined,     undefined,   delivery]"
    [specialClass]="    [undefined,     undefined,                 undefined,            undefined,     undefined,   undefined]"
    [cellZize]="        [undefined,     undefined,                 undefined,             'big',      undefined,   undefined]"
    [preIcon]=" !this.companyS.recambiosModule ?   
    [undefined,     undefined,      undefined,            undefined,       undefined,    undefined]:
    [preicon,     undefined,      undefined,            undefined,       undefined ,     undefined,      undefined]"
    [filters]="filters" [filter]="f"
    (onclickRow)="params.go(v.editOr, $event.id)"
    [quickFilter]="quickFilter"
    [rightMenu]="rightMenu"
    [createButton]="
        {
            text : 'Crear or' , 
            icon : 'build', 
            view : v.createOr, 
            cssClass : 'onboarding-create-or'
        }">
</app-page-structure>

<ng-template #rightMenu>
    <button mat-menu-item (click)="openMassiveOr()">
        <mat-icon>receipt_long</mat-icon>
        Facturación massiva
    </button>
</ng-template>