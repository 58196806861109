import { M_Vehicle } from "./Vehicles/M_Vehicle";

export class M_StockVehicles {
    quantity: number;
    quant_reserved: number;
    quant_available: number;
    custom: number;
    available_vehicles: M_Vehicle[] = [];

    constructor(d: any) {
        this.quantity = d.quantity;
        this.quant_reserved = d.quant_reserved;
        this.quant_available = d.quant_available;
        this.custom = d.custom;

        if (d.available_vehicles) {
            for (let i = 0; i < d.available_vehicles.length; i++) {
                this.available_vehicles.push(new M_Vehicle(d.available_vehicles[i]))
            }
        }
    }
}
