import { M_Contact } from "./M_Contact";
import { M_Vehicle } from "./Vehicles/M_Vehicle";
import { M_TaskAppointment } from "./M_TaskAppointment";
import { M_RAGroup } from "./M_RAGroup";
import { M_Action } from "./M_Action";
import { IPageStructureItem } from "../interfaces/IPageStructureItem";
import { AppointmentStatus } from "../enums/AppointmentStatus";
import { getArrayOf } from "../utils/FunctionUtils";
import { match } from "../services/search.service";
import { M_User } from "./M_User";

export class M_Appointment implements IPageStructureItem {
    id: number;
    cita: boolean = true;
    license: string;
    vehicle_brand: string | undefined;
    vehicle_model: string | undefined;
    name_client: string;
    phone: string | undefined;
    email: string | undefined;
    hash: string;
    tasks: M_TaskAppointment[] = [];
    state: AppointmentStatus;
    estimated_time_hours: number | undefined;
    estimated_time_minutes: number | undefined;
    control_1: string | undefined;
    control_2: string | undefined;
    control_3: string | undefined
    client: M_Contact | undefined;
    vehicle: M_Vehicle | undefined;
    date_appointment: Date;
    id_appointment: string | undefined;
    or: M_Action | undefined;

    receptor: number | undefined;
    receptor_user : M_User | undefined;
    
    worker: number | undefined;
    

    constructor(d: any) {
        this.id = d.id;
        this.license = d.license;
        this.vehicle_brand = d.vehicle_brand;
        this.vehicle_model = d.vehicle_model;
        this.name_client = d.name_client;
        this.email = d.email;
        this.phone = d.phone;
        this.hash = d.hash;
        this.state = d.state;
        this.estimated_time_hours = d.estimated_time_hours;
        this.estimated_time_minutes = d.estimated_time_minutes;
        this.control_1 = d.control_1;
        this.control_2 = d.control_2;
        this.control_3 = d.control_3;
        this.receptor = d.receptor;
        this.receptor_user = d.receptor_user ? new M_User(d.receptor_user) : undefined;
        this.worker = d.worker;
        this.or = d.or ? new M_Action(d.or) : undefined;

        this.id_appointment = d.id_appointment;
        if (d.vehicle && d.vehicle.client) {
            this.vehicle = new M_Vehicle(d.vehicle);
            this.client = new M_Contact(d.vehicle.client);
        }
        this.date_appointment = new Date(d.date_appointment);
        if (d.tasks) {
            this.tasks = getArrayOf(M_TaskAppointment, d.tasks);
        }
    }
    get title_appointment(): string | undefined {
        return this.id_appointment;
    }

    get clientphone(): string | undefined {
        if (this.client) {
            return this.client.phone;
        }
        return this.phone;
    }

    get clientemail(): string | undefined {
        if (this.client) {
            return this.client.email;
        }
        return this.email;
    }

    get status(): AppointmentStatus {
        return this.state;
    }

    set setStatus(status: AppointmentStatus) {
        this.state = status;
    }

    get plate(): string {
        return this.license;
    }

    get brand(): string | undefined {
        return this.vehicle_brand;
    }

    get isClient(): boolean {
        return this.client != undefined;
    }

    get appointment_date(): Date {
        return this.date_appointment;
    }

    get isEditable() {
        return this.isPending;
    }

    get canRA(): boolean {
        return this.status != AppointmentStatus.canceled;
    }

    set day(v: Date) {
        this.date_appointment = v;
    }

    get reparations(): string[] {
        return this.tasks.map(t => t.task);
    }

    get origin() { return this.control_1 != undefined ? "Web" : "Eina"; }
    get isCanceled() { return this.status == AppointmentStatus.canceled; }
    get isPending() { return this.status == AppointmentStatus.pending; }
    get isDone() { return this.status == AppointmentStatus.done; }

    clientName(): string | undefined {
        return this.name_client;
    }

    clientNif(): string | undefined {
        if (this.client) {
            return this.client!.nif;
        }
        return '';
    }

    vehicleLicense(): string | undefined {
        if (this.vehicle) {
            return this.vehicle.license;
        }
        return this.license;
    }

    vehicleName(): string | undefined {
        if (this.vehicle) {
            return this.vehicle.getName();
        }
        return this.license ? this.license : this.vehicle_brand + ' ' + this.vehicle_model;
    }

    get itemId() {
        return this.id;
    }

    get notRegisteredClient() {
        let c = new M_Contact({});
        c.name = this.clientName() || "";
        c.surname = "";
        c.phone = this.phone;
        c.email = this.email;
        return c;
    }

    get notRegisteredVehicle(){
        let v = new M_Vehicle({});
        v.brand = this.brand;
        v.model = this.vehicle_model;
        v.license = this.license;
        return v;
    }

    defaultSearchFilter(val: string): boolean {
        val = val.toLocaleLowerCase();
        return match(val,
            this.id_appointment,
            this.date_appointment.shortFormat(),
            this.clientName(),
            this.clientNif(),
            this.clientemail ? this.clientemail : '',
            this.clientphone ? this.clientphone : '',
            this.vehicleLicense());
    }
}