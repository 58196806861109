import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Faqs } from '../../custom-classes/Onboarding/Faqs';

@Component({
  selector: 'lib-faqs',
  templateUrl: './faqs.component.html',
  styles:['.question{font-weight:500; font-size:14px; margin-bottom: 5px !important; display:flex; margin-top: 5px;letter-spacing: 1px;cursor: pointer;} .answer{margin-left:25px; font-size:13px} .hidden-faq{display: none !important;}']
})
export class FaqsComponent implements OnInit {
  @HostBinding('class') classes = 'faqsSelector';
  @Input() faq! : Faqs;
  show : boolean = false;
  constructor() { }
  ngOnInit(): void {}

  change(){
    this.show = !this.show;
  }

}
