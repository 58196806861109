<div class="container">

    <div class="title" *ngIf="loaded">
        <app-go-back class="back" [text]="'Listado pedidos'" [v]="v.orders" [show]="loaded"></app-go-back>
        <app-view-title class="nmb">Editar pedido</app-view-title>
    </div>

    <div class="order">
        <app-card [contentLoaded]="loaded" class="bc_b1">

            <app-advanced-details *ngIf="order" [showButton]="false">
    
                <app-advanced-title>
                    <div class="df aic gap5">
                        {{order.order}}
                        <app-order-status-label [status]="order.order_status"></app-order-status-label>
                    </div>
                </app-advanced-title>
    
                <app-advanced-subtitle>
                    {{order.created_at?.dayMonthYearFormat()}}
                </app-advanced-subtitle>
    
            </app-advanced-details>
    
            <app-advanced-details-footer [showTitle]="false" *ngIf="order && order.provider">
                <app-footer-right>
                    <div class="df gap5">
                        <app-contact-client-rectangle [client]="order.provider"></app-contact-client-rectangle>
                        <div class="card-wrapper-fit">
                            <mat-checkbox (change)="changeSend(order)" [(ngModel)]="order.send">Pedido
                                enviado</mat-checkbox>
                        </div>
                    </div>
                </app-footer-right>
            </app-advanced-details-footer>
    
    
        </app-card>
    </div>

    <app-card class="orderBreakdown">
        <ng-container *ngIf="!orderFailedToLoad">
            <ng-container *ngIf="order">
                <app-card-title>Desglose</app-card-title>
                <app-product-line-table [canDeleteLastProduct]="false" [products]="order.products"
                    [lineTypes]="['product']" [showSave]="true" [showDiscount]="false" [showProductsStock]="false"
                    [showLocation]="false" [showCost]="true" [showPVP]="false" [canModifyTable]="order.canEdit"
                    [showDest]="true" [extendedInfoConfig]="{
                        showColumn : true,
                        actions : ['quantity-by-stock-min']
                    }"></app-product-line-table>
                <div class="df jcc">
                    <button mat-flat-button color="primary" *ngIf="order.canEdit"
                        [disabled]="productLineTable != undefined && !productLineTable.hasChanges"
                        (click)="editOrder()">Guardar</button>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="orderFailedToLoad">
            <p>Pedido no encontrado</p>
        </ng-container>
    </app-card>

</div>