<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title>Faltas</p>

<!-- Dialog content -->
<div mat-dialog-content>
    <app-card [noStyle]="true" [contentLoaded]="loaded">
        <ng-container *ngIf="faults.length; else noFaults">
            <ng-container  *ngFor="let fault of faults">
                <app-fault [fault]="fault" [goFault]="true"></app-fault>
                <mat-divider></mat-divider>
            </ng-container>
        </ng-container>
        <ng-template #noFaults>
            <p class="c_t2">{{no_stock_message}}</p>
        </ng-template>
    </app-card>
</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button color="primary" mat-flat-button mat-dialog-close cdkFocusInitial>Ok</button>
</div>