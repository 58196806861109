<app-card [contentLoaded]="dragLoaded" [noStyle]="true" class="drag-card-parent">
    <div cdkDropListGroup *ngIf="dragLoaded">
        <app-drag-group #dragGroup
                        *ngFor="let g of action?.groups"
                        [group]="g"
                        [product_list]="product_list" 
                        [price_hour]="action!.price_hour"
                        [type]="action!.type_id"
                        [abono]="abono"
                        [isBudgetPage]="isBudgetPage"
                        [isRecambiosBudget]="isRecambiosBudget"
                        [canModifyGroup]="canModifyGroup(g)"
                        [canModifyTable]="canModifyTable(g)"
                        (onRemoveGroup)="onRemoveGroup($event)" 
                        (onStatusChange)="onStatusChange($event)"
                        (onTypeChangeEvent)="onTypeChange($event)"
                        (onInvoiceTask)="onInvoiceTask($event)"
                        (onDropEvent)="onDrop($event)" 
                        (onGroupChanges)="onSomeGroupChanges.emit()"
                        ></app-drag-group>
        <div class="mt20 tac">
            <p *ngIf="action?.groups?.length == 0 || action == undefined" class="c_t2">Todavía no has añadido ninguna intervención</p>
            <div>
                <button color="primary" mat-flat-button (click)="addNew()" *ngIf="!action?.isDone() && abono == false">
                    <mat-icon [filled]="true">splitscreen_add</mat-icon>
                    <span class="fw500 nmb">Nueva intervención</span>
                </button>
            </div>
        </div>
    </div>
</app-card>