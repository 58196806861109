import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

// Validador de SWIFT/BIC (8 o 11 caracteres alfanuméricos)
export function swiftValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const swiftRegex = /^[A-Z0-9]{8}([A-Z0-9]{3})?$/;
        if (control.value == null || control.value == "") { return null }
        return swiftRegex.test(control.value) ? null : { invalidSwift: true };
    };
}
