<app-card [noStyle]="true">
  <div class="df aic" *ngIf="!quickFilter && label">
    <app-card-subtitle>{{filter.label}}</app-card-subtitle>
  </div>
  <mat-chip-set multiple>
    <mat-chip *ngFor="let kv of filter.options | keyvalue; let i = index"
      [ngClass]="kv.value.hidden ? 'notShow' : filter.isSelected(i) ? 'chipSelected' : 'notSelected'"
      [selected]="filter.isSelected(i)" (click)="filter.toggleSelected(i); change.emit(i)" [value]="kv.key">
      <span matChipAvatar *ngIf="filter.circularPrefix" [class]="getClassName(i)" [ngClass]="{'circle' : true}"></span>
      <mat-icon *ngIf="kv.value.icon" matChipAvatar smartIcon class="mr5" [ngClass]="{'c_w' : filter.isSelected(i)}">{{kv.value.icon}}</mat-icon>
      {{kv.value.name}}
    </mat-chip>
  </mat-chip-set>
</app-card>