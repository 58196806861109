<div class="df" [ngClass]="{'box-shadow' : boxShadow, 'cp' : imageUpload, 'circle-disabled' : disabled}"
    (click)="uploadNewImage()" *ngIf="contentLoaded; else skeleton">
    <!-- Circle -->
    <div [class]="'relative-circle circle ' + (!hasImage || loadImgError? '_' + circleBackground : '')"
        [ngClass]="{'grayOut' : grayOut, 'small' : small, 'big' : big, 'medium' : medium}"
        [matTooltip]="this.showTooltip ? this.imageUpload ?  ('Subir nueva imagen') : this.userName : ''">
        <!-- If the user has image -->
        <img *ngIf="hasImage && !loadImgError" [src]="user!.img" (error)="imgError()">

        <!-- First charater of the user -->
        <span *ngIf="!hasImage || loadImgError " class="singleLetter" [ngClass]="{'me' : isMe && checkIsMe}">
            {{firstLetter}}
        </span>
        <mat-icon *ngIf="showEditIcon" class="edit-icon" [filled]="true">edit</mat-icon>
        <mat-icon *ngIf="showChangeIcon" class="edit-icon" [filled]="true">change_circle</mat-icon>
    </div>
    <!-- By default, the component shows the user full name -->
    <div class="df aic ml5" *ngIf="showFullName">
        <p class="nmb nametag">{{userName}}</p>
    </div>
</div>

<ng-template #skeleton>
    <app-skeleton class="pen" [circle]="true" [width]="small ? 24 : medium ? 100 : big ? 120 : 32"
        [height]="small ? 24 : medium ? 100 : big ? 120 : 32"></app-skeleton>
</ng-template>

<lib-file-upload style="display: none" [crop]="true" *ngIf="imageUpload"
    (stateChanged)="processNewUpload($event)"></lib-file-upload>