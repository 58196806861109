import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RouterService } from './router.service';


export interface ConfirmData {
  title: string,
  body: string | string[],
  type?: "confirm" | "warn" | "info" | "danger",
  confirmTxt?: string,
  cancelText?: string,
  showCancel?: boolean,
  disableClose?: boolean
  action?: "refresh"
}

@Injectable({
  providedIn: "root"
})

/**
 * [COMPONENT]
 * Simple dialog with title and body text.
 * @param data: { title : string, body : string, confirmTxt : string, cancelText : string, showCancel? : boolean, disableClose?: boolean }
 * Close values :
 *  True --> OK
 *  Flase --> Cancel
 */

@Component({
  selector: 'app-confirm-dialog',
  template: `
  <p mat-dialog-title>{{data.title}}</p>
  <div mat-dialog-content>
    <p *ngFor="let message of bodyAsArray">{{message}}</p>
  </div>
  <div mat-dialog-actions style="justify-content: center;">
    <button mat-button [mat-dialog-close]="false" *ngIf="data.showCancel != false">{{data.cancelText ? data.cancelText : 'Cancelar'}}</button>
    <button class="c_w confirm_button" mat-button (click)="onOk()">{{data.confirmTxt ? data.confirmTxt : 'Ok'}}</button>
  </div>`
})

export class ConfirmDialogService {

  bodyAsArray: string[] = [];

  constructor(public dialogRef: MatDialogRef<ConfirmDialogService>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmData, private dialog: MatDialog) {
    this.bodyAsArray = Array.isArray(this.data.body) ? this.data.body : [this.data.body];
  }


  show(v: ConfirmData): MatDialogRef<ConfirmDialogService> {
    var ref = this.dialog.open(ConfirmDialogService, {
      data: v,
      autoFocus: false,
      disableClose: v.disableClose,
      panelClass: !v.type || v.type == "confirm" ? "core-confirm-dialog" :
        v.type == "danger" ? "core-error-dialog" :
          v.type == "info" ? "core-info-dialog" : "core-warn-dialog"
    });
    return ref;
  }

  onOk() {
    if (!this.data.action) {
      this.dialogRef.close(true);
    }
    else {
      if (this.data.action == "refresh") {
        window.location.reload();
      }
    }
  }

  /** Normal webpage error. This function is usually called by the user */
  showError(title: string, ...error: string[]) {
    this.show({
      title: title,
      body: error,
      showCancel: false,
      confirmTxt: "Ok",
      type: "danger"
    });
  }

  /**
   * On DEV and PRE, show the full stacktracke of the error.
   * On PROD, the
   * @param error : HttpErrorResponse
   */
  showGenericHttpError(error: HttpErrorResponse) {
    this.show({
      title: this.randomErrorTitle,
      body: this.getMessageError(error),
      showCancel: false,
      confirmTxt: "Ok",
      type: "danger"
    });
  }

  get randomErrorTitle() {
    let errors = [
      "¡Oops! Parece que algo salió mal",
      "¡Oops! Desajuste detectado",
      "¡Uy! Un error inesperado ha aparecido",
      "¡Pum! Parece que se ha pinchado una rueda",
      "¡Ups! Algo se ha soltado en el motor",
      "¡Chispas! Algo se ha desajustado en el motor",
      "Se ha encendido una luz en el tablero. Vuelve a intentarlo.",
      "Esta página necesita un cambio de aceite. Inténtalo de nuevo.",
      "Parece que nos hemos desviado de la ruta. Vuelve a intentarlo.",
      "Este enlace se ha quedado sin gasolina.",
      "¡Vaya! Esta página parece estar fuera de pista.",
    ]
    let random = Math.floor(Math.random() * errors.length);
    return errors[random];
  }

  /** Try to get the best error message to show to the user */
  getMessageError(error: HttpErrorResponse) {
    console.log("Stringifyed error : ")
    console.log(JSON.stringify(error, ["message", "arguments", "type", "name"]))

    var defaultError = "Ayúdanos a mejorar tu experiencia enviándonos el error"
    var finalError: string | object = defaultError;

    if (error && error.error.errors) {
      for (const key in error.error.errors) {
        if (error.error.errors[key] && error.error.errors[key].length > 0) {
          return error.error.errors[key][0];
        }
      }
    }
    else if (error && error.error.error?.message) {
      if (error.error.error.message == "Unexpected end of JSON input") {
        return `Respuesta vacía en ${error.url}`
      }
      return error.error.error.message;
    }
    else if (error.error.message) {
      finalError = error.error.message
    }
    else if (error.error.error) {
      finalError = error.error.error
    }
    else if (error.message) {
      finalError = error.message;
    }

    if (typeof finalError == "object") {
      if (finalError instanceof ProgressEvent) {
        finalError = "No se ha podido procesar la solicitud";
      }
      else {
        finalError = JSON.stringify(finalError);
      }
    }

    return finalError;
  }
}
