import { Component, Input } from '@angular/core';
import { AppointmentStatus } from '../../enums/AppointmentStatus';

/**
 * Appointment status label
 */
@Component({
  selector: 'lib-appointment-status-label',
  templateUrl: './appointment-status-label.component.html',
  styleUrls: ['./appointment-status-label.component.css']
})
export class AppointmentStatusLabelComponent {
  /**The appointment status */
  @Input() status!: AppointmentStatus;
  /**Show the appointment status with a circular dot. */
  @Input() circularStatus: boolean = false;
  /**Show the status name ? */
  @Input() showText: boolean = false;
  /** Open menu icon */
  @Input() menuIconOpen: boolean = false;
  @Input() pointer: boolean = false;
  @Input() showIcon: boolean = true;

  constructor() { }

  get statusNum() {
    return this.status == AppointmentStatus.pending ? 0 : this.status == AppointmentStatus.done ? 1 : 2;
  }

  get statusText() {
    return this.status == AppointmentStatus.pending ? "Pendiente" :
      this.status == AppointmentStatus.done ? "Hecha" :
        "Cancelada";
  }

  get canceled() {
    return this.status == AppointmentStatus.canceled;
  }

  get pending() {
    return this.status == AppointmentStatus.pending;
  }

  get done() {
    return this.status == AppointmentStatus.done;
  }
}
