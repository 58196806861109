<div class="tbg">
  <div class="theader">
    <i class="fa fa-cog"></i>
    <i class="fa fa-comments"></i>
    <div class="tlogo">
      <img src="https://1000marcas.net/wp-content/uploads/2019/12/logo-Tinder.png" alt="Tinder Logo"
        title="Tinder Logo" />
    </div>
  </div>
  <app-hour-appointment-tndr [parentSubject]="parentSubject"></app-hour-appointment-tndr>
  <div class="tbgwrap">
    <div class="tcontrols">
      <div (click)='cardAnimation("swipeleft")' class="tno jcc aic"><img class="cncl"
          src="https://t4.ftcdn.net/jpg/05/38/49/71/360_F_538497120_KNVvLjOqc4XWl0JM2Vp9LoqEgjGneL1Y.png" width="80">
      </div>
      <div (click)='cardAnimation("swiperight")' class="tyes"><img class="fav"
          src="https://images.squarespace-cdn.com/content/v1/5ea5565e8a60162dcfc27a78/93098b64-7e88-48e5-9350-bfcc1131b75f/Heart.png"
          width="90"></div>
    </div>
  </div>
</div>