<div style="margin-top: 10px;"></div>
<app-page-structure class="paycomet-invoices" [autocompleteID]="'paycomet-invoices'" searchLabel="Buscar pago"
    [pageTitle]="''" listTitleText="" [data]="[]" [filters]="filters"
    [filter]="f" [displayedHeader]=" ['Referencia', 'Estado',    'Fecha',   'Precio', 'PDF']"
    [displayedColumns]="['reference',  'payed',     'created_at', 'amount','file']"
    [specialText]="     [undefined,    payedName,   undefined,    'money', havePDF]"
    [specialClass]="    [undefined,    payedClass,  undefined,   undefined, payedClass]"
    [listTitleText]="'Listado de pagos'" [showPagination]="true" [createButton]="undefined"
    [dots]="dots">
</app-page-structure>

<ng-template #dots>
    <ng-container *ngIf="ps.selected">
        <button *ngIf="ps.selected.file" (click)="previewPDF(ps.selected.file)" mat-menu-item color="primary" style="margin-bottom: 10px;">
            <mat-icon>save</mat-icon>
            Descargar PDF
        </button>
        <button *ngIf="!ps.selected.file" (click)="requestPDF(this.ps.selected.reference!)" mat-menu-item color="primary" style="margin-bottom: 10px;">
            <mat-icon>send</mat-icon>
            Pedir PDF
        </button>
    </ng-container>
</ng-template>