import { ViewPath } from "../app-routing.module";

export enum TimeLapseEnum {
    APPOINTMENT = 1,
    BUDGET = 2,
    COMERCIAL_BUDGET = 3,
    OR = 4,
    ALBARAN = 5,
    SELL_INVOICE = 6,
    BUY_INVOICE = 7,
}

export class M_Timelapse {
    id: number;
    name: string;
    object_type: TimeLapseEnum;
    date: Date | undefined;
    constructor(d: any) {
        this.id = d.id;
        this.name = d.name;
        this.object_type = d.object_type;
        this.date = d.date ? new Date(d.date) : undefined;
    }

    get icon(): string {
        switch (this.object_type) {
            case TimeLapseEnum.APPOINTMENT:
                return "event";
            case TimeLapseEnum.BUDGET:
            case TimeLapseEnum.COMERCIAL_BUDGET:
                return "calculate";
            case TimeLapseEnum.OR:
                return "build";
            case TimeLapseEnum.ALBARAN:
                return "assignment";
            case TimeLapseEnum.SELL_INVOICE:
                return "receipt_long";
            case TimeLapseEnum.BUY_INVOICE:
                return "shopping_basket";
        }
    }

    get view() {
        switch (this.object_type) {
            case TimeLapseEnum.APPOINTMENT:
                return ViewPath.editAppointment;
            case TimeLapseEnum.BUDGET:
                return ViewPath.createEditWorkshopBudget;
            case TimeLapseEnum.COMERCIAL_BUDGET:
                return ViewPath.createComercialBudget;
            case TimeLapseEnum.OR:
                return ViewPath.editOr;
            case TimeLapseEnum.ALBARAN:
                return ViewPath.createEditAlbaran;
            case TimeLapseEnum.SELL_INVOICE:
                return ViewPath.invoiceDetails;
            case TimeLapseEnum.BUY_INVOICE:
                return ViewPath.purchaseDetails;
        }
    }
}