import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Directive({
  selector: '[moreThan]'
})
export class MoreThanDirective implements OnInit {

  @Input('moreThan') moreThan!: number;


  constructor(private el: ElementRef<HTMLElement>, private responsiveS: ResponsiveService) {
    responsiveS.onResize.subscribe(val => {
      this.setDisplay(val > this.moreThan)
    })
  }
  
  ngOnInit(): void {
    this.setDisplay(this.responsiveS.w > this.moreThan);
  }

  setDisplay(display: boolean | undefined) {
    if (!display) {
      this.el.nativeElement.classList.add("dn_i")
    }
    else {
      this.el.nativeElement.classList.remove("dn_i")
    }
  }

}
