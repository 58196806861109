<div class="advanced-details-container">
    <app-card>
        <app-card-title>Exportación MTR</app-card-title>
        <form eForm>
            <div eForm-wrapper>
                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo</mat-label>
                        <mat-select [formControl]="type">
                            <mat-option [value]="'FR'">Facturas de venta</mat-option>
                            <mat-option [value]="'FC'">Facturas de compra</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Centro</mat-label>
                        <mat-select [formControl]="center_id">
                            <mat-option *ngFor="let center of einaDataS.company.centers" [value]="center.id">
                                {{ center.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Desde</mat-label>
                        <input matInput [matDatepicker]="picker_1" [max]="dataEnd.value" [formControl]="dataIni"
                            placeholder="dd/mm/yyyy" (click)="picker_1.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker_1"></mat-datepicker-toggle>
                        <mat-datepicker #picker_1></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Hasta</mat-label>
                        <input matInput [matDatepicker]="picker_2" [min]="dataIni.value" [formControl]="dataEnd"
                            placeholder="dd/mm/yyyy" (click)="picker_2.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker_2"></mat-datepicker-toggle>
                        <mat-datepicker #picker_2></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <p></p>
        <div class="df jcc">
            <button [disabled]="!isOk" mat-flat-button color="primary" (click)="export()">Exportar</button>
        </div>
    </app-card>
</div>