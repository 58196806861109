<app-close-dialog-button></app-close-dialog-button>

<!-- ======= ERROR =========-->
<ng-container *ngIf="!data; else successBlock">
    <p mat-dialog-title>Parece que ha habido un problema con tu pago.</p>
    <mat-dialog-content>
        <p>Lamentamos informarte que hemos detectado un inconveniente al procesar tu registro debido a un error en el pago.</p>
        <p>Para ayudarte a completar tu registro, te recomendamos que te pongas en contacto con nuestro equipo de soporte.
            Estamos aquí para asistirte en la resolución de este inconveniente y responder a cualquier pregunta que puedas
            tener.
            <app-contact-us-text></app-contact-us-text>
        </p>
        <p>Agradecemos tu paciencia y comprensión mientras trabajamos para solucionar esta situación y
            esperamos tenerte como parte de nuestra comunidad muy pronto.
            Tu satisfacción es de suma importancia para nosotros,
            y haremos todo lo posible para garantizar que tu registro sea exitoso.
        </p>
        <p>Gracias por tu interés y por elegirnos.</p>
    </mat-dialog-content>
</ng-container>

<!-- ======= SUCCES =========-->
<ng-template #successBlock>
    <p mat-dialog-title>¡Compra realizada con éxito!</p>
    <mat-dialog-content>
        <p>Gracias por confiar en nuestros servicios. Valoramos tu apoyo y estamos aquí para ayudarte en lo que necesites.
        </p>
        <p>Esperamos que disfrutes de nuestros servicios al máximo. Si tienes preguntas, no dudes en contactarnos.</p>
        <app-contact-us-text></app-contact-us-text>
        <p>¡Gracias nuevamente por elegirnos!</p>
    </mat-dialog-content>
</ng-template>

<!-- SHHARED ACTIONS ON ERROR AND SUCCES-->
<mat-dialog-actions align="center">
    <button mat-flat-button color="primary" mat-dialog-close>Ok</button>
</mat-dialog-actions>