<div [formGroup]="form ? form : fg">
    <input type="file" #input formControlName="{{formCName ? formCName : 'none_field'}}" [required]="required"
        class="vh dn" type="file" [accept]="acceptedExtensions" [multiple]="multiple"
        (change)="crop ? openCropDialog(input) : uploadS.processFile(input, this, acceptedTypes); input.value=''">
</div>

<div class="componentInput" [ngSwitch]="uploadtype">
    <!-- SINGLE -->
    <div *ngSwitchCase="'single'">
        <div class="imageContainer" *ngIf="acceptedTypes.includes(ft.image)">
            <div *ngIf="this.hasFiles() && !a.isArray(uploadedFiles)">
                <img class="companyLogo" [src]="this.uploadedFiles!.src" (click)="showPreview(this.uploadedFiles)">
                <button type="button" mat-fab (click)="clickInputTrigger()">
                    <mat-icon class="iconGray">edit</mat-icon>
                </button>
            </div>
            <div *ngIf="!this.hasFiles()" class="matIconUploadParent {{getClassRequiredForm()}}"
                (click)="clickInputTrigger()">
                <mat-icon style="transform: scale(5); color: gray;">upload</mat-icon>
            </div>
            <div *ngIf="showMax">
                <p class="fss tac c_gray" style="margin-top: 5px">{{getMax()}}</p>
            </div>
        </div>
        <div *ngIf="!acceptedTypes.includes(ft.image)">
            <lib-uploaded-file *ngIf="this.hasFiles() && !a.isArray(uploadedFiles)" [canDelete]="canDelete"
                [cf]="uploadedFiles!" [selected]="uploadedFiles!.id == selected"></lib-uploaded-file>
            <button (click)="clickInputTrigger()" mat-flat-button>
                <mat-icon>file_upload</mat-icon>
                Subir archivo
            </button>
        </div>
    </div>
    <!-- MULTIPLE -->
    <div *ngSwitchCase="'multiple'">
        <div *ngIf="a.isArray(uploadedFiles)">
            <div class="df fww">
                <div *ngFor="let cf of uploadedFiles">
                    <lib-uploaded-file [canDelete]="canDelete" [cf]="cf"
                        [principal]="cf.id == principal"></lib-uploaded-file>
                </div>
            </div>
            <div class="add jcc" *ngIf="canAddImages">
                <div (click)="clickInputTrigger()" mat-icon-button class="cp df fdc aic jcc c_g pr"
                    style="height : 100%">
                    <div class="mb10">
                        <mat-icon class="iconBig">add_circle_outline</mat-icon>
                    </div>
                    <div>
                        <p class="fsm">{{getText()}}</p>
                    </div>
                    <div *ngIf="showMax">
                        <p class="fss">{{getMax()}}</p>
                    </div>
                    <div class="counter">{{getMaxLength()}}</div>
                </div>
            </div>
        </div>
    </div>
    <!-- DRAG AND DROP -->
    <div *ngSwitchCase="'drag'">
        <div class="dropZone tac df column aic jcc" dragAndDrop
            (fileDropped)="uploadS.onFileDropped($event, this, acceptedTypes)" *ngIf="a.isArray(uploadedFiles)">
            <div *ngIf="uploadedFiles?.length == 0">
                <p>{{dragPlaceHolder}}</p>
                <p *ngIf="showMax" class="c_gray fss">{{getMax()}}</p>
            </div>
            <div class="df row wrap jcs w100 jcc">
                <div *ngFor="let cf of uploadedFiles">
                    <lib-uploaded-file [canDelete]="canDelete" [cf]="cf" [principal]="cf.id == principal"
                        [selected]="cf.id == selected"></lib-uploaded-file>
                </div>
            </div>
            <button *ngIf="canAddImages" [disabled]="isOnMaximum()" mat-raised-button class="scb mt-20"
                (click)="clickInputTrigger()">{{getText()}}</button>
        </div>
        <div class="counterDrag c_gray">{{getMaxLength()}}</div>
    </div>
</div>