<h1 mat-dialog-title>Términos y condiciones de uso</h1>
<div mat-dialog-content>

    <h1 style="color: var(--t); font-weight: bold;">Condiciones de uso</h1>
    Serveis Informatics d’Igualda SL (en adelante Sinigual), es un proveedor de servicio online para gestionar
    diferentes aspectos de un negocio.
    Las presentes condiciones exponen los derechos y obligaciones del usuario y de Sinigual como proveedor del servicio.
    Sinigual supondrá que cualquier usuario que utilice el servicio, ha leído y aceptado las condiciones de uso, además
    Sinigual se reserva el derecho de actualizar y modificar las Condiciones de uso sin ningún tipo de aviso previo,
    estando éstos disponibles siempre en la dirección web www.sinigual.com.Sinigual, con NIF B59034363 y domicilio en la
    calle Av. Dr. Pasteur, 66, 08700 Igualada (BARCELONA), inscrita en el Registro Mercantil de Barcelona, proporciona
    el sitio web EINA y sus servicios relacionados de acuerdo con los términos establecidos a continuación.
    El presente acuerdo tendrá validez a partir de la fecha en la que marque la casilla de aceptación de este acuerdo
    que aparece en el pie de este documento. Las nuevas características que pudieran añadirse a los Servicios estarán
    sujetas a las Condiciones de uso. En caso de que el usuario continúe haciendo uso del Servicio después de cualquier
    modificación, supondrá su conformidad con dichas modificaciones. Siempre se podrá consultar la versión más reciente
    de las condiciones de uso en http://www.sinigual.com/eina/terminos_y_condiciones.El incumplimiento de cualquiera de
    las Condiciones de uso podrá ser causa de anulación de su cuenta

    <div class="mb20"></div>

    <p class="fwb">Condiciones de la cuenta</p>
    Para acceder al servicio es imprescindible ser mayor de dieciséis años (16).
    Se deberá proporcionar un nombre legal completo, una dirección de correo electrónico, y toda la información
    requerida por Sinigual en el proceso de creación de la cuenta.
    Es responsabilidad del usuario proporcionar información veraz, y Sinigual se reserva el derecho de eliminar
    cualquier cuenta, si se sospecha de su veracidad o pudiera incumplir alguna de las normas de uso de Sinigual.
    El usuario es responsable de mantener la privacidad de su cuenta. Sinigual no se hará responsable de cualquier daño
    o pérdida que pueda ser consecuencia de un error del usuario al proteger su información de acceso.
    Las partes aceptan equiparar jurídicamente la firma autógrafa del cliente a la realizada mediante cualquier otro
    tipo de clave, código o elemento de seguridad identificativo. Sin perjuicio de lo anterior el prestador de los
    Servicios podrá exigir cuando lo considere necesario confirmación por escrito al cliente


    <div class="mb20"></div>

    <p class="fwb">Tipos de contrato</p>
    La contratación de un plan de pago de Sinigual se inicia en el momento en el que el usuario introduce los datos de
    la tarjeta y realiza un primer pago. En ningún caso se realizarán abonos o devoluciones.
    El cliente es responsable de cualquier contratación y por tanto de evaluar todas y cada una de las funcionalidades.
    No se realizan desarrollos a medida y cualquier información sobre nuevas funcionalidades es meramente informativa y
    no tendrá ningún vínculo contractual
    Una vez contratado un plan de pago, éste se renovará automáticamente en la fecha de renovación hasta que el usuario
    elimine la cuenta.
    Sinigual ofrece un sistema de tarifas que se adapta al uso de cada usuario. Los límites de cada tarifa se
    especifican en el apartado de precios en www.sinigual.com/eina/precios.
    Sinigual se reserva el derecho de modificar las tarifas de forma unilateral y en cualquier momento, sin otorgar
    dicho cambio ningún derecho a indemnización por parte de los usuarios

    <div class="mb20"></div>

    <p class="fwb">Pago y acceso</p>
    Es necesaria una tarjeta bancaria válida para realizar los pagos. Ésta no se requiere para crear o usar una cuenta
    gratuita. Sinigual le cobrará de forma recurrente una cuota periódica en función del tipo de cuenta que tenga
    contratada.
    El Servicio se cobra cada periodo por adelantado, y no es reembolsable. No habrá reembolsos o créditos por meses
    parciales del Servicio, ni se harán reembolsos en caso de que el usuario no haga uso del Servicio durante el periodo
    de tiempo en que la cuenta se encuentre abierta. En ningún caso se harán excepciones.
    El cliente está de acuerdo en que no será necesario confirmar la recepción de la aceptación del contrato cuando éste
    se haya celebrado exclusivamente mediante comunicación electrónica.
    En caso de que el usuario cambie la contratación, no se realizará un cargo en su tarjeta con el nuevo importe hasta
    la próxima fecha de facturación. A partir de este momento, en la próxima facturación se le realizará un cargo en su
    tarjeta por el nuevo importe, a no ser que se cancele la cuenta.
    En caso de impago o devolución del recibo, se le notificará el impago y perderá automáticamente la capacidad de
    acceder al Servicio. Los datos serán eliminados pasados treinta (30) días desde la fecha del impago.
    Ninguna cuota incluye los impuestos u obligaciones exigidos por sus autoridades gubernamentales. El usuario es
    responsable del pago de estos impuestos u obligaciones. El usuario decide quién tiene derecho a entrar en su cuenta
    con el rol que considere oportuno


    <div class="mb20"></div>

    <p class="fwb">Modificaciones del servicio y de los planes</p>
    Sinigual se reserva el derecho de modificar o suspender, temporal o permanentemente, el Servicio en cualquier
    momento por cualquier razón con o sin aviso si lo considera conveniente. Sinigual se reserva el derecho a cambiar
    las cuotas mensuales con un preaviso de 30 días. Las notificaciones del cambio de cuotas se publicarán en el sitio
    web de Sinigual y por escrito.

    <div class="mb20"></div>

    <p class="fwb">Cancelación y rescisión</p>
    El usuario es responsable de la adecuada cancelación de su cuenta. Puede cancelar su cuenta en cualquier momento a
    través del menú de Configuración > Cuenta > Eliminar. Una vez eliminada su cuenta, todo su contenido será eliminado
    al cabo de sesenta (60) días. El usuario puede cancelar su cuenta en cualquier momento, pero se hará responsable de
    todos los cobros realizados hasta ese instante, incluido el cargo mensual completo por el mes en que suspende el
    servicio. A partir de ese momento, no se le realizará ningún otro cargo. Sinigual se reserva el derecho de cancelar
    una cuenta o impedir el uso del Software a quien incumpla las presentes condiciones de uso.

    <div class="mb20"></div>

    <p class="fwb">Derecho de copia y propiedad intelectual</p>
    Sinigual posee todos los derechos de la Propiedad Intelectual de todos y cualquiera de los componentes del Servicio
    que puedan protegerse, incluyendo, pero no limitándose al nombre del Servicio, material gráfico, todo el software
    asociado con el Servicio y los elementos de la interfaz de usuario contenidos en el Servicio, muchas de las
    características individuales y la documentación relacionada. El usuario se compromete a no copiar, adaptar,
    reproducir, distribuir, aplicar ingeniería inversa, descompilar, o disimular cualquier faceta del Servicio que
    Sinigual posea. El usuario también acepta y se obliga a no utilizar robots, spiders, otros aparatos automatizados, o
    procesos manuales para controlar o copiar cualquier contenido del Servicio. Sinigual no reclamará derechos sobre la
    Propiedad Intelectual del Contenido que el usuario suba o proporcione al Servicio. Sin embargo, utilizando el
    Servicio para enviar contenido, el usuario acepta que terceros puedan ver y compartir este contenido enviado por el
    usuario.

    <div class="mb20"></div>

    <p class="fwb">Condiciones generales</p>
    El usuario es completamente responsable del acceso y correcto uso de Sinigual con sujeción a la legalidad vigente,
    sea nacional o internacional, así como a los principios de buena fe, a la moral, buenas costumbres y orden público.
    Y específicamente, adquiere el compromiso de observar diligentemente las presentes Condiciones generales de uso.
    El usuario acepta no revender, duplicar, reproducir o explotar cualquier parte del Servicio sin el expreso
    consentimiento escrito de Sinigual. El usuario no puede usar el Servicio para almacenar, hospedar, o enviar correo
    electrónico dañino o no solicitado (spam).
    El usuario entiende que el Servicio puede utilizarse para la transmisión de su Contenido, y que durante este
    proceso, su Contenido, incluidas facturas, avisos de pago, y mensajes personales, puede transferirse sin encriptar a
    través de Internet. El usuario no
    Las Condiciones de uso establecen un entendimiento absoluto entre el usuario y Sinigual con respecto al Servicio y
    prevalecen ante cualquier acuerdo previo alcanzado entre el usuario y Sinigual .Las Condiciones, y su relación con
    Sinigual con arreglo a estas Condiciones, se regirá por las leyes de España. el usuario y Sinigual aceptan someterse
    a la jurisdicción exclusiva de los tribunales de Barcelona para resolver cualquier cuestión jurídica en relación con
    las Condiciones.

    <div class="mb20"></div>

    <p class="fwb">Seguridad y privacidad</p>
    Serveis Informatics d’Igualada S.L se encuentra debidamente inscrita en la Agencia Española de Protección de Datos
    cumpliendo así la normativa vigente sobre confidencialidad y seguridad de los datos confidenciales.
    Los datos que los usuarios introducen en Sinigual se encuentran alojados en los servidores de okITup en la Unión
    Europea, garantizando así tanto la normativa en alojamiento de datos como los más altos niveles de seguridad y
    accesibilidad del mercado.
    Serveis Informatics d’Igualada S.L y sus empleados tienen acceso los datos de las cuentas con el objetivo de poder
    mejorar el servicio así como de ofrecer un buen servicio de soporte. Todos los empleados y socios de Sinigual tienen
    firmado un contrato de confidencialidad en cumplimiento con las normativas de privacidad inclusive la GDPR.

    <div class="mb20"></div>

    <p class="fwb">Política de devoluciones</p>
    Suscripciones Mensuales: Si en algún momento durante los primeros 30 días utilizando Sinigual no estás satisfecho
    con el servicio, por favor contacta con nosotros en sinigual@sinigual.com. Haremos todo lo posible para encontrar
    una solución a tus necesidades, y si no fuera posible, te devolveremos el dinero de la primera mensualidad.
    Suscripciones Anuales: Sinigual no obliga a contratar de forma anual. Preferimos que puedas elegir. A cambio de
    contratar la suscripción anual y como agradecimiento al compromiso ofrecemos un importante descuento a unas tarifas
    ya muy competitivas. Si durante los primeros 30 días tras la contratación de tu suscripción anual no estás
    satisfecho con el servicio, por favor contacta con nosotros en sinigual@sinigual.com. Haremos todo lo posible para
    encontrar una solución a tus necesidades, y si no fuera posible, te devolveremos el dinero.
    Renovación de las suscripciones: Para tu comodidad, las suscripciones se renuevan automáticamente hasta que canceles
    la suscripción. Sabemos que a veces los clientes se olvidan de una suscripción que no desean renovar y que ya se ha
    cobrado, por eso ofrecemos 7 días, a contar desde la renovación, durante los cuales te devolveremos el dinero de la
    suscripción renovada si nos lo solicitas a través del referido apartado sinigual@sinigual.com.
    Excepción de nuestra política de devoluciones: No aceptaremos solicitudes de devoluciones de usuarios a los que les
    hayamos suspendido o resuelto el servicio por el incumplimiento de nuestros términos y condiciones.


    <div class="mb20"></div>



    <h1 style="color: var(--t); font-weight: bold;">Política de Privacidad</h1>
    <p class="fwb">Introduccion</p>
    La política de privacidad de Sinigual sigue la legislación internacional y europea (GDPR) sobre protección de datos.
    Además, Sinigual pretende informar al usuario a través del presente documento de sus derechos y obligaciones
    respecto de la privacidad de sus datos, además de explicar el porqué del almacenamiento y uso de los datos.

    <div class="mb20"></div>

    <p class="fwb">Datos recogidos por Sinigual</p>
    Sinigual recoge toda la información introducida en la aplicación por el usuario y la almacena en sus servidores
    propios o en los servidores del proveedor de almacenamiento de Sinigual. Al tratarse de un servicio Cloud, el
    almacenamiento en servidores es un requisito para el funcionamiento de la aplicación, es por ello que el usuario
    acepta tal hecho.
    Sinigual clasifica los datos en dos categorías: datos de usuario y datos de cuenta. Los datos de usuario comprenden
    el nombre del usuario y los datos personales, así como fotografías o demás documentación que el usuario decida subir
    a la aplicación en la parte de información de cuenta y la información de contacto. Los datos de cuenta son aquellos
    relacionados con la cuenta de negocio creada por el usuario, en cualquiera de sus diferentes formas: Autónomo,
    Sociedad Civil Personal o Sociedad Limitada, que comprenden datos de facturación, gastos, contactos, trabajadores y
    productos.
    Por último, Sinigual recoge datos del dispositivo de conexión a través de cookies. El usuario puede desactivar las
    cookies en su navegador para que Sinigual no recoja dicha información en caso de que el usuario no quiera que así
    sea. Dicha información es utilizada para realizar estudios de navegación y acceso a la aplicación, así como del uso
    de la misma. La información de navegación comprende el tipo de dispositivo y sus características, la ubicación y los
    tiempos de conexión.

    <div class="mb20"></div>

    <p class="fwb">¿Quién es el responsable del tratamiento de sus datos?</p>
    Identidad: Serveis Informatics d’Igualda SL
    Dirección Postal: Av. Dr. Pasteur, 66, 08700 Igualada (BARCELONA)
    Correo electrónico: sinigual@sinigual.com
    ¿Con qué finalidad tratamos sus datos personales?
    En Serveis Informatics d’Igualda SL tratamos la información que nos facilitan las personas interesadas con el fin de
    prestar y/o comercializar los productos y/o servicios ofertados por nuestra firma.
    ¿Por cuánto tiempo conservaremos sus datos?
    Los datos personales proporcionados se conservarán por el tiempo imprescindible para la prestación del servicio
    solicitado o comercialización del producto y durante los plazos legalmente establecidos.
    ¿Cuál es la legitimación para el tratamiento de sus datos?
    La base legal para el tratamiento de sus datos es la ejecución de un contrato o si no es el caso, será el
    consentimiento del interesado.
    ¿A qué destinatarios se comunicarán sus datos?
    Los datos no se comunicarán a terceros salvo obligación legal.
    ¿Cuáles son sus derechos cuando nos facilita sus datos?
    Cualquier persona tiene derecho a obtener información sobre si en Serveis Informatics d’Igualda S.L estamos tratando
    datos personales que les conciernan, o no.
    Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de
    los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean
    necesarios para los fines que fueron recogidos.
    En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo
    caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.
    En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán
    oponerse al tratamiento de sus datos. En este caso, Serveis Informatics d’Igualda S.L dejará de tratar los datos,
    salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.
    Podrá ejercitar materialmente sus derechos de la siguiente forma: enviando un correo electrónico a
    sinigual@sinigual.com identificándose debidamente e indicando de forma expresa el concreto derecho que se quiere
    ejercer.
    Si ha otorgado su consentimiento para alguna finalidad concreta, tiene derecho a retirar el consentimiento otorgado
    en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su
    retirada.
    En caso de que sienta vulnerados sus derechos en lo concerniente a la protección de sus datos personales,
    especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, puede presentar una reclamación
    ante la Autoridad de Control en materia de Protección de Datos competente a través de su sitio web: www.agpd.es

    <div class="mb20"></div>

    <p class="fwb">¿Cómo hemos obtenido sus datos?</p>
    Los datos personales que tratamos en Serveis Informatics d’Igualda S.L proceden de las categorías de datos que se
    tratan son: No se tratan categorías especiales de datos personales.
    Datos compartidos por el usuario
    Muchos de nuestros servicios te permiten compartir información con otros usuarios. Recuerda que, cuando compartes
    información públicamente, esta puede ser indexada por motores de búsqueda. Nuestros servicios te proporcionan
    diferentes opciones sobre cómo compartir y eliminar tu contenido. Cómo acceder a tus datos personales y
    actualizarlos siempre que utilizas nuestros servicios, nuestro objetivo consiste en proporcionarte acceso a tu
    información personal. Si esa información no es correcta, nos esforzamos para proporcionarte formas de eliminarla o
    actualizarla rápidamente, a menos que tengamos que mantener esa información por motivos legaleso empresariales
    legítimos. Al actualizar tu información personal, podremos pedirte que verifiques tu identidad para que podamos
    procesar tu solicitud.
    Podremos rechazar solicitudes que sean más repetitivas de lo razonable, que requieran un esfuerzo técnico
    desproporcionado (por ejemplo, desarrollar un nuevo sistema o cambiar de forma radical una práctica existente),que
    pongan en peligro la privacidad de otros usuarios o que no sean nada prácticas (por ejemplo, solicitudes que hagan
    referencia a información almacenada en sistemas de copia de seguridad). Cuando podamos ofrecerte la posibilidad de
    acceder a tus datos personales y modificarlos, lo haremos de forma gratuita, salvo que ello requiera un esfuerzo
    desproporcionado.
    Al prestar nuestros servicios, protegeremos tus datos procurando que no puedan ser eliminados de forma accidental o
    intencionada. Por este motivo, aunque elimines tus datos de nuestros servicios, es posible que no destruyamos de
    inmediato las copias residuales almacenadas en nuestros servidores activos ni los datos almacenados en nuestros
    sistemas de seguridad.
    Acceso y modificación de los datos del usuario
    El usuario podrá modificar en cualquier momento sus datos de usuario o de las cuentas de las que es propietario.
    Sinigual no almacena la información modificada, de forma que una vez el usuario modifique o elimine la información,
    la perderá para siempre dentro de la aplicación.
    Los datos que compartimos
    No compartimos información personal con empresas, organizaciones ni particulares que no tengan relación con
    Sinigual, a menos que se dé alguna de las siguientes circunstancias:
    Consentimiento: Compartiremos tus datos personales con empresas, organizaciones o personas físicas ajenas a Sinigual
    cuando nos hayas dado tu consentimiento para hacerlo. Tu consentimiento será necesario para compartir datos
    personales especialmente protegidos.
    Tratamiento externo: Proporcionamos información personal a nuestros afiliados o a otras personas o empresas de
    confianza para que lleven a cabo su procesamiento por parte de Sinigual, siguiendo nuestras instrucciones y de
    conformidad con nuestra Política de privacidad, y adoptando otras medidas de seguridad y confidencialidad adecuadas.
    Motivos legales: Compartiremos tus datos personales con empresas, organizaciones o personas físicas ajenas a
    Sinigual si consideramos de buena fe que existe una necesidad razonable de acceder a dichos datos o utilizarlos,
    conservarlos o revelarlos para: cumplir cualquier requisito previsto en la legislación o normativa aplicable o
    atender cualquier requerimiento de un órgano administrativo o judicial, cumplir lo previsto en las Condiciones de
    servicio vigentes, incluida la investigación de posibles infracciones, detectar o impedir cualquier fraude o
    incidencia técnica o de seguridad o hacerles frente de otro modo, proteger los derechos, los bienes o la seguridad
    de Sinigual, de nuestros usuarios o del público en general en la medida exigida o permitida por la legislación
    aplicable.
    Seguridad de los datos
    Para Sinigual la seguridad es lo más importante. Actualmente el servicio de almacenamiento de datos se realiza a
    través de proveedores especializados con certificados de seguridad y sistemas anti hacking. Sinigual ha decidido
    externalizar el almacenamiento para asegurarse que el proveedor cumple los más altos estándares de seguridad, en
    niveles que Sinigual no podría ofrecer en servidores de almacenamiento propios.
    Nos esforzamos por proteger a Sinigual y a nuestros usuarios frente a cualquier modificación, divulgación o
    destrucción no autorizada de los datos que conservamos o frente al acceso no autorizado a los mismos. En particular:
    Encriptamos muchos de nuestros servicios mediante el protocolo SSL. Revisamos nuestra política en materia de
    recogida, almacenamiento y tratamiento de datos, incluyendo las medidas de seguridad físicas, para impedir el acceso
    no autorizado a nuestros sistemas. Limitamos el acceso de los contratistas, los agentes y los empleados de Sinigual
    a la información personal que deben procesar para Sinigual y nos aseguramos de que cumplan las estrictas
    obligaciones de confidencialidad contractuales y de que estén sujetos a las condiciones disciplinarias pertinentes o
    al despido si no cumplen dichas obligaciones. Cuándo se aplica esta Política de privacidad Nuestra Política de
    privacidad se aplica a todos los servicios ofrecidos por Sinigual y sus filiales, incluidos Sinigual, pero excluye
    aquellos servicios que estén sujetos a políticas de privacidad independientes que no incorporen la presente Política
    de privacidad.
    Aplicación de la política de privacidad
    Nuestra Política de privacidad se aplica a todos los servicios ofrecidos por Sinigual y sus filiales, incluidos
    Sinigual, pero excluye aquellos servicios que estén sujetos a políticas de privacidad independientes que no
    incorporen la presente Política de privacidad.
    Cumplimiento de la ley
    En Sinigual verificamos el cumplimiento de nuestra Política de privacidad de forma regular. Asimismo, nos adherimos
    a diferentes códigos de autorregulación. En caso de que recibamos una reclamación formal por escrito, nos pondremos
    en contacto con la persona que la haya formulado para hacer un seguimiento de la misma. Trabajaremos con las
    autoridades reguladoras competentes, incluyendo las autoridades locales de protección de datos, para resolver
    cualquier reclamación relacionada con la transferencia de datos de carácter personal que no hayamos podido
    solucionar directamente con el usuario.
    Modificaciones
    Nuestra Política de privacidad se podrá modificar en cualquier momento. No limitaremos los derechos que te
    corresponden con arreglo a la presente Política de privacidad sin tu expreso consentimiento. Publicaremos todas las
    modificaciones de la presente Política de privacidad en esta página y, si son significativas, efectuaremos una
    notificación más destacada (por ejemplo, te enviaremos una notificación por correo electrónico si la modificación
    afecta a determinados servicios). Además, archivaremos las versiones anteriores de la presente Política de
    privacidad para que puedas consultarlas.

    <div class="mb20"></div>

    <p class="fwb">Más información sobre el tratamiento de sus datos</p>
    Cuando precisemos obtener información por su parte, siempre le solicitaremos que nos la proporcione voluntariamente
    prestando su consentimiento de forma expresa a través de los medios habilitados para ello.
    El tratamiento de los datos recabados a través de los formularios de recogida de datos del sitio web u otras vías,
    quedará incorporado al Registro de Actividades de Tratamiento del cual es responsable Serveis Informatics d’Igualda
    S.L.
    Serveis Informatics d’Igualda S.L trata los datos de forma confidencial y adopta las medidas técnicas y
    organizativas apropiadas para garantizar el nivel de seguridad adecuado al tratamiento, en cumplimiento de lo
    requerido por el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 y demás
    normativa aplicable en materia de Protección de Datos.
    No obstante, Serveis Informatics d’Igualda S.L no puede garantizar la absoluta invulnerabilidad de los sistemas, por
    tanto, no asume ninguna responsabilidad por los daños y perjuicios derivados de alteraciones que terceros puedan
    causar en los sistemas informáticos, documentos electrónicos o ficheros del usuario.
    Si opta por abandonar nuestro sitio web a través de enlaces a sitios web no pertenecientes a nuestra entidad,
    Serveis Informatics d’Igualda S.L no se hará responsable de las políticas de privacidad de dichos sitios web ni de
    las cookies que éstos puedan almacenar en el ordenador del usuario.
    Nuestra política con respecto al envío de nuestros correos electrónicos se centra en remitir únicamente
    comunicaciones que usted haya solicitado recibir. Si prefiere no recibir estos mensajes por correo electrónico le
    ofreceremos a través de los mismos la posibilidad de ejercer su derecho de supresión y renuncia a la recepción de
    estos mensajes, en conformidad con lo dispuesto en el Título III, artículo 22 de la Ley 34/2002, de Servicios para
    la Sociedad de la Información y de Comercio Electrónico.
    <div class="mb20"></div>

</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Cerrar</button>
    <button mat-flat-button color="primary" [mat-dialog-close]="true">Aceptar</button>

</div>