import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseInput } from './base-input';


/**
 * [Component]
 * Input para introducir un email.
 * Si se introduce un valor que no es un email, se muestra un error automáticamente.
 */

@Component({
  selector: 'app-email-input',
  styleUrls: [],
  template:` <div class="{{class_}}" [formGroup]="form">
              <mat-form-field  appearance="outline">
                  <mat-label>{{label ? label : 'Email'}}</mat-label>
                  <input matInput type="email" 
                        formControlName="{{formCName}}" 
                        [required]="required" 
                        [disabled]="disabled" 
                        [attr.autocomplete]="disableAutocomplete ? 'off' : null"
                        [placeholder]="placeHolder? placeHolder : 'Introduce el email'">
                  <mat-error *ngIf="hasErrors()">{{getError_()}}</mat-error>
              </mat-form-field>
            </div>`
})
export class EmailInputComponent extends BaseInput implements OnInit {
  @HostBinding('class') classes = 'emailInput ' + this.classSelector;
  constructor(changeDetectorr: ChangeDetectorRef) {
    super(changeDetectorr)
  }

  override ngOnInit(): void {
    this.control!.setValidators([Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$")])
    if (this.required) {
      this.control?.addValidators([Validators.required])
    }
  }

  setRepeatedError(){
    this.setErrors({repeated:true})
  }
  
  getError_(){
    if (this.control?.errors != undefined && this.control?.errors['repeated'] != undefined){
      return "Esta dirección de correo ya existe"
    }
    return "Introduce una dirección de correo válida"
  }

}
