import { CustomFile } from "../custom-classes/CustomFile";
import { IPageStructureItem } from "../interfaces/IPageStructureItem";
import { match } from "../services/search.service";

export class M_PayCometInvoice implements IPageStructureItem {
    id: number;
    company_id: number;
    amount: number;
    payed: number;
    authcode: string | null;
    reference: string | undefined;
    error: number;
    created_at: Date;
    updated_at: Date;
    file: CustomFile | undefined;


    constructor(data: any) {
        this.id = data.id;
        this.company_id = data.company_id;
        this.amount = data.amount;
        if (this.amount) { this.amount /= 100; }
        this.payed = data.payed;
        this.authcode = data.authcode;
        this.reference = data.reference;
        this.error = data.error;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.file = data.file ? new CustomFile(data.file, undefined) : undefined;

    }

    get itemId() {
        return this.id;
    }

    defaultSearchFilter(text: string): boolean {
        return match(text, this.reference, this.amount.toString());
    }
}
