import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { ActivatedRoute } from '@angular/router';
import { M_Product } from 'src/app/models/Products/M_Product';
import { ApiService } from 'src/app/services/Api/api.service';
import { ViewPath } from 'src/app/app-routing.module';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { M_StoreHouse } from 'src/app/models/M_StoreHouse';
import { MatTabGroup } from '@angular/material/tabs';
import { ParamsService } from 'src/app/services/params.service';
import { MovTypeEnum } from 'src/app/enums/MovTypeEnum';
import { AddRemoveComponent } from '../../products/add-remove/add-remove.component';
import { M_Location } from 'src/app/models/M_Location';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { toMoney } from 'src/app/utils/FunctionUtils';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-manual-stock-moves',
  templateUrl: './manual-stock-moves.component.html',
  styleUrls: ['./manual-stock-moves.component.css']
})
export class ManualStockMovesComponent extends ParameterStateComponent implements OnInit {

  @ViewChild(MatTabGroup, { static: true }) tabsGroup!: MatTabGroup;

  v = ViewPath;
  loaded: boolean = false

  form_in: UntypedFormGroup;
  form_out: UntypedFormGroup;
  form_intern: UntypedFormGroup;

  product: M_Product | undefined;
  stores: M_StoreHouse[] = []
  precioCoste: number = 0;

  constructor(public companyS: CompanyService, routerS: RouterService, private fb: UntypedFormBuilder, public responsiveS: ResponsiveService,
    route: ActivatedRoute, private apiS: ApiService, public paramS: ParamsService, private chdRef: ChangeDetectorRef) {
    super(routerS, route, ["product"]);
    this.form_in = this.genericForm(true);
    this.form_out = this.genericForm(false);
    this.form_intern = this.fb.group({
      product_id: ['', [Validators.required]],
      mov_type: [MovTypeEnum.TM],
      location_from: ['', [Validators.required]],
      location_to: ['', [Validators.required]],
      quantity: ['', [Validators.required]],
      comment: ['']
    })
  }

  override onParam(_k: any, v: any) {
    this.apiS.getProductById(v).then(res => {
      if (res !== undefined) {
        this.product = res;
        this.loaded = true;
        this.form_in.get('precioCoste')?.patchValue(this.product.buy_price);
        this.patchProductId(this.product.product_id!);
        if (this.product.control_stock) {
          this.form_in.get('location_id')?.addValidators(Validators.required);
          this.form_out.get('location_id')?.addValidators(Validators.required);
        }
      }
    })
  }

  patchProductId(id: number) {
    this.form_in.patchValue({ product_id: id })
    this.form_out.patchValue({ product_id: id })
    this.form_intern.patchValue({ product_id: id })
  }

  ngOnInit(): void {
    this.apiS.storeHouses().then(res => {
      this.stores = res;
    });

    let comment_in = this.form_in.get('comment')!;
    let comment_out = this.form_out.get('comment')!;

    this.form_in.get('reason')?.valueChanges.subscribe(selectedReason => {
      this.addOrRemoveRequiredValidator(comment_in, selectedReason === "Otros");
    });

    this.form_out.get('reason')?.valueChanges.subscribe(selectedReason => {
      this.addOrRemoveRequiredValidator(comment_out, selectedReason == "Otros");
    });
  }

  genericForm(in_: boolean): UntypedFormGroup {
    return this.fb.group({
      product_id: ['', [Validators.required]],
      location_id: [''],
      mov_type: [in_ ? MovTypeEnum.EM : MovTypeEnum.SM, [Validators.required]],
      mov_id: [0, [Validators.required]],
      reason: ['', [Validators.required]],
      comment: [''],
      item_id: [0, [Validators.required]],
      quantity: ['', [Validators.required]],
      precioCoste: [''],
      importe: ['']
    })
  }


  addOrRemoveRequiredValidator(control: AbstractControl<any, any>, add: boolean) {
    if (add) { control.addValidators([Validators.required]); }
    else { control.removeValidators([Validators.required]); }
    control.updateValueAndValidity();
    this.chdRef.detectChanges();
  }

  onSave(): void {
    if (this.currentFormValid) {
      this.apiS.manualStockMove(this.currentFormValue).then(res => {
        this.goProduct();
      })
    }
  }

  patchQuantity(location: M_Location, form: UntypedFormGroup) {
    let q = form.get('quantity')?.value || 0;
    if (q > location.disp) {
      form.get('quantity')?.setValue(location.disp);
    }
  }

  get currentFormValid() {
    if (this.tabsGroup.selectedIndex == 0) { return this.form_in.valid }
    else if (this.tabsGroup.selectedIndex == 1) { return this.form_out.valid }
    else if (this.tabsGroup.selectedIndex == 2) { return this.form_intern.valid && this.form_intern.get('location_from')?.value != this.form_intern.get('location_to')?.value }
    else return false;
  }

  get currentFormValue() {
    return this.currentForm?.value;
  }
  get currentForm() {
    if (this.tabsGroup.selectedIndex == 0) { return this.form_in }
    else if (this.tabsGroup.selectedIndex == 1) { return this.form_out }
    else { return this.form_intern }
  }

  goProduct() {
    this.paramS.go(ViewPath.productDetails, this.product!.product_id!);
  }

  import(ar: AddRemoveComponent) {
    let val = ar.getValue() * this.form_in.get('precioCoste')?.value;
    this.form_in.get('importe')?.setValue(val);
    return toMoney(val);
  }
}
