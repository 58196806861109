import { Component, Input } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { Views } from 'src/app/custom-classes/View';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-model-not-found',
  templateUrl: './model-not-found.component.html',
  styleUrls: ['./model-not-found.component.css']
})
export class ModelNotFoundComponent {
  v = ViewPath;
  @Input({ required: true }) title!: string;
  @Input({ required: true }) goText!: string;
  @Input({ required: true }) view!: Views;
  constructor(public routerS: RouterService) { }
}