import { AbstractControl, ValidatorFn } from '@angular/forms';

export function validatorTax(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
     const valor = control.value;
     if (valor < 0 || valor > 21) {
       return {'porcentajeInvalido': true};
     }
     return null;
    };
   }
