import { Component, Inject, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShowAdvanceClientComponent } from 'src/app/components/show-advance-client/show-advance-client.component';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Invoice } from 'src/app/models/M_Invoice';
import { M_LastMovements } from 'src/app/models/M_LastMovement';
import { M_Purchase } from 'src/app/models/M_Purchase';
import { M_TypePayment } from 'src/app/models/M_TypePayment';

/**
 * Pay a bill. You can apply advance payments.
 * If the user apply some advance, the dialog closes with the advance data.
 */

@Component({
  selector: 'app-pay-all-invoice-dialog',
  templateUrl: './pay-all-invoice-dialog.component.html',
  styleUrls: ['./pay-all-invoice-dialog.component.css']
})
export class PayAllInvoiceDialogComponent {

  checked = false;
  dOpened = false;
  selectedPayment: any;
  @ViewChildren(ShowAdvanceClientComponent)
  lsmv?: QueryList<ShowAdvanceClientComponent>;
  selectedValue: string = '';
  pending: boolean = false;
  mushroomChecked: boolean = false;
  showForm: boolean = false;
  selectedDate: Date | null = null;

  constructor(public dRef: MatDialogRef<PayAllInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      invoice: M_Invoice | undefined;
      last_movements: M_LastMovements[] | undefined;
      pending: boolean;
      typePayment: M_TypePayment[];
      client: M_Contact;
      purchase : M_Purchase | undefined;
    }, @Inject(MAT_DIALOG_DATA) public data_: { invoice: M_Invoice, isDialog: boolean, purchase:M_Purchase }) {

    if (this.data_) {
      if(this.data_.invoice){
        this.data.invoice = this.data_.invoice;
      }
      if(this.data_.purchase){
        this.data.purchase = this.data_.purchase;
      }
    }
  }
}
