<p mat-dialog-title>{{data.title}}</p>
<div mat-dialog-content>
    <div class="df jcc">
        <lord-icon src="https://cdn.lordicon.com/nqisoomz.json" trigger="loop" delay="900"
            colors="primary:#121331,secondary:#ebe6ef,tertiary:{{accent()}},quaternary:#3a3347"
            style="width:250px;height:250px">
        </lord-icon>
    </div>
    <p>{{data.body}}</p>
</div>
<div mat-dialog-actions>
    <button mat-flat-button mat-dialog-close color="primary">Ok</button>
</div>