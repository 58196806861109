import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { templateTypedoc } from '../../models/M_TemplateField';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PreviewService } from '../../services/preview.service';
import { PdfDocumentsUploadComponent } from '../pdf-documents-upload/pdf-documents-upload.component';
import { DocumentationConfigComponent } from 'src/app/views/settings/company-tab/documentation-config/documentation-config.component';
import { TemplateFieldsFormComponent } from '../template-fields-form/template-fields-form.component';

@Component({
  selector: 'app-documentation-compra-venta',
  templateUrl: './documentation-compra-venta.component.html',
  styleUrls: ['./documentation-compra-venta.component.css']
})
export class DocumentationCompraVentaComponent {
  @Input() pdf_type!: templateTypedoc;
  public form: UntypedFormGroup;
  public pending = false;
  @Output() pendingChange = new EventEmitter<boolean>();
  formData = new FormData();
  public file_out: File | undefined;
  @Output() fileChanged = new EventEmitter<File | null>();
  @ViewChild(PdfDocumentsUploadComponent) pdfUploadComponent!: PdfDocumentsUploadComponent; // Referencia al componente hijo
  @ViewChild("compraventa") compraventaConfig!: DocumentationCompraVentaComponent;
  @ViewChild(TemplateFieldsFormComponent) templateComponent! : TemplateFieldsFormComponent;


  constructor(@Inject(DocumentationConfigComponent) public parent: DocumentationConfigComponent, private formBuilder: UntypedFormBuilder, private previewS: PreviewService) {
    this.form = this.formBuilder.group({
      footer: [''],
    });

    this.form.get('footer')?.valueChanges.subscribe(_v => {
      if (this.parent) {
        this.pending = true;
      }
    })
  }

  getFooter(): string {
    return this.form.get('footer')?.value
  }

  setFooter(f: string | undefined): void {
    if (f) {
      this.form.get('footer')?.setValue(f, { emitEvent: false });
    }
  }

  showPreview() {
    this.previewS.showPreview(this.pdf_type, undefined, { c: this.parent.parent.companyTab.companyForm.c!, p: this.parent.parent.companyTab.companyForm.cp.getPrimary(), s: this.parent.parent.companyTab.companyForm.cp.getSecondary(), footer: this.getFooter() })
  }
}
