import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-cash-total-return',
  templateUrl: './dialog-cash-total-return.component.html',
  styleUrls: ['./dialog-cash-total-return.component.css']
})
export class DialogCashTotalReturnComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data:{ changeAmount:number}){

  }
}
