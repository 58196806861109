import { Component, Input } from '@angular/core';

/** Skeleton loading component */

@Component({
  selector: 'app-skeleton',
  template: `
    <div [ngStyle]="getMyStyles()" [ngClass]="{'transparency': transparency, 'loader' : true}"></div>
  `,
  styleUrls: ['./skeleton.component.css']
})
export class SkeletonComponent {

  /** Width in px */
  @Input() width!: number;
  /** Width in %*/
  @Input() widthPercentage!: number;
  /** The component width = 100% */
  @Input() fullW!: boolean;
  /** Height in px */
  @Input() height!: number;
  /** Height in %*/
  @Input() heightPercentage!: number;
  /** Show the component with some transparency */
  @Input() transparency: boolean = false;
  /** Males the component circula */
  @Input() circle!: boolean;

  constructor() { }

  getMyStyles() {
    const myStyles = {
      'width.px': this.width ? this.width : '',
      'height.px': this.height ? this.height : '',
      'height.%': this.heightPercentage ? this.heightPercentage : '',
      'border-radius': this.circle ? '50%' : '',
      'width.%': this.fullW ? '100' : this.widthPercentage ? this.widthPercentage : ''
    };
    return myStyles;
  }

}
