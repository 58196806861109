import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { SubscriptionService } from '../services/EinaMainData/subscription.service';

@Directive({
  selector: 'button[subscribed-user]'
})
export class SubscribedUserDirective {

  constructor(private el: ElementRef<HTMLButtonElement>, private renderer: Renderer2, private subS: SubscriptionService) {
    if (this.subS.isBasic) {
      this.renderer.addClass(this.el.nativeElement, "trial-disabled");
      const child = document.createElement('p');
      child.innerText = subS.proPlanRequiredTxt;
      this.renderer.addClass(child, "c_r");
      this.renderer.appendChild(this.el.nativeElement, child);
      this.renderer.setStyle(this.el.nativeElement, "flex-direction", "column");
    }
  }
}
