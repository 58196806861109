<app-page-structure #ps [pageTitle]="'Balance'" [masterClass]="undefined" [autocompleteID]="'users'"
    searchLabel="Buscar movimiento" listTitleText="Listado de balance" [data]="[]"
    [displayedHeader]=" ['Importe', 'Cliente','Fecha','Tipo','Documento']"
    [displayedColumns]="['importNatural','contact','created_at','typeName','invoice']"
    [specialText]="[    'money',         contact,            undefined,           typeName,      invoice]"
    [preIcon]="[balanceIcon, undefined]" [quickFilter]="quickFilter" [filter]="f" [filters]="filtersToShow"
    (onclickRow)="params.go(viewClick($event),getIds($event))"
    [createButton]="
        {
            text : 'Control de caja' , 
            icon : 'point_of_sale',
        }" (onClickCreateButton)="openDialog()" [quickFilter]="quickFilter">
</app-page-structure>