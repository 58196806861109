import { Component, Input } from '@angular/core';

@Component({
  selector: 'to-do',
  templateUrl: './to-do.component.html',
  styleUrls: ['./to-do.component.css']
})

/** Component that shows a message, by default : 🚧 En desarrollo 🚧 
 * This component only shows on dev and pre environments
*/
export class ToDoComponent {
  @Input() txt : string | undefined;
}
