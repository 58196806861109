import { DateGranularity } from "../enums/DateGranularity";

export interface DateByGranularity {
    splitDate: Date
    data: Date[]
}

export class CustomDateByGranularity {

    dates: Date[]
    granularity: DateGranularity;

    result: DateByGranularity[] = [];

    constructor(dates: Date[], granularity: DateGranularity, year: number) {
        this.dates = dates.filter(d => d.getFullYear() == year);
        this.granularity = granularity;
        this.result = this.splitBy(granularity);
    }

    private splitBy(dg: DateGranularity): DateByGranularity[] {
        let allDays: DateByGranularity[] = [];
        for (let i = 0; i < this.dates.length; i++) {
            let index = this.containsDay(allDays, this.dates[i], dg);
            if (index == -1) {
                allDays.push({ splitDate: this.dates[i], data: [this.dates[i]] })
            }
            else {
                allDays[index].data.push(this.dates[i]);
            }
        }

        allDays.sort(function (a, b) {
            if (a.splitDate < b.splitDate) { return -1; }
            if (a.splitDate > b.splitDate) { return 1; }
            return 0;
        });

        return allDays;
    }

    private containsDay(day_recep: DateByGranularity[], day: Date, dg: DateGranularity) {
        switch (dg) {
            case DateGranularity.DAY:
                for (let i = 0; i < day_recep.length; i++) {
                    if (day_recep[i].splitDate.isEquals(day)) {
                        return i;
                    }
                }
                return -1;
            case DateGranularity.MONTH:
                for (let i = 0; i < day_recep.length; i++) {
                    if (day_recep[i].splitDate.isMonthEqual(day)) {
                        return i;
                    }
                }
                return -1
            case DateGranularity.YEAR:
            default: return -1;
        }
    }

    getData(): DateByGranularity[] {
        return this.result;
    }
}