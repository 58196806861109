import { Injectable } from '@angular/core';
import { DeleteDialogComponent } from '../components/delete-dialog/delete-dialog.component';
import { RouterService } from './router.service';
import { CompanyService } from './EinaMainData/company.service';
import { M_Contact } from '../models/M_Contact';
import { M_User } from '../models/M_User';
import { M_Vehicle } from '../models/Vehicles/M_Vehicle';
import { M_Product } from '../models/Products/M_Product';
import { ViewPath } from '../app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class DeleteService {

  v = ViewPath;
  constructor(public d: DeleteDialogComponent, private routerS: RouterService, public companyS: CompanyService) { }

  delete(item: M_Contact | M_Vehicle | M_Product | M_User) {
    this.d.show(item).afterClosed().subscribe(res => {
      if (res) {
        if (item instanceof M_User) {
          this.routerS.goWithQueryParams(ViewPath.settings, { tab: 1 })
        }
        else {
          this.routerS.goTo(this.getViewFromObj(item));
        }
      }
    });
  }

  getViewFromObj(item: M_Contact | M_Vehicle | M_Product | M_User) {
    if (item instanceof M_Contact) {
      return this.v.contacts;
    }
    else if (item instanceof M_Vehicle) {
      return this.v.vehicles;
    }
    else if (item instanceof M_Product) {
      return this.v.products;
    }
    else if (item instanceof M_User) {
      return this.v.settings;
    }
    return this.v.dashboard;
  }


}
