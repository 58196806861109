<app-card [noStyle]="true">
    <div class="df aic">
        <app-card-subtitle>{{filter.label}}</app-card-subtitle>
    </div>
    <mat-form-field appearance="outline">
        <mat-label>Seleccione un centro</mat-label>
        <mat-select #ms (selectionChange)="emitChange($event)" [value]="filter.selected">
            <mat-option>--</mat-option>
            <mat-option *ngFor="let center of filter.centers" [value]="center">
                {{center.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</app-card>