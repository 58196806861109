
export class M_Register {
    name: string
    email: string
    password: string
    password_confirmation: string
    constructor(obj: any) {
        this.name = obj.name;
        this.email = obj.email;
        this.password = obj.password;
        this.password_confirmation = obj.password_confirmation;
    }
}