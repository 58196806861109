import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Contact } from 'src/app/models/M_Contact';
import { R_Zip } from 'src/app/models/R_Zip';
import { ExtraAddressService } from 'src/app/services/extra-address.service';

@Component({
  selector: 'app-create-edit-sending-adress',
  templateUrl: './create-edit-sending-adress.component.html',
  styleUrls: ['./create-edit-sending-adress.component.css']
})
export class CreateEditSendingAdressComponent {

  fg: FormGroup;
  selectedPayment: any;
  loadingZip : boolean = false;
  personalizadoSelected = false;
  arrayZips: R_Zip[] | undefined = [];
  finalsearh ="";
  constructor(@Inject(MAT_DIALOG_DATA) public data: M_Contact, public dialogRef: MatDialogRef<CreateEditSendingAdressComponent>, private apiS: ApiService, private extraAddressS: ExtraAddressService,public chdRef: ChangeDetectorRef) {
    this.fg = this.extraAddressS.generateAddresFormFroup()
    this.fg.addControl("client_id", new FormControl(this.data.client_id));
    this.fg.get('zip')?.valueChanges.subscribe(v => {
      this.onZipChange(v);
      this.chdRef.detectChanges();
    })
  }
  onZipChange(searched: string) {

    this.finalsearh = searched;
    let zipControl = this.fg.get('zip')!;

    if (!searched) {
      this.markZipAsInvalid(zipControl);
    }
    else if (searched && !this.loadingZip) {
      this.loadingZip = true;
      this.apiS.getLocationByZip(searched).then(res => {

        this.arrayZips = res;
        if (this.arrayZips?.length == 0) {
          this.markZipAsInvalid(zipControl);
        }

        if (this.arrayZips && this.arrayZips.length === 1) {
          // Si solo hay un registro, selecciona automáticamente ese registro
          let selectedZip = this.arrayZips[0];

          // Realiza las operaciones necesarias para marcar el ZIP como válido
          this.markZipAsValid(zipControl, selectedZip);

        } else {
          let selectedZip = this.arrayZips?.find(zip => zip.id == this.fg.get('city')?.value);
          
          if (selectedZip) {
            this.markZipAsValid(zipControl, selectedZip)
          }
        }

        this.loadingZip = false;

        /** Make another call if necessary */
        if (searched != this.finalsearh) {
          this.onZipChange(this.finalsearh);
        }
      });
    }
  }
  markZipAsValid(zip: AbstractControl, model: R_Zip) {
    zip.setErrors(null);
    this.fg.patchValue({ 'city': model.id })
    this.fg.patchValue({ 'province': model.provincia })
    this.fg.patchValue({ 'ccaaObject': model.ccaa.ccaaname })
    this.fg.patchValue({ 'ccaa': model.ccaa.id })
    this.chdRef.detectChanges();
  }
  markZipAsInvalid(zip: AbstractControl) {
    zip.markAsDirty();
    zip.markAsTouched();
    zip.setErrors({ notfound: true });
    this.fg.patchValue({ 'city': undefined })
    this.fg.patchValue({ 'province': undefined })
    this.fg.patchValue({ 'ccaaObject': undefined })
    this.fg.patchValue({ 'ccaa': undefined })
  }
  stateChange(event: MatSelectChange) {
    const selectedValue = event.value;
    
    this.selectedPayment = this.arrayZips?.find(
      (zip) => zip.id === selectedValue
    );
    
    let zipControl = this.fg.get('zip')!;
    
    if (selectedValue) {
      this.markZipAsValid(zipControl, this.selectedPayment);
    }

    if (selectedValue === 'personalizado') {
      this.personalizadoSelected = true;
      this.fg.get('city')?.setValidators([Validators.required]);
    } else {
      if (this.personalizadoSelected) {
        this.personalizadoSelected = false;
        this.fg.get('city')?.clearValidators();
      }
    }
    if (selectedValue === 'personalizado') {
      this.personalizadoSelected = true;
      this.fg.get('city')?.setValidators([Validators.required]);
    }
    else {
      if (this.personalizadoSelected) {
        this.personalizadoSelected = false;
        this.fg.get('city')?.clearValidators();
      }
    }
    this.fg.get('city')?.updateValueAndValidity();
  }
  createAndClose() {
    this.apiS.createExtraAddress(this.fg).then(res => {
      this.dialogRef.close(res);
    })
  }

}
