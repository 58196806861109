import { M_Schedule } from "./M_Schdeule";

export class M_SigningTime {

    day: Date;
    morningStart: Date | undefined;
    morningEnd: Date | undefined;
    afternoonStart: Date | undefined;
    afternoonEnd: Date | undefined;
    schedule: M_Schedule;

    constructor(d: any) {
        if (d.day) {
            this.day = new Date(d.day);
        }
        else {
            throw Error("Signing without day")
        }

        this.morningStart = d.morningStart ?? undefined;
        this.morningEnd = d.morningEnd ?? undefined;;
        this.afternoonStart = d.afternoonStart ?? undefined;;
        this.afternoonEnd = d.afternoonEnd ?? undefined;;
        this.schedule = this.getScheduleByDay(d.schedule);
    }

    getScheduleByDay(s: M_Schedule[]): M_Schedule {
        console.log("Getting scheules")
        return s[this.day.weekDay()];
    }

    get break() {
        return this.schedule.break;
    }

    startStop() {
        if (!this.morningStart) { this.morningStart = new Date() }
        else if (!this.morningEnd) { this.morningEnd = new Date() }
        else if (!this.afternoonStart) { this.afternoonStart = new Date() }
        else if (!this.afternoonEnd) { this.afternoonEnd = new Date() }
        console.log("To send backend:", this);
    }

    getTeoricDifference(): { hours: number, minutes: number } {
        const teoricMinutes = this.schedule.getTotalTimeInMinutes();
        const workedMinutes = this.workedMinutes || 0;
        const differenceInMinutes = Math.ceil(teoricMinutes - workedMinutes);

        const absoluteMinutes = Math.abs(differenceInMinutes);
        const hours = Math.floor(absoluteMinutes / 60);
        const minutes = absoluteMinutes % 60;

        const sign = differenceInMinutes < 0 ? -1 : 1;
        return { hours: hours * sign, minutes: minutes * sign };
    }


    get scheduleHasMorining(): boolean {
        return this.schedule.morningStart != "" && this.schedule.morningEnd != "";
    }

    get scheduleHasAfternoon(): boolean {
        return this.schedule.afternoonStart != "" && this.schedule.afternoonEnd != "";
    }

    get canStart() {
        return (!this.morningStart && this.scheduleHasMorining) ||
            (this.morningStart && this.morningEnd && !this.afternoonStart && this.scheduleHasAfternoon);
    }

    get canEnd() {
        return (this.morningStart && !this.morningEnd && this.scheduleHasMorining) ||
            (this.morningStart && this.morningEnd && this.afternoonStart && !this.afternoonEnd && this.scheduleHasAfternoon);
    }

    get elapsed(): string {
        const now = new Date();
        let totalSeconds = 0;

        if (!this.morningStart) { return "--:--:--"; }

        /** Morning */
        if (this.morningStart && !this.morningEnd) {
            totalSeconds += this.morningStart.differenceWith(now).totals.seconds;
        }
        else if (this.morningStart && this.morningEnd) {
            totalSeconds += this.morningStart.differenceWith(this.morningEnd).totals.seconds;
        }

        /** Afternoon */
        if (this.afternoonStart && !this.afternoonEnd) {
            totalSeconds += this.afternoonStart.differenceWith(now).totals.seconds;
        }
        else if (this.afternoonStart && this.afternoonEnd) {
            totalSeconds += this.afternoonStart.differenceWith(this.afternoonEnd).totals.seconds;
        }

        const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, '0');
        const seconds = (totalSeconds % 60).toString().padStart(2, '0');

        return `${hours}:${minutes}:${seconds}`;
    }

    get isWorking() {
        return this.morningStart && !this.morningEnd || (this.morningStart && this.morningEnd && this.afternoonStart && !this.afternoonEnd);
    }

    // get shouldSigning() {
    //     if (this.isWorking) { return false; }
    //     if (this.morningStart && this.morningEnd && this.afternoonStart && this.afternoonEnd) { return false; }
    //     const now = new Date();
    //     if (!this.morningStart) { 
    //         return this.schedule.morningStart; 
    //     }
    //     else if (!this.morningEnd) { 
    //         return true; 
    //     }
    //     else if (!this.afternoonStart) { 
    //         return true; 
    //     }
    //     else if (!this.afternoonEnd) { 
    //         return true;
    //     }
    //     return false;
    // }

    /** In minutes */
    get workedMinutes() {
        const now = new Date();
        let minutes = 0;

        if (!this.morningStart) { return 0; }

        /** Morning */
        if (this.morningStart && !this.morningEnd) {
            minutes += this.morningStart.differenceWith(now).totals.minutes;
        }
        else if (this.morningStart && this.morningEnd) {
            minutes += this.morningStart.differenceWith(this.morningEnd).totals.minutes;
        }

        /** Afternoon */
        if (this.afternoonStart && !this.afternoonEnd) {
            minutes += this.afternoonStart.differenceWith(now).totals.minutes;
        }
        else if (this.afternoonStart && this.afternoonEnd) {
            minutes += this.afternoonStart.differenceWith(this.afternoonEnd).totals.minutes;
        }

        return minutes;
    }

    editPresence(morningStart: string, morningEnd: string, afternoonStart: string, afternoonEnd: string) {
        let newMS = morningStart ? new Date(this.day).setHHmm(morningStart) : undefined;
        let newME = morningEnd ? new Date(this.day).setHHmm(morningEnd) : undefined;
        let newAS = afternoonStart ? new Date(this.day).setHHmm(afternoonStart) : undefined;
        let newAE = afternoonEnd ? new Date(this.day).setHHmm(afternoonEnd) : undefined;

        this.morningStart = newMS;
        this.morningEnd = newME;
        this.afternoonStart = newAS;
        this.afternoonEnd = newAE;
    }

    get finished() {
        return ((this.schedule.morningStart && this.morningStart) || !this.schedule.morningStart) &&
            ((this.schedule.morningEnd && this.morningEnd) || !this.schedule.morningEnd) &&
            ((this.schedule.afternoonStart && this.afternoonStart) || !this.schedule.afternoonStart) &&
            ((this.schedule.afternoonEnd && this.afternoonEnd) || !this.schedule.afternoonEnd);
    }

}