import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SvgIconsService {

  allIcons: string[] = [];
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer,) { }

  /** Add a svg icon to the matIconRegistry. The svg needs to be at : ""./assets/icons/foo.svg" */
  addSvgIcons(...icons: string[]) {
    this.allIcons = icons;
    icons.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/" + icon + ".svg"));
    })
  }

  exists(icon: string) {
    return this.allIcons.includes(icon);
  }
}