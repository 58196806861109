<div class="advanced-details-container">
    <div class="title" style="grid-area: title;">
        <app-view-title>
            Crear factura <span class="c_t2" *ngIf="recoveredDraft"> (borrador) </span>
        </app-view-title>
    </div>

    <div class="ig onboarding-invoice-selec-user" [formGroup]="form">
        <!-- INFORMACIÓN GENERAL -->
        <app-card [contentLoaded]="contentLoaded" class="bc_b1" [cornerMatMenu]="deleteTemplate">
            <app-card-title>Información general</app-card-title>
            <form [formGroup]="form" eForm>
                <div eForm-wrapper>
                    <app-class-searcher [showLeftNumber]="false" #csearcher class="onboarding-invoice-select-client"
                        [masterClass]="client" [allData]="" [width100]="true" searchPlaceHolder="Buscar cliente"
                        [extendedInfo]="true" [form_]="form" [formCname]="'client_id'" (onSelect)="formChanged = true"
                        (onRemove)="formChanged = true" [required]="classSearcherRequired()" [createData]="{
                            text : 'Nuevo cliente / empresa',
                            by : 'dialog'
                        }">
                    </app-class-searcher>
                    <mat-form-field appearance="outline" class="w100">
                        <mat-label>Comentarios</mat-label>
                        <textarea (input)="formChanged = true" matInput formControlName="comments" cdkTextareaAutosize
                            cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" [placeholder]="'Comentarios'">
                        </textarea>
                    </mat-form-field>
                </div>
            </form>
            <ng-template #deleteTemplate>
                <button mat-icon-button class="mt10"
                    [mat-menu-trigger-for]="deleteMenu"><mat-icon>more_vert</mat-icon></button>
                <mat-menu #deleteMenu="matMenu">
                    <ng-template></ng-template>
                    <button mat-menu-item [disabled]="isDeleteDisaled" (click)="disacrdDraft()">
                        <mat-icon>delete</mat-icon> Eliminar
                    </button>
                </mat-menu>
            </ng-template>
        </app-card>
    </div>

    <div class="products">
        <app-card [contentLoaded]="contentLoaded" class="pSection mt10">
            <!-- PRODUCTOS -->
            <app-card-title>Productos</app-card-title>
            <app-product-line-table *ngIf="currentBill" [products]="currentBill.breakdown.all"
                [lineTypes]="['product', 'custom']"
                [extendedInfoConfig]="{showColumn:true, actions:['manual-fault','remove-reservation']}"></app-product-line-table>
            <mat-divider></mat-divider>

            <!-- Totals table -->
            <app-invoice-totals-table *ngIf="currentBill" [alignment]="'right'" [invoice]="currentBill" [fixedTotals]="false"></app-invoice-totals-table>

            <div *ngIf="canInvoiceS.data(currentBill, this) as invocieData" [matTooltip]="invocieData.tooltip"
                class="df jcc">
                <button class="onboarding-invoice-finish" [disabled]="!canCreateInvoice() || invocieData.disable"
                    [matBadge]="invocieData.badge" [matBadgeColor]="invocieData.badgeColor" subscribed-user
                    mat-flat-button color="primary" (click)="createBill()">
                    Crear factura
                </button>
            </div>

        </app-card>
    </div>
</div>

<app-floating-save-button class="onboarding-invoice-save" [show]="invoiceHasChanges && isClientOk"
    (click)="saveInvoice()">
</app-floating-save-button>