<div class="recepComentarios addInfoFlex" [formGroup]="form">
    <mat-form-field appearance="outline">
        <mat-label>{{label ? label : 'Comentarios'}}</mat-label>
        <mat-chip-grid #chipList [disabled]="form.disabled">
            <mat-chip-row *ngFor="let comment of comments" (removed)="removeComment(comment)"
                [removable]="form.enabled"
                [disabled]="form.disabled"
                [ngClass]="{'databaseFilledChip' : comment.filledFromDatabase}">
                {{comment.text}}
                <div *ngIf="comment.icon">
                    <img matChipAvatar style="width: 24px; padding-left: 7px;"
                        src="./assets/img/comentarios/{{comment.icon}}.png">
                </div>
                <button matChipRemove *ngIf="form.enabled">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <input #input [placeholder]="placeHolder ? placeHolder : 'Nuevo comentario'" formControlName="comments"
                [matChipInputFor]="chipList" [maxlength]="maxPerComment ? maxPerComment : null"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)" [disabled]="form.disabled">
            <mat-hint *ngIf="maxPerComment" align="end">{{input.value.length}}/{{maxPerComment}}</mat-hint>
        </mat-chip-grid>
        <mat-hint align="start" *ngIf="showEnter && input.value.length">
            <span class="c_t">
                <mat-icon>keyboard_return</mat-icon> para añadir
            </span>
        </mat-hint>
    </mat-form-field>
</div>