<div class="df aic">
    <app-card-subtitle>{{filter.label}}</app-card-subtitle>
  </div>
<mat-form-field class="w100" appearance="outline">
    <mat-label>Introduce el texto</mat-label>
    <input matInput placeholder="Texto a filtrar" [formControl]="formControl" [matAutocomplete]="auto">

    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
    
</mat-form-field>