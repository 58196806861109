import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ListenerService {

  constructor() { }

  addBeforeInput() {
    window.addEventListener("beforeinput", (event) => {
      if (this.separationInput(event)) {
        if (!event.data!.isNumber()) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    })

    /*window.addEventListener("input", (event) => {
      if (this.separationInput(event) && event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
        event.target!.value = this.getSeparation(event.target.value)
      }
    })*/
  }

  getSeparation(val: string) {
    if (val) {
      let cleanValue: string = val.replace(',', '').replace(/\./g, '')
      let newValue = Number(cleanValue).toLocaleString('es');
      return newValue;
    }
    return val;
  }

  separationInput(event: Event): event is InputEvent {
    if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
      if (event instanceof InputEvent && event.data && event.target.classList.contains('separation')) {
        return true;
      }
    }
    return false;
  }
}