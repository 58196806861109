export class M_BuyTransac {
    id: number;
    buy_num: number;
    type: number;
    user: number;
    date: Date;
    company_id: number;
    client_id: number
    urlpdf: string;
    total: number;
    iva: number;
    vehicle_id: number;
    docum_prov_num: string;
    comment: string;
    created_at: Date;
    updated_at: Date;
    constructor(d: any) {
        this.id = d.id;
        this.buy_num = d.buy_num;
        this.type = d.type;
        this.user = d.user;
        this.date = new Date(d.date);
        this.company_id = d.company_id;
        this.client_id = d.client_id
        this.urlpdf = d.urlpdf;
        this.total = d.total;
        this.iva = d.iva;
        this.vehicle_id = d.vehicle_id;
        this.docum_prov_num = d.docum_prov_num;
        this.comment = d.comment;
        this.created_at = new Date(d.created_at);
        this.updated_at = new Date(d.updated_at);
    }
}