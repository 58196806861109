<div class="absolute-center">
    <app-card>
        <form [formGroup]="loginForm" (ngSubmit)="login()" eForm>
            <app-card-title class="tal">Iniciar sesión de acceso compartido</app-card-title>
            <app-info-panel-rectangle [panel]="'warn'">
                <span class="fw600">Acceso compartido: </span>
                Esta opción te permite usar Eina en un mismo dispositivo para varios operarios, cambiando de usuario rápidamente. 
                Es ideal para situaciones en las que se necesita compartir el dispositivo en el taller, permitiendo a cada operario acceder a su cuenta y ver el trabajo principal sin necesidad de cerrar y abrir sesión.
            </app-info-panel-rectangle>
            <div eForm-wrapper>
                <div eForm-section>
                    <app-email-input [form]="loginForm" formCName="email"></app-email-input>
                </div>
                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Contraseña</mat-label>
                        <input #passwRef type="password" matInput placeholder="Contraseña" formControlName="password">
                        <button type="button"
                            (click)="passwRef.type=='text'? passwRef.type='password' : passwRef.type='text'"
                            mat-icon-button matSuffix>
                            <mat-icon *ngIf="passwRef.type == 'password'">visibility</mat-icon>
                            <mat-icon *ngIf="passwRef.type == 'text'">visibility_off</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
            <div class="mb20 mt20">
                <button mat-flat-button class="w100" color="primary">Iniciar sesión</button>
            </div>
        </form>
    </app-card>
</div>