import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { BrandModelInputComponent } from 'src/app/components/brand-model-input/brand-model-input.component';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { M_Brand } from 'src/app/models/M_Brand';
import { M_Serie } from 'src/app/models/M_Serie';
import { SerieFormComponent } from 'src/app/serie-form/serie-form.component';
import { ApiService } from 'src/app/services/Api/api.service';
import { RouterService } from 'src/app/services/router.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-create-serie',
  templateUrl: './create-serie.component.html',
  styleUrls: ['./create-serie.component.css']
})
export class CreateSerieComponent implements OnInit {
  v = ViewPath;
  public loaded = false;
  isEdit : boolean = false;
  serie : M_Serie | undefined;
  @ViewChild(SerieFormComponent, { static: true }) serieform!: SerieFormComponent;
  constructor(private apiS: ApiService,private formBuilder: UntypedFormBuilder,route: ActivatedRoute, public routerS: RouterService,public snackS : SnackService,
    @Optional() public dialogRef: MatDialogRef<CreateSerieComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: M_Serie | undefined,
  ){
    if(this.isOnDialog && this.data != undefined){
      this.serie = new M_Serie(this.data);
      
    }
  }
  ngOnInit(): void {
    this.loaded = true;
  }
  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }
  create(){
    console.log(this.serieform.form.value);
    if(!this.serieform.form.valid){return;}
    else{
      let serie : M_Serie = new M_Serie(this.serieform.form.value);
      this.apiS.createSerie(serie).then(res=>{
        if(res){
          this.routerS.goTo(this.v.series);
          this.snackS.show('Serie creado');
          this.dialogRef.close(res);
        }
      });
    }
  }
}
