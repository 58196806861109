import { Component, Input } from '@angular/core';
import { M_Action } from '../../models/M_Action';
import { ParamsService } from '../../services/params.service';
import { ViewPath } from 'src/app/app-routing.module';

@Component({
  selector: 'app-last-intervention-or',
  templateUrl: './last-intervention-or.component.html',
  styleUrls: ['./last-intervention-or.component.css']
})
export class LastInterventionOrComponent {
  v = ViewPath;
  @Input() or: M_Action | undefined;
  constructor(public paramS: ParamsService) { }
}
