export enum ModulesEnum {
    RECAMBIOS = 1,
    VNVO = 2,
    HONDA = 3,
    TEMPLATES = 4,
    MTR = 5
}

export enum ModuleType {
    MODULE = 1,
    INTEGRATION = 2
}