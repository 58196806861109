<app-page-structure #ps [pageTitle]="'Modelos'" [masterClass]="undefined" [autocompleteID]="'modelos'"
    searchLabel="Buscar modelos" listTitleText="Listado de modelos" [data]="[]"
    [displayedHeader]=" ['Id', 'Nombre','Marca']"
    [displayedColumns]="['id','name','getName']" 
    [specialText]="[undefined,undefined,getName]"
    [quickFilter]="quickFilter"
    (onClickCreateButton)="onDialogRef($event)"
    [filter]="f" [filters]="filtersToShow" (onclickRow)="onDialogRef($event)" [createButton]="
        {
            text : 'Crear modelo' , 
            icon : 'transportation',
        }"  [quickFilter]="quickFilter">
</app-page-structure>