<app-close-dialog-button *ngIf="isOnDialog"></app-close-dialog-button>
<div class="create-item create-product-form">
    <!-- <app-go-back *ngIf=" p != undefined" [show]="isEdit && !isOnDialog" [text]="p!.name"
        (click)="goBackProduct()"></app-go-back> -->
    <app-go-back *ngIf="!isOnDialog" [v]="v.concepts"></app-go-back>
    <app-card [contentLoaded]="loaded" mat-dialog-content [noStyle]="isOnDialog" [ngClass]="{'not-dialog':!isOnDialog}">
        <form [formGroup]="form" eForm>
            <app-card-title>{{isEdit ? "Editar concepto":"Crear concepto"}}</app-card-title>
            <div eForm-section>
                <mat-button-toggle-group [value]="CTP.NORMAL" formControlName="concept_type">
                    <mat-button-toggle [value]="CTP.NORMAL" (click)="notDiscount('N')">
                        Normal
                    </mat-button-toggle>
                    <mat-button-toggle [value]="CTP.DESCUENTO" (click)="isDiscount()">
                        Descuento
                    </mat-button-toggle>
                    <mat-button-toggle [value]="CTP.COMPRA" (click)="notDiscount('C')">
                        Compra
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <app-card-subtitle>Datos básicos</app-card-subtitle>

            <div eForm-section eForm-wrapper>

                <mat-form-field class="example-full-width">
                    <mat-label>Nombre del concepto</mat-label>
                    <input matInput #comment formControlName="name" class="styl" maxlength="140"
                        placeholder="Nombre concepto">
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="form.get('concept_type')?.value != CTP.COMPRA">
                    <mat-label>PVP</mat-label>
                    <input type="number" matInput placeholder="PVP" formControlName="price"
                        (input)="adjustPriceValue()">
                    <mat-error *ngIf="form.get('price')?.hasError('required')">
                        El valor introducido no es correcto
                    </mat-error>
                    <mat-error *ngIf="form.get('price')?.hasError('max')">Número demasiado grande</mat-error>
                    <mat-error *ngIf="form.get('price')?.hasError('min')">
                        El precio de venta debe ser positivo.
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" [formGroup]="form"
                    *ngIf="form.get('concept_type')?.value != CTP.DESCUENTO">
                    <mat-label>Coste</mat-label>
                    <input type="number" matInput placeholder="Coste" formControlName="cost">
                    <mat-error *ngIf="this.form.get('cost')?.hasError('min')">El valor debe ser mayor o igual que
                        0</mat-error>
                    <mat-error *ngIf="this.form.get('cost')?.hasError('max')">Número demasiado grande</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Grupo contable</mat-label>
                    <input type="string" matInput formControlName="accounting">
                </mat-form-field>
            </div>



            <div class="df aic" [ngClass]="{'not_display': form.get('concept_type')?.value === CTP.DESCUENTO}">
                <app-card-subtitle>Exento IVA</app-card-subtitle>
                <mat-slide-toggle [color]="'accent'" class="ml10" #conceptToggle formControlName="exento"
                    [matTooltip]="responsiveS.w < 900 ? 'No se aplicará el IVA en el concepto.' : ''"></mat-slide-toggle>
            </div>
            <div eForm-section eForm-wrapper *ngIf="form.get('concept_type')?.value != CTP.DESCUENTO">

                <mat-form-field *ngIf="isExento" appearance="outline">
                    <mat-label>Tipo de exento</mat-label>
                    <mat-select formControlName="exempt_type" [value]="0">
                        <mat-option [value]="0">Suplido</mat-option>
                        <mat-option [value]="1">Operaciones con Area Exentas de IVA</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>IVA</mat-label>
                    <mat-select formControlName="tax" #iva [value]="21">
                        <mat-option *ngIf="conceptToggle.checked" [value]="0">0%</mat-option>
                        <mat-option [value]="4">4%</mat-option>
                        <mat-option [value]="10">10%</mat-option>
                        <mat-option [value]="21">21%</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <app-card-subtitle>Datos opcionales</app-card-subtitle>
            <div class="checkbox-section">
                <mat-checkbox formControlName="in_invoice" #checkInv
                    [matTooltip]="responsiveS.w < 900 ? 'Aplicar en factura.' : ''">
                    <p>No Aplicar en factura</p>
                    <mat-hint *ngIf="checkInv.checked">No se reflectará en la suma de la factura.</mat-hint>
                </mat-checkbox>
                <mat-checkbox formControlName="record_historic"
                    [matTooltip]="responsiveS.w < 900 ? 'Se aplica como histórico el vehículo.' : ''">
                    <p>Aplicar como histórico.</p>
                </mat-checkbox>
                <mat-checkbox formControlName="registration_tax" #checkTax
                    [matTooltip]="responsiveS.w < 900 ? 'Aplicar impuesto de matriculación' : ''">
                    <p>Aplicar impuesto de matriculación</p>
                    <mat-hint *ngIf="checkTax.checked">Se aplicarà el tipo de porcentaje que se haya seleccionado en el
                        vehículo.</mat-hint>
                </mat-checkbox>
            </div>
            <div class="df jcc mt10">
                <button subscribed-user mat-flat-button color="primary" class="onboarding-product-finish"
                    [disabled]="!this.form.valid" (click)="create()">
                    <span>Guardar</span>
                </button>
            </div>
        </form>
    </app-card>
</div>