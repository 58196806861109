<app-close-dialog-button *ngIf="bmi.loaded"></app-close-dialog-button>
<app-card [contentLoaded]="bmi.loaded" [noStyle]="true">

  <p mat-dialog-title>{{onEdit ? editTitle : 'Editar marca y modelo'}}</p>

  <div mat-dialog-content>

    <!-- Search -->
    <div [ngClass]="{'dn' : onEdit}" eForm-wrapper>

      <form eForm>
        <div eForm-section>
          <app-brand-model-input #bmi [form]="form" [showPrice]="true"></app-brand-model-input>
        </div>

      </form>


    </div>
    <div class="df jcfe mt10" [ngClass]="{'dn' : onEdit }">
      <button mat-button color="primary" (click)="goNext()"
        [disabled]="this.form.get('model')?.value == undefined">Siguiente</button>
    </div>
    <!-- Edit -->
    <div class="df jcfs" [ngClass]="{'dn' : !onEdit}">
      <button mat-button color="primary" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
        Atrás
      </button>
    </div>
    <div [ngClass]="{'dn' : !onEdit}">
      <form eForm [formGroup]="editForm" class="formModel">
        <mat-tab-group mat-align-tabs="center" [color]="'primary'">
          <mat-tab>
            <ng-template mat-tab-label>
              <img class="module-img small vam" src="./assets/img/modules/module-2/module-2.png">
              Campos de comercial
            </ng-template>
            <app-card-subtitle>Información</app-card-subtitle>
            <div eForm-wrapper>
              <div eForm-section>
                <mat-form-field appearance="outline">
                  <mat-label>Precio</mat-label>
                  <input type="number" min="0" matInput formControlName="price">
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Coste</mat-label>
                  <input type="number" min="0" matInput formControlName="cost">
                </mat-form-field>
              </div>
              <div eForm-section>
                <mat-form-field appearance="outline">
                  <mat-label>IVA</mat-label>
                  <mat-select formControlName="tax" [value]="21">
                    <mat-option [value]="0">Sin IVA</mat-option>
                    <mat-option [value]="4">4%</mat-option>
                    <mat-option [value]="10">10%</mat-option>
                    <mat-option [value]="21">21%</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Impuesto matriculación</mat-label>
                  <mat-select formControlName="tax_registration">
                    <mat-option [value]="RTE.Low"> {{registrationText(RTE.Low)}}</mat-option>
                    <mat-option [value]="RTE.MediumLow">{{registrationText(RTE.MediumLow)}}</mat-option>
                    <mat-option [value]="RTE.MediumHigh">{{registrationText(RTE.MediumHigh)}}</mat-option>
                    <mat-option [value]="RTE.High">{{registrationText(RTE.High)}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="w50" appearance="outline">
                  <mat-label>Porcentaje para calcular coste</mat-label>
                  <input type="number" matInput placeholder="IVA" formControlName="percentage_cost">
                  <mat-icon matSuffix>percent</mat-icon>
                  <mat-error *ngIf="form.get('percentage_cost')?.touched && form.get('percentage_cost')?.errors">Valor
                    máx.: 21</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Grupo contable</mat-label>
                  <input min="0" matInput formControlName="accounting">
                </mat-form-field>
              </div>
            </div>
            <!-- CONCEPTOS -->
            <app-card-subtitle>Conceptos</app-card-subtitle>
            <app-concepts-line-table [concepts]="getConcepts()" [showSave]="true" [isInfo]="false"
              [canModifyTable]="true" [isDialog]="true"></app-concepts-line-table>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon">two_wheeler</mat-icon>
              Detalles del Modelo
            </ng-template>
            <app-card-subtitle>Motor</app-card-subtitle>
            <div eForm-section eForm-wrapper>
              <mat-form-field appearance="outline">
                <mat-label>Tipo de cambio</mat-label>
                <mat-select formControlName="gears_type" [value]="0">
                  <mat-option [value]="0">Manual</mat-option>
                  <mat-option [value]="1">Automático</mat-option>
                </mat-select>
              </mat-form-field>
              <!-- Número de marchas -->
              <mat-form-field appearance="outline">
                <mat-label>Número de marchas</mat-label>
                <mat-select formControlName="gears" [value]="0">
                  <mat-option [value]="0">0</mat-option>
                  <mat-option [value]="4">4</mat-option>
                  <mat-option [value]="5">5</mat-option>
                  <mat-option [value]="6">6</mat-option>
                </mat-select>
              </mat-form-field>

              <!-- Cilindrada en cm3 -->
              <mat-form-field appearance="outline">
                <mat-label>Cilindrada en cm3</mat-label>
                <input matInput type="number" formControlName="cylinder" placeholder="Cilindrada en cm3">
              </mat-form-field>

            </div>
            <app-card-subtitle>Modelo</app-card-subtitle>
            <div eForm-section eForm-wrapper>
              <!-- Porcentaje para calcular coste -->


              <!-- Prefijo bastidor -->
              <mat-form-field appearance="outline">
                <mat-label>Prefijo bastidor</mat-label>
                <input matInput formControlName="chassis_prefix" placeholder="Prefijo bastidor">
              </mat-form-field>

              <!-- Peso unitario -->
              <mat-form-field appearance="outline">
                <mat-label>Peso unitario</mat-label>
                <input matInput type="number" formControlName="weight" placeholder="Peso unitario">
              </mat-form-field>
            </div>
            <app-card-subtitle>Notas</app-card-subtitle>
            <div eForm-section eForm-wrapper>
              <mat-form-field appearance="outline" class="w100">
                <mat-label>Descripción</mat-label>
                <textarea matInput formControlName="description" placeholder="Descripción"></textarea>
              </mat-form-field>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon">document_scanner</mat-icon>
              Serie y versión
            </ng-template>
            <app-card-subtitle>Versión</app-card-subtitle>
            <div eForm-wrapper>
              <div eForm-section>
                <mat-form-field appearance="outline">
                  <mat-label>Código versión</mat-label>
                  <input matInput formControlName="version" placeholder="Código versión">
                </mat-form-field>

                <!-- Código Intrastat -->
                <mat-form-field appearance="outline">
                  <mat-label>Código Intrastat</mat-label>
                  <input matInput formControlName="intrastat" placeholder="Código Intrastat">
                </mat-form-field>

                <!-- Descripción -->

              </div>
            </div>
            <app-card-subtitle>Serie</app-card-subtitle>
            <div eForm-wrapper>
              <div eForm-section>
                <mat-form-field appearance="outline">
                  <mat-label>Código serie</mat-label>
                  <div matSuffix [ngClass]="{'vh' : !brandModelInputComponent.loadingModels}">
                    <mat-spinner [strokeWidth]="6" [diameter]="25" mode="indeterminate" style="margin-right: 5px;"
                      *ngIf="brandModelInputComponent.loadingModels"></mat-spinner>
                  </div>

                  <input #series matInput placeholder="Serie" formControlName="serie_id" [matAutocomplete]="serieAuto"
                    (focus)="clearSerie()">
                  <mat-autocomplete #serieAuto="matAutocomplete" (optionSelected)="onSerieSelected($event.option.value)"
                    [displayWith]="brandModelInputComponent.displaySerie">
                    <mat-option
                      *ngFor="let s of brandModelInputComponent.filteredSeries | slice:0:brandModelInputComponent.MAX_RESULTS"
                      [value]="s"
                      (mousedown)="brandModelInputComponent.selectingOption = true; brandModelInputComponent.onSerieSelected(s,false)">{{s.name}}</mat-option>
                  </mat-autocomplete>
                </mat-form-field>

                <!-- Denominación serie -->
                <mat-form-field appearance="outline">
                  <mat-label>Denominación serie</mat-label>
                  <input matInput formControlName="serie_name" placeholder="Denominación serie">
                </mat-form-field>
              </div>

            </div>
          </mat-tab>

        </mat-tab-group>
      </form>
      <div class="df jcc mt10">
        <button mat-flat-button color="primary" (click)="update()">Guardar</button>
      </div>
    </div>
  </div>
</app-card>