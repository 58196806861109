<table mat-table [dataSource]="dataSource" *ngIf="faults.length; else noData">
    <ng-container *ngIf="client != undefined && product == undefined" matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef> Referencia </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.product;else noRow" class="vam">{{element.product.reference}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="document">
        <th mat-header-cell *matHeaderCellDef> Documento </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.document; else noRow" class="vam">{{element.document}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef> Cantidad </th>
        <td mat-cell *matCellDef="let element">
            <span class="vam">{{element.quantity}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="created_at" *ngIf="responsiveS.w > 500">
        <th mat-header-cell *matHeaderCellDef> Creación </th>
        <td mat-cell *matCellDef="let element">
            <span>{{element.created_at.dayMonthYearFormat()}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="Eliminar" *ngIf="responsiveS.w > 500">
        <th mat-header-cell *matHeaderCellDef>Eliminar</th>
        <td mat-cell *matCellDef="let element">
            <button [disabled]="!canDeleteFault(element)" mat-icon-button (click)="deleteFault(element)">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="goByFult(row)"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>


<ng-template #noRow class="mt10">
    <span class="c_t2">Sin datos</span>
</ng-template>

<ng-template #noData>
    <p *ngIf="faults">Este {{client ? "cliente": "producto"}} no tiene asociado ningúna falta</p>
</ng-template>