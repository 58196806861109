<app-close-dialog-button></app-close-dialog-button>
<p mat-dialog-title>Tiempo estimado</p>
<div mat-dialog-content>
    <form eForm [formGroup]="form">
        <div eForm-section eForm-wrapper>
            <mat-form-field appearance="outline">
                <mat-label>Horas</mat-label>
                <input max="99" type="number" matInput formControlName="estimated_time_hours">
                <mat-error *ngIf="this.form.get('estimated_time_hours')?.hasError('max')">Máx. 99 horas</mat-error>
            </mat-form-field>
    
            <mat-form-field appearance="outline">
                <mat-label>Minutos</mat-label>
                <input max="59" type="number" matInput formControlName="estimated_time_minutes">
                <mat-error *ngIf="this.form.get('estimated_time_minutes')?.hasError('max')">Máx. 59 minutos</mat-error>
            </mat-form-field>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
    <button color="primary" mat-flat-button cdkFocusInitial (click)="onClickOk()" [disabled]="!form.valid">Ok</button>
</div>