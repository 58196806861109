<p class="tac nmb">Trabajado hoy</p>

<div class="df jcc aic mb5">
    <p class="tac c_t2 fss nmb">{{signingS.elapsed}}</p>
    <!-- <mat-icon class="ml5 c_o vam" [filled]="true" *ngIf="signingS.signingData?.now?.shouldSigning">warning</mat-icon> -->
</div>


<mat-progress-bar mode="determinate" [value]="signingS.workedPercentage" class="time-bar"></mat-progress-bar>
<button mat-flat-button color="primary" class="mt10 w100" *ngIf="signingS.canStop" (click)="startStop(); $event.stopPropagation()">Parar</button>
<button mat-flat-button color="primary" class="mt10 w100" *ngIf="signingS.canStart" (click)="startStop(); $event.stopPropagation()">Empezar</button>