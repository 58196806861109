import { IPageStructureItem } from "../interfaces/IPageStructureItem";
import { match } from "../services/search.service";
import { M_AccountingGroup } from "./AccountingGroups/M_AccountingGroup";
import { M_Brand } from "./M_Brand";

export class M_Serie implements IPageStructureItem {
    id:number;
    brand_id: number;
    brand_name: string;
    serie: string;
    name: string;
    accounting: M_AccountingGroup | undefined;
    accounting_id: number;
    accounting_detail: string;
    accounting_agent: string;
    created_at : Date | undefined;
    updated_at : Date | undefined;
    brand : M_Brand | undefined;
    constructor(d: any) {
        this.id = d.id;
        this.brand_id = d.brand_id;
        this.brand_name = d.brand_name;
        this.serie = d.serie;
        this.name = d.name;
        if(d.acc_groups){
            this.accounting  = new M_AccountingGroup(d.acc_groups);
        }
        this.accounting_id = d.accounting_id;
        this.accounting_detail = d.accounting_detail;
        this.accounting_agent = d.accounting_agent;
        this.created_at = d.created_at ? new Date(d.created_at) : undefined;
        this.updated_at = d.updated_at ? new Date(d.updated_at) : undefined;
        if(d.brand){
            this.brand = new M_Brand(d.brand);
            if(this.brand.name != undefined){
                this.brand_name = this.brand.name;
            }
        }
    }
    defaultSearchFilter(text: string): boolean {
       return  match(text, this.brand_name, this.name, this.accounting?.name);
    }
    get itemId(): number | undefined {
        return this.id;
    }
}
