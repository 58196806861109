<div class="reports">
    <div class="report-card">
        <div class="report-header">
            <p>{{report.name}}</p>
            <div class="report-filters">
                <mat-form-field appearance="outline" class="small-form-field">
                    <mat-label>Centro</mat-label>
                    <mat-select [value]="35">
                        <mat-option *ngFor="let center of companyS.company.centers" [value]="center.id">
                            {{ center.name }}
                        </mat-option>
                        <mat-option [value]="'all'">Todos</mat-option>
                    </mat-select>
                </mat-form-field>
                <app-filters [filters]="report.filters"></app-filters>
            </div>
        </div>
        <!-- <div class="report-filter-hint">
            <p>Filtrando por lo que sea</p>
        </div> -->
        <div class="report-cards">
            <div class="result" *ngFor="let result of report.results">
                <p class="result-title">{{result.title}}</p>
                <p class="result-value">
                    <span *ngIf="result.value; else noVal">
                        <span *ngIf="!result.ismoney">{{result.value}}</span>
                        <span *ngIf="result.ismoney" money [val]="result.value || 'Sin valor'"></span>
                    </span>
                    <ng-template #noVal>
                        <span class="c_t2">Sin valor</span>
                    </ng-template>
                </p>
            </div>
        </div>
    </div>

</div>