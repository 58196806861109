<div class="df aic" *ngIf="!circularStatus; else circular">
    <app-card-subtitle *ngIf="showText" class="subtitleNoBottom" [first]="true">Estado</app-card-subtitle>
    <div>
      <mat-chip-set>
          <mat-chip [class]="'albaran_status albaran_status' + status?.num" [ngClass]="{'cp_i' : menuIconOpen || pointer}">
              <mat-icon matChipAvatar class="status_icon" *ngIf="status && showIcon && status.open">content_paste_go</mat-icon>
              <mat-icon matChipAvatar class="status_icon" *ngIf="status && showIcon && status.closed">assignment_turned_in</mat-icon>
              <mat-icon matChipAvatar class="status_icon" *ngIf="status && showIcon && status.invoiced">receipt_long</mat-icon>
            <span [class]="'status-text' + status?.num">{{status?.name}}</span>
            <mat-icon style="pointer-events: none;" matChipRemove *ngIf="menuIconOpen" class="drop-down-icon">arrow_drop_down</mat-icon>
          </mat-chip>
      </mat-chip-set>
    </div>
</div>

<ng-template #circular>
  <div [class]=" 'df jcfs aic fss albaran_status ' + ' _circular' + status?.num">
    <mat-icon matChipAvatar>circle</mat-icon> <span *ngIf="showText">{{status?.name}}</span>
  </div>
</ng-template>