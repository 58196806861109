<div class="advanced-details-container my-presence-grid">
    <to-do></to-do>
    <!-- Today time-->
    <div class="today">
        <app-card [contentLoaded]="signingComponent.signingS.loaded">
            <app-card-title>Tu jornada hoy</app-card-title>
            <div class="today-container">
                <div super-user (click)="openSchedule()" class="today-square schedule" [matTooltip]="'Ver horario'">
                    <mat-icon class="c_t2">schedule</mat-icon>
                    <p class="fw600">Horario</p>
                    <p>{{signingComponent.signingS.expectedTimeString}}</p>
                </div>
                <div class="vertical-bar"></div>
                <div class="today-square">
                    <mat-icon class="c_t2">location_away</mat-icon>
                    <p class="fw600">Centro</p>
                    <p>Esto es un ejemplo</p>
                </div>
                <div class="df fw1 work-time-container">
                    <app-signing-timer #signingComponent class="df fdc fw1"></app-signing-timer>
                </div>
                <div class="today-breakdown-container">
                    <app-today-signing-breakdown [signingData]="signingComponent.signingS.signingData"></app-today-signing-breakdown>
                </div>
            </div>
        </app-card>
    </div>


    <!-- My presence-->
    <div class="full">
        <app-card [contentLoaded]="signingComponent.signingS.loaded">
            <app-card-title>Mi presencia</app-card-title>
            <div class="df aic jcfe gap5">
                <button mat-icon-button (click)="adjustMonth('from')">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <mat-form-field appearance="outline">
                    <mat-label>Desde</mat-label>
                    <input matInput [matDatepicker]="picker_1" [max]="toDate.value" [formControl]="fromDate"
                        placeholder="dd/mm/yyyy" (click)="picker_1.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker_1"></mat-datepicker-toggle>
                    <mat-datepicker #picker_1></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Hasta</mat-label>
                    <input matInput [matDatepicker]="picker_2" [min]="fromDate.value" [formControl]="toDate"
                        placeholder="dd/mm/yyyy" (click)="picker_2.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker_2"></mat-datepicker-toggle>
                    <mat-datepicker #picker_2></mat-datepicker>
                </mat-form-field>
                <button mat-icon-button (click)="adjustMonth('to')">
                    <mat-icon>chevron_right</mat-icon>
                </button>
            </div>
            <table class="eina-table">
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Entrada mañana</th>
                        <th>Salida mañana</th>
                        <th>Entrada tarde</th>
                        <th>Salida tarde</th>
                        <th>Descanso</th>
                        <th>Horario teórico</th>
                        <th>Total horas</th>
                        <th>Diferencia</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let signing of signingComponent.signingS.signingData?.history" (click)="openPresenceEdit(signing)">
                        <!-- Fecha -->
                        <td>{{signing.day.getDayName(true)}}<br>{{signing.day.dayMonthYearFormat()}}</td>
                        <!-- Entrada mañana -->
                        <td>{{signing.morningStart?.hourMinuteSecond() || "--:--:--"}}</td>
                        <!-- Salida mañana -->
                        <td>{{signing.morningEnd?.hourMinuteSecond() || "--:--:--"}}</td>
                        <!-- Entrada tarde -->
                        <td>{{signing.afternoonStart?.hourMinuteSecond() || "--:--:--"}}</td>
                        <!-- Salida tarde -->
                        <td>{{signing.afternoonEnd?.hourMinuteSecond() || "--:--:--"}}</td>
                        <!-- Descanso -->
                        <td>{{signing.break}}</td>
                        <!-- Horario teórico -->
                        <td>{{signing.schedule.getTotalTimeString()}}</td>
                        <!-- Total horas -->
                        <td>{{signing.elapsed}}</td>
                        <!-- Diferencia -->
                        <td *ngIf="signing.getTeoricDifference() as diff" [ngClass]="{
                                'red-time' : diff.minutes > 0 || diff.minutes > 0,
                                'green-time' : diff.minutes < 0 || diff.minutes < 0
                                }">
                            {{abs(diff.hours)+"h " + abs(diff.minutes) + "m"}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </app-card>
    </div>

</div>