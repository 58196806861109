import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { M_CustomProduct } from '../../models/Products/M_CustomProduct';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ViewPath } from 'src/app/app-routing.module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { max_price } from '../../constants/constants';
import { getQuantityFromTime, hourToTime } from '../../constants/time-picker-constants';
import { M_Action } from '../../models/M_Action';
import { ApexAxisChartSeries, ApexChart, ChartComponent, ApexTooltip, ApexDataLabels, ApexXAxis, ApexPlotOptions, ApexStroke } from "ng-apexcharts";
import { CustomProductType } from '../../enums/CustomProductType';
import { getPrimaryColor, getAccentColor } from 'src/app/utils/FunctionUtils';
import { CustomTime } from 'src/app/custom-classes/CustomTime';
import { RouterService } from 'src/app/services/router.service';
import { FormService } from 'src/app/services/form.service';
import { SETTINGS_TAB } from 'src/app/views/settings/settings.component';

export interface IAddTime {
  showPriceHour: boolean,
  action?: M_Action,
  ct?: CustomTime,
  priceHour?: number,
  product?: M_CustomProduct,
}

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'app-add-time',
  templateUrl: './add-time.component.html',
  styleUrls: ['./add-time.component.css']
})
export class AddTimeComponent implements OnInit {

  @ViewChild(ChartComponent) chart?: ChartComponent;
  public chartOptions?: Partial<ChartOptions>;
  custom: M_CustomProduct | undefined;
  form: UntypedFormGroup;
  v = ViewPath;
  ST = SETTINGS_TAB;

  constructor(public routerS: RouterService, private fs: FormService, private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddTimeComponent>, @Inject(MAT_DIALOG_DATA) public data: IAddTime) {
    this.form = this.fb.group({
      hours: [''],
      minutes: [''],
      is_hours: [true],
      name:['']
    })
    this.form.get('hours')!.valueChanges.subscribe(v => { this.updateChart() })
    this.form.get('minutes')!.valueChanges.subscribe(v => { this.updateChart() })
  }

  ngOnInit(): void {
    if (this.data.showPriceHour) {
      let fc = new FormControl('price');
      fc.addValidators([Validators.required, Validators.min(0), Validators.max(max_price)]);
      this.form.addControl('price', fc);
      this.form.patchValue({ price: this.data.priceHour })
    }

    if (this.data.ct) {
      this.form.patchValue({ hours: this.data.ct.getHours() })
      this.form.patchValue({ minutes: this.data.ct.getMinutes() })
      if(this.data.product){
        this.form.patchValue({ name: this.data.product.name })
      }
    }

    this.chartOptions = this.constructChart();
  }

  preventNegativeInput(event: KeyboardEvent) {
    if (event.key === '-') {
      event.preventDefault();
    }
  }

  get hours(): number {
    let val = this.form.get('hours')!.value;
    return val ? val : 0;
  }

  get minutes(): number {
    let val = this.form.get('minutes')!.value;
    return val ? val : 0;
  }

  create() {
    let t: CustomTime = new CustomTime(this.hours.toString(), this.minutes.toString());
    let q = getQuantityFromTime(t);
    if (this.fs.isOk(this.form) && t != undefined && q != 0) {
      let c = new M_CustomProduct(this.form.value);
      if(!c.name){
        c.name = "MANO DE OBRA";
      }else{
      }
      c.setQuantity(q);
      c.type = CustomProductType.TIME;
      c.reference = "";
      c.buy_price = 0;
      this.dialogRef.close(c);
    }
  }

  get disabled() {
    return (!this.hours && !this.minutes) || !this.form.valid;
  }

  get totalWorked() {
    let worked = 0;
    /** Action times */
    if (this.data.action) {
      worked = this.data.action.timers.reduce((sum, t) => sum += (t.elapsed.breakdown.hours) + (t.elapsed.breakdown.minutes / 60), 0);
    }
    return worked.castDecimals(2);
  }

  /** Prevent the time passed as a parameter to the dialog from being added more than once. */
  preventScreenAndParamTime(): M_CustomProduct[] {
    let tasks: M_CustomProduct[] = [];
    if (this.data.action) {
      this.data.action.groups.forEach(g => {
        g.products.forEach(p => {
          if (p.instanceofCustom() && p.isTime) { tasks.push(p); }
        })
      })
      if (this.data.ct) {
        tasks.forEach((t, index) => {
          let ct = new CustomTime(hourToTime(t.quantity));
          if (ct.getValue() == this.data.ct?.getValue()) {
            tasks.splice(index, 1);
            return;
          }
        })
      }
    }
    return tasks;
  }

  get totalImputed() {

    let imputed = 0;

    if (this.data.action) {
      let tasksWithTime = this.preventScreenAndParamTime();
      imputed = tasksWithTime.reduce((sum, t) => {
        let ct = new CustomTime(hourToTime(t.quantity));
        return sum + ct.getHours() + (ct.getMinutes() / 60);
      }, 0);
    }

    /** Current screen time */
    let screenTime: CustomTime = new CustomTime(this.hours.toString(), this.minutes.toString());

    imputed += screenTime.getHours() + (screenTime.getMinutes() / 60);

    return imputed.castDecimals(2);
  }

  constructChart() {
    if (this.data.action) {
      let chart: Partial<ChartOptions> = {
        series: [
          {
            name: "Tiempo imputado",
            data: [this.totalImputed],
            color: getAccentColor()
          },
          {
            name: "Tiempo trabajado",
            data: [this.totalWorked],
            color: getPrimaryColor()
          }
        ],
        chart: {
          type: "bar",
          height: 150,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top"
            }
          }
        },
        tooltip: {
          y: {
            formatter: (val) => this.hourFormatter(val)
          }
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"]
          },
          formatter: (val) => this.hourFormatter(val),
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"]
        },
        xaxis: {
          categories: ["Tiempo"],
          labels: {
            formatter: (val) => this.hourFormatter(val)
          }
        }
      };
      return chart;
    }
    return undefined;
  }

  hourFormatter(val: string | number | number[]) {
    if (typeof val == "number") {
      let t = new CustomTime(hourToTime(val));
      let h = t.getHours();
      let m = t.getMinutes();
      return "" + (h ? h + "h" : "") + (h && m ? " " : "") + (m ? m + "m" : "");
    }
    return val.toString() + "h";
  }

  updateChart() {
    this.chart?.updateSeries(
      [
        {
          name: "Tiempo imputado",
          data: [this.totalImputed]
        },
        {
          name: "Tiempo trabajado",
          data: [this.totalWorked]
        }
      ]
    )
  }

}