import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { M_Product } from 'src/app/models/Products/M_Product';

@Component({
  selector: 'app-product-details-reserves-dialog',
  templateUrl: './product-details-reserves-dialog.component.html',
  styleUrls: ['./product-details-reserves-dialog.component.css']
})
export class ProductDetailsReservesDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public p: M_Product) { }

}
