<div recambios [userCanGo]="v.createStoreHouseEntry">
    <ng-container *ngIf="style == 'flat'">
        <button mat-flat-button [color]="'primary'" [go]="v.createStoreHouseEntry" [param]="{'client' : c.client_id}">
            <mat-icon>archive</mat-icon>
            Entrada de almacén
        </button>
    </ng-container>
    <ng-container *ngIf="style == 'menu-option'">
        <button mat-menu-item [go]="v.createStoreHouseEntry" [param]="{'client' : c.client_id}">
            <mat-icon>archive</mat-icon>
            Entrada de almacén
        </button>
    </ng-container>
</div>