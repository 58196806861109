import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProTipsDialogComponent } from '../pro-tips-dialog/pro-tips-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pro-tips-button',
  templateUrl: './pro-tips-button.component.html',
  styleUrls: ['./pro-tips-button.component.css']
})
export class ProTipsButtonComponent {
  e = environment;
  constructor(private d: MatDialog) { }
  openTip() { this.d.open(ProTipsDialogComponent, {autoFocus : false}); }
}
