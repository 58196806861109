<app-close-dialog-button *ngIf="isOnDialog"></app-close-dialog-button>

<p mat-dialog-title *ngIf="isOnDialog">{{this.serie?.name}}</p>
<div class="advanced-details-container" mat-dialog-content>
    <app-card [contentLoaded]="loaded" [noStyle]="isOnDialog">
        <app-card-title>Editar Serie</app-card-title>
        <app-serie-form [serie]="this.serie"></app-serie-form>
        <div class="df aic jcc mt10">
            <button mat-flat-button [disabled]="!serieForm?.form?.valid" color="primary" (click)="create()">Crear
                serie</button>
        </div>
    </app-card>
</div>