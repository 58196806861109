<div>
    <mat-form-field appearance="outline">
        <mat-label>Buscar</mat-label>
        <input type="text" #searchInput matInput placeholder="Buscar" (input)="applySearchFilter(searchInput.value)">
        <mat-icon matSuffix class="cp" (click)="applySearchFilter(searchInput.value)">search</mat-icon>
    </mat-form-field>
    <app-filters #eventFilter [filters]="filters" (onfiltersDone)="filter($event)"></app-filters>
</div>

<table mat-table [dataSource]="dataFiltrada">
    <!-- Type Mov Col -->
    <ng-container matColumnDef="factor">
        <th mat-header-cell *matHeaderCellDef>Tipo</th>
        <td mat-cell *matCellDef="let element">
            <div class="df aic vac">
                <div *ngIf="responsiveS.w > 500">
                    <mat-icon class="mr10" *ngIf="element.factor === moveEnum.ENTRADA">save_alt</mat-icon>
                    <mat-icon class="mr10" *ngIf="element.factor === moveEnum.SALIDA">receipt_long</mat-icon>
                    <mat-icon class="mr10" *ngIf="element.factor === moveEnum.NO_MOVE">keyboard_tab</mat-icon>
                </div>
                <div>
                    <p class="nmb fw400">
                        <span class="fss">{{element.transac_name}} </span>
                    </p>
                </div>
            </div>
        </td>
    </ng-container>

    <!-- Quantity Col -->
    <!-- <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef>Unidades</th>
        <td mat-cell *matCellDef="let element">
            <span prod-move-number [val]="element.quantity"></span>
        </td>
    </ng-container> -->

    <!-- Disponible Col -->
    <ng-container matColumnDef="quanti_mov">
        <th mat-header-cell *matHeaderCellDef>Cantidad</th>
        <td mat-cell *matCellDef="let element">
            <span prod-move-number [val]="element.quanti_mov"></span>
        </td>
    </ng-container>

    <!-- Location Col -->
    <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Ubicación</th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.location_name; else noRow"> {{element.location}}</span>
        </td>
    </ng-container>

    <!-- Documents Col -->
    <ng-container matColumnDef="document">
        <th mat-header-cell *matHeaderCellDef>Documento</th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.document; else noRow">{{element.document}}</span>
        </td>
    </ng-container>

    <!-- Created Col -->
    <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef>Fecha</th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.created_at;else noRow">{{element.created_at.shortFormat()}} </span>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openDialog(row)"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>

<ng-template #noRow>
    <span class="c_t2">Sin datos</span>
</ng-template>