import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ButtonToggleFilter } from '../../../custom-classes/Filter';

@Component({
  selector: 'app-button-toggle-filter',
  templateUrl: './button-toggle-filter.component.html',
  styleUrls: ['./button-toggle-filter.component.css']
})
export class ButtonToggleFilterComponent {
  @Input() filter! : ButtonToggleFilter;
  constructor(private chdRef: ChangeDetectorRef) { }
  clear() {
    this.filter.checked = null;
    this.chdRef.detectChanges();
  }
}
