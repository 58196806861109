import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-view-title-lord-icon',
  templateUrl: './view-title-lord-icon.component.html',
  styleUrls: ['./view-title.component.css']
})
export class ViewTitleLordIconComponent implements OnInit {
  @Input() pageTitle! : string;
  @Input() lordIcon!: TemplateRef<any>;
  constructor() { }
  ngOnInit(): void {}
}
