import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { M_User } from 'src/app/models/M_User';
import { ApiService } from 'src/app/services/Api/api.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {

  public form: UntypedFormGroup;

  constructor( @Inject(MAT_DIALOG_DATA) public u: M_User , public dRef: MatDialogRef<ChangePasswordComponent>, private formBuilder: UntypedFormBuilder, private apiS: ApiService, private snackS: SnackService) {
    this.form = this.formBuilder.group({
      pass1: [''],
      pass2: ['', [Validators.required]],
    });
  }



  updatePass(pass: string) {
    this.apiS.updatePass(pass).then(_res => {
      this.snackS.show("Contraseña modificada con éxito");
      this.dRef.close();
    })
  }

}
