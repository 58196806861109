
<app-close-dialog-button *ngIf="isDialog"></app-close-dialog-button>
<p *ngIf="isDialog" mat-dialog-title>Listado de vehículos</p>


<div mat-dialog-content>
    <app-vehicle-row *ngFor="let v of showVehicles" [item]="v" (onClick)="closeDialog()"></app-vehicle-row>
</div>

<button mat-stroked-button class="w100" *ngIf="showMore" (click)="openDialog()">
    <mat-icon>format_list_bulleted</mat-icon>
    Ver todos ({{vehicles.length}})
</button>