<p mat-dialog-title>{{title}}</p>

<div mat-dialog-content>
    <p>{{subtitle}} <span class="fw500">{{subtitleBold}}</span> {{isQuestion ? '?' : ''}}</p>

    <p>Introduce el código de seguridad <span class="fw500">{{fd.securityCode}}</span> para poder proceder</p>
    
    <div class="df jcc">
        <app-fourdigits #fd (deleteByEnter)="deleteObj()"></app-fourdigits>
    </div>
</div>

<div mat-dialog-actions align="center">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button (click)="deleteObj()" color="warn" [disabled]="!fd.isOk()" mat-flat-button cdkFocusInitial>Eliminar</button>
</div>