import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/Api/api.service';
import { ViewPath } from 'src/app/app-routing.module';
import { forkJoin } from 'rxjs';
import { M_User } from 'src/app/models/M_User';
import { WorkloadService } from 'src/app/services/workload.service';
import { PrevisionChartOtions, chartGenerationPrevision } from './chart-generation/chartPrevision';
import { SemiCircleChart, semiCircleChart } from './chart-generation/semiCircle';
import { environment } from 'src/environments/environment';
import { ORPageFiltesrEnum } from '../or/or.component';
import { UserService } from 'src/app/services/EinaMainData/user.service';
import { BillPageFiltesrEnum } from '../invoices/invoices.component';
import { PeriodEnum } from 'src/app/enums/PeriodEnum';
import { or_status_close, or_status_invoiced, or_status_open } from 'src/app/custom-classes/or_states';
import { isMobile } from 'src/app/utils/Browserutils';
import { RouterService } from 'src/app/services/router.service';
import { Endpoint } from 'src/app/custom-classes/Endpoint';
import { M_Dashboard } from 'src/app/models/M_Dashboard';
import { endpoints } from 'src/app/constants/Endpoints';
import { roleGroup } from 'src/app/enums/RolesEnum';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  e = endpoints;
  v = ViewPath;
  public previsionChart?: Partial<PrevisionChartOtions>;
  public semiCircleChart?: Partial<SemiCircleChart>;
  data?: M_Dashboard;
  loaded = false;
  pe = PeriodEnum;
  open = or_status_open;
  closed = or_status_close;
  invoiced = or_status_invoiced;
  isp = isMobile()
  env = environment;
  BPFE = BillPageFiltesrEnum;
  OPFE = ORPageFiltesrEnum;
  test = false;

  deployDate: string | null = null;


  constructor(private apiS: ApiService, private routerS: RouterService, public wS: WorkloadService,
    public userS: UserService, private http: HttpClient) {
    console.log(new Date().getDay())
    if (this.isWorkloadDashboard) {
      this.routerS.goTo(ViewPath.cargataller)
    }

    let allEndpoints: Endpoint[] = []
    Object.keys(endpoints).forEach(key => {
      allEndpoints.push(endpoints[key as keyof typeof endpoints])
    });
  }

  get isWorkloadDashboard() {
    let role = this.userS.getRole();
    if (!role) { return false; }
    return roleGroup.workloadAsDashboard.includes(role)
  }

  get isBigMamaDashboard() {
    let role = this.userS.getRole();
    if (!role) { return false; }
    return roleGroup.bigMamaAsDashboard.includes(role)
  }

  ngOnInit(): void {
    let a = this.apiS.dashboard();
    forkJoin([a]).subscribe(res => {
      this.data = res[0];
      /** ----- CHART GENERATION ------ */
      // 1 -  Prevision chart
      this.previsionChart = chartGenerationPrevision(this.data);
      // 2 - Workload chart
      this.semiCircleChart = semiCircleChart(this.data);
    })
    console.log("?")
    if (!environment.local && !environment.production) {
      this.http.get<{ deployDate: string }>('/assets/deploy-info.json')
        .subscribe(
          data => this.deployDate = data.deployDate,
          error => console.error('No se pudo cargar la fecha de despliegue', error)
        );
    }
  }

}
