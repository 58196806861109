import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { VehicleRowContainerComponent } from 'src/app/components/vehicle-row-container/vehicle-row-container.component';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { ParamsService } from 'src/app/services/params.service';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-client-details-vehicle-table',
  templateUrl: './client-details-vehicle-table.component.html',
  styleUrls: ['./client-details-vehicle-table.component.css']
})
export class ClientDetailsVehicleTableComponent {
  MAX_RESULTS = 2;
  v = ViewPath;
  @Input({ required: true }) c!: M_Contact;

  constructor(public paramS: ParamsService, public responsiveS: ResponsiveService, private d: MatDialog) { }

  goVehicle(v: M_Vehicle) {
    this.paramS.goVehicleDetails(v);
  }

  openAllVehicles() {
    this.d.open(VehicleRowContainerComponent, { data: this.c.vehicles, autoFocus: false });
  }

}
