<app-close-dialog-button></app-close-dialog-button>
<p mat-dialog-title>{{data ? 'Editar comentario' : 'Añadir comentario'}}</p>
<div mat-dialog-content>
    <form [formGroup]="form" class="add-time-dialog p10">
        <div class="form">
            <div class="df jcsb">
                <mat-form-field class="example-full-width">
                    <mat-label>Comentario</mat-label>
                    <textarea matInput #comment formControlName="comment" class="styl" maxlength="140" placeholder="Escribe aquí tu comentario..."></textarea>
                    <mat-hint align="end">{{comment.value.length}} / 140</mat-hint>
                </mat-form-field>

            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions align="center">
    <button [mat-dialog-close]="false" mat-button>Cancelar</button>
    <button color="primary" mat-flat-button (click)="create()" [disabled]="disabled">{{'Guardar'}}</button>
</div>