export enum NotificationType{
    CITAMODIFICADA = 0,
    CITACREADA = 1,
    CITAFINALIZADA = 14,
    CITAELIMINADA = 15,
    TICKETCOMMENT = 2,
    TICKETSTATUS = 3,
    TICKETNEW = 4,
    HOLIDAYS = 5,
    ORCHANGE = 6,
    ORASSIGN = 7,
    ORFINISH = 8,
    MASSIVE_MATRICULAS = 9,
    MASSIVE_STOCKVO = 10,
    MASSIVE_OR = 11,
    MASSIVE_OPERARIOS = 12,
    MASSIVE_ARTICULOS = 13,
    TICKETASSIGNCHANGE = 14,
}