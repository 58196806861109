import { AfterViewInit, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { AccountingGroupSelectorComponent } from 'src/app/components/accounting-group-selector/accounting-group-selector.component';
import { BrandModelInputComponent } from 'src/app/components/brand-model-input/brand-model-input.component';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { M_AccountingGroup } from 'src/app/models/AccountingGroups/M_AccountingGroup';
import { M_Brand } from 'src/app/models/M_Brand';
import { M_Model } from 'src/app/models/M_Model';
import { M_Serie } from 'src/app/models/M_Serie';
import { M_Color } from 'src/app/models/Vehicles/M_Color';
import { ApiService } from 'src/app/services/Api/api.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-create-color',
  templateUrl: './create-color.component.html',
  styleUrls: ['./create-color.component.css']
})
export class CreateColorComponent  extends ParameterStateComponent implements OnInit{
  v = ViewPath;
  public color:M_Color | undefined;
  public acc_group:M_AccountingGroup[]=[];
  public loaded: boolean = false;
  public form: UntypedFormGroup;
  public isEdit: boolean = false;
  @ViewChild(BrandModelInputComponent, { static: true }) inputsBrandAndModelSerie!: BrandModelInputComponent;
  @ViewChild(AccountingGroupSelectorComponent, { static: true }) accGroup!: AccountingGroupSelectorComponent;
  constructor(private apiS: ApiService, private formBuilder: UntypedFormBuilder, route: ActivatedRoute, routerS: RouterService,
    @Optional() public dialogRef: MatDialogRef<CreateColorComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: M_Color | undefined,
  ) {
    super(routerS, route, ["color"]);
    this.form = this.formBuilder.group({
      serie: ['',],
      name: ['', [Validators.required]],
      accounting: [''],
      accounting_id: [''],
      accounting_detail: [''],
      accounting_agent: [''],
      model: [''],
      color: [''],
      version: [''],
      brand:[''],
      brand_id:[''],
      model_id:[''],
      serie_id:[''],
      code:[''],
      price:[''],
      import_cost:[''],
      cost:[''],
      type:['']
    }) 
    
  }
ngOnInit(): void {

  if(this.isOnDialog && this.data != undefined){
    this.color = new M_Color(this.data);
    this.loaded = true;
    this.form.patchValue(this.color);
    if(this.color.brand){
      if(this.color.brand.series){
      this.inputsBrandAndModelSerie.form.patchValue({series:this.color.brand.series})}
      this.inputsBrandAndModelSerie.showingSeries = this.color.brand.series;
    }
    console.log(this.color);
    if(this.color.accounting != undefined){
      this.form.patchValue({accounting_id:this.color?.accounting?.id});
      this.form.get('accounting_id')?.disable();
    }
  }
}
  override onParam(_k: string, v: any) {
    console.log(v);
    if(v != undefined){
      this.apiS.getColorById(v).then(res => {
        if (res) {
          this.form.patchValue(res);
          this.color = res;
          this.loaded = true;
          if(this.color?.accounting == undefined){
            this.callGroupCntApi();
          }
        }
      })
    }else{
      this.callGroupCntApi();
    }
    
  }
  get showInputs(){
    return this.inputsBrandAndModelSerie.showingSeries.length > 0;
  }
  selectBrand(b: M_Brand) {
    let brand = this.form.get('brand')?.value;
    if(brand){
      this.form.patchValue({brand_id:b.id});
    }
  }
  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }
  selectSerie(s: M_Serie) {
    let model = this.form.get('model')?.value;
    if(model){
      this.form.patchValue({accounting:s.accounting});
    }
  }
  selectModel(m:M_Model){
    let serie = this.form.get('serie')?.value;
    if(m){
      if(serie.accounting != undefined){
        this.form.patchValue({accounting:serie.accounting});
        this.form.get('accounting')?.disable();
      }
      
    }
  }
  get valueAccountingName(){  
    return this.form.get('accounting')?.value; 
  }
  clearSerie(){
    this.form.patchValue({accounting:""});
    this.form.get('accounting')?.enable();
  }
  callGroupCntApi(){
    this.apiS.getGroupAccouting().then(res=>{
      if(res){
        this.acc_group = res;
      }
    });
  }
  onChangeSelect(acc:M_AccountingGroup){
    this.form.get('accounting')?.setValue(acc);
  }
  hasSerieAndModelo(): boolean {
    return this.form.get('serie')?.value != undefined && this.form.get('modelo')?.value != undefined;
  }
  create(){
    let s = this.form.get('serie')?.value;
    this.form.patchValue({accounting:s.accounting});
    console.log(this.form.getRawValue());
  }
}
