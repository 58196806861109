<!-- Optional close button-->
<app-close-dialog-button></app-close-dialog-button>

<!-- Mat dialog title-->
<p mat-dialog-title>Añadir proveedor</p>

<!-- Dialog content -->
<div mat-dialog-content>
    <app-class-searcher #providerSearch [showLeftNumber]="false" class="w100" [masterClass]="provider" [width100]="true"
        searchPlaceHolder="Buscar proveedores" [extendedInfo]="true" [disableIf]="disabledIf.bind(this)"
        [specialRow]="'Crear proveedor'"
        [createData]="{
            text : 'Crear proveedor',
            by : 'dialog',
            forceType : CE.PROVIDER
        }">
    </app-class-searcher>
</div>

<!-- Mat dialog actions -->
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="undefined">Cancelar</button>
    <button [disabled]="!providerSearch.selected" color="primary" mat-flat-button (click)="closeWithProvider()">Guardar</button>
</div>