import { Component, Input } from '@angular/core';
import { Comentario, CommentManager } from '../../../custom-classes/Comentarios';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CommentTypes } from '../../../enums/CommentTypes';

interface ComentarioNode {
  text: string;
  children?: Comentario[];
}

@Component({
  selector: 'app-comentarios-moto',
  templateUrl: './comentarios-moto.component.html',
  styleUrls: ['./comentarios-moto.component.css']
})
export class ComentariosMotoComponent {
  @Input() isEina : boolean = false;
  cm = CommentManager;
  treeControl = new NestedTreeControl<ComentarioNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<ComentarioNode>();
  hasChild = (_: number, node: ComentarioNode) => !!node.children && node.children.length > 0;
  TREE_DATA: ComentarioNode[] =[];

  constructor() {}

  ngOnInit(){
    if(this.isEina){
      this.TREE_DATA= [
        {
          text: 'Selecciona los servicios',
          children: this.isEina ? CommentManager.getTaskEina().filter(c => c.section.includes(CommentTypes.eina)) : CommentManager.getTaskEina(),
        }
      ]; 
    }else{
      this.TREE_DATA= [
        {
          text: 'Revisión periódica',
          children: this.isEina ? CommentManager.getTaskEina().filter(c => c.section.includes(CommentTypes.revisionperiodica)) :  CommentManager.getPeriodicReivisonSection(),
        },
        {
          text: 'Revisión',
          children: this.isEina ? CommentManager.getTaskEina().filter(c => c.section.includes(CommentTypes.revision)) : CommentManager.getRevisionSection(),
        }
        ,
        {
          text: 'Cambio',
          children: this.isEina ? CommentManager.getTaskEina().filter(c => c.section.includes(CommentTypes.cambio)) : CommentManager.getChangeSection(),
        }
        ,
        {
          text: 'Reglaje',
          children: this.isEina ? CommentManager.getTaskEina().filter(c => c.section.includes(CommentTypes.reglaje)) : CommentManager.getAdjustmentSection(),
        },
        {
          text: '',
          children: this.isEina ? CommentManager.getTaskEina().filter(c => c.section.includes(CommentTypes.eina)) : CommentManager.getTaskEina(),
        }
      ];
    }
    
    this.dataSource.data = this.TREE_DATA;

  }

  setSelected(change: MatCheckboxChange, node: Comentario) {
    node.selected = change.checked;
  }

  getBadge(node : ComentarioNode){
    var count : number | null = null;
    node.children?.forEach(ch => {
      if (ch.selected){
        if (count == null) { count = 1;}
        else {count++;}
      }
    })
    return count;
  }

  get selecteds() {
    var comments : Comentario[] = [];
    this.dataSource.data.forEach(cn => {
      if (cn.children) {
        cn.children.forEach(comment => {
          if (comment.selected) {
            comments.push(comment);
          }
        })
      }
    })
    return comments;
  }
}
