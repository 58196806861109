import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { EinaDataService } from 'src/app/services/EinaMainData/eina-data.service';
import { downloadBlob } from 'src/app/utils/FunctionUtils';

@Component({
  selector: 'app-mtr',
  templateUrl: './mtr.component.html',
  styleUrls: ['./mtr.component.css']
})
export class MtrComponent {

  v = ViewPath;
  type: FormControl<string | null> = new FormControl("I", Validators.required);
  center_id: FormControl<number | null> = new FormControl(null, Validators.required);
  dataIni: FormControl<Date | null> = new FormControl(new Date().firstMonthDay(), Validators.required);
  dataEnd: FormControl<Date | null> = new FormControl(new Date().lastMonthDay(), Validators.required);

  constructor(private apiS: ApiService, public einaDataS : EinaDataService, private fb: FormBuilder, private confirmD: ConfirmDialogService) { }

  export() {

    const t = this.type.value;
    const c = this.center_id.value;
    const dIni = this.dataIni.value;
    const dEnd = this.dataEnd.value;

    if (t && c && dIni && dEnd) {
      this.apiS.exportToMTR({ type: t, center_id : c, dateIni: dIni, dateEnd: dEnd }).then(res => {
        if (res) {
          var downloadName = "MTR - " + new Date().dayMonthYearFormat();
          downloadBlob(res, downloadName);
        }
        else {
          this.confirmD.show({
            title: "Upps...",
            body: "Parece ser que algo no ha ido bien en la exportación",
            showCancel: false,
            confirmTxt: "Ok",
            type: "danger"
          })
        }
      })
    }
  }

  get isOk() {
    return this.type.valid && this.center_id.valid && this.dataIni.valid && this.dataEnd.valid;
  }

}
