import { Component, Input } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-vehicle-rectangle',
  templateUrl: './vehicle-rectangle.component.html',
  styleUrls: ['./vehicle-rectangle.component.css']
})
export class VehicleRectangleComponent {
  v = ViewPath;

  constructor(public responsiveS: ResponsiveService) { }

  @Input({ required: true }) vehicle!: M_Vehicle;
  @Input() onlyIconOn!: number | undefined;

  get clickable() {
    return this.vehicle.vehicle_id && !this.vehicle.deleted;
  }

  get tooltip(){
    return this.vehicle.deleted ? "Vehículo eliminado" : !this.vehicle.vehicle_id ? "Vehículo no registrado" : ""
  }

}
