import { Component, Input } from '@angular/core';
import { randomNumber } from 'src/app/utils/FunctionUtils';

@Component({
  selector: 'app-day-percentage-bar',
  templateUrl: './day-percentage-bar.component.html',
  styleUrls: ['./day-percentage-bar.component.css']
})
export class DayPercentageBarComponent {

  @Input({ required: true }) percentage!: number;

}
