<div class="df jcc aic fdc" style="height: 100vh">
    <p class="fsxxl" style="margin-top: 20px">404</p>
    <p class="fsl">Página no encontrada</p>
    <button mat-button (click)="dashBoardOrLogin()" color="accent">
        Volver al inicio
    </button>
    <script src="https://cdn.lordicon.com/xdjxvujz.js"></script>
    <lord-icon
        src="https://cdn.lordicon.com/exlwjaxd.json"
        trigger="loop"
        class="lord-huge">
    </lord-icon>
</div>