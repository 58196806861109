import { Component, Input, OnInit } from '@angular/core';
import { feature } from 'src/app/utils/FeaturesController';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  @Input() isLandingPage = false;
  features = feature;
  constructor(public subS: SubscriptionService) { }
  ngOnInit(): void { }

  get price() {
    try { return this.subS.status.typedetails.first_user; } 
    catch (error) {
      return 30;
    }
  }

}
