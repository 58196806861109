import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-modules-explanation',
  templateUrl: './modules-explanation.component.html',
  styleUrls: ['./modules-explanation.component.css']
})
export class ModulesExplanationComponent {

  constructor(public dRef: MatDialogRef<ModulesExplanationComponent>){}
}
