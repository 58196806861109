<app-page-structure [pageTitle]="'Citas'" [masterClass]="'appointment'" [autocompleteID]="'appointments'"
    searchLabel="Buscar cita" listTitleText="Listado de citas"
    [data]="[]"
    [displayedHeader]=" ['Número','Fecha','Nombre','Matrícula','Estado', 'Origen']"
    [displayedColumns]="['id_appointment','date_appointment','name_client','license','state', 'origin']"
    [dateFormat]="[undefined, 'show-hour', undefined, undefined,undefined,undefined]" [filters]="filters" [filter]="f"
    [showPagination]="true" [cellZize]="[undefined, 'big', undefined, undefined, 'big', 'small']"
    [specialText]="[undefined]"
    [preIcon]="[appointmentIcon, undefined, undefined, undefined, undefined, appointmentOrigin]"
    [specialClass]="[undefined]" [rightMenu]="rightMenu" 
    (onclickRow)="paramsS.go(v.editAppointment, $event.id)" [quickFilter]="quickFilter"
    [createButton]="
        {
            text : 'Crear cita' , 
            icon : 'event', 
            view : v.createappointment,
            cssClass : 'onboarding-appointments-create'
        }">
</app-page-structure>

<ng-template #rightMenu>
    <app-skeleton class="mla" *ngIf="!center?.urlToken" [width]="176" [height]="36"></app-skeleton>
    <button mat-menu-item class="mla" *ngIf="center?.urlToken" (click)="showAppointments()">
        <mat-icon>desktop_windows</mat-icon>
        Proyectar citas
    </button>
</ng-template>