<form eForm class="create-appoitnment-form" [ngClass]="{ 'is-edit': isEdit }" style="max-width: unset"
  (keydown.enter)="$event.preventDefault()" [formGroup]="form">
  <div class="container">

    <div class="back">
      <app-go-back [text]="'Listado de citas'" [v]="v.appointments"></app-go-back>
    </div>

    <div class="top_card" *ngIf="isEdit">
      <app-card [contentLoaded]="loaded" class="bc_b1">

        <app-advanced-details *ngIf="appointment">

          <app-advanced-title>
            <!-- EDIT APPOINTMENT TITLE -->
            <div class="df aic" *ngIf="isEdit">
              <span class="mr10">{{appointment.id_appointment}}</span>
              <lib-appointment-status-label [menuIconOpen]="isFormOk" [status]="appointment.status"
                [mat-menu-trigger-for]="isFormOk ? status: null"></lib-appointment-status-label>
              <mat-menu #status="matMenu">
                <button *ngIf="!appointment.isCanceled" mat-menu-item class="chip-option"
                  (click)="changeStatus(app_status.canceled)">
                  <lib-appointment-status-label [pointer]="true"
                    [status]="app_status.canceled"></lib-appointment-status-label>
                </button>
                <button *ngIf="!appointment.isPending" mat-menu-item class="chip-option"
                  (click)="changeStatus(app_status.pending)">
                  <lib-appointment-status-label [pointer]="true"
                    [status]="app_status.pending"></lib-appointment-status-label>
                </button>
                <button *ngIf="!appointment.isDone" mat-menu-item class="chip-option"
                  (click)="changeStatus(app_status.done)">
                  <lib-appointment-status-label [pointer]="true"
                    [status]="app_status.done"></lib-appointment-status-label>
                </button>
              </mat-menu>
            </div>
          </app-advanced-title>

          <app-advanced-subtitle>
            {{subtitle}}
          </app-advanced-subtitle>

          <app-advanced-main-actions>
            <div class="df fdc">
              <div [matTooltip]="!appointment.canRA ? 'La cita esta cancelada' : ''">
                <button mat-flat-button (click)="toOr()" color="primary" [disabled]="!isFormOk || !appointment.canRA">
                  {{appointment.or ? 'Generar otra OR' : 'Pasar a OR'}}
                </button>
              </div>
              <p *ngIf="appointment.or" class="nmb tac" style="margin-top: 5px;">
                <span class="fsi">Ya se ha pasado a OR</span>
                <span class="ml5 c_b c_p underline_hover fw600" (click)="goOr(appointment.or.id)">Ver</span>
              </p>
            </div>
          </app-advanced-main-actions>

          <app-advanced-menu-actions>
            <button mat-menu-item (click)="goWokrload()">
              <mat-icon>equalizer</mat-icon>
              Ver en la carga de taller
            </button>
          </app-advanced-menu-actions>

        </app-advanced-details>

        <app-advanced-details-footer *ngIf="appointment" [showTitle]="false">
          <app-footer-right class="df fdr jcsa aifs fww gap10">
            <app-contact-client-rectangle
              [client]="appointment.client || appointment.notRegisteredClient"></app-contact-client-rectangle>
            <app-vehicle-rectangle
              [vehicle]="appointment.vehicle || appointment.notRegisteredVehicle"></app-vehicle-rectangle>
          </app-footer-right>
        </app-advanced-details-footer>

      </app-card>
    </div>

    <div class="interventions">
      <app-card [contentLoaded]="loaded">

        <div [dn_i]="isEdit">
          <app-card-subtitle [first]="true">Propietario</app-card-subtitle>
          <div eForm-wrapper>
            <app-class-searcher [canChange]="appointment == undefined" class="w100" #vehicleSearcher
              [masterClass]="vehicle" searchPlaceHolder="Buscar vehículo o propietario"
              [noSelectionPlaceHolder]="'Ningún vehículo seleccionado'" [specialRow]="''"
              (onSelect)="changeClient($event)" [required]="true" [createData]="{
              text : 'Nuevo vehículo',
              by : 'dialog'
          }">
            </app-class-searcher>
          </div>
        </div>

        <app-card-subtitle>Fecha y hora</app-card-subtitle>
        <div class="onboarding-create-appointment-hour" eForm-wrapper>
          <app-hour-picker [isEdit]="isEdit" [matFormFieldsClasses]="'w50'" [form]="form"></app-hour-picker>
        </div>

        <app-card-subtitle>Receptor y operario</app-card-subtitle>

        <div eForm-section eForm-wrapper>
          <app-class-searcher [form_]="form" [formCname]="'receptor'" [canChange]="!form.disabled" class="w100"
            #receptorSeacher [masterClass]="users" searchPlaceHolder="Buscar receptor"
            [disabled]="form.disabled"
            [noSelectionPlaceHolder]="'Ningún receptor seleccionado'" [specialRow]="''" [required]="false"
            [createData]="undefined" [hint]="'Usuario que recibirá la cita'"></app-class-searcher>

          <app-class-searcher [form_]="form" [formCname]="'worker'" [canChange]="!form.disabled" class="w100"
            #workerSearcher [masterClass]="users" searchPlaceHolder="Buscar operario"
            [disabled]="form.disabled"
            [noSelectionPlaceHolder]="'Ningún operario seleccionado'" [specialRow]="''" [required]="false"
            [createData]="undefined" [hint]="'Usuario que trabajrá en la OR'"></app-class-searcher>
        </div>



        <app-card-subtitle>Intervenciones</app-card-subtitle>
        <div class="onboarding-create-appointment-reparations" eForm-section eForm-wrapper>
          <app-comentarios-moto [isEina]="true" #moto style="display: none"></app-comentarios-moto>
          <app-comentarios [showEnter]="true" [removable]="!appointment?.isDone" [form]="form"
            [label]="'Introduce las intervenciones'" [placeHolder]="'Intervenciones'" [moto]="moto"></app-comentarios>


          <!-- Estimated time-->
          <mat-form-field appearance="outline" (click)="!form.disabled ? openEstimatedTimeDialog() : {}">
            <mat-label>Tiempo estimado</mat-label>
            <input [readonly]="!form.disabled" [disabled]="form.disabled" type="string" matInput
              [value]="formattedTime()">
            <button mat-icon-button matSuffix [disabled]="form.disabled">
              <mat-icon>schedule</mat-icon>
            </button>
          </mat-form-field>

          <div class="dn_i">
            <mat-form-field appearance="outline">
              <input type="number" matInput formControlName="estimated_time_hours">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <input type="number" matInput formControlName="estimated_time_minutes">
            </mat-form-field>
          </div>

        </div>

        <div class="mt10 mb-20 df jcc">
          <button subscribed-user mat-raised-button type="submit" [disabled]="!isFormOk" color="primary" *ngIf="!isEdit"
            class="onboarding-create-appointment-finish" style="width: 130px !important; margin: auto"
            (click)="createAppointment()">
            Crear cita
          </button>
          <button *ngIf="isEdit" mat-flat-button color="primary" (click)="editAppointment()"
            [disabled]="!hasChanges || !isFormOk">
            Guardar
          </button>
        </div>

      </app-card>
    </div>
  </div>
</form>

<!-- MISSING COMPANY INFO COMPONENT-->
<ng-template #notfound>
  <app-model-not-found [title]="'No se ha encontrado la cita'" [goText]="'Ir al listado de citas'"
    [view]="v.appointments">
  </app-model-not-found>
</ng-template>