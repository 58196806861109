import { M_Product } from "../models/Products/M_Product";
import { M_Location } from "../models/M_Location";
import { IProductLineTableComponent } from "../interfaces/IProductLineTableComponent";
import { M_Contact } from "../models/M_Contact";

/**
 * Calculate the total stock of the product.
 * If "Recambios" module is active, returns only the current selected storehouse stock.
 * Also update the current view "Product searchers" stock.
 * @param p Product
 * @param comp I_ProductLineTableComponent
 * @param recambios Is the recambios module activated ?
 * @returns Total stock of the product.
 */
export function getStock(p: M_Product, comp: IProductLineTableComponent, client: M_Contact | undefined, recambios: boolean): number {
  if (!p || !comp || !comp.productLineTable) { return 0 }
  let remaining = getStockByModule(p, client, recambios) - getOthersStockSubsctractionOf(p, comp, recambios);
  updateProductSearcherStock(p, comp, client, remaining,);
  return remaining;
}

/** Get the current product available stock checking if "Recambios" module is active. */
function getStockByModule(p: M_Product, client: M_Contact | undefined, recambios: boolean) {
  if (recambios) {
    if (p.locations.length) {
      try {
        /** To do : this is ok ? (The total reservation by client) --> + p.selectedLocation!.getClientReserved(client) */
        return (p.selectedLocation!.disp + p.selectedLocation!.getClientReserved(client)) - p.absoluteValue();
      }
      catch {
        //console.error("Selected location error")
        return 0 - p.absoluteValue();
      }
    }
    return 0;
  }
  else {
    return p.stock - p.absoluteValue();
  }
}

/**
 * This function is responsible for checking if the product is into other group tasks, lines...
 * If this is the case, calculate what has actually left/added to the stock.
 * @param p Product
 * @param comp DragGroupComponent || CreateBillComponent || CreateOrderComponent ...
 * @param recambios Is the 'Recambios' module activated ?
 * @returns The total to be subtracted from the stock.
 */
function getOthersStockSubsctractionOf(p: M_Product, comp: IProductLineTableComponent, recambios: boolean) {
  let total = 0;
  comp.productLineTable.getOtherOnView(p).forEach(other => {
    if (!recambios || (recambios && p.locations.length && other.selected_location == p.selected_location)) {
      total += other.absoluteValue();
    }
  })
  return total;
}

/**
 * Updates the class searcher stock.
 * @param p Product
 * @param comp DragGroupComponent || CreateBillComponent || CreateOrderComponent
 * @param remaining Total stock remaining.
 */
function updateProductSearcherStock(p: M_Product, comp: IProductLineTableComponent, client : M_Contact | undefined, remaining: number) {
  comp.productLineTable.getAllSearchersOf(p).forEach(p => {
    p.cs_stock = p.locations.length ? allLocationsAvailable(p, comp.productLineTable.getTableProducts(), p.locations, client) : remaining;
  })
}

/**
 * Return the available stock of the diferent locations.
 * @param locationProduct Current product
 * @param products All screen products
 * @param location Location to check the available stock
 * @returns
 */
export function allLocationsAvailable(locationProduct: M_Product, products: M_Product[], locations: M_Location[], client : M_Contact | undefined): number {
  return locations.reduce((sum, location) => { return sum + locationAvailable(locationProduct, products, location, client) }, 0)
}

/**
 * Return the available stock of a location.
 * @param locationProduct Current product
 * @param products All screen products
 * @param location Location to check the available stock
 * @returns
 */
export function locationAvailable(locationProduct: M_Product, products: M_Product[], location: M_Location, client : M_Contact | undefined): number {
  let finalRemaining = 0;
  let remaining = location.disp + location.getClientReserved(client);
  products = products.filter(p => p.selected_location == location.id && p.product_id == locationProduct.product_id);
  products.forEach(p => {
    remaining += p.getInitialValue() - p.quantity;
  })
  finalRemaining += remaining;
  return finalRemaining;
}


export function moveFromLocation(product: M_Product, to: M_Location, otherProducts: M_Product[]) {
  /** Updating current selected location disp. */
  updateSelectedLocationDisp(product, otherProducts, "add");
  /** Change the location */
  product.selected_location = to.id;
  /** Updating new location location disp. */
  updateSelectedLocationDisp(product, otherProducts, "remove");
}

function updateSelectedLocationDisp(product: M_Product, otherProducts: M_Product[], action: "add" | "remove") {
  let locationsToUpdate = otherProducts.map(a => a.locations.find(l => l.id == product.selected_location));
  locationsToUpdate.forEach(loc => {
    if (loc) {
      if (action == "add") {
        loc.disp += product.initialLocationValues.find(initial => initial.location == loc.id)?.quantity || 0;
      }
      else {
        loc.disp -= product.initialLocationValues.find(initial => initial.location == loc.id)?.quantity || 0;
      }
    }
  })
}
