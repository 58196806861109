<div class="speech-bubble" #bubble *ngIf="section?.onProcess" [ngStyle]="getOffset()">
    <button mat-icon-button class="close-icon" (click)="onboardingParent.closeSection()">
        <mat-icon >close</mat-icon>
    </button>
    <p *ngFor="let t of section!.step.info; let first = first" [ngClass]="{'mt20' : first}">
        {{t}}
    </p>
    <button mat-stroked-button class="next-step" (click)="onboardingParent.nextStep()">
     {{this.section!.isLastStep ? '¡Finalizar!' : 'Siguiente paso'}} ({{(this.section!.index + 1) + "/" + (this.section!.steps.length)}})
    </button>
</div>
