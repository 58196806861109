export class ReportedProblem {
    constructor(public summary: string, public desciption: string, public files: File[]) { }
    get multipartformdata() {
        const formData = new FormData();
        formData.append("summary", this.summary);
        formData.append("description", this.desciption);
        this.files.forEach((f, index) => {
            formData.append('file' + index, f)
        })
        return formData;
    }
}