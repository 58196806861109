<div class="cloudTicketContainer" #element [ngClass]="{'needsHighlight' : needsHighlight}"
    (click)="needsHighlight = false">
    <mat-expansion-panel class="card">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="titles-container" [ngClass]="large? 'w40 pd25' : 'w100'">
                    <div class="title-label"
                        [ngClass]="{'aic' : !clientSide, 'title-margin-bottom' : clientSide || !clientSide && cloudTicket.solved}">
                        <app-card-subtitle [first]="true"><span
                                class="fwb">{{cloudTicket.technicalname}}</span></app-card-subtitle>
                        <div class="status-container" [ngClass]="{'cp': !clientSide, 'large' : large}">
                            <p [ngSwitch]="cloudTicket.status" [class]="'status ' +  'status'+cloudTicket.status">
                                <span *ngSwitchCase="ts.pending">Pendiente</span>
                                <span *ngSwitchCase="ts.revision">En revisión</span>
                                <span *ngSwitchCase="ts.solved">Solucionado</span>
                            </p>
                            <p *ngIf="!clientSide && cloudTicket.userCanEdit" (click)="prevent($event)"
                                [matMenuTriggerFor]="statusMenu" class="change-status">Cambiar</p>
                            <mat-menu #statusMenu="matMenu">
                                <button mat-menu-item (click)="emitStatusChange(ts.pending)">Pendiente</button>
                                <button mat-menu-item (click)="emitStatusChange(ts.revision)">En revisión</button>
                                <button mat-menu-item (click)="emitStatusChange(ts.solved)">Solucionado</button>
                            </mat-menu>
                        </div>
                    </div>
                    <app-card-subtitle [first]="true">{{cloudTicket.title}}</app-card-subtitle>
                    <div *ngIf="large" [ngTemplateOutlet]="descriptionText"></div>
                    <div *ngIf="large" [ngTemplateOutlet]="jira"></div>
                </div>
                <div class="df fdc w60" *ngIf="large">
                    <div [ngTemplateOutlet]="description"></div>
                </div>
            </mat-panel-title>


            <mat-panel-description>
                <div [ngTemplateOutlet]="descriptionText" *ngIf="!large"></div>
                <div [ngTemplateOutlet]="description" *ngIf="!large"></div>
                <!-- CREATED AT -->
                <p class="issue-id" [ngClass]="{'mlai' : !clientSide}">{{cloudTicket.created_at.temporalFormat()}}</p>
            </mat-panel-description>


            <ng-template #descriptionText>
                <p class="c_t2 fw300">{{cloudTicket.description}}</p>
            </ng-template>

            <ng-template #description>
                <div *ngIf="!clientSide" class="df jcsa w100 borders">
                    <div *ngIf="cloudTicket.responsible">
                        <p class="nmb tac">Responsable</p>
                        <app-circular-letter (click)="emitChangeUser()" [small]="true" [user]="cloudTicket.responsible"></app-circular-letter>
                    </div>
                    <div *ngIf="cloudTicket.reporter">
                        <p class="nmb tac">Usuario</p>
                        <app-circular-letter [small]="true" [user]="cloudTicket.reporter"></app-circular-letter>
                    </div>
                </div>
                <div class="w100 mt-7">
                    <div class="df fdr jcsb w100">
                        <p (click)="emitCompany($event)" style="margin-right: 10px;" class="companyName"
                            *ngIf="!clientSide && cloudTicket.client_name && showClient">
                            <span class="fw500">Cliente - </span>
                            {{cloudTicket.client_name}}
                        </p>
                        <p (click)="emitCompany($event)" class="companyName"
                            *ngIf="!clientSide && cloudTicket.company_name && showCompany">
                            <span class="fw500">Compañía - </span>
                            {{cloudTicket.company_name}}
                        </p>
                    </div>
                </div>

                <div *ngIf="!large" [ngTemplateOutlet]="jira"></div>
            </ng-template>

        </mat-expansion-panel-header>


        <!-- DROPDOWN CONTENTS-->
        <div class="image-container">
            <img *ngFor="let img of cloudTicket.images" (click)="preiewImg(img)" [src]="img.src">
        </div>

        <div class="add-comment mb10 mt10" *ngIf="showInput">
            <app-circular-letter [user]="cloudTicket.you" [showFullName]="false"
                [showTooltip]="false"></app-circular-letter>
            <div class="comment-section" *ngIf="cloudTicket.userCanEdit; else noComents">
                <mat-form-field class="w100" appearance="outline">
                    <input #commentInput (focus)="saveCancel = true" (keyup.enter)="addComment()"
                        [formControl]="comment" matInput placeholder="Escribir comentario">
                </mat-form-field>

                <div class="attached-img" *ngIf="lfu.getCustomFile()">
                    <span class="underline_hover" (click)="previewS.openPreview(lfu.getCustomFile()!.src)">Imagen adjunta</span>
                    <button class="vam mla" (click)="lfu.uploadedFiles = undefined" [matTooltip]="'Quitar'" mat-icon-button>
                        <mat-icon>close</mat-icon>
                    </button>
                </div>

                <p class="c_r nmb fss" *ngIf="saveCancel">Todos los comentarios son revisados.</p>
                <div [ngClass]="{'dn' : !saveCancel}">
                    <div class="saveCancel">
                        <button (click)="cancelComment()" class="c_t2" mat-stroked-button>Cancelar</button>
                        <button (click)="addComment()" color="primary" mat-flat-button>Guardar</button>
                        <button *ngIf="cloudTicket.project == 'weberp'" (click)="lfu.clickInputTrigger()" color="primary" mat-icon-button>
                            <mat-icon>upload_file</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <ng-template #noComents>
                <p class="no-comments">Al no estar implicado en el ticket, no puedes añadir un comentario</p>
            </ng-template>
        </div>

        <div *ngFor="let comment of cloudTicket.comments">
            <app-cloud-ticket-comment [event]="comment" [clientSide]="clientSide"
                [cloudTicket]="cloudTicket"></app-cloud-ticket-comment>
        </div>

        <p class="issue-id">{{cloudTicket.technicalname}}</p>

    </mat-expansion-panel>
</div>


<!-- Jira Issue Container-->
<ng-template #jira>
    <div class="jira-container" *ngIf="!clientSide">

        <div *ngIf="cloudTicket.jira_id; else passToJira">

            <p *ngIf="!cloudTicket.showingJiraIssue">
                <svg style="vertical-align: middle;" height="24" preserveAspectRatio="xMidYMid" width="24"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 -30.632388516510233 255.324 285.95638851651023">
                    <linearGradient id="a">
                        <stop offset=".18" stop-color="#0052cc" />
                        <stop offset="1" stop-color="#2684ff" />
                    </linearGradient>
                    <linearGradient id="b" x1="98.031%" x2="58.888%" xlink:href="#a" y1=".161%" y2="40.766%" />
                    <linearGradient id="c" x1="100.665%" x2="55.402%" xlink:href="#a" y1=".455%" y2="44.727%" />
                    <path
                        d="M244.658 0H121.707a55.502 55.502 0 0 0 55.502 55.502h22.649V77.37c.02 30.625 24.841 55.447 55.466 55.467V10.666C255.324 4.777 250.55 0 244.658 0z"
                        fill="#2684ff" />
                    <path
                        d="M183.822 61.262H60.872c.019 30.625 24.84 55.447 55.466 55.467h22.649v21.938c.039 30.625 24.877 55.43 55.502 55.43V71.93c0-5.891-4.776-10.667-10.667-10.667z"
                        fill="url(#b)" />
                    <path
                        d="M122.951 122.489H0c0 30.653 24.85 55.502 55.502 55.502h22.72v21.867c.02 30.597 24.798 55.408 55.396 55.466V133.156c0-5.891-4.776-10.667-10.667-10.667z"
                        fill="url(#c)" />
                </svg>
                Incidencia pasada a Jira
                <span class="c_b" (click)="getMoreJiraInfo($event)">Ver más</span>
            </p>

            <p *ngIf="cloudTicket.showingJiraIssue && cloudTicket.jira_issue == undefined" class="c_t2 fss">Solicitando
                datos...</p>

            <div *ngIf="cloudTicket.showingJiraIssue && cloudTicket.jira_issue"
                (click)="openJira(cloudTicket.jira_issue.url, $event)" class="jira-component-parent">
                <div class="jira-component">
                    <svg height="24" preserveAspectRatio="xMidYMid" width="24" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 -30.632388516510233 255.324 285.95638851651023">
                        <linearGradient id="a">
                            <stop offset=".18" stop-color="#0052cc" />
                            <stop offset="1" stop-color="#2684ff" />
                        </linearGradient>
                        <linearGradient id="b" x1="98.031%" x2="58.888%" xlink:href="#a" y1=".161%" y2="40.766%" />
                        <linearGradient id="c" x1="100.665%" x2="55.402%" xlink:href="#a" y1=".455%" y2="44.727%" />
                        <path
                            d="M244.658 0H121.707a55.502 55.502 0 0 0 55.502 55.502h22.649V77.37c.02 30.625 24.841 55.447 55.466 55.467V10.666C255.324 4.777 250.55 0 244.658 0z"
                            fill="#2684ff" />
                        <path
                            d="M183.822 61.262H60.872c.019 30.625 24.84 55.447 55.466 55.467h22.649v21.938c.039 30.625 24.877 55.43 55.502 55.43V71.93c0-5.891-4.776-10.667-10.667-10.667z"
                            fill="url(#b)" />
                        <path
                            d="M122.951 122.489H0c0 30.653 24.85 55.502 55.502 55.502h22.72v21.867c.02 30.597 24.798 55.408 55.396 55.466V133.156c0-5.891-4.776-10.667-10.667-10.667z"
                            fill="url(#c)" />
                    </svg>
                    <p class="title">{{cloudTicket.jira_issue.title}}</p>
                    <p [class]="'status ' + cloudTicket.jira_issue.status">
                        {{cloudTicket.jira_issue.getStatusTranslation}}</p>
                </div>
                <p class="c_t2 fss one-line nmb">{{cloudTicket.description}}</p>
            </div>

        </div>

        <ng-template #passToJira>
            <div *ngIf="cloudTicket.jira_issue == undefined && cloudTicket.userCanEdit; else noToJira">
                <button *ngIf="cloudTicket.project == 'weberp'" mat-stroked-button
                    (click)="passTicketToJira(false, $event)">
                    <svg style="vertical-align: middle;" height="24" preserveAspectRatio="xMidYMid" width="24"
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 -30.632388516510233 255.324 285.95638851651023">
                        <linearGradient id="a">
                            <stop offset=".18" stop-color="#0052cc" />
                            <stop offset="1" stop-color="#2684ff" />
                        </linearGradient>
                        <linearGradient id="b" x1="98.031%" x2="58.888%" xlink:href="#a" y1=".161%" y2="40.766%" />
                        <linearGradient id="c" x1="100.665%" x2="55.402%" xlink:href="#a" y1=".455%" y2="44.727%" />
                        <path
                            d="M244.658 0H121.707a55.502 55.502 0 0 0 55.502 55.502h22.649V77.37c.02 30.625 24.841 55.447 55.466 55.467V10.666C255.324 4.777 250.55 0 244.658 0z"
                            fill="#2684ff" />
                        <path
                            d="M183.822 61.262H60.872c.019 30.625 24.84 55.447 55.466 55.467h22.649v21.938c.039 30.625 24.877 55.43 55.502 55.43V71.93c0-5.891-4.776-10.667-10.667-10.667z"
                            fill="url(#b)" />
                        <path
                            d="M122.951 122.489H0c0 30.653 24.85 55.502 55.502 55.502h22.72v21.867c.02 30.597 24.798 55.408 55.396 55.466V133.156c0-5.891-4.776-10.667-10.667-10.667z"
                            fill="url(#c)" />
                    </svg>
                    PASAR A PROGRAMACIÓN</button>
                <button *ngIf="cloudTicket.project == 'sinicloud'" mat-stroked-button
                    [mat-menu-trigger-for]="projectSelector" (click)="prevent($event)">
                    <svg style="vertical-align: middle;" height="24" preserveAspectRatio="xMidYMid" width="24"
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 -30.632388516510233 255.324 285.95638851651023">
                        <linearGradient id="a">
                            <stop offset=".18" stop-color="#0052cc" />
                            <stop offset="1" stop-color="#2684ff" />
                        </linearGradient>
                        <linearGradient id="b" x1="98.031%" x2="58.888%" xlink:href="#a" y1=".161%" y2="40.766%" />
                        <linearGradient id="c" x1="100.665%" x2="55.402%" xlink:href="#a" y1=".455%" y2="44.727%" />
                        <path
                            d="M244.658 0H121.707a55.502 55.502 0 0 0 55.502 55.502h22.649V77.37c.02 30.625 24.841 55.447 55.466 55.467V10.666C255.324 4.777 250.55 0 244.658 0z"
                            fill="#2684ff" />
                        <path
                            d="M183.822 61.262H60.872c.019 30.625 24.84 55.447 55.466 55.467h22.649v21.938c.039 30.625 24.877 55.43 55.502 55.43V71.93c0-5.891-4.776-10.667-10.667-10.667z"
                            fill="url(#b)" />
                        <path
                            d="M122.951 122.489H0c0 30.653 24.85 55.502 55.502 55.502h22.72v21.867c.02 30.597 24.798 55.408 55.396 55.466V133.156c0-5.891-4.776-10.667-10.667-10.667z"
                            fill="url(#c)" />
                    </svg>
                    PASAR A PROGRAMACIÓN
                </button>
                <mat-menu #projectSelector="matMenu">
                    <button mat-menu-item (click)="passTicketToJira(true, $event)">SINIWIN</button>
                    <button mat-menu-item (click)="passTicketToJira(false, $event)">SINICLOUD</button>
                </mat-menu>
                <p *ngIf="cloudTicket.project == undefined" class="c_r">No se sabe el proyecto de este cloud ticket</p>
            </div>
        </ng-template>

        <ng-template #noToJira>
            <p class="no-comments fsss">Al no estar implicado en el ticket, no puedes pasarlo a programación</p>
        </ng-template>
    </div>
</ng-template>




<!-- IMAGE UPLOAD COMPONENT-->
<lib-file-upload class="dn" #lfu [uploadtype]="'single'"></lib-file-upload>
