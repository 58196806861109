import { Directive, Input } from '@angular/core';
import { FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { ApiService } from 'src/app/services/Api/api.service';

@Directive({
  selector: '[appInvoiceDayChecker]',
  exportAs: 'appInvoiceDayChecker',
})
export class InvoiceDayCheckerDirective {

  @Input({ required: true }) fc!: FormControl;

  public loaded: boolean = false;
  public showError : boolean = false;
  public minDate: Date | undefined;

  constructor(private apiS: ApiService) { }

  ngAfterContentInit() {
    this.fc.addValidators(this.isDateOk())
    this.fc.markAsTouched();
    this.fc.updateValueAndValidity();
    this.fc.disable();
    this.apiS.lastInvoiceDate().then(res => {
      this.loaded = true;
      this.minDate = res;
      this.fc.updateValueAndValidity();
      this.fc.enable();
    });
  }

  isDateOk(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      this.showError = false;
      if (!this.loaded) { return { notLoaded: true }; }
      else {
        if (this.minDate && control.value) {
          let v = new Date(control.value);
          let isOk = this.minDate.isEquals(v) || this.minDate < v;
          if (isOk) { return null }
          else {
            this.showError = true;
            return null;
          }
          //else { return { badDay: true } }
        }
        else { return null }
      }
    }
  }

  get error() {
    return this.showError ? "Fecha anterior a la última factura " +  "(" + this.minDate?.dayMonthYearFormat() + ")" : "";
    //return this.fc.hasError('badDay') ? "Fecha anterior a la última factura " +  "(" + this.minDate?.dayMonthYearFormat + ")" : "";
  }

  get hint() {
    if (this.minDate) {
      return "Última factura : " + this.minDate.dayMonthYearFormat();
    }
    else { return null }

  }
}