import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CustomLog } from '../../custom-classes/CustomLog';
import { DeveloperService } from '../../services/developer.service';
import { endpointResponse } from '../types/endpoint-response';
import { RouterService } from '../../services/router.service';
import { AuthGuard } from '../../guards/auth.guard';
import { projectConfiguration } from 'src/app/app.module';
import { feature } from 'src/app/utils/FeaturesController';

/** State of the developer helper box */
enum BOX_STATE {
  maximized,
  minimized,
  closed
}

/** [COMPONENT]
 *  The developer helper is a box on bottom right corner of the screen.
 *  Render all the html that is inside this component tag.
 *  Helps to test the aplication. It's only visible on local or dev.
 */
@Component({
  selector: 'app-developer-helper',
  templateUrl: './developer-helper.component.html',
  styleUrls: ['./developer-helper.component.css']
})
export class DeveloperHelperComponent {
  status = BOX_STATE.minimized;
  s = BOX_STATE;
  endpointResponses: endpointResponse[] = [];
  errors: CustomLog[] = [];
  show: boolean = false;
  core = projectConfiguration;

  constructor(router: Router, routerS: RouterService, private developerS: DeveloperService) {

    /** Endpoints */
    this.developerS.onEndpointResponse.subscribe(val => {
      this.endpointResponses.push(val);
    })
    this.developerS.onClearEndpoints.subscribe(val => {
      this.endpointResponses = []
    })


    /** Errors */
    this.developerS.onError.subscribe(val => {
      this.errors.push(val);
    })
    this.developerS.onClearError.subscribe(val => {
      this.errors = []
    })

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        var view = routerS.getViewFromRoute(val.url);
        if (view) {
          this.show = routerS.hasGuard(view, AuthGuard) && (feature.developerHelper);
        }
      }
    });
  }

  setMaximized() {
    if (feature.developerHelper){
      this.status = BOX_STATE.maximized;
    }
  }

}
