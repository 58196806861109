import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { HourPickerComponent } from 'src/app/components/hour-picker/hour-picker.component';
import { MASTER_USER_CENTER, MASTER_VECHILE_MINIFY } from 'src/app/constants/masters';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_TaskAppointment } from 'src/app/models/M_TaskAppointment';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { DialogSetComponent } from '../appointments/dialog-set/dialog-set.component';
import { ActivatedRoute } from '@angular/router';
import { M_Appointment } from 'src/app/models/M_Appointment';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { AppointmentToOrComponent } from './appointment-to-or/appointment-to-or.component';
import { environment } from 'src/environments/environment';
import { ParamsService } from 'src/app/services/params.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { AppointmentStatus } from 'src/app/enums/AppointmentStatus';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { ComentariosMotoComponent } from 'src/app/components/comentarios/comentarios-moto/comentarios-moto.component';
import { ComentariosComponent } from 'src/app/components/comentarios/comentarios.component';
import { Comentario, CommentManager } from 'src/app/custom-classes/Comentarios';
import { RouterService } from 'src/app/services/router.service';
import { SnackService } from 'src/app/services/snack.service';
import { CreateMasterInDialogService } from 'src/app/services/create-master-in-dialog.service';
import { CustomTime } from 'src/app/custom-classes/CustomTime';
import { EstimatedTimeComponent } from 'src/app/components/estimated-time/estimated-time.component';
import { PS_HIGHLIGHT } from 'src/app/constants/constants';
import { asyncPlate } from 'src/app/validators/plateAsyncValidator';
import { AlreadyExistsService } from 'src/app/services/already-exists.service';

@Component({
  selector: 'app-appointment-form',
  templateUrl: './appointment-form.component.html',
  styleUrls: ['./appointment-form.component.css'],
})
export class AppointmentFormComponent extends ParameterStateComponent {
  app_status = AppointmentStatus;
  isEdit = false;
  screenLoaded = false;
  hasChanges = false;
  public form: UntypedFormGroup;
  public matriculaFound = false;
  public task: M_TaskAppointment[] = [];
  vehicle = MASTER_VECHILE_MINIFY;
  users = MASTER_USER_CENTER;
  @ViewChild(HourPickerComponent, { static: true }) hourpciker: | HourPickerComponent | undefined;
  @ViewChild(ClassSearcherComponent, { static: true }) cs!: ClassSearcherComponent<M_Contact>;
  @ViewChild('vehicleSearcher', { static: true }) vehicleSearcher!: ClassSearcherComponent<M_Vehicle>;
  @ViewChild(ComentariosMotoComponent) comentariosComponent!: ComentariosMotoComponent;
  @ViewChild(ComentariosComponent) comentarios!: ComentariosComponent;
  textInput: string = '';
  isNotClient: boolean = true;
  v = ViewPath;
  cm = CommentManager;
  appointment: M_Appointment | undefined;
  comments: Comentario[] | undefined;
  appointmentDay: Date | undefined;
  constructor(
    private apiS: ApiService,
    private fb: UntypedFormBuilder,
    route: ActivatedRoute,
    routerS: RouterService,
    private d: MatDialog,
    private snackS: SnackService,
    private existsS: AlreadyExistsService,
    public subS: SubscriptionService,
    private chdRef: ChangeDetectorRef,
    private paramsS: ParamsService,
    public createMasterS: CreateMasterInDialogService
  ) {
    super(routerS, route, ['appointment', 'appointmentDay', 'vehicle']);
    this.form = this.fb.group({
      license: [],
      name_client: [''],
      phone: [''],
      email: [''],
      date_appointment: [''],
      closing_appointment_date: [''],
      vehicle_brand: [''],
      vehicle_model: [''],
      day: '',
      hour: '',
      tasks: '',
      client_id: '',
      vehicle_id: '',
      estimated_time_hours: '',
      estimated_time_minutes: '',
      receptor: '',
      worker: '',
      comments: []
    });

    this.form.valueChanges.subscribe(v => {
      if (this.loaded) {
        this.hasChanges = true;
      }
    })

  }

  ngOnInit(): void { }

  getCurrentVehicle() { return this.appointment?.vehicle; }

  ngAfterViewInit() {
    if (this.appointmentDay) {
      this.hourpciker?.setDay(this.appointmentDay, undefined);
      this.hourpciker?.form.get('day')?.markAsTouched();
      this.screenLoaded = true;
    }
  }

  goWokrload() {
    if (this.appointment) {
      this.routerS.goWithQueryParams(ViewPath.cargataller, {
        section: 'appointment',
        id: this.appointment.id,
        date: this.appointment.appointment_date,
        apfilter0: this.appointment.state
      })
    }
  }

  formattedTime() {
    let hours = this.form.get('estimated_time_hours')?.value || 0;
    let minutes = this.form.get('estimated_time_minutes')?.value || 0;
    if (hours || minutes) {
      return new CustomTime(hours, minutes).getText();
    }
    return "";
  }

  openEstimatedTimeDialog() {

    const alreadyOpened = this.d.openDialogs.some(dialog => dialog.componentInstance instanceof EstimatedTimeComponent);
    if (alreadyOpened) { return; }

    this.d.open<EstimatedTimeComponent, { hours: number | undefined, minutes: number | undefined }>(EstimatedTimeComponent, {
      data: {
        hours: this.form.get('estimated_time_hours')?.value,
        minutes: this.form.get('estimated_time_minutes')?.value
      }
    }).afterClosed().subscribe(v => {
      if (v) {
        this.form.patchValue({ 'estimated_time_hours': v.estimated_time_hours })
        this.form.patchValue({ 'estimated_time_minutes': v.estimated_time_minutes })
      }
    })
  }

  get subtitle() {
    let v = this.form.get('day')?.value;
    if (v)
      if (v instanceof Date) {
        return v.temporalFormat();
      }
      else {
        return new Date(v).temporalFormat();
      }
    return v;
  }

  override noParams(): void {
    this.screenLoaded = true;
  }

  override onParam(_k: string, v: any) {
    if (_k == "vehicle") {
      this.vehicleSearcher.select(v);
      this.screenLoaded = true;
    }
    else if (_k == "appointmentDay") {
      this.appointmentDay = new Date(v);
    }
    else {
      this.apiS.getAppointmentId(v).then((res) => {
        if (res == undefined) {
          this.appointment = undefined;
        }
        if (res instanceof M_Appointment) {
          this.isEdit = true;
          this.appointment = res;
          this.form.patchValue(this.appointment);
          if (this.appointment.vehicle) {
            this.vehicleSearcher.select(this.appointment.vehicle.vehicle_id);
          }

          if (this.hourpciker) {
            this.hourpciker.isEdit = true;
            this.hourpciker.setDay(this.appointment.date_appointment, undefined);
            this.hourpciker.setHour(this.appointment.date_appointment);
            this.hourpciker.form.get('day')?.markAsTouched();
          }

          this.appointment.tasks.forEach((t) => {
            this.comentarios.addNewComment(
              new Comentario(t.task!, [], undefined, undefined, undefined)
            );
          });
        }
        this.enableByState();
        this.screenLoaded = true;
      });
    }


  }

  get loaded() {
    return this.screenLoaded && this.hourpciker?.loadedHours && this.vehicleSearcher?.loaded;
  }

  get isFormOk(): boolean {
    if (this.form.disabled) { return true; }
    /** Already client */
    if (!this.isEdit) {
      return this.vehicleSearcher.selected != undefined && this.hourpciker?.form.get('hour')?.value
    }
    /** Not client */
    else {
      return this.form.valid;
    }
  }

  get minimumRequiredFilled() {
    return this.hasVehicle && this.form.valid;
  }

  get hasLicense() {
    return this.form.get('license')?.valid;
  }

  get hasVehicle() {
    return this.vehicleSearcher?.selected != undefined;
  }

  checkForTindr() {
    if (environment.local || environment.dev) {
      const normalizedText = this.textInput.toLowerCase();
      // Verifica si el texto del input
      if (normalizedText === 'tinder') {
        this.d.open(DialogSetComponent);
      }
    }
  }

  changeClient(v: M_Vehicle) {
    if (this.appointment) {
      this.appointment.client = v.client;
      this.appointment.vehicle = v;
    }
  }

  openForm() {
    this.isNotClient = false;
  }

  toOr() {
    this.editAppointment().then(res => {
      this.d.open<AppointmentToOrComponent, M_Appointment>(AppointmentToOrComponent, { data: this.appointment, autoFocus: false });
    });
  }

  changeStatus(appo_status: AppointmentStatus) {
    this.hasChanges = true;
    if (this.appointment) {
      this.appointment.setStatus = appo_status;
      this.editAppointment().then(res => {
        this.enableByState();
      });
    }
  }

  enableByState() {
    if (this.appointment?.isDone || this.appointment?.isCanceled) {
      this.form.disable({ emitEvent: false });
    }
    else {
      this.form.enable({ emitEvent: false });
    }
    this.chdRef.detectChanges();
  }

  goOr(id: number | undefined) {
    if (!id) { return; }
    this.paramsS.go(ViewPath.editOr, id);
  }

  createAppointment() {
    const vehicle = this.vehicleSearcher.selected;
    const body = this.buildAppointmentBody(vehicle);
    this.apiS.createAppointmentClient(body).then((res) => {
      this.routerS.goWithQueryParams(this.v.appointments, { [PS_HIGHLIGHT]: res });
      this.snackS.show('Se ha creado la cita correctamente');
    });
  }

  async editAppointment() {
    return new Promise<boolean>(resolve => {
      if (this.hasChanges) {
        this.apiS.updateAppointmentClient(this.buildAppointmentBody(this.appointment?.vehicle)).then((res) => {
          this.appointment = res;
          this.snackS.show('Cambios guardados con éxito');
          this.hasChanges = false;
          resolve(true);
        });
      }
      else {
        this.hasChanges = false;
        resolve(false);
      }
    })

  }

  private buildAppointmentBody(vehicle: M_Vehicle | undefined): any {
    const formValues = this.form.value;
    const { license, name_client, phone, email, vehicle_brand, vehicle_model, estimated_time_hours, estimated_time_minutes, receptor, worker } = formValues;
    return {
      id: this.appointment?.id,
      state: this.appointment?.state,
      license: vehicle ? vehicle.license : license,
      client_id: vehicle ? vehicle.client_id || '' : '',
      vehicle_id: vehicle ? vehicle.vehicle_id || '' : '',
      name_client: vehicle ? vehicle.client?.name : name_client,
      phone: vehicle ? vehicle.client?.phone : phone,
      email: vehicle ? vehicle.client?.email : email,
      vehicle_brand: vehicle ? vehicle.brand : vehicle_brand || '',
      vehicle_model: vehicle ? vehicle.model : vehicle_model || '',
      estimated_time_hours: estimated_time_hours || 0,
      estimated_time_minutes: estimated_time_minutes || 0,
      receptor: receptor || undefined,
      worker: worker || undefined,
      taks: this.comentarios.getCommentsAsArray(),
      date_appointment: this.hourpciker!.getDate(),
    };
  }

}
