<app-page-structure [masterClass]="'accounting_group'" pageTitle="Grupos contables" [autocompleteID]="'accounting_group'"
    searchLabel="Buscar grupo contable" searchOnboardingClass="onboarding-search-accounting-group"
    [filter]="f"
    [filters]="filters"
    listTitleText="Listado de grupos contables" noDataCreateNew="Crear grupo contable" [data]="[]"
    [displayedHeader]=" ['Identificador',   'Nombre',   'Tipo',         'Código',   'Centro']" 
    [displayedColumns]="['id',              'name',     'type_name',    'code',     'center_name']"
    [cellZize]="        ['small',           undefined,  'small',        'small',    undefined]"
    (onclickRow)="params.go(v.createEditAG, $event.id)" 
    [createButton]="
        {
            text : 'Crear grupo contable' , 
            icon : 'table_view', 
            view :  v.createEditAG, 
            cssClass : 'onboarding-create-client'
        }"
    [quickFilter]="quickFilter">
</app-page-structure>