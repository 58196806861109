import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-floating-save-button',
  templateUrl: './floating-save-button.component.html',
  styleUrls: ['./floating-save-button.component.css']
})
export class FloatingSaveButtonComponent {
  @Input({ required: true }) show! : boolean;
}
