<app-card [nopadding]="true" class="company-parent-card" [contentLoaded]="loaded">
    <div class="df">
        <mat-tab-group [ngClass]="responsiveS.w >= 900 ? 'vertical-tabs' : 'vetical-to-horitzontal'">
            <mat-tab *ngFor="let center of companyCenters">
                <ng-template mat-tab-label>
                    <mat-icon>location_away</mat-icon>
                    {{centerform.center?.name}}
                </ng-template>
                <app-center-form #centerform [center]="center"></app-center-form>
            </mat-tab>
            <mat-tab (click)="createCenter($event)">
                <ng-template mat-tab-label>
                    <mat-icon>add</mat-icon>
                    Añadir centro
                </ng-template>
                <app-center-form (onCreateCenter)="onCreateCenter($event)" [center]="undefined"></app-center-form>
            </mat-tab>
        </mat-tab-group>
    </div>
</app-card>