/** @param text Text to display. The second position is the link text */
export class TextWithLink {
    constructor(public text: string[] | string, public link: string) { }

    get start() {
        if (Array.isArray(this.text) && this.text[0] != undefined) {
            return this.text[0];
        }
        return ""
    }

    get linkText() {
        if (Array.isArray(this.text) && this.text[1] != undefined) {
            return this.text[1];
        }
        if (!Array.isArray(this.text)) {
            return this.text;
        }
        return ""
    }

    get end() {
        if (Array.isArray(this.text) && this.text[2] != undefined) {
            return this.text[2];
        }
        return ""
    }
}