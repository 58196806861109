import { ChangeDetectorRef, Component, HostBinding, Inject, Input, OnInit } from '@angular/core';
import { ImageToggleComponent } from '../image-toggle.component';

@Component({
  selector: 'app-img-toggle-item',
  templateUrl: './img-toggle-item.component.html',
  styleUrls: ['./img-toggle-item.component.css']
})
export class ImgToggleItemComponent implements OnInit {
  @HostBinding('class') classes = 'toggleitem';

  /**Required inputs */
  @Input() img?: string;
  @Input() text!: string;
  @Input() startSelected: boolean = false;
  @Input() locked: boolean = false;
  @Input() formValue!: string | number | undefined;

  index = -1;
  selectedItem: boolean = false;
  dimensions: number | undefined = undefined;
  blackWitheFilter: boolean = false;

  constructor(@Inject(ImageToggleComponent) public parent: ImageToggleComponent, private chdRedf: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.selectedItem = this.startSelected;
    this.chdRedf.detectChanges();
  }

  setDimensions(d: number) {
    this.dimensions = d;
  }

  setIndex(i: number) {
    this.index = i;
  }

  setSelected(value: boolean, byUser = false) {
    if (!this.locked) {
      this.selectedItem = value;
      if (this.parent.updateForm) {
        this.parent.form.get(this.parent.formCName)?.patchValue(this.formValue ? this.formValue : this.index, { emitEvent: false, onlySelf: true })
        if (byUser) { this.parent.onToggle.emit(this.index) }
      }
      this.chdRedf.detectChanges();
    }
  }

  get selected() {
    return this.selectedItem;
  }

}
