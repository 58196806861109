<div *ngIf="this.config && 'color : ' + config.primary_color;"
    class="appointments-list-parent">
    <div class="df aic jcsb p20">
        <img class="comapny_logo" [src]="config.company_logo ? this.config.company_logo : ''">
        <app-view-title class="tac fs40">
            <p>PRÓXIMAS CITAS</p>
        </app-view-title>
        <p class="fs50 fw500">{{actual_hour.hourFormat()}}</p>
    </div>

    <table>
        <thead>
            <tr>
                <th>Hora</th>
                <th>Matrícula</th>
                <th>Vehículo</th>
            </tr>
        </thead>
        <tbody *ngIf="loaded && today_appointments.length != 0; else noData">
            <tr *ngFor="let appointment of today_appointments;" class="row-appointment"
                [ngClass]="{'done' : isAppontmentDone(appointment), 'now' :  isAppointmentNow(appointment)}">
                <td>
                    <div class="df jcc">
                        <mat-icon class="arrow-icon" *ngIf="isAppointmentNow(appointment)"
                            [style]="'color : ' + config.primary_color">arrow_forward</mat-icon>
                        <p class="fs30">{{appointment.date_appointment.hourFormat()}}</p>
                    </div>
                </td>
                <td>
                    <p class="fs30">{{appointment.license}}</p>
                </td>
                <td>
                    <p class="fs30" [ngClass]="{'c_t2' : appointment.vehicle_brand == undefined}">
                        {{appointment.vehicle_brand? appointment.vehicle_brand : 'Sin datos'}}
                    </p>
                </td>

            </tr>
        </tbody>
        <ng-template #noData>
            <tbody>
                <tr class="row-appointment">
                    <td *ngIf="loaded" class="fs25 c_t2" colspan="3" style="text-transform: none;">Sin citas programadas
                        para hoy</td>
                    <td *ngIf="!loaded" colspan="3"><mat-progress-bar style="margin: 10px;"
                            [mode]="'indeterminate'"></mat-progress-bar></td>
                </tr>
            </tbody>
        </ng-template>
    </table>
</div>

<div class="actions" [ngClass]="showActions ? 'visible' : 'invisible'">
    <button mat-button (click)="toggleFullScreen()">
        <mat-icon>{{this.isFullScreen ? 'fullscreen' : 'fullscreen_exit'}}</mat-icon>
        {{this.isFullScreen ? 'Pantalla completa' : 'Quitar pantalla completa'}}
    </button>
</div>

<ng-template #loading>
    <app-skeleton [fullW]="true" [height]="100"></app-skeleton>
    <div class="mt20">
        <app-skeleton style="margin-top: 20px;" [fullW]="true" [height]="300"></app-skeleton>
    </div>
</ng-template>