<form [formGroup]="form" eForm>
    <app-card-subtitle>{{requiredFieldsTitle ? requiredFieldsTitle : 'Datos obligatorios'}}</app-card-subtitle>
    <div class="onboarding-vehicle-required" eForm-wrapper>
        <ng-container *ngIf="formVnVo">
            <div eForm-section>
                <mat-button-toggle-group [value]="CVT.VN" formControlName="comercialType">
                    <mat-button-toggle [value]="CVT.VN">
                        Vehículo nuevo
                        <app-vn-vo-label [type]="CVT.VN"></app-vn-vo-label>
                    </mat-button-toggle>
                    <mat-button-toggle [value]="CVT.VO">
                        Vehículo de ocasión
                        <app-vn-vo-label [type]="CVT.VO"></app-vn-vo-label>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </ng-container>


        <div eForm-section>
            <mat-form-field appearance="outline">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="type">
                    <mat-option [value]="1">Moto</mat-option>
                    <mat-option [value]="2">Coche</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Matricula</mat-label>
                <app-async-validator-spinner matSuffix [control]="form.get('license')"></app-async-validator-spinner>
                <input matInput placeholder="Matricula" [disabled]="licenseBlocked" formControlName="license" />
                <button *ngIf="licenseBlocked" mat-icon-button matPrefix type="button" (click)="enablePlateEdition()">
                    <mat-icon>edit</mat-icon>
                </button>
                <mat-error *ngIf="form.get('license')?.hasError('license-exists')">
                    La matrícula ya existe.
                </mat-error>
            </mat-form-field>
        </div>

        <div eForm-section>
            <app-brand-model-input [onlyVehicle]="true" [OnlyBrand]="true" [OnlyModel]="true" [OnlySerie]="false" [form]="form" [showPrice]="formVnVo"></app-brand-model-input>
        </div>

    </div>

    <div [ngClass]="{'dn' : onlyRequired}">
        <app-card-subtitle>Datos opcionales</app-card-subtitle>

        <div class="df fww jcc ">

            <!-- CAR TYPE -->
            <app-image-toggle [updateForm]="false" [default]="false" [form]="form" [formCName]="'serie'"
                *ngIf="showMe(VT.car)">
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m1.png" text="Microcoche"
                    [formValue]="0"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m2.png" text="Urbano"
                    [formValue]="1"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m3.png" text="Sedan"
                    [formValue]="2"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m4.png" text="Descapotable"
                    [formValue]="3"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m5.png" text="Coupé"
                    [formValue]="4"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m6.png" text="Deportivo"
                    [formValue]="5"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m7.png" text="Monovolúmen"
                    [formValue]="6"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m8.png" text="Todo Terreno"
                    [formValue]="7"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m9.png" text="SUV"
                    [formValue]="8"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m10.png" text="Camioneta"
                    [formValue]="9"></app-img-toggle-item>
            </app-image-toggle>

            <!-- MOTO TYPE -->
            <app-image-toggle [updateForm]="false" [default]="false" [form]="form" [formCName]="'serie'"
                *ngIf="showMe(VT.bike)">
                <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m1.png" text="Sport"
                    [formValue]="0"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m2.png" text="Touring"
                    [formValue]="1"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m3.png" text="Trail"
                    [formValue]="2"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m4.png" text="Scooter"
                    [formValue]="3"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m5.png" text="Offroad"
                    [formValue]="4"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m6.png" text="Custom"
                    [formValue]="5"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m7.png" text="Naked"
                    [formValue]="6"></app-img-toggle-item>
            </app-image-toggle>
        </div>

        <div eForm-wrapper>
            <div eForm-section>
                <app-chassis-input [form]="form" [currentVehicle]="vehicle" [required]="false"></app-chassis-input>
                <mat-form-field appearance="outline" *ngIf="vehicleIs('v', 'vo')">
                    <mat-label>Kilómetros</mat-label>
                    <input type="number" matInput formControlName="km">
                </mat-form-field>
            </div>


            <div eForm-section>
                <mat-form-field appearance="fill">
                    <mat-label>Color</mat-label>
                    <mat-select formControlName="color_id">
                        <mat-option *ngFor="let color of availableColors" [value]="color.manufacturer_id">
                            {{ color.brand_name }} {{ color.serie_name }} ({{ color.percentage_cost }}% de coste)
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Cilindrada</mat-label>
                    <input type="number" matInput placeholder="Cilindrada" formControlName="cc">
                </mat-form-field>
            </div>

            <div eForm-section>
                <mat-form-field appearance="outline">
                    <mat-label>Tipo de motor</mat-label>
                    <mat-select formControlName="engine_type" #et>
                        <mat-option [value]="0">Gasolina</mat-option>
                        <mat-option [value]="1">Eléctrico</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Nº serie batería </mat-label>
                    <input matInput placeholder="Nº serie batería" formControlName="battery_1_no">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Nº serie batería extra</mat-label>
                    <input matInput placeholder="Nº serie batería extra" formControlName="battery_2_no">
                </mat-form-field>
            </div>

            <div eForm-section *ngIf="!formVnVo">
                <mat-form-field appearance="outline">
                    <mat-label>Matriculación</mat-label>
                    <input placeholder="dd/mm/aaaa" #pickerInput matInput [matDatepicker]="picker" [max]="minDate"
                        (click)="picker.open()" formControlName="license_date">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Última ITV</mat-label>
                    <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_1" [max]="minDate"
                        (click)="picker_1.open()" formControlName="last_itv">
                    <mat-datepicker-toggle matSuffix [for]="picker_1"></mat-datepicker-toggle>
                    <mat-datepicker #picker_1 [min]="minDate"></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Próxima ITV</mat-label>
                    <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_2" [min]="minDate"
                        (click)="picker_2.open()" formControlName="next_itv">
                    <mat-datepicker-toggle matSuffix [for]="picker_2"></mat-datepicker-toggle>
                    <mat-datepicker #picker_2></mat-datepicker>
                </mat-form-field>
            </div>
            <div eForm-section *ngIf="formVnVo || isVoVn">
               <mat-form-field appearance="outline">
                    <mat-label>Nº motor</mat-label>
                    <input matInput placeholder="Nº motor" formControlName="num_motor">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Modelo taller</mat-label>
                    <input matInput placeholder="Nº serie batería extra" formControlName="workshop_model">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Deposito</mat-label>
                    <input matInput placeholder="Deposito" formControlName="deposit">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Fecha vencimiento documentación</mat-label>
                    <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_3" [min]="minDate"
                        (click)="picker_3.open()" formControlName="exp_documentation">
                    <mat-datepicker-toggle matSuffix [for]="picker_3"></mat-datepicker-toggle>
                    <mat-datepicker #picker_3></mat-datepicker>
                </mat-form-field>

            </div>

            <div eForm-section>
                <app-class-searcher #csearcher [masterClass]="client" class="w100" [width100]="true" [form_]="form"
                    [formCname]="'clientInvoice_id'" searchPlaceHolder="Cliente a facturar por defecto"
                    [extendedInfo]="false" [required]="false" [createData]="{
                        text : 'Nuevo cliente / empresa',
                        by : v.createContact,
                    }">
                </app-class-searcher>
            </div>
            <div eForm-section>
                <mat-form-field appearance="outline" class="w100">
                    <mat-label>Observaciones</mat-label>
                    <textarea matInput formControlName="observations" cdkTextareaAutosize cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="5" [placeholder]="'Observaciones'">
                    </textarea>
                </mat-form-field>
            </div>
        </div>
        <p></p>
        <div eForm-section eForm-wrapper>
            <mat-form-field appearance="fill">
                <mat-label>Versión</mat-label>
                <input matInput formControlName="version" placeholder="Código versión">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Fin de garantía</mat-label>
                <input matInput formControlName="end_warranty" [matDatepicker]="pickerEndWarranty">
                <mat-datepicker-toggle matSuffix [for]="pickerEndWarranty"></mat-datepicker-toggle>
                <mat-datepicker #pickerEndWarranty></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Número de garantía</mat-label>
                <input matInput formControlName="number_warranty" placeholder="Número de garantía">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Tipo de cambio</mat-label>
                <input matInput formControlName="gears_type" placeholder="Tipo de cambio">
            </mat-form-field>
        </div>

        <ng-container *ngIf="formVnVo">
            <app-card-subtitle>
                <img class="module-img small vam" src="./assets/img/modules/module-2/module-2.png">
                Campos de comercial
            </app-card-subtitle>
            <div eForm-section eForm-wrapper>
                <mat-form-field appearance="outline">
                    <mat-label>Precio</mat-label>
                    <input type="number" min="0" matInput formControlName="price">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Coste</mat-label>
                    <input type="number" min="0" matInput formControlName="cost">
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="!isVo">
                    <mat-label>Impuesto matriculación</mat-label>
                    <mat-select formControlName="registration_tax">
                        <mat-option [value]="RTE.Low"> {{registrationText(RTE.Low)}}</mat-option>
                        <mat-option [value]="RTE.MediumLow">{{registrationText(RTE.MediumLow)}}</mat-option>
                        <mat-option [value]="RTE.MediumHigh">{{registrationText(RTE.MediumHigh)}}</mat-option>
                        <mat-option [value]="RTE.High">{{registrationText(RTE.High)}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <p></p>
            <div eForm-section eForm-wrapper *ngIf="isVn">
                <!-- Vehiculo VN -->
                <mat-form-field appearance="fill">
                    <mat-label>En depósito</mat-label>
                    <mat-select formControlName="in_deposit">
                        <mat-option [value]="true">Sí</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Gastos financieros</mat-label>
                    <input matInput type="number" formControlName="financial_expenses" placeholder="Gastos financieros">
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Albarán del proveedor</mat-label>
                    <input matInput formControlName="delivery_num" placeholder="Número del albarán">
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Vencimiento de documentación</mat-label>
                    <input matInput formControlName="documentation_expiration" [matDatepicker]="pickerDocExp"
                        (click)="pickerDocExp.open()">
                    <mat-datepicker-toggle matSuffix [for]="pickerDocExp"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDocExp></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Grupo contable</mat-label>
                    <input matInput formControlName="accounting" placeholder="Grupo contable">
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Año</mat-label>
                    <input matInput type="number" formControlName="year" placeholder="Año del vehículo">
                </mat-form-field>

            </div>

            <ng-container *ngIf="isVo">
                <app-card-subtitle>Propietario compra V.O</app-card-subtitle>
                <div eForm-section eForm-wrapper>
                    <app-class-searcher [showLeftNumber]="false" #csearcher [masterClass]="client" [allData]=""
                        [width100]="true" searchPlaceHolder="Buscar cliente" [extendedInfo]="false" [form_]="form"
                        [formCname]="'client_origin'" [required]="true" [createData]="undefined">
                    </app-class-searcher>
                </div>
            </ng-container>

            <app-card-subtitle *ngIf="this.vehicle == undefined">Compra</app-card-subtitle>


            <div eForm-wrapper>
                <div eForm-section *ngIf="this.vehicle == undefined">
                    <app-class-searcher [showLeftNumber]="false" #csearcher2 [masterClass]="client" [allData]=""
                        [width100]="true" searchPlaceHolder="Buscar cliente o Proveedor" [extendedInfo]="false"
                        [form_]="form" [formCname]="'client_id'" [required]="true"
                        (onSelect)="handleClientSelection($event)" [createData]="{
                            text : 'Nuevo cliente / empresa',
                            by : v.createContact,
                        }">
                    </app-class-searcher>


                    <mat-form-field appearance="outline" class="w50">
                        <mat-label>Fecha de Compra</mat-label>
                        <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_3" [min]="minDate"
                            (click)="picker_3.open()" formControlName="date">
                        <mat-datepicker-toggle matSuffix [for]="picker_3"></mat-datepicker-toggle>
                        <mat-datepicker #picker_3></mat-datepicker>
                    </mat-form-field>

                </div>


                <div eForm-section>
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>IVA</mat-label>
                        <input #input type="number" matInput placeholder="IVA." formControlName="tax" type="number"
                            DecimalInput>
                        <mat-icon matSuffix>percent</mat-icon>
                        <mat-error *ngIf="form.get('tax')?.touched && form.get('tax')?.errors">Valor máx.
                            :
                            21</mat-error>
                    </mat-form-field>

                    <mat-form-field class="w50" appearance="outline"
                        *ngIf="client_select?.type == 2 || client_select?.type == 3">
                        <mat-label>Número de Factura</mat-label>
                        <input type="text" min="0" matInput formControlName="doc_prov_num">
                    </mat-form-field>
                </div>
                <div *ngIf="client_select?.type == 2 || client_select?.type == 3">
                    <app-card-subtitle>Adjuntar documentación de compra</app-card-subtitle>
                    <div>
                        <app-pdf-documents-upload></app-pdf-documents-upload>
                    </div>
                </div>
            </div>
            <app-card-subtitle>Conceptos</app-card-subtitle>
            <app-concepts-line-table [concepts]="getConcepts()" [canModifyTable]="!isOnDialog" [isDialog]="isOnDialog"
                [showSave]="false" [isInfo]="true"></app-concepts-line-table>
        </ng-container>
    </div>
</form>