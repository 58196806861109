<app-close-dialog-button></app-close-dialog-button>
<p mat-dialog-title>{{data.day.humanFormat()}}</p>
<app-card [noStyle]="true" [contentLoaded]="loaded">
    <div mat-dialog-content>
        <p mat-dialog-subtitle>
            Día festivo
        </p>
        <mat-slide-toggle [formControl]="toggleControl" [disabled]="toggleError != undefined">
            Día festivo
        </mat-slide-toggle>
        <p class="c_r" *ngIf="toggleError">{{toggleError}}</p>

        <div class="mt20"></div>
        <mat-divider></mat-divider>

        <p mat-dialog-subtitle>Horario excepcional</p>
        <app-scheudle-table [fullW]="true" [disabled]="disabledText" [horario]="horario"
            [showDay]="false"></app-scheudle-table>

    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>Cancelar</button>
        <button color="primary" mat-flat-button [disabled]="!isOk" (click)="close()">Ok</button>
    </div>
</app-card>