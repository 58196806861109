<div class="unlock-pro-parent">
    <p mat-dialog-title>Obtén el plan PRO</p>

    <div mat-dialog-content>
        <div class="df jcc">
            <script src="https://cdn.lordicon.com/fudrjiwc.js"></script>
            <lord-icon src="https://cdn.lordicon.com/vkcxnlyx.json" trigger="loop"
                colors="primary:#121331,secondary:#ebe6ef,tertiary:{{warn()}},quaternary:{{accent()}},quinary:#ff893f,senary:#3a3347"
                class="lord-huge">
            </lord-icon>
        </div>
        <p mat-dialog-subtitle class="tac">
            <span *ngIf="data"> '<span class="fw600">{{data}}</span>' y muchas más funcionalidades te están esperando
                con el plan <span class="fw600 c_p">PRO</span>.</span>
            <span *ngIf="!data">Se requiere la version <span class="fw600 c_p">PRO</span> para usar esta
                funcionalidad</span>
        </p>
    </div>

    <div mat-dialog-actions super-user>
        <button mat-raised-button class="button-gradient obtain-pro"
            (click)="routerS.goWithQueryParams(v.settings, {'tab' : ST.SUBSCRIPTION}); dialogRef.close()">
            <span class="fw500">Obtener PRO</span>
            <mat-icon class="c_w">arrow_forward</mat-icon>
        </button>
    </div>

    <div mat-dialog-actions [not-roles]="[R.ADMIN, R.GESTOR]">
        <p class="comunicate-admin">¡No te quedes atascado, comunícate con el administrador del taller para desbloquear
            el <span class="underline_b fw600"> acceso completo </span> a Eina!
        </p>
    </div>

</div>