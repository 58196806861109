import { M_Parser } from "../custom-classes/ModelParser";
import { or_types, or_types_cargo_interno, or_types_normal } from "../custom-classes/or_types";

export class M_RAGroup implements M_Parser {
    title: string = ""
    needsToBeParsed = true;
    or_type: or_types = or_types_normal;
    type: number = 0;
    constructor(title: string) {
      this.title = title;
    }
  
    parse() {
      this.type = this.or_type.num;
    }
    standarStatus(){
      this.or_type = or_types_normal;
    }
    onlyInternal(){
      this.or_type = or_types_cargo_interno;
    }
  }