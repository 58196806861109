import { Pipe, PipeTransform } from '@angular/core';

/** UsageExample :  
 * On HTML : {{ 'foo' | plural:2 | translate: { foo: 2} }} 
 * On JSON : {
    "foo": {
        "none": "No foo",
        "singular": "{{ foo }} foo", ---> 1 foo
        "plural": "{{ foo }} foos"   ---> 2+ foos 
      },
    } 
 */
@Pipe({
  name: 'plural'
})
export class PluralPipe implements PipeTransform {
  transform(key: string, number: number): string {
    return `${key}.${number == 0 ? 'none' : number == 1 ? 'singular' : 'plural'}`;
  }
}
