<div class="df aic gap5 card-wrapper-fit" [ngClass]="clickable ? 'rectangle-clickable' : 'rectangle-no-clickable'" mat-ripple [matRippleDisabled]="!clickable"
    [go]="!clickable ? undefined : v.contactDetails" [param]="client.client_id" [newTab]="true" [matTooltip]="tooltip" [matTooltipPosition]="'above'">
    <mat-icon class="vam">{{client.client_id ? client.icon : 'no_accounts'}}</mat-icon>

    <ng-container *ngIf="!onlyIconOn || (onlyIconOn && onlyIconOn < responsiveS.w)">
        <div class="user-name">{{client.getName()}}</div>
        <div class="icon-buttons rectange">
            <app-contact-buttons>
                <app-whatsapp [phone]="client.phone"></app-whatsapp>
                <app-email-button [email]="client.email"></app-email-button>
                <app-phone-button [phone]="client.phone"></app-phone-button>
            </app-contact-buttons>
        </div>
    </ng-container>

</div>