<ng-container *ngIf="or">
    <div class="df fdc clickable-item">
        <div class="df aic " style="width: fit-content;" (click)="paramS.go(v.editOr, or.id)">
            <div class="df aic mr5" style="gap:10px;">
                <app-or-status-label [pointer]="true" [action]="or"></app-or-status-label>
                <app-or-type-label class="no-wrap-chips" [pointer]="true" [wrapOn]="1" [small]="true" [showText]="false"
                    [tipo]="or.type"></app-or-type-label>
            </div>
            <div>
                <p class="one-line nmb">
                    <span>{{or.title_id}}</span>
                    <mat-icon *ngIf="!or.groups.length" class="vam">chevron_right</mat-icon>
                    <span class="c_t2 ml10 mr10" *ngIf="or.groups.length">|</span>
                    <span>
                        <span class="c_t2" *ngFor="let g of or.groups; let i = index; let last= last">
                            {{g.title + (i == or.groups.length-2 ? ',' : '')}}
                            <mat-icon *ngIf="last" class="c_t2 vam">chevron_right</mat-icon>
                        </span>
                    </span>
                </p>
            </div>
        </div>
        <div>
            <p class="c_t2 fss nmb">{{or.created_at.temporalFormat()}}</p>
        </div>
    </div>

</ng-container>

<p *ngIf="!or" class="c_t2">Sin intervenciones.</p>