import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSlider } from '@angular/material/slider';
import { SliderFilter } from '../../../custom-classes/Filter';

@Component({
  selector: 'app-slider-filter',
  templateUrl: './slider-filter.component.html',
  styleUrls: ['./slider-filter.component.css']
})
export class SliderFilterComponent implements OnInit {
  @Input() filter!: SliderFilter;
  @ViewChild(MatSlider) slider!: MatSlider;

  constructor() {
  }

  ngOnInit(): void {
    if (this.filter == undefined) {
      this.filter = new SliderFilter("", 100);
    }
  }

  onChangeMin(e: any) {
    this.filter.minval = e.target?.valueAsNumber;
  }

  onChangeMax(e: any) {
    this.filter.maxval = e.target?.valueAsNumber;
  }

}
