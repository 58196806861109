import { Injectable } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { endpoints } from '../constants/Endpoints';
import { UserService } from './EinaMainData/user.service';
import { WorkloadData } from '../custom-classes/WorkloadData';
import { SessionService } from './session.service';
import { IWorkload } from '../interfaces/IWorkload';

@Injectable({
  providedIn: 'root'
})
export class WorkloadService {
  workloadData!: WorkloadData;
  constructor(private apiS: ApiService, private userS: UserService, private sessionS: SessionService) {}

  refresh() {
    var configuration: IWorkload = {
      defaultView: "or",
      switch: this.userS.userIsMechanic ? false : true,
      general: {
        users: this.apiS.usersCenters.bind(this.apiS),
        center: this.apiS.getMyCenter.bind(this.apiS)
      },
      or: {
        schedules: this.apiS.action.schedules.bind(this.apiS),
        updateSchedule: endpoints.updateSchedule,
        reorderAction: endpoints.reorderActions,
        assignAction: endpoints.assignAction,
        userPermission: true,
        views: {
          editOr: ViewPath.editOr,
          createOr: undefined
        },
        draggable: {
          day: this.userS.userIsAdminOrAdviser,
          week: this.userS.userIsAdminOrAdviser,
          month: this.userS.userIsAdminOrAdviser,
        },
        canUserAssign: this.userS.userIsAdminOrAdviser,
      },
      appointments: {
        appointments: this.apiS.appointments.bind(this.apiS),
        updateAppointment: endpoints.changeAppointmentDate,
        assignUserAppointment : endpoints.assignUserAppointment,
        canUserAssign : true,
        userPermission: this.userS.userIsAdminOrAdviser,
        views: {
          createAppointment: ViewPath.createappointment,
          editAppointment: ViewPath.editAppointment
        },
        draggable: {
          day: true,
          week: true,
          month: true,
        }
      }
    }

    this.workloadData = new WorkloadData(configuration)
  }


  get wd() {
    return this.workloadData;
  }
}
