import { Filter } from "src/app/custom-classes/Filter";
import { ApiService } from "src/app/services/Api/api.service";

export type reportResult = {
    title: string,
    key: string,
    value?: string | number,
    ismoney?: boolean,
}

export class M_Report {
    constructor(public name: string, public filters: Filter[], public results: reportResult[]) { }
    refreshData() {

    }
}

// export class M_ReportGestion extends M_Report{
//     constructor(name : string, filters : Filter[], results: reportResult[],apiS: ApiService){
//         super(name, filters, results, apiS);
//     }
// }