<button (click)="clear()">CLEAR</button>
<div *ngFor="let error of er.reverse()">
    <p> 
        <span class="fw500">Message :</span>
        <span *ngIf="error.message; else nodata">{{error.message}}</span>
    </p>

    <p> 
        <span class="fw500">Stack :</span>
        <span *ngIf="error.stack; else nodata">{{error.stack}}</span>
    </p>

    <div style="margin-top: 10px;"></div>
    <mat-divider></mat-divider>
    <div style="margin-bottom: 10px;"></div>

</div>


<ng-template #nodata>
    <span class="c_t2">Sin datos</span>
</ng-template>