<div>
    <mat-toolbar color="primary">

        <!-- <button [color]="'primary'" *ngIf="showMenuButton" #btn (click)="onclick.emit();" onclick="this.blur()"
            mat-icon-button class="df aic cp br5" [ngClass]="{'onboarading-menu' : mc.hiddenToUser}">
            <mat-icon>{{mc.lockmenu && mc.isDesktop ? 'menu_open' : 'menu'}}</mat-icon>
        </button> -->

        <app-menu-trigger *ngIf="showMenuButton" [mc]="mc" [isOn]="'toolbar'"
            (onclick)="onclick.emit()"></app-menu-trigger>

        <!-- CURRENTLY WORKING OR-->
        <app-working-or-timer [goAction]="true"></app-working-or-timer>

        <app-big-mama *ngIf="f.bigMama && showBigMamaByRoleAndView()" class="gsearcher"
            (onclick)="onclick.emit();"></app-big-mama>

        <span class="example-spacer"></span>


        <div class="df aic" style="gap:5px">


            <!-- Incidencias -->
            <button mat-mini-fab color="primary" class="issues" [matTooltip]="'Reportar incidencia'" [go]="v.issues">
                <mat-icon [filled]="false">support_agent</mat-icon>
            </button>

            <!-- Help -->
            <button [matTooltip]="'Ayuda'" class="help onboarading-help-toolbar" mat-mini-fab color="primary"
                (click)="onboarding.open()">
                <mat-icon [filled]="false">help_outline</mat-icon>
            </button>

            <!-- Notification -->
            <app-notifications [class_]="'mr-20'" [autoUpdate]="false" [getAll]="apiS.getNotifications.bind(apiS)"
                [readAll]="apiS.readAllNotifications.bind(apiS)" [setReaded]="apiS.setNotificationReaded.bind(apiS)"
                (onClickNotification)="mc.close(); notificationAction($event)">
            </app-notifications>


            <!-- Profile -->
            <button mat-icon-button [matMenuTriggerFor]="profileMenu" class="onboarding-profile" [ngClass]="{'pulse-animation' : signingTimerComponent.isWorking}">

                <div>
                    <app-circular-letter class="absolute-center" [checkIsMe]="false" [showTooltip]="false"
                        [user]="userS.user" [showFullName]="false"></app-circular-letter>
                    <mat-icon class="mini-icon-profile-time stop-icon" [filled]="true" *ngIf="signingTimerComponent.isWorking;">stop_circle</mat-icon>
                    <!-- <mat-icon class="mini-icon-profile-time alet-icon" [filled]="true" *ngIf="signingTimerComponent.shouldSigning">warning</mat-icon> -->

                </div>

                <ng-template #loadingProfile>
                    <app-skeleton class="absolute-center" [circle]="true" [transparency]="true" [height]="34"
                        [width]="34"></app-skeleton>
                </ng-template>
            </button>

            <mat-menu #profileMenu>

                <div class="toolbar-profile-menu">

                    <div class="df fdc aic jcc">
                        <app-circular-letter [imageUpload]="true" [showTooltip]="true" [checkIsMe]="false" [showTooltip]="false" [medium]="true"
                            [user]="userS.user" [showFullName]="false" [showEditIcon]="true" (onUploadImage)="uploadImage($event)">
                        </app-circular-letter>
                        <p>{{greetingPhrase}}</p>
                    </div>

                    <div eForm-wrapper>
                        <div class="wrapper-of-wrapper">
                            <app-signing-timer #signingTimerComponent></app-signing-timer>
                        </div>
                        <p></p>
                        
                        <button mat-menu-item (click)="routerS.goTo(v.myPresence)">
                            <mat-icon>calendar_clock</mat-icon>
                            Mi presencia
                        </button>
                    </div>

                    <p></p>

                    <div eForm-wrapper>
                        <button mat-menu-item (click)="goProfile()">
                            <mat-icon>person</mat-icon>
                            Perfil
                        </button>
                        <button class="help-on-profile" mat-menu-item (click)="onboarding.open()">
                            <mat-icon>help_outline</mat-icon>
                            Ayuda
                        </button>
                        <button class="issues-on-profile" mat-menu-item (click)="routerS.goTo(v.issues)">
                            <mat-icon>support_agent</mat-icon>
                            Incidencias
                        </button>
                        <button mat-menu-item (click)="sessionS.logOutDialog()">
                            <mat-icon>logout</mat-icon>
                            Cerrar sesión
                        </button>
                    </div>
                </div>

            </mat-menu>

        </div>
    </mat-toolbar>
</div>