<div [ngClass]="!responsive.isPhone() ? 'entry-point': 'entry-point-phone'">
    <div *ngIf="displayToolbar; else router">
        <app-wifi-checker></app-wifi-checker>
        <app-onboarding #onboarding [userOnboarding]="refSercice.onboarding" [faqs]="refSercice.faqs"
            [showProgress]="false" [refService]="refSercice" (onStartSection)="menu.openAllDropdowns()">
            <mat-drawer-container [hasBackdrop]="true" (backdropClick)="menu.close()">
                <mat-drawer #drawer [mode]="'over'">
                    <div class="df jcc mt20 mb10">
                        <img class="logoMenu" src="./assets/img/logos/eina_logo.png">
                    </div>
                    <div class="menu-logo-line"></div>
                    <app-menu #phoneMenu [drawer]="drawer" [isDesktop]="false" [ngClass]="{'dn' : !responsive.isPhone(),
                                          'menu-shadow' : menu.opened && !responsive.isPhone()}"
                        [refService]="refSercice">
                    </app-menu>
                </mat-drawer>
                <mat-drawer-content class="main-drawer-content">


                    <div [ngClass]="{'entry-point-contents' : !responsive.isPhone()}">
                        <app-menu #menu [ngClass]="{'menuDesktop' : !responsive.isPhone(),
                                              'dn' : responsive.isPhone(),
                                              'menu-shadow' : menu.opened && !menu.lockmenu,
                                              'fixed' : menu.lockmenu && !responsive.isPhone()}" [isDesktop]="true"
                            [refService]="refSercice" (onclicktrigger)="toggleMenu(menu, phoneMenu)">
                        </app-menu>
                        <div class="df fdc w100">
                            <app-toolbar [onboarding]="onboarding" [mc]="menu" (onclick)="toggleMenu(menu, phoneMenu)"
                                [showMenuButton]="responsive.isPhone()"></app-toolbar>
                            <app-app-contents>
                                <div [ngTemplateOutlet]="router">
                                </div>
                            </app-app-contents>
                        </div>
                    </div>
                </mat-drawer-content>
            </mat-drawer-container>
        </app-onboarding>
    </div>
</div>

<ng-template #router>
    <router-outlet></router-outlet>
    <!-- Confetti canvas -->
    <canvas class="confetti-canvas" id="my-canvas"></canvas>
</ng-template>

<app-developer-helper #dh>
    <mat-tab-group>
        <mat-tab label="SUBSCRIPTION">
            <ng-template matTabContent>
                <div class="subscriptions">
                    <p></p>
                    <p class="fw600 fsm">TRIAL :</p>
                    <p class="df gap10" style="margin-left: 11px;">
                        <button mat-raised-button color="primary" (click)="subS.setSubscriptionStatus(trial(30))">
                            Trial 30 days remaining
                        </button>
                        <button mat-raised-button color="primary" (click)="subS.setSubscriptionStatus(trial(10))">
                            Trial 10 days remaining
                        </button>
                        <button mat-raised-button color="primary" (click)="subS.setSubscriptionStatus(trial(5))">
                            Trial 5 days remaining
                        </button>
                        <button mat-raised-button color="primary" (click)="subS.setSubscriptionStatus(trial(1))">
                            Trial 1 days remaining
                        </button>
                    </p>

                    <button mat-raised-button color="warn" (click)="subS.setSubscriptionStatus(trial_end)">
                        Trial end
                    </button>

                    <p></p>
                    <p class="fw600 fsm">PRO :</p>
                    <button mat-raised-button (click)="subS.setSubscriptionStatus(pro)" color="primary">
                        Subscribed user
                        <mat-icon class="c_y" [filled]="true">star</mat-icon>
                    </button>
                    <button mat-raised-button color="warn" (click)="subS.setSubscriptionStatus(pro_canceled)">
                        Pro subscription canceled
                    </button>
                    <button mat-raised-button color="warn" (click)="subS.setSubscriptionStatus(pro_ended)">
                        Pro subscription ended
                    </button>
                </div>
            </ng-template>
        </mat-tab>


        <mat-tab label="ROLE">
            <ng-template matTabContent>

                <p></p>
                <p class="fw600 c_r">ESTO CAMBIA EL ROL EN LA BASE DE DATOS</p>

                <p *ngIf="userS.hasRole(); else noRole">Rol actual :
                    <span class="fw600">
                        <span *ngIf="rIs(re.ADMIN)">ADMIN ({{userS.getRole()}})</span>
                        <span *ngIf="rIs(re.GESTOR)">GESTOR ({{userS.getRole()}})</span>
                        <span *ngIf="rIs(re.ADVISER)">ADVISER ({{userS.getRole()}})</span>
                        <span *ngIf="rIs(re.COMERCIAL)">COMERCIAL ({{userS.getRole()}})</span>
                        <span *ngIf="rIs(re.RECAMBISTA)">RECAMBISTA ({{userS.getRole()}})</span>
                        <span *ngIf="rIs(re.MECHANIC)">MECHANIC ({{userS.getRole()}})</span>
                    </span>
                </p>

                <ng-template #noRole>
                    <p>Usuario sin rol</p>
                </ng-template>

                <div class="df gap5">
                    <button mat-flat-button [color]="rIs(re.ADMIN) ? 'primary' : 'ice'" (click)="setRole(re.ADMIN)">ADMIN</button>
                    <button mat-flat-button [color]="rIs(re.GESTOR) ? 'primary' : 'ice'" (click)="setRole(re.GESTOR)">GESTOR</button>
                </div>
                <p></p>
                <div class="df gap5">
                    <button mat-flat-button [color]="rIs(re.ADVISER) ? 'primary' : 'ice'" (click)="setRole(re.ADVISER)">ASESOR</button>
                    <button mat-flat-button [color]="rIs(re.COMERCIAL) ? 'primary' : 'ice'" (click)="setRole(re.COMERCIAL)">COMERCIAL</button>
                    <button mat-flat-button [color]="rIs(re.RECAMBISTA) ? 'primary' : 'ice'" (click)="setRole(re.RECAMBISTA)">RECAMBISTA</button>
                    <button mat-flat-button [color]="rIs(re.MECHANIC) ? 'primary' : 'ice'" (click)="setRole(re.MECHANIC)">MECÁNICO</button>
                </div>
            </ng-template>
        </mat-tab>

        <mat-tab label="MODULES">
            <ng-template matTabContent>
                <p>Módulos del usuario :</p>
                <p *ngFor="let m of allModules">
                    <mat-slide-toggle *ngIf="companyS.company" [checked]="companyS.company.hasModule(m[1])"
                        (change)="onChangeModule(m[1])">
                        {{m[0]}}
                    </mat-slide-toggle>
                </p>
            </ng-template>
        </mat-tab>

        <!-- <mat-tab label="COMPANY">
            <mat-slide-toggle *ngIf="companyS.company" [checked]="companyS.companyMissingInfo"
                (change)="companyS.company.missing_info = !companyS.company.missing_info">
                Falta información de empresa
            </mat-slide-toggle>
        </mat-tab> -->

        <mat-tab label="FRONTEND DOCUMENTATION">
            <p>Para acceder a la documentación, escribe alguna de las siguientes palabras:</p>
            <p *ngFor="let k of keyWorkds">
                • {{k}}
            </p>
        </mat-tab>

        <mat-tab label="LOCAL STORAGE">
            <ng-template matTabContent>
                <div *ngFor="let kv of items">
                    <p class="fw600 nmb">{{kv[0]}}</p>
                    <p>{{kv[1]}}</p>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="ENDPOINTS">
            <lib-endpoint-viewer [er]="dh.endpointResponses"></lib-endpoint-viewer>
        </mat-tab>
        <mat-tab label="ERRORS">
            <lib-error-viewer [er]="dh.errors"></lib-error-viewer>
        </mat-tab>
    </mat-tab-group>
</app-developer-helper>

<app-entry-point-listener></app-entry-point-listener>