<app-card [noStyle]="true">
  <form [formGroup]="form" eForm>

    <!-- TALLER -->
    <app-card-title>Información de taller</app-card-title>
    <div eForm-section>
      <mat-form-field appearance="outline">
        <mat-label>Plazas taller</mat-label>
        <input type="number" matInput placeholder="Plazas taller" formControlName="places">
        <button prevent type="button" mat-icon-button matSuffix
          [matTooltip]="'Indica la cantidad de espacios físicos libres en su taller. De esta manera, podrás saber si hay capacidad para aceptar más vehículos en su agenda.'">
          <mat-icon>info</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Vehículo por defecto</mat-label>
        <mat-select formControlName="default_vehicle" #typeInput (selectionChange)="vehicleChange($event)">
          <mat-option [value]="1">Moto</mat-option>
          <mat-option [value]="2">Coche</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Precio por hora</mat-label>
        <input #input type="number" matInput placeholder="Precio por hora trabajada" formControlName="price_hour">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>IVA</mat-label>
        <mat-select formControlName="iva">
          <mat-option [value]="0">Sin IVA</mat-option>
          <mat-option [value]="10">10%</mat-option>
          <mat-option [value]="21">21%</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="mt40"></div>

    <!-- CITAS -->
    <app-card-title>Configuración de citas de taller</app-card-title>

    <ng-container *ngIf="center && center.urlToken && center.url">
      <app-card-subtitle [first]="true">Web cita previa</app-card-subtitle>
      <p class="taj c_t2">A través de este enlace, tus clientes podrán pedir cita previa en tu taller:</p>
      <div class="df fww aic">
        <div class="appoitments_web">
          <a [href]="center.url" [target]="'_blank'" [matTooltip]="'Visitar enlace'"
            (click)="goClientSideAppointments()">{{center.url}}</a>
          <div class="df mla mra">
            <button mat-icon-button [color]="'primary'" [matTooltip]="'Copiar enlace'" (click)="copyToClippboard()">
              <mat-icon>content_copy</mat-icon>
            </button>
            <button mat-icon-button [color]="'primary'" [matTooltip]="'Ver QR'" (click)="openQRDialog(center.url)">
              <mat-icon>qr_code</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <!--INPUTS DE HORA ENTRADA/SALIDA-->
    <div class="form df fww fdc">
      <app-card-subtitle>Horario</app-card-subtitle>
      <div eForm-section>
        <mat-form-field appearance="outline">
          <mat-label>Apertura (mañana)</mat-label>
          <mat-select #select1 formControlName="morning_work_schedule_start" required
            (selectionChange)="inputChanged($event, 0)">
            <div *ngFor="let time of COMPANY_HOURS[0]">
              <mat-option [value]="time.getValue()">{{
                time.getText()
                }}</mat-option>
            </div>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Cierre (mañana)</mat-label>
          <mat-select #select2 formControlName="morning_work_schedule_finish" required
            (selectionChange)="inputChanged($event, 1)">
            <div *ngFor="let time of COMPANY_HOURS[1]">
              <mat-option [value]="time.getValue()">{{time.getText()}}</mat-option>
            </div>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Apertura (tarde)</mat-label>
          <mat-select #select3 formControlName="afternoon_work_schedule_start"
            (selectionChange)="inputChanged($event, 2)">
            <mat-option>Sin establecer</mat-option>
            <div *ngFor="let time of COMPANY_HOURS[2]">
              <mat-option [value]="time.getValue()">{{
                time.getText()
                }}</mat-option>
            </div>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Cierre (tarde)</mat-label>
          <mat-select #select4 formControlName="afternoon_work_schedule_finish"
            (selectionChange)="inputChanged($event, 3)">
            <mat-option>Sin establecer</mat-option>
            <div *ngFor="let time of COMPANY_HOURS[3]">
              <mat-option [value]="time.getValue()">{{
                time.getText()
                }}</mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </div>

      <!--MARGEN -->
      <app-card-subtitle>Intervalos, margen de días y número máximo de citas por día</app-card-subtitle>

      <div eForm-section>

        <mat-form-field appearance="outline">
          <mat-label>Margen de días</mat-label>
          <mat-select [value]="1" formControlName="range_of_days">
            <mat-option [value]="0">Sin margen</mat-option>
            <mat-option [value]="1">1 día</mat-option>
            <mat-option [value]="2">2 días</mat-option>
            <mat-option [value]="3">3 días</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Máximo de citas/día</mat-label>
          <mat-select [value]="1" formControlName="total_appointment">
            <mat-option [value]="0">Sin margen</mat-option>
            <mat-option [value]="1">1 cita</mat-option>
            <mat-option [value]="2">2 citas</mat-option>
            <mat-option [value]="3">3 citas</mat-option>
            <mat-option [value]="4">4 citas</mat-option>
            <mat-option [value]="5">5 citas</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Intervalo para pedir cita</mat-label>
          <mat-select [value]="5" formControlName="granularity">
            <mat-option [value]="5">5 minutos</mat-option>
            <mat-option [value]="10">10 minutos</mat-option>
            <mat-option [value]="15">15 minutos</mat-option>
            <mat-option [value]="20">20 minutos</mat-option>
            <mat-option [value]="25">25 minutos</mat-option>
            <mat-option [value]="30">30 minutos</mat-option>
          </mat-select>
        </mat-form-field>

      </div>
    </div>

  </form>
</app-card>