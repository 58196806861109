
export class M_QuantsProduct {

    id: number;
    quant_stored : number; //  Cantidad en almacen
    quant_engaged : number; // Cantidad de unidades en camino / pedida
    quant_fault : number; // Cnatidad de la falta
    quant_pend: number; // Cantidad pendiente --> No tiene porque estar pedido

    constructor(d:any){
        this.id = d.id;
        this.quant_stored = d.quant_stored;
        this.quant_engaged = d.quant_engaged;
        this.quant_fault = d.quant_fault;
        this.quant_pend = d.quant_pend;
    }

}